import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import MissionDataService from '../../services/mission.service';

export default function MissionList(props) {
    const [data, setData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [tabClass, setTabClass] = useState('list-container-divider-1');
    const currentUser = useAuthState();

    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );

    useEffect(() => {
        let key = 'SELECT Mission WHERE status IN ("ongoing", "pending")';

        const fields =
            'id, name, status, display_status, start_date, end_date, je, price_ht, company_id_friendlyname';
        MissionDataService.getAll(key, fields).then(response => {
            setData(response);
        });
    }, []);

    const handleAction = (e, id) => {
        setCurrentIndex(id);
    };

    const handleTab = className => {
        setTabClass(className);
    };

    const columns = [
        {
            name: 'Référence',
            cell: row => (
                <Link className='list-table-link' to={'/mission/' + row.id + '/profil'}>
                    {row.ref}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.ref < b.ref) {
                    return -1;
                }
                if (a.ref > b.ref) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Etudiant',
            cell: row => (
                <Link className='list-table-link' to={'/etudiant/' + row.student_id + '/profil'}>
                    {row.student}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.student < b.student) {
                    return -1;
                }
                if (a.student > b.student) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Entreprise',
            cell: row => (
                <Link className='list-table-link' to={'/entreprise/' + row.company_id + '/profil'}>
                    {row.company}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.company < b.company) {
                    return -1;
                }
                if (a.company > b.company) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Association',
            cell: row => (
                <Link
                    className='list-table-link'
                    to={'/association/' + row.student_org_id + '/profil'}>
                    {row.student_org_name}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.student_org_name < b.student_org_name) {
                    return -1;
                }
                if (a.student_org_name > b.student_org_name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Statut',
            selector: row => 'Statut fictif',
            filterable: true,
            sortable: true,
        },
    ];

    const ExpandedMission = ({ data }) => (
        <pre>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Titre mission : </span>
                    <span className='list-mission-value'>{data.name}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Tarif JE HT : </span>
                    <span className='list-mission-value'>{data.price_ht} €</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Total JE : </span>
                    <span className='list-mission-value'>{data.je}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Nombre d'échéances : </span>
                    <span className='list-mission-value'>{data.terms_list.length}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Association : </span>
                    <span className='list-mission-value'>
                        {data && data.student_org_id > 0 ? (
                            <Link
                                className='list-table-link'
                                to={'/association/' + data.student_org_id + '/profil'}>
                                {data.student_org_name}
                            </Link>
                        ) : (
                            "Pas d'association"
                        )}
                    </span>
                </div>
            </div>
        </pre>
    );

    return (
        <div className='backend-container'>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>Missions</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4 list-container-tabs'>
                    <Link
                        to='/mission/list/ongoing'
                        className='list-tabs'
                        onMouseOver={() => handleTab('list-container-divider-1')}>
                        En cours
                    </Link>
                    <Link
                        to='/mission/list/completed'
                        className='list-tabs list-second-tab'
                        onMouseOver={() => handleTab('list-container-divider-2')}
                        onMouseOut={() => handleTab('list-container-divider-1')}>
                        Terminées
                    </Link>
                </div>
                <div className='list-flex-child list-flex-container'>
                    <div className='list-container-searchbar'>
                        <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                        <input
                            id='search'
                            className='list-container-searchinput'
                            type='text'
                            placeholder='Rechercher'
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                        />
                    </div>
                    <div className='list-container-addbutton'>
                        <span className='list-container-addlabel'>
                            <Link to='/mission/list/ongoing'>+</Link>
                        </span>
                    </div>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className={tabClass} />
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child'>
                    <p className='list-container-subtitle list-tab-subtitle'>
                        Liste de toutes les missions en cours sur le réseau Jobers.
                    </p>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucune mission trouvée.</span>
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={ExpandedMission}
                    />
                    {currentIndex > 0 && (
                        <Navigate to={'/association/' + currentIndex} replace={true} />
                    )}
                </div>
            </div>
        </div>
    );
}
