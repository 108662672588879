import axios from 'axios';
export default axios.create({
    method: 'post',
    auth: {
        username: 'admin',
        password: 'Jobers123*$',
    },
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
    },
});
