import './RegisterCompanyForm.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import Logo from '../Logo/Logo';
import Form from '../Utils/Form';
import Input from '../Utils/Input';
import SelectCountry from '../Utils/SelectCountry';

export default function RegisterCompanyForm(props) {
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });

    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    const onSubmit = async (fields, errors) => {
        setFormData({
            fields,
            errors,
        });

        let dataFields = {
            name: fields.name.value,
            domain: fields.domain.value,
            siret: fields.siret.value,
            picture: null,
            address: fields.address.value,
            postal_code: fields.postcode.value,
            city: fields.city.value,
            country: fields.country.value,
            phone: fields.phonenumber.value,
            email: fields.email.value,
            password: fields.password.value,
            contact_last_name: fields.contact_last_name.value,
            contact_first_name: fields.contact_first_name.value,
            contact_email: fields.contact_email.value,
            contact_phone: fields.contact_phone.value,
            legal_last_name: fields.legal_last_name.value,
            legal_first_name: fields.legal_first_name.value,
            // legal_email: fields.legal_email.value,
        };

        // if (typeof newCompanyInfo.logo[0] !== 'undefined') {
        //     let data = await convertBase64(newCompanyInfo.logo[0]);
        //     data = data.substring(data.indexOf('base64,') + 7);

        //     dataFields.picture = {
        //         data: data,
        //         filename: newCompanyInfo.logo[0].name,
        //         mimetype: newCompanyInfo.logo[0].type,
        //     };
        // }

        let data = new URLSearchParams();
        data.append('operation', 'create_company');
        data.append('data', JSON.stringify(dataFields));

        var config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        let response = await axios(config);

        if (response.data.code !== 0) {
            console.log(response.data.message);
        }

        if (response.data.code === 0) {
            setSubmitted(true);
        }
    };

    // const [newCompanyInfo, setNewCompanyInfo] = useState({
    //     logo: {},
    // });

    // const updateUploadedFiles = files => {
    //     setNewCompanyInfo({ ...newCompanyInfo, logo: files });
    // };

    const RegisterCompanyForm = props => {
        return (
            <div className='register-organization screen'>
                <Link to='/register'>
                    <img
                        className='arrow-left'
                        alt='go-back'
                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3dde03eda8a1cb03a287a/img/arrow-left@2x.svg'
                    />
                </Link>
                <Logo />
                <div className='register-organization-title'>Informations de l’entreprise</div>
                <Form
                    className='form'
                    onSubmit={onSubmit}
                    fields={formData.fields}
                    errors={formData.errors}
                    validate={true}
                    validateOnSubmit={true}>
                    <div className='contact-panel'>
                        <div className='contact-title'>Informations de connexion</div>
                        <div className='login-divider'></div>
                    </div>
                    <Input
                        id='email'
                        label='Adresse mail de connexion'
                        placeholder='contact@agence.fr'
                        validate='email|required'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box border-1px-mischka inter-normal-pale-sky-16px',
                        }}
                    />
                    <Input
                        id='password'
                        label='Mot de passe'
                        placeholder='•••••••••'
                        validate='password|required'
                        type='password'
                        hintmessage='Doit être au moins de 8 caractères et avoir un caractère spécial (@$!%*#?&)'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box inter-normal-pale-sky-16px',
                        }}
                    />
                    <Input
                        id='confirm_password'
                        name='confirm_password'
                        label='Confirmez mot de passe'
                        placeholder='•••••••••'
                        type='password'
                        validate='required|confirm_password'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box inter-normal-pale-sky-16px',
                        }}
                    />
                    <div className='contact-panel'>
                        <div className='contact-title'>Informations de l'entreprise</div>
                        <div className='login-divider'></div>
                    </div>
                    <Input
                        id='name'
                        label='Raison sociale'
                        placeholder='Raison sociale'
                        validate='required'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box border-1px-mischka inter-normal-pale-sky-16px',
                        }}
                    />
                    <div className='input-field'>
                        <Input
                            id='domain'
                            label='Domaine'
                            type='select'
                            placeholder='Architecture'
                            value='architecture'
                            validate='required'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                            options={[
                                { value: 'architecture', label: 'Architecture' },
                                { value: 'paysage', label: 'Paysage' },
                                { value: 'commerce', label: 'Commerce' },
                                { value: 'communication', label: 'Communication' },
                                { value: 'ingenierie', label: 'Ingenierie' },
                            ]}
                        />
                        <span id='domain_tooltip'>
                            <div className='icon-size'>
                                <img
                                    className='icon-img'
                                    alt='go-back'
                                    src='/img/10_QUESTION_MARK.svg'
                                />
                            </div>
                            <Tooltip
                                anchorId='domain_tooltip'
                                content='Nous vous mettrons en contact avec les étudiants de votre domaine d’expertise !'
                                place='left'
                                style={{
                                    backgroundColor: '#fff',
                                    color: 'gray',
                                    width: '20vw',
                                    border: '1px solid #ccc',
                                    'margin-top': '10px',
                                    'margin-left': '-30px',
                                    'border-radius': '8px',
                                    'font-family': 'Mont-Book',
                                    'font-size': '11pt',
                                    'letter-spacing': 0,
                                }}
                            />
                        </span>
                    </div>
                    <Input
                        id='siret'
                        label='Numéro de SIRET'
                        placeholder='123 568 941 00056'
                        validate='required'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box border-1px-mischka inter-normal-pale-sky-16px',
                        }}
                    />
                    <Input
                        id='phonenumber'
                        label='Numéro de téléphone principal'
                        placeholder='+33 6 11 22 33 44'
                        validate='phone|required'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box border-1px-mischka inter-normal-pale-sky-16px',
                        }}
                    />
                    <div className='contact-container'>
                        <div className='contact-panel'>
                            <div className='contact-title'>Adresse</div>
                            <div className='login-divider'></div>
                        </div>
                        <Input
                            id='address'
                            label='Adresse'
                            placeholder='Place de l’Hôtel-de-Ville'
                            validate='required'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <div className='div-2-col'>
                            <Input
                                id='city'
                                label='Ville'
                                placeholder='Paris'
                                validate='required'
                                classes={{
                                    contClass: 'input-col-1',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-1col border-1px-mischka inter-normal-mirage-16px',
                                }}
                            />
                            <Input
                                id='postcode'
                                label='Code postal'
                                placeholder='75004'
                                validate='required'
                                classes={{
                                    contClass: 'input-col-1',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-1col border-1px-mischka inter-normal-mirage-16px',
                                }}
                            />
                        </div>
                        <SelectCountry
                            id='country'
                            label='Pays'
                            validate='required'
                            value='France'
                            defaultValue='France'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass: 'input-box border-1px-mischka inter-normal-mirage-16px',
                            }}
                        />
                    </div>
                    <div className='contact-container'>
                        <div className='contact-panel'>
                            <div className='contact-title'>Représentant légal</div>
                            <div className='login-divider'></div>
                        </div>
                        <div className='div-2-col'>
                            <Input
                                id='legal_last_name'
                                label='Nom'
                                placeholder='Doe'
                                validate='required'
                                classes={{
                                    contClass: 'input-col-1',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-1col border-1px-mischka inter-normal-mirage-16px',
                                }}
                            />
                            <Input
                                id='legal_first_name'
                                label='Prénom'
                                placeholder='John'
                                validate='required'
                                classes={{
                                    contClass: 'input-col-1',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-1col border-1px-mischka inter-normal-mirage-16px',
                                }}
                            />
                        </div>
                        <div className='contact-panel'>
                            <div className='contact-title'>Personne de contact (Facultatif)</div>
                            <div className='login-divider'></div>
                            <span id='contact_tooltip'>
                                <div className='icon-size'>
                                    <img
                                        className='icon-img'
                                        alt='go-back'
                                        src='/img/10_QUESTION_MARK.svg'
                                    />
                                </div>
                                <Tooltip
                                    anchorId='contact_tooltip'
                                    content='Si vous souhaitez préciser une personne de contact, nous communiquerons avec vous grâce à ces coordonnées'
                                    place='left'
                                    style={{
                                        backgroundColor: '#fff',
                                        color: 'gray',
                                        width: '20vw',
                                        border: '1px solid #ccc',
                                        'margin-top': '10px',
                                        'margin-left': '-30px',
                                        'border-radius': '8px',
                                        'font-family': 'Mont-Book',
                                        'font-size': '11pt',
                                        'letter-spacing': 0,
                                    }}
                                />
                            </span>
                        </div>
                        <div className='div-2-col'>
                            <Input
                                id='contact_last_name'
                                label='Nom'
                                placeholder='Doe'
                                classes={{
                                    contClass: 'input-col-1',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-1col border-1px-mischka inter-normal-mirage-16px',
                                }}
                            />
                            <Input
                                id='contact_first_name'
                                label='Prénom'
                                placeholder='John'
                                classes={{
                                    contClass: 'input-col-1',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-1col border-1px-mischka inter-normal-mirage-16px',
                                }}
                            />
                        </div>
                        <Input
                            id='contact_email'
                            label='Adresse mail comptable'
                            placeholder='contact@agence.fr'
                            validate='email_equal'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='contact_phone'
                            label='Téléphone comptable'
                            placeholder='06 11 22 33 44'
                            validate='phone'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <div className='checkbox-group-item'>
                            <div className='checkbox'>
                                <div className='checkbox-1'>
                                    <input
                                        type='checkbox'
                                        required='required'
                                        className='checkbox-base checkbox-round border-1px-mischka'
                                        id='accept_checkbox'
                                    />
                                </div>
                                <label
                                    className='inter-medium-oxford-blue-14px'
                                    htmlFor='accept_checkbox'>
                                    J’accepte la gestion et le traitements de mes informations.
                                </label>
                            </div>
                        </div>
                        <div className='bottom-actions'>
                            <button type='submit' className='primary-submit'>
                                Valider l'inscription
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    };

    const RegisterCompanyConfirmation = props => {
        return (
            <div className='register-organization-submit screen'>
                <div className='content'>
                    <div className='header'>
                        <Logo />
                        <div className='text-and-supporting-text'>
                            <div className='text'>Merci pour votre message</div>
                            <p className='supporting-text'>
                                Nous reviendrons vers vous dès que possible
                            </p>
                        </div>
                    </div>
                    <Link to='/'>
                        <button type='submit' className='primary-submit'>
                            Revenir à l'accueil
                        </button>
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <div className='container-center-horizontal'>
            {submitted ? <RegisterCompanyConfirmation /> : <RegisterCompanyForm />}
        </div>
    );
}
