import React from 'react';
import { Link } from 'react-router-dom';
import Gear from '../Icon/Gear';
import './Sidebar.css';

const Parameter = () => {
    const name = 'Paramètres';

    return (
        <div
            className={
                window.location.pathname === '/parameter'
                    ? 'content-parameter content-parameter-active'
                    : 'content-parameter'
            }>
            <div>
                <Gear />
            </div>
            <Link to='/parameter' className='parameter'>
                {name}
            </Link>
        </div>
    );
};

export default Parameter;
