import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import Form from '../Utils/Form';
import Input2 from '../Utils/Input2';
import moment from 'moment';
import axios from 'axios';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import OrganizationDataService from '../../services/organization.service';

export default function JobersRate(props) {
    const currentUser = useAuthState();
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });
    const [initialObjectState, setInitialObjectState] = useState(false);
    const [loading, setLoading] = useState(true);
    const [associations, setAssociations] = useState([]);
    const [assoId, setAssoId] = useState('0');
    const { association_id } = useParams();

    useEffect(() => {
        OrganizationDataService.getAll(
            'SELECT Association',
            'id, friendlyname, school_name, started_at'
        )
            .then(response => {
                setAssociations(response);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des associations :', error);
                setLoading(false);
            });
    }, [loading]);

    const onSubmit = async (fields, errors) => {
        setFormData({
            fields,
            errors,
        });

        let aDataFields = {};
        // add each id in fields to the to aDataFields object as property
        Object.keys(fields).forEach(function (key) {
            aDataFields[key] = fields[key].value;
        });

        let data = new URLSearchParams();
        data.append('operation', 'set_asso_rate');
        data.append('data', JSON.stringify(aDataFields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        let response = await axios(config);

        if (response.data.code === 0) {
            window.location.reload(false);
        }

        if (response.data.code !== 0) {
            console.log(response.data.message);
        }
    };

    const fetchObject = async () => {
        let objectData = {
            association: association_id,
            smic_h: '0',
            asso_tax: '0',
            csg_d_e: '0',
            csg_nd_e: '0',
            crds_e: '0',
            ass_mal_p: '0',
            csa_p: '0',
            ass_vieil_d_p: '0',
            ass_vieil_d_e: '0',
            ass_vieil_p_p: '0',
            ass_vieil_p_e: '0',
            all_f_p: '0',
            fnal_p: '0',
            ass_cho_p: '0',
            ags_p: '0',
            con_org_syn_p: '0',
            acc_t_p: '0',
        };

        if (association_id == 99) {
            objectData.reg_loc_als_mos = '0';
        }

        if (objectData.association !== '0') {
            let config = {
                method: 'get',
                url:
                    'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=get_asso_rate&asso_id=' +
                    objectData.association,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json',
                },
            };

            let response = await axios(config);

            if (response.data.code === 0) {
                Object.keys(response.data.data).forEach(function (key) {
                    // if the key is in the objectData object
                    objectData[key] = response.data.data[key];
                });
            }

            if (response.data.code !== 0) {
                console.log(response.data.message);
            }
        }
        setInitialObjectState(objectData);
    };

    useEffect(() => {
        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    if (currentUser.function == 'Jobers')
        return (
            <div className='backend-container'>
                <Sidebar />
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4'>
                        <h1 className='list-container-title'>Cotisations sociales</h1>
                    </div>
                </div>
                {initialObjectState && (
                    <Form
                        onSubmit={onSubmit}
                        fields={formData.fields}
                        errors={formData.errors}
                        validateOnSubmit={true}>
                        {loading == false && (
                            <div className='list-flex-container'>
                                <Input2
                                    id='association'
                                    label='Association'
                                    type='select'
                                    placeholder='Sélectionner une association'
                                    classes={{
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    }}
                                    options={
                                        loading
                                            ? [{ value: '', label: 'Chargement...' }]
                                            : [
                                                  {
                                                      value: '0',
                                                      label: '--- Choisir une association ---',
                                                  },
                                                  ...associations.map(asso => ({
                                                      value: asso.id,
                                                      label: asso.name,
                                                  })),
                                              ]
                                    }
                                    value={
                                        typeof association_id !== 'undefined' ? association_id : '0'
                                    }
                                    events={{
                                        onChange: e => {
                                            // navigate('/rates/' + e.value);
                                            window.location.href =
                                                'https://app.jobers.fr/rates/' + e.value;
                                        },
                                    }}
                                />
                                <Input2
                                    id='smic_h'
                                    label='SMIC Horaire'
                                    type='text'
                                    placeholder='11.27'
                                    classes={{
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    }}
                                    value={initialObjectState.smic_h}
                                />
                            </div>
                        )}
                        <div className='list-flex-container'>
                            <Input2
                                id='asso_tax'
                                label='Commission Association'
                                type='text'
                                placeholder='0.0680'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.asso_tax}
                            />
                            <Input2
                                id='csg_d_e'
                                label='CSG déductible'
                                type='text'
                                placeholder='11.27'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.csg_d_e}
                            />
                        </div>
                        <div className='list-flex-container'>
                            <Input2
                                id='csg_nd_e'
                                label='CSG non déductible'
                                type='text'
                                placeholder='0.0680'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.csg_nd_e}
                            />
                            <Input2
                                id='crds_e'
                                label='CRDS'
                                type='text'
                                placeholder='11.27'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.crds_e}
                            />
                        </div>
                        <div className='list-flex-container'>
                            <Input2
                                id='ass_mal_p'
                                label='Ass. maladie'
                                type='text'
                                placeholder='0.0680'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.ass_mal_p}
                            />
                            <Input2
                                id='csa_p'
                                label='Complément Ass. maladie'
                                type='text'
                                placeholder='11.27'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.csa_p}
                            />
                        </div>
                        <div className='list-flex-container'>
                            <Input2
                                id='ass_vieil_d_p'
                                label='Ass. vieillesse déplafonnée (p)'
                                type='text'
                                placeholder='0.0680'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.ass_vieil_d_p}
                            />
                            <Input2
                                id='ass_vieil_d_e'
                                label='Ass. vieillesse déplafonnée (e)'
                                type='text'
                                placeholder='11.27'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.ass_vieil_d_e}
                            />
                        </div>
                        <div className='list-flex-container'>
                            <Input2
                                id='ass_vieil_p_p'
                                label='Ass. vieillesse plafonnée (p)'
                                type='text'
                                placeholder='0.0680'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.ass_vieil_p_p}
                            />
                            <Input2
                                id='ass_vieil_p_e'
                                label='Ass. vieillesse plafonnée (e)'
                                type='text'
                                placeholder='11.27'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.ass_vieil_p_e}
                            />
                        </div>
                        <div className='list-flex-container'>
                            <Input2
                                id='acc_t_p'
                                label='Accident du travail'
                                type='text'
                                placeholder='0.0680'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.acc_t_p}
                            />
                            <Input2
                                id='all_f_p'
                                label='Allocations familliales'
                                type='text'
                                placeholder='11.27'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.all_f_p}
                            />
                        </div>
                        <div className='list-flex-container'>
                            <Input2
                                id='fnal_p'
                                label='F.N.A.L'
                                type='text'
                                placeholder='11.27'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.fnal_p}
                            />
                            <Input2
                                id='ass_cho_p'
                                label='Assurance chômage'
                                type='text'
                                placeholder='0.0680'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.ass_cho_p}
                            />
                        </div>
                        <div className='list-flex-container'>
                            <Input2
                                id='ags_p'
                                label='AGS'
                                type='text'
                                placeholder='11.27'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.ags_p}
                            />
                            <Input2
                                id='con_org_syn_p'
                                label='Contribution organisations syndicales'
                                type='text'
                                placeholder='0.0680'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                value={initialObjectState.con_org_syn_p}
                            />
                        </div>
                        {initialObjectState && initialObjectState.association == 99 && (
                            <div className='list-flex-container'>
                                <Input2
                                    id='reg_loc_als_mos'
                                    label='Régime local Alsace Moselle'
                                    type='text'
                                    placeholder='0.0130'
                                    classes={{
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    }}
                                    value={initialObjectState.reg_loc_als_mos}
                                />
                                <div class='list-flex-child profil-flex-container'></div>
                            </div>
                        )}
                        {initialObjectState && initialObjectState.association > 0 && (
                            <div className='list-flex-container'>
                                <div className='profil-container-submitbutton new-mission-submitbutton'>
                                    <span className='list-container-submitlabel'>
                                        <button type='submit' className='primary-submit'>
                                            Valider les taux
                                        </button>
                                    </span>
                                </div>
                            </div>
                        )}
                    </Form>
                )}
            </div>
        );
}
