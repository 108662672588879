import http from '../http-common';
import vars from '../config/vars';
const getAll = async (key, fields) => {
    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'DocumentNote',
        output_fields:
            'id, created_at, org_id, org_name, name, mission_id, mission_type, mission_id_student_id, mission_id_student_firstname, mission_id_student_name, documenttype_name, status, description',
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if (response.data.code == '0' && response.data.objects) {
        Object.keys(response.data.objects).forEach(key => {
            let currentObject = {};
            currentObject.id = response.data.objects[key].fields.id;
            currentObject.created_at = response.data.objects[key].fields.created_at;
            currentObject.name = response.data.objects[key].fields.name;
            currentObject.org_id = response.data.objects[key].fields.org_id;
            currentObject.org_name = response.data.objects[key].fields.org_name;
            currentObject.mission_id = response.data.objects[key].fields.mission_id;
            currentObject.mission_type = response.data.objects[key].fields.mission_type;
            currentObject.mission_id_student_id =
                response.data.objects[key].fields.mission_id_student_id;
            currentObject.mission_id_student_firstname =
                response.data.objects[key].fields.mission_id_student_firstname;
            currentObject.mission_id_student_name =
                response.data.objects[key].fields.mission_id_student_name;
            currentObject.documenttype_name = response.data.objects[key].fields.documenttype_name;
            currentObject.status = response.data.objects[key].fields.status;
            currentObject.description =
                response.data.objects[key].fields.description !== ''
                    ? JSON.parse(response.data.objects[key].fields.description)
                    : [];
            currentObject.description.name = response.data.objects[key].fields.name;
            objects.push(currentObject);
        });
    }

    // sort objects by created_at
    objects.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    });

    return objects;
};
const get = async key => {
    let returnValue = {};

    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'DocumentNote',
        output_fields:
            'id, created_at, org_id, org_name, name, mission_id, mission_type, mission_id_student_id, mission_id_student_firstname, mission_id_student_name, documenttype_name, status, description',
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        Object.keys(response.data.objects).forEach(key => {
            returnValue.id = response.data.objects[key].fields.id;
            returnValue.created_at = response.data.objects[key].fields.created_at;
            returnValue.name = response.data.objects[key].fields.name;
            returnValue.org_id = response.data.objects[key].fields.org_id;
            returnValue.org_name = response.data.objects[key].fields.org_name;
            returnValue.mission_id = response.data.objects[key].fields.mission_id;
            returnValue.mission_type = response.data.objects[key].fields.mission_type;
            returnValue.mission_id_student_id =
                response.data.objects[key].fields.mission_id_student_id;
            returnValue.mission_id_student_firstname =
                response.data.objects[key].fields.mission_id_student_firstname;
            returnValue.mission_id_student_name =
                response.data.objects[key].fields.mission_id_student_name;
            returnValue.documenttype_name = response.data.objects[key].fields.documenttype_name;
            returnValue.status = response.data.objects[key].fields.status;
            returnValue.description =
                response.data.objects[key].fields.description !== ''
                    ? JSON.parse(response.data.objects[key].fields.description)
                    : [];
            returnValue.description.name = response.data.objects[key].fields.name;
        });
    }

    return returnValue;
};
const DocumentDataService = {
    getAll,
    get,
};
export default DocumentDataService;
