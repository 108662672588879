import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import axios from 'axios';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import MissionDataService from '../../services/mission.service';

export default function OffreList(props) {
    const [data, setData] = useState([]);
    const [editMode, setEditMode] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const currentUser = useAuthState();
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );

    useEffect(() => {
        let key =
            'SELECT Mission AS M WHERE student_id = 0 AND (private_students = "[]" OR private_students = "" OR private_students LIKE "%' +
            currentUser.userDetails +
            '%") AND M.id NOT IN (SELECT Mission AS M2 JOIN lnkMissionToStudent AS lnk ON lnk.mission_id = M2.id WHERE lnk.student_id = ' +
            currentUser.id +
            ')';

        /*
        if (currentUser.function === 'Compte entreprise') {
            key = 'SELECT Mission WHERE company_id = ' + currentUser.org_id;
        }
        if (currentUser.function === 'Président') {
            key =
                'SELECT Mission AS M JOIN Person AS P ON M.student_id = P.id WHERE P.org_id = ' +
                currentUser.org_id;
        }
        */

        const fields =
            'id, name, status, display_status, start_date, end_date, je, price_ht, company_id_friendlyname';
        MissionDataService.getAll(key, fields).then(response => {
            setData(response);
        });
    }, []);

    // const apply = (student_id, mission_id) => {
    //     let aDataFields = {
    //         mission_id: mission_id,
    //         student_id: currentUser.id,
    //     };

    //     let data = new URLSearchParams();
    //     data.append('operation', 'apply_to_mission');
    //     data.append('data', JSON.stringify(aDataFields));

    //     let config = {
    //         method: 'post',
    //         url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
    //         headers: {
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //             Accept: 'application/json',
    //         },
    //         data: data,
    //     };

    //     axios(config)
    //         .then(function (response) {
    //             if (response.data.code === 0) {
    //                 window.location.reload(false);
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // };

    const convertArrayOfObjectsToCSV = array => {
        let result;
        let exportableFields = [
            'created_at',
            'company',
            'city',
            'domain',
            'start_date',
            'end_date',
        ];
        let translatedFields = [
            'Date de publication',
            'Entreprise',
            'Ville',
            'Domaine',
            'Date de début',
            'Durée',
        ];

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = exportableFields;

        result = '';
        result += translatedFields.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                let value = item[key];
                if (key === 'start_date' || key === 'end_date' || key === 'created_at') {
                    value = moment(item[key]).format('DD-MM-YYYY');
                }
                if (key === 'domain') {
                    console.log(item);
                    value =
                        item[key] != ''
                            ? item[key].charAt(0).toUpperCase() + item[key].slice(1)
                            : '';
                }
                if (key === 'end_date') {
                    value =
                        Math.floor(
                            moment
                                .duration(
                                    moment(item['end_date'], 'YYYY/MM/DD').diff(
                                        moment(item['start_date'], 'YYYY/MM/DD')
                                    )
                                )
                                .asDays()
                        ) + ' jours';
                }

                result += value;

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    let columns = [
        {
            name: 'Date de publication',
            selector: row => moment(row.created_at).format('DD-MM-YYYY'),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.created_at < b.created_at) {
                    return -1;
                }
                if (a.created_at > b.created_at) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Entreprise',
            cell: row =>
                currentUser.function == 'Jobers' ? (
                    <Link
                        className='list-table-link'
                        to={'/entreprise/' + row.company_id + '/profil'}>
                        {row.company}
                    </Link>
                ) : (
                    <span>{row.company}</span>
                ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.company < b.company) {
                    return -1;
                }
                if (a.company > b.company) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Ville',
            selector: row => row.city,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Domaine',
            selector: row => row.domain && row.domain.charAt(0).toUpperCase() + row.domain.slice(1),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Date de début',
            selector: row => moment(row.start_date).format('DD-MM-YYYY'),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.start_date < b.start_date) {
                    return -1;
                }
                if (a.start_date > b.start_date) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Durée',
            selector: row =>
                Math.floor(
                    moment
                        .duration(
                            moment(row.end_date, 'YYYY/MM/DD').diff(
                                moment(row.start_date, 'YYYY/MM/DD')
                            )
                        )
                        .asDays()
                ) + ' jours',
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.end_date < b.end_date) {
                    return -1;
                }
                if (a.end_date > b.end_date) {
                    return 1;
                }
                return 0;
            },
        },
    ];

    // if (currentUser.function === 'Student') {
    columns.push({
        name: 'Actions',
        cell: row => (
            <Link className='list-table-link' to={'/mission/' + row.id + '/profil'}>
                Consulter
            </Link>
        ),
        filterable: true,
        sortable: true,
        sortFunction: (a, b) => {
            if (a.id < b.id) {
                return -1;
            }
            if (a.id > b.id) {
                return 1;
            }
            return 0;
        },
    });
    // }

    const renderList = (list, additional_str, separator) => {
        let str = '';
        list.map((item, index) => {
            str += item.label + additional_str;
            if (index < list.length - 1) {
                str += separator;
            }
        });
        return str;
    };

    const ExpandedMission = ({ data }) => (
        <div>
            <pre>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Titre mission : </span>
                        <span className='list-mission-value'>{data.name}</span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Type : </span>
                        <span className='list-mission-value'>
                            {data.mission_type === 'normal'
                                ? 'Convention temps complet'
                                : data.mission_type === 'parttime'
                                ? 'Convention temps partiel'
                                : 'Convention HMONP'}
                        </span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Niveau d'études : </span>
                        <span className='list-mission-value'>
                            {renderList(data.study_levels, '', ', ')}
                        </span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Adresse : </span>
                        <span className='list-mission-value'>{data.address}</span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Compétences : </span>
                        <span className='list-mission-value'>
                            {renderList(data.main_skills, '', ', ')}
                        </span>
                    </div>
                </div>
            </pre>
        </div>
    );

    return (
        <div
            className='backend-container'
            onClick={() => {
                if (editMode === 1) {
                    setEditMode(0);
                }
            }}>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>Offres de mission</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <p className='list-container-subtitle'>
                        Voici toutes les offres actives sur le réseau Jobers.
                    </p>
                </div>
                <div className='list-flex-child list-flex-container'>
                    <div
                        className={
                            currentUser.function == 'Student'
                                ? 'list-container-searchbar'
                                : 'list-container-searchbar searchbar-with-button'
                        }>
                        <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                        <input
                            id='search'
                            className='list-container-searchinput'
                            type='text'
                            placeholder='Rechercher'
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                        />
                    </div>
                    {editMode < 2 && currentUser.function !== 'Student' && (
                        <div className='profil-container-wipbutton'>
                            <span className='list-container-addlabel'>
                                <a
                                    onClick={() => {
                                        editMode === 1 ? setEditMode(0) : setEditMode(1);
                                    }}>
                                    +
                                </a>
                            </span>
                        </div>
                    )}
                    {editMode == 1 && (
                        <div className='more_actions'>
                            <div
                                className='more_actions_action'
                                onClick={() => {
                                    downloadCSV(filteredItems);
                                }}>
                                {/* display ham-menu icon */}
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                <div className='more_actions_item'>Exporter</div>
                            </div>
                            <div
                                className='more_actions_action'
                                // onClick={() => setEditMode(2)}
                            >
                                {/* display ham-menu icon */}
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                <div className='more_actions_item disabled'>Filtrer</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-container-divider'></div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucune offre trouvée.</span>
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={ExpandedMission}
                    />
                </div>
            </div>
        </div>
    );
}
