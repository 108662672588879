import './Input.css';
import './FileUpload.css';
import './Autocomplete.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormCtx } from './Form';
import DatePicker from 'react-datepicker';
import FileUpload from './FileUpload';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import 'react-datepicker/dist/react-datepicker.css';

const Input2 = props => {
    const { id } = props;
    const { setFields, addField, fields, validateField, errors, doValidate } = useContext(FormCtx);
    const field = fields[id] || {};
    const KILO_BYTES_PER_BYTE = 1000;
    const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 2097152;
    const convertNestedObjectToArray = nestedObj =>
        Object.keys(nestedObj).map(key => nestedObj[key]);
    const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE);

    const {
        name,
        rows,
        cols,
        value,
        validate,
        options,
        disabled,
        validatemessage,
        hintmessage,
        placeholder,
        label = '',
        updateFilesCb,
        maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
        type = 'text',
        events = {},
        classes = {},
    } = field;
    const fieldError = errors[id];
    const [autocompleteState, setAutocompleteState] = useState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: '',
    });

    const { onChange, ...restEvents } = events;
    const { contClass, fieldClass, errorClass } = classes;

    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const addNewFiles = newFiles => {
        for (let file of newFiles) {
            if (file.size <= maxFileSizeInBytes) {
                if (!props.multiple) {
                    return { file };
                }
                files[file.name] = file;
            }
        }
        return { ...files };
    };

    const callUpdateFilesCb = files => {
        const filesAsArray = convertNestedObjectToArray(files);
        updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = e => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
    };

    const removeFile = fileName => {
        delete files[fileName];
        setFiles({});
        callUpdateFilesCb({});
    };

    const UploadedFile = props => {
        return (
            <div>
                <div className='panel-uploaded'>
                    <div className='content-uploaded'>
                        <div className='file-uploaded-icon'>
                            <div className='uploaded-featured-icon'>
                                <img
                                    className='file'
                                    alt='file-button'
                                    src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62fd3755a99ae40e7cadc620/img/file@2x.svg'
                                />
                            </div>
                        </div>
                        <div className='uploaded-details-panel'>
                            <div className='uploaded-details'>
                                <div className='uploaded-filename inter-medium-oxford-blue-14px'>
                                    {props.file.name}
                                </div>
                                <div className='uploaded-filesize inter-normal-pale-sky-14px'>
                                    2 MO
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='uploaded-checkbox-base'>
                        <img
                            className='uploaded-check'
                            alt='check-upload'
                            src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62fd3755a99ae40e7cadc620/img/check@2x.svg'
                        />
                    </div>
                </div>
                <div className='uploaded-button'>
                    <div className='uploaded-button-base border-1px-mischka'>
                        <div
                            className='uploaded-delete inter-medium-oxford-blue-14px'
                            onClick={() => removeFile(props.file.name)}>
                            Supprimer
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const UploadFile = props => {
        return (
            <div className='component-upload' onClick={handleUploadBtnClick}>
                <div className='label-upload inter-medium-oxford-blue-14px'>{label}</div>
                <div className='panel-upload'>
                    <div className='file-upload-base border-1px-gallery'>
                        <div className='content-upload'>
                            <div className='featured-icon'>
                                <img
                                    className='upload-cloud'
                                    alt='upload-cloud'
                                    src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f63225612113ed342a99d0/img/upload-cloud@2x.svg'
                                />
                            </div>
                            <div className='text-upload'>
                                <div className='action-upload'>
                                    <div className='button-upload'>
                                        <div className='button-base-upload'>
                                            <div className='download-upload'>
                                                Cliquer pour télécharger
                                            </div>
                                        </div>
                                    </div>
                                    <div className='drag-upload inter-normal-pale-sky-14px'>
                                        ou drag’n’drop
                                    </div>
                                </div>
                                <p className='format-upload'>PNG, JPG ou PDF (max. 2MO)</p>
                            </div>
                        </div>
                        <input
                            type='file'
                            ref={fileInputField}
                            onChange={handleNewFileUpload}
                            id={props.id}
                            {...props}
                        />
                    </div>
                </div>
                <p className={errorClass}>{fieldError}</p>
            </div>
        );
    };

    const handleChange = event => {
        if (type == 'file') {
            handleNewFileUpload(event);
        }

        try {
            setFields(event, field);
        } catch (error) {
            throw error;
        }

        if (type == 'autocomplete') {
            const { suggestions } = props;
            const userInput = event.currentTarget.value;

            const filteredSuggestions = suggestions.filter(
                suggestion => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
            );

            setAutocompleteState({
                activeSuggestion: 0,
                filteredSuggestions,
                showSuggestions: true,
                userInput: event.currentTarget.value,
            });
        }

        if (typeof onChange === 'function') {
            onChange({
                ...field,
                value: typeof event.target !== 'undefined' ? event.target.value : event,
            });
        }
    };

    const onAutocompleteClick = e => {
        let value = e.currentTarget.innerText;
        setFields(false, { id, value });
        setAutocompleteState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false,
            userInput: e.currentTarget.innerText,
        });
    };

    const onAutocompleteKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = autocompleteState;

        if (e.keyCode === 13) {
            setAutocompleteState({
                activeSuggestion: 0,
                showSuggestions: false,
                userInput: filteredSuggestions[activeSuggestion],
            });
        } else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }
            setAutocompleteState({ activeSuggestion: activeSuggestion - 1 });
        } else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }
            setAutocompleteState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    const { activeSuggestion, filteredSuggestions, showSuggestions, userInput } = autocompleteState;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
        if (filteredSuggestions.length) {
            suggestionsListComponent = (
                <ul className='suggestions'>
                    {filteredSuggestions.map((suggestion, index) => {
                        let className;

                        // Flag the active suggestion with a class
                        if (index === activeSuggestion) {
                            className = 'suggestion-active';
                        }
                        return (
                            <li
                                className={className}
                                key={suggestion}
                                onClick={onAutocompleteClick}>
                                {suggestion}
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            suggestionsListComponent = (
                <div className='no-suggestions'>
                    <em>Pas de suggestion disponible.</em>
                </div>
            );
        }
    }

    useEffect(() => {
        if (value !== undefined && doValidate) {
            validateField(id);
        }
    }, [value, id]);

    useEffect(() => {
        addField({
            field: props,
            value,
        });
    }, []);

    useEffect(() => {
        if (props.files) {
            setFiles(props.files);
        }
    }, [props.files]);

    const fieldProps = {
        ...restEvents,
        id,
        name,
        type,
        value,
        validate,
        options,
        disabled,
        validatemessage,
        hintmessage,
        placeholder,
        className: fieldError ? 'input-error ' + fieldClass : fieldClass,
        onChange: handleChange,
    };

    if (type === 'textarea') {
        delete fieldProps.type;
        delete fieldProps.value;

        fieldProps.defaultValue = value;
        fieldProps.cols = cols || 90;
        fieldProps.rows = rows || 10;
    }

    const renderInput = type => {
        let requiredLabel = label;
        if (validate && validate.indexOf('required') > -1 && value == '') {
            requiredLabel = (
                <>
                    {label} <span className='red-value'> *</span>
                </>
            );
        }
        switch (type) {
            case 'autocomplete':
                return (
                    <div className={contClass}>
                        <div className='profil-flex-child'>
                            <div className='profil-label'>
                                <label htmlFor={fieldProps.id}>{requiredLabel}</label>
                            </div>
                        </div>
                        <div className='profil-flex-child'>
                            <input {...fieldProps} type='text' onKeyDown={onAutocompleteKeyDown} />
                            {suggestionsListComponent}
                            <p className={errorClass}>{fieldError}</p>
                            {hintmessage && <p className='hint-text'>{hintmessage}</p>}
                        </div>
                    </div>
                );
            case 'textarea':
                return (
                    <div className={contClass}>
                        <div className='profil-flex-child'>
                            <div className='profil-label'>
                                <label htmlFor={fieldProps.id}>{requiredLabel}</label>
                            </div>
                        </div>
                        <div
                            className={
                                label === 'Présentez-vous' || label === 'Description mission'
                                    ? 'profil-flex-child student-description-flex'
                                    : 'profil-flex-child'
                            }>
                            <textarea {...fieldProps} />
                            <p className={errorClass}>{fieldError}</p>
                            {hintmessage && <p className='hint-text'>{hintmessage}</p>}
                        </div>
                    </div>
                );
            case 'file':
                return (
                    <div className='component-upload'>
                        {Object.keys(files).length === 0 && <UploadFile {...fieldProps} />}
                        {Object.keys(files).length !== 0 &&
                            Object.keys(files).map((fileName, index) => {
                                let file = files[fileName];

                                return (
                                    <UploadedFile key={index} file={file} removeFile={removeFile} />
                                );
                            })}
                    </div>
                );
            case 'select':
                return (
                    <div className={contClass}>
                        <div className='profil-flex-child'>
                            <div className='profil-label'>
                                <label htmlFor={fieldProps.id}>{requiredLabel}</label>
                            </div>
                        </div>
                        <div className='profil-flex-child'>
                            <select {...fieldProps}>
                                {fieldProps.options.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <p className={errorClass}>{fieldError}</p>
                            {hintmessage && <p className='hint-text'>{hintmessage}</p>}
                        </div>
                    </div>
                );
            case 'nb_je':
                return (
                    <div className={contClass}>
                        <input {...fieldProps} />
                    </div>
                );
            default:
                return (
                    <div className={contClass}>
                        <div className='profil-flex-child'>
                            <div className='profil-label'>
                                <label htmlFor={fieldProps.id}>{requiredLabel}</label>
                            </div>
                        </div>
                        <div className='profil-flex-child'>
                            <input {...fieldProps} />
                            {type == 'autocomplete' && suggestionsListComponent}
                            <p className={errorClass}>{fieldError}</p>
                            {hintmessage && <p className='hint-text'>{hintmessage}</p>}
                            {label === 'N° inscription' && (
                                <span id='hmonp_tooltip' className='tooltip'>
                                    <div className='icon-size'>
                                        <img
                                            className='icon-img'
                                            alt='go-back'
                                            src='/img/10_QUESTION_MARK.svg'
                                        />
                                    </div>
                                    <Tooltip
                                        anchorId='hmonp_tooltip'
                                        content="Les missions HMONP ne peuvent pas être proposées que par les entreprises d'architecture inscrites à l'Ordre des Architectes. Nous mettrons à jour votre profil avec ces informations."
                                        place='bottom'
                                        style={{
                                            backgroundColor: '#fff',
                                            color: 'gray',
                                            width: '20vw',
                                            border: '1px solid #ccc',
                                            'margin-top': '10px',
                                            'margin-left': '-30px',
                                            'border-radius': '8px',
                                            'font-family': 'Mont-Book',
                                            'font-size': '11pt',
                                            'letter-spacing': 0,
                                        }}
                                    />
                                </span>
                            )}
                        </div>
                    </div>
                );
        }
    };

    return field && field.value !== undefined ? renderInput(type) : '';
};

export default Input2;
