import './OrganizationList.css';
import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import Sidebar from '../../Sidebar/Sidebar';

import OrganizationDataService from '../../../../services/organization.service';

export default function OrganizationList(props) {
    const [data, setData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);

    useEffect(() => {
        const key = 'SELECT Association';
        const fields = 'id, friendlyname, status, started_at, persons_list';
        OrganizationDataService.getAll(key, fields)
            .then(response => {
                setData(response);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    const handleAction = (e, id) => {
        setCurrentIndex(id);
    };

    const columns = [
        {
            name: "Nom de l'association",
            selector: row => row.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Statut',
            selector: row => row.status,
            filterable: true,
            sortable: true,
        },
        {
            name: "Nombre d'étudiants",
            selector: row => row.nb_student,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Date de création',
            selector: row => row.started_at,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => <button onClick={e => handleAction(e, row.id)}>Editer</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className='organizationList screen'>
            <Sidebar />
            <div className='page-header'>
                <div className='content-9'>
                    <div className='text-and-supporting-text-1'>
                        <div className='text-8'>Associations</div>
                        <p className='supporting-text-1 inter-normal-pale-sky-16px'>
                            Cet onglet permet de voir toutes les associations associées à Jobers
                        </p>
                    </div>
                    <div className='actions'>
                        <div className='button'>
                            <div className='button-base-3'>
                                <div className='text-12'>
                                    <Link to='/association'>Créer une association</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable columns={columns} data={data} />
                {currentIndex > 0 && (
                    <Navigate to={'/association/' + currentIndex} replace={true} />
                )}
            </div>
        </div>
    );
}
