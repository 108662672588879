import React, { useState, useEffect } from 'react';
import { useAuthState } from '../../context';

import LoginForm from '../LoginForm/LoginForm';

import Sidebar from './Sidebar/Sidebar';

export default function MyDashboard() {
    const currentUser = useAuthState();

    return (
        <div className='container-center-horizontal'>
            <Sidebar />
            {currentUser.function === '' && <LoginForm />}
            {currentUser.function === 'Student' && <h1>Etudiant</h1>}
            {currentUser.function === 'Compte entreprise' && <h1>Entreprise</h1>}
            {currentUser.function === 'Président' && <h1>Association</h1>}
        </div>
    );
}
