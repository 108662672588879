import React from 'react';
import { Link } from 'react-router-dom';
import Disconnect from '../Icon/Disconnect';
import './Sidebar.css';

import { useAuthDispatch, logout, useAuthState } from '../../../context';

const Parameter = () => {
    const name = 'Se déconnecter';
    const dispatch = useAuthDispatch();
    const handleLogout = () => {
        logout(dispatch);
    };

    return (
        <div className='content-parameter'>
            <div className='logOutIcon'>
                <Disconnect />
            </div>
            <Link to='/' onClick={handleLogout} className='parameter'>
                {name}
            </Link>
        </div>
    );
};

export default Parameter;
