import http from '../http-common';
import vars from '../config/vars';
const getAll = async (key, fields) => {
    let aFields = [
        'id',
        'name',
        'code',
        'domain',
        'status',
        'school_name',
        'started_at',
        // 'persons_list',
        'nb_ongoing_missions',
        'nb_terminated_missions',
    ];
    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Association',
        output_fields: aFields.join(','),
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        Object.keys(response.data.objects).forEach(key => {
            objects.push({
                id: response.data.objects[key].fields.id,
                name: response.data.objects[key].fields.name,
                domain: response.data.objects[key].fields.domain,
                status: response.data.objects[key].fields.status,
                school_name: response.data.objects[key].fields.school_name,
                started_at: response.data.objects[key].fields.started_at,
                nb_student: response.data.objects[key].fields.code,
                nb_ongoing_missions: response.data.objects[key].fields.nb_ongoing_missions,
                nb_terminated_missions: response.data.objects[key].fields.nb_terminated_missions,
            });
        });
    }

    return objects;
};
const getName = async key => {
    let returnValue = false;

    let fields = ['id', 'name', 'friendlyname'];

    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Association',
        output_fields: fields.join(','),
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        if (response.data.objects === null) {
            return response.data;
        }
        Object.keys(response.data.objects).forEach(key => {
            returnValue = {
                id: response.data.objects[key].fields.id,
                name: response.data.objects[key].fields.name,
                friendlyname: response.data.objects[key].fields.friendlyname,
            };
        });
    }

    return returnValue;
};
const get = async key => {
    let returnValue = false;

    let fields = [
        'id',
        'name',
        'friendlyname',
        'status',
        'school_name',
        'started_at',
        'domain',
        'facebook',
        'instagram',
        'siret',
        'tva',
        'urssaf',
        'ape',
        'bank',
        'iban',
        'bic',
        'picture',
    ];

    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Association',
        output_fields: fields.join(','),
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        if (response.data.objects === null) {
            return response.data;
        }
        Object.keys(response.data.objects).forEach(key => {
            returnValue = {
                id: response.data.objects[key].fields.id,
                name: response.data.objects[key].fields.name,
                friendlyname: response.data.objects[key].fields.friendlyname,
                status: response.data.objects[key].fields.status,
                school_id: response.data.objects[key].fields.school_name,
                started_at: response.data.objects[key].fields.started_at,
                domain: response.data.objects[key].fields.domain,
                facebook: response.data.objects[key].fields.facebook,
                instagram: response.data.objects[key].fields.instagram,
                siret: response.data.objects[key].fields.siret,
                tva: response.data.objects[key].fields.tva,
                urssaf: response.data.objects[key].fields.urssaf,
                ape: response.data.objects[key].fields.ape,
                bank: response.data.objects[key].fields.bank,
                iban: response.data.objects[key].fields.iban,
                bic: response.data.objects[key].fields.bic,
                picture: response.data.objects[key].fields.picture,
            };
        });
    }

    return returnValue;
};
const create = async (fields, output_fields) => {
    let returnValue = false;

    // BUILD POST PARAMS
    const data = {
        operation: 'core/create',
        comment: 'Création par Jobers',
        class: 'Association',
        output_fields: output_fields,
        fields: fields,
    };

    // BUILD PARAMS ARRAY
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    Object.keys(response.data.objects).forEach(key => {
        returnValue = response.data.objects[key];
    });

    return returnValue;
};
const update = async (key, fields, output_fields) => {
    let returnValue = false;

    // BUILD POST PARAMS
    const data = {
        operation: 'core/update',
        comment: 'Création par Jobers',
        class: 'Association',
        key: key,
        fields: fields,
        output_fields: output_fields,
    };

    // BUILD PARAMS ARRAY
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // RETURN WRITTEN OBJECT
    Object.keys(response.data.objects).forEach(key => {
        returnValue = response.data.objects[key];
    });

    return returnValue;
};
const upsert = async (key, fields, output_fields) => {
    let returnValue = false;

    // BUILD POST PARAMS
    let data = {
        operation: 'core/create',
        comment: 'Création par Jobers',
        class: 'Association',
        fields: fields,
        output_fields: output_fields,
    };

    // UPDATE CASE
    if (key) {
        data.operation = 'core/update';
        data.key = key;
    }

    // BUILD PARAMS ARRAY
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // RETURN WRITTEN OBJECT
    Object.keys(response.data.objects).forEach(key => {
        returnValue = response.data.objects[key];
    });

    return returnValue;
};
const remove = id => {
    return http.delete(`/tutorials/${id}`);
};
const removeAll = () => {
    return http.delete(`/tutorials`);
};
const findByTitle = title => {
    return http.get(`/tutorials?title=${title}`);
};
const OrganizationDataService = {
    getAll,
    get,
    getName,
    create,
    update,
    upsert,
    remove,
    removeAll,
    findByTitle,
};
export default OrganizationDataService;
