import http from '../http-common';
import vars from '../config/vars';
const getAll = async (key, fields) => {
    const aFields = [
        'id',
        'first_name',
        'name',
        'description',
        'email',
        'phone',
        'function',
        'location_address',
        'location_postal_code',
        'location_city',
        'location_country',
        'started_at',
        'joined_at',
        'nationality',
        'year',
        'school_year',
        'school',
        'ssn',
        'bank',
        'iban',
        'bic',
        'book_url',
        'status',
        'display_status',
        'org_id',
        'org_name',
        'birthday',
        'birth_city',
        'birth_country',
        'birth_dept',
        'gender',
    ];

    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Person',
        output_fields: aFields.join(','),
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if (response.data.code == '0' && response.data.objects) {
        Object.keys(response.data.objects).forEach(key => {
            let currentObject = {};
            currentObject.id = response.data.objects[key].fields.id;
            currentObject.first_name = response.data.objects[key].fields.first_name;
            currentObject.name = response.data.objects[key].fields.name;
            currentObject.status = response.data.objects[key].fields.status;
            currentObject.display_status = response.data.objects[key].fields.display_status;
            currentObject.org_name = response.data.objects[key].fields.org_name;
            currentObject.org_id = response.data.objects[key].fields.org_id;
            currentObject.email = response.data.objects[key].fields.email;
            currentObject.phone = response.data.objects[key].fields.phone;
            currentObject.description = response.data.objects[key].fields.description;
            currentObject.birthday = response.data.objects[key].fields.birthday;
            currentObject.birth_city = response.data.objects[key].fields.birth_city;
            currentObject.birth_country = response.data.objects[key].fields.birth_country;
            currentObject.birth_dept = response.data.objects[key].fields.birth_dept;
            currentObject.gender = response.data.objects[key].fields.gender;
            currentObject.address = response.data.objects[key].fields.location_address;
            currentObject.postcode = response.data.objects[key].fields.location_postal_code;
            currentObject.city = response.data.objects[key].fields.location_city;
            currentObject.country = response.data.objects[key].fields.location_country;
            currentObject.function = response.data.objects[key].fields.function;
            currentObject.started_at = response.data.objects[key].fields.started_at;
            currentObject.joined_at = response.data.objects[key].fields.joined_at;
            currentObject.nationality = response.data.objects[key].fields.nationality;
            currentObject.year = response.data.objects[key].fields.year;
            currentObject.school_year = response.data.objects[key].fields.school_year;
            currentObject.school = response.data.objects[key].fields.school;
            currentObject.ssn = response.data.objects[key].fields.ssn;
            currentObject.bank = response.data.objects[key].fields.bank;
            currentObject.iban = response.data.objects[key].fields.iban;
            currentObject.bic = response.data.objects[key].fields.bic;
            currentObject.book_url = response.data.objects[key].fields.book_url;
            objects.push(currentObject);
        });
    }

    return objects;
};
const get = async key => {
    let returnValue = {};

    const aFields = [
        'id',
        'first_name',
        'name',
        'description',
        'email',
        'phone',
        'function',
        'location_address',
        'location_postal_code',
        'location_city',
        'location_country',
        'started_at',
        'joined_at',
        'nationality',
        'year',
        'school_year',
        'school',
        'domain',
        'skills',
        'ssn',
        'id_stripe',
        'bank',
        'iban',
        'bic',
        'book_url',
        'status',
        'display_status',
        'org_id',
        'org_name',
        'birthday',
        'birth_city',
        'birth_country',
        'birth_dept',
        'gender',
        'missions_list',
    ];

    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Person',
        output_fields: aFields.join(','),
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        Object.keys(response.data.objects).forEach(async key => {
            returnValue.id = response.data.objects[key].key;
            returnValue.first_name = response.data.objects[key].fields.first_name;
            returnValue.name = response.data.objects[key].fields.name;
            returnValue.status = response.data.objects[key].fields.status;
            returnValue.display_status = response.data.objects[key].fields.display_status;
            returnValue.org_id = response.data.objects[key].fields.org_id;
            returnValue.org_name = response.data.objects[key].fields.org_name;
            returnValue.email = response.data.objects[key].fields.email;
            returnValue.phone = response.data.objects[key].fields.phone;
            returnValue.description = response.data.objects[key].fields.description;
            returnValue.birthday = response.data.objects[key].fields.birthday;
            returnValue.birth_city = response.data.objects[key].fields.birth_city;
            returnValue.birth_country = response.data.objects[key].fields.birth_country;
            returnValue.birth_dept = response.data.objects[key].fields.birth_dept;
            returnValue.gender = response.data.objects[key].fields.gender;
            returnValue.address = response.data.objects[key].fields.location_address;
            returnValue.postcode = response.data.objects[key].fields.location_postal_code;
            returnValue.city = response.data.objects[key].fields.location_city;
            returnValue.country = response.data.objects[key].fields.location_country;
            returnValue.function = response.data.objects[key].fields.function;
            returnValue.started_at = response.data.objects[key].fields.started_at;
            returnValue.joined_at = response.data.objects[key].fields.joined_at;
            returnValue.nationality = response.data.objects[key].fields.nationality;
            returnValue.year = response.data.objects[key].fields.year;
            returnValue.school_year = response.data.objects[key].fields.school_year;
            returnValue.school = response.data.objects[key].fields.school;
            returnValue.domain = response.data.objects[key].fields.domain;
            returnValue.skills =
                response.data.objects[key].fields.skills != ''
                    ? JSON.parse(response.data.objects[key].fields.skills)
                    : [];
            returnValue.ssn = response.data.objects[key].fields.ssn;
            returnValue.id_stripe = response.data.objects[key].fields.id_stripe;
            returnValue.bank = response.data.objects[key].fields.bank;
            returnValue.iban = response.data.objects[key].fields.iban;
            returnValue.bic = response.data.objects[key].fields.bic;
            returnValue.book_url = response.data.objects[key].fields.book_url;
            returnValue.missions_list = response.data.objects[key].fields.missions_list;
        });
    }

    return returnValue;
};
const StudentDataService = {
    getAll,
    get,
};
export default StudentDataService;
