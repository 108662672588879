import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import axios from 'axios';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import MissionTermDataService from '../../services/missionterm.service';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function PaymentList(props) {
    const [data, setData] = useState([]);
    const currentUser = useAuthState();
    const [editMode, setEditMode] = useState(0);
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    const [status, setStatus] = useState('published');
    useEffect(() => {
        let key =
            'SELECT MissionTerm AS MT JOIN Mission AS M ON MT.mission_id = M.id WHERE M.status != "pending" AND payment_id NOT IN (SELECT DocumentNote AS DN WHERE DN.status = "' +
            status +
            '") AND MT.start_date < NOW() AND MT.nb_je > 0';

        if (status === 'draft') {
            key += ' AND MT.status = "terminated"';
        }

        MissionTermDataService.getAll(key).then(response => {
            setData(response);
        });
    }, [status, data]);

    const setPaid = (term_id, date) => {
        let aDataFields = {
            term_id: term_id,
            date: date,
            type: 'payment_date',
        };

        let termData = new URLSearchParams();
        termData.append('operation', 'set_term_paid');
        termData.append('data', JSON.stringify(aDataFields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: termData,
        };

        axios(config)
            .then(function (response) {
                if (response.data.code === 0) {
                    let index = data.findIndex(x => x.id === term_id);
                    data[index].payment_date = date;
                    setData(data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const renderStatus = row => {
        if (
            row.payment_id > 0 &&
            moment(row.end_date).isBefore(moment().subtract(30, 'days')) &&
            row.payment_status !== 'published'
        ) {
            return <span className='badge badge-danger red-value'>En retard</span>;
        }
        if (row.payment_status == 'published') {
            return <span className='badge badge-info fluo-value'>Réglé</span>;
        }
        if (row.payment_id > 0) {
            return <span className='badge badge-info green-value'>Envoyé</span>;
        }
        if (row.status !== 'terminated') {
            return <span className='badge badge-warning yellow-value'>En attente DJ</span>;
        }
    };

    const renderRef = row => {
        if (row.payment_id > 0) {
            return (
                <a
                    className='list-table-link'
                    href={'https://itop.jobers.fr/pdf_bv.php?mission_term_id=' + row.id}
                    target='_blank'>
                    {row.payment_id_friendlyname}
                </a>
            );
        }

        return row.mission_ref.replace('M', 'BV') + row.name;
    };

    let columns = [
        {
            name: 'Référence',
            cell: row => renderRef(row),
            filterable: true,
            sortable: true,
            width: '8vw',
            sortFunction: (a, b) => {
                if (a.facture_id_friendlyname < b.facture_id_friendlyname) {
                    return -1;
                }
                if (a.facture_id_friendlyname > b.facture_id_friendlyname) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Mois',
            cell: row => moment(row.start_date).format('MM'),
            filterable: true,
            sortable: true,
            width: '5vw',
            sortFunction: (a, b) => {
                if (moment(a.start_date).format('MM') < moment(b.start_date).format('MM')) {
                    return -1;
                }
                if (moment(a.start_date).format('MM') > moment(b.start_date).format('MM')) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Année',
            cell: row => moment(row.start_date).format('YYYY'),
            filterable: true,
            sortable: true,
            width: '6vw',
            sortFunction: (a, b) => {
                if (moment(a.start_date).format('YYYY') < moment(b.start_date).format('YYYY')) {
                    return -1;
                }
                if (moment(a.start_date).format('YYYY') > moment(b.start_date).format('YYYY')) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Entreprise',
            cell: row => (
                <Link
                    className='list-table-link'
                    to={'/entreprise/' + row.mission_id_company_id + '/profil'}>
                    {row.mission_id_company_name}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.mission_id_company_name < b.mission_id_company_name) {
                    return -1;
                }
                if (a.mission_id_company_name > b.mission_id_company_name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Etudiant',
            cell: row => (
                <Link
                    className='list-table-link'
                    to={'/etudiant/' + row.mission_id_student_id + '/profil'}>
                    {row.mission_id_student_firstname} {row.mission_id_student_name}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                let aStudentName = a.mission_id_student_name + a.mission_id_student_firstname;
                let bStudentName = b.mission_id_student_name + b.mission_id_student_firstname;
                if (aStudentName < bStudentName) {
                    return -1;
                }
                if (aStudentName > bStudentName) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Etat',
            cell: row => renderStatus(row),
            filterable: true,
            sortable: true,
            width: '7vw',
        },
        {
            name: 'Net €',
            selector: row => row.net_student,
            filterable: true,
            sortable: true,
            width: '5vw',
        },
        {
            name: 'Statut',
            cell: row =>
                row.payment_id > 0 &&
                row.facture_date != '' &&
                row.payment_date == '' &&
                row.data !== 'waiting_rapport' ? (
                    <DatePicker
                        className='profil-value facture-datepicker'
                        dateFormat='dd/MM/yyyy'
                        onChange={date => {
                            setPaid(row.id, date);
                        }}
                    />
                ) : row.payment_date == '' ? (
                    ''
                ) : (
                    moment(row.payment_date).format('DD-MM-YYYY')
                ),
            filterable: true,
            sortable: true,
            width: '7vw',
            sortFunction: (a, b) => {
                if (a.payment_id < b.payment_id) {
                    return -1;
                }
                if (a.payment_id > b.payment_id) {
                    return 1;
                }
                return 0;
            },
        },
    ];

    const convertArrayOfObjectsToCSV = array => {
        let result = '';
        let exportableFields = [];
        let translatedFields = [];

        if (status === 'published') {
            exportableFields = ['ref', 'mission_id_company_name', 'student_net'];
            translatedFields = ['Référence', 'Entreprise', 'Net'];
        }

        if (status === 'draft') {
            exportableFields = [
                'ref',
                'mission_id_student_firstname',
                'mission_id_student_name',
                'mission_id_company_name',
                'mission_id_student_org_name',
                'student_net',
            ];
            translatedFields = [
                'Référence',
                'Prénom étudiant',
                'Nom étudiant',
                'Entreprise',
                'Association',
                'Net',
            ];
        }

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = exportableFields;

        result = '\uFEFF';
        result += translatedFields.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                let value = item[key];

                if (key === 'ref') {
                    value = renderRef(item);
                }

                if (typeof value === 'object') {
                    if (
                        typeof value.props !== 'undefined' &&
                        typeof value.props.children !== 'undefined'
                    ) {
                        value = value.props.children;
                    }
                }

                result += value;

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        let filenameStatus = status === 'published' ? 'en_attente' : 'archivees';
        const filename = `jobers_app_bv_${filenameStatus}_${moment().format(
            'YYYY-MM-DD_HH-mm-ss'
        )}.csv`;

        csv = 'data:text/csv;charset=utf-8,' + encodeURI(csv);

        link.setAttribute('href', csv);
        link.setAttribute('download', filename);
        link.click();
    };

    const ExpandedMission = ({ data }) => (
        <pre>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Association : </span>
                    <span className='list-mission-value'>{data.mission_id_student_org_name}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Echéance : </span>
                    <span className='list-mission-value'>{data.name}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>RIB : </span>
                    <span className='list-mission-value'>{data.mission_id_student_iban}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Mail étudiant : </span>
                    <span className='list-mission-value'>{data.mission_id_student_email}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Tél étudiant : </span>
                    <span className='list-mission-value'>{data.mission_id_student_phone}</span>
                </div>
            </div>
        </pre>
    );

    if (currentUser.function == 'Jobers')
        return (
            <div
                className='backend-container'
                onClick={() => {
                    if (editMode === 1) {
                        setEditMode(0);
                    }
                }}>
                <Sidebar />
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4'>
                        <h1 className='list-container-title'>Bulletins de versement</h1>
                    </div>
                    <div className='list-flex-child'>
                        <span className='list-container-counter'>
                            {filteredItems && filteredItems.length}
                        </span>
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4 list-container-tabs'>
                        <Link
                            to={'#'}
                            className='profil-tabs'
                            onClick={() => {
                                setStatus('published');
                                setData([]);
                            }}>
                            En attente
                        </Link>
                        <Link
                            to={'#'}
                            className='profil-tabs'
                            onClick={() => {
                                setStatus('draft');
                                // setData([]);
                            }}>
                            Archivés
                        </Link>
                    </div>
                    <div className='list-flex-child list-flex-container'>
                        <div className='list-container-searchbar searchbar-with-button'>
                            <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                            <input
                                id='search'
                                className='list-container-searchinput'
                                type='text'
                                placeholder='Rechercher'
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                            />
                        </div>
                        {editMode < 2 && (
                            <div className='profil-container-wipbutton'>
                                <span className='list-container-addlabel'>
                                    <a
                                        onClick={() => {
                                            editMode === 1 ? setEditMode(0) : setEditMode(1);
                                        }}>
                                        +
                                    </a>
                                </span>
                            </div>
                        )}
                        {editMode == 1 && (
                            <div className='more_actions'>
                                <div
                                    className='more_actions_action'
                                    onClick={() => {
                                        downloadCSV(filteredItems);
                                    }}>
                                    {/* display ham-menu icon */}
                                    <div className='more_actions_icon'>
                                        <img
                                            className='ham_menu'
                                            src='/img/ham-menu.svg'
                                            alt='more actions'
                                        />
                                    </div>
                                    <div className='more_actions_item'>Exporter</div>
                                </div>
                                <div className='more_actions_action' onClick={() => setEditMode(1)}>
                                    {/* display ham-menu icon */}
                                    <div className='more_actions_icon'>
                                        <img
                                            className='ham_menu'
                                            src='/img/ham-menu.svg'
                                            alt='more actions'
                                        />
                                    </div>
                                    <div className='more_actions_item disabled'>Filtrer</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div
                        className={
                            status === 'published'
                                ? 'list-container-divider-1'
                                : 'list-container-divider-2'
                        }
                    />
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child'>
                        {status == 'published' && (
                            <p className='list-container-subtitle list-tab-subtitle'>
                                Liste des bulletins de versement à régler
                            </p>
                        )}
                        {status == 'draft' && (
                            <p className='list-container-subtitle list-tab-subtitle'>
                                Liste des bulletins de versement archivés
                            </p>
                        )}
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child list-table-container flex-4'>
                        <DataTable
                            noDataComponent={
                                <span className='rdt_noresults'>Aucun BV trouvé.</span>
                            }
                            columns={columns}
                            data={filteredItems}
                            persistTableHead
                            expandableRows
                            expandableRowsComponent={ExpandedMission}
                        />
                    </div>
                </div>
            </div>
        );
}
