import './RegisterStudentForm.css';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../Logo/Logo';
import Form from '../Utils/Form';
import Input from '../Utils/Input';
import SelectCountry from '../Utils/SelectCountry';
import { useAuthDispatch, logout, useAuthState } from '../../context';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import StudentDataService from 'services/student.service';

export default function RegisterStudentRenew(props) {
    const [clientSecret, setClientSecret] = useState(false);
    const [initialObjectState, setInitialObjectState] = useState(null);
    const [publicKey, setPublicKey] = useState();
    const [stripePromise, setStripePromise] = useState(false);
    const userDetails = useAuthState();

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {};
            if (userDetails.id) {
                let data = new URLSearchParams();
                data.append('operation', 'get_pk_stripe');
                data.append('data', JSON.stringify({ id: userDetails.id }));

                let config = {
                    method: 'post',
                    url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json',
                    },
                    data: data,
                };

                let response = await axios(config);

                if (response.data.code !== 0) {
                    console.log(response.data.message);
                }

                if (response.data.code === 0 && response.data.key !== 1) {
                    if (response.data.pk_stripe !== '') {
                        setPublicKey(response.data.pk_stripe);
                        setStripePromise(loadStripe(response.data.pk_stripe));
                    }
                }

                StudentDataService.get(userDetails.id).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    setInitialObjectState(objectData);
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState, publicKey, stripePromise, clientSecret]);

    const CheckoutForm = props => {
        const stripe = useStripe();
        const elements = useElements();
        const [message, setMessage] = useState(null);
        const [isLoading, setIsLoading] = useState(false);

        useEffect(() => {
            if (!stripe) {
                return;
            }

            const clientSecret = new URLSearchParams(window.location.search).get(
                'payment_intent_client_secret'
            );

            if (!clientSecret) {
                return;
            }

            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
                switch (paymentIntent.status) {
                    case 'succeeded':
                        setMessage('Payment succeeded!');
                        break;
                    case 'processing':
                        setMessage('Your payment is processing.');
                        break;
                    case 'requires_payment_method':
                        setMessage('Your payment was not successful, please try again.');
                        break;
                    default:
                        setMessage('Something went wrong.');
                        break;
                }
            });
        }, [stripe]);

        const handleSubmit = async e => {
            e.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            setIsLoading(true);

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url:
                        'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=validate_stripe_renew&person_id=' +
                        userDetails.id,
                },
            });

            if (error.type === 'card_error' || error.type === 'validation_error') {
                setMessage(error.message);
            } else {
                setMessage('An unexpected error occurred.');
            }

            setIsLoading(false);
        };

        const paymentElementOptions = {
            layout: 'tabs',
        };

        return (
            <form id='payment-form' onSubmit={handleSubmit}>
                <PaymentElement id='payment-element' options={paymentElementOptions} />
                <button disabled={isLoading || !stripe || !elements} id='submit'>
                    <span id='button-text'>
                        {isLoading ? (
                            <div class='lds-roller'>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        ) : (
                            <div className='bottom-right-actions payment-submit'>
                                <div className='bottom-right-actions-button-base'>
                                    <button
                                        type='button'
                                        onClick={() => {
                                            // setSubmitted(submitted + 1);
                                        }}
                                        className='validation-button bottom-right-button-text bottom-right-button-submit'>
                                        Payer et finaliser votre renouvellement
                                    </button>
                                </div>
                            </div>
                        )}
                    </span>
                </button>
                {/* Show any error or success messages */}
                {message && <p className='error-text'>{message}</p>}
            </form>
        );
    };

    useEffect(() => {
        const fetchClientSecret = async () => {
            const data = new URLSearchParams();
            data.append('operation', 'create_stripe_indent');
            data.append('public_key', publicKey);

            let config = {
                method: 'post',
                url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json',
                },
                data: data,
            };

            await axios(config).then(response => {
                setClientSecret(response.data.clientSecret);
            });
        };

        if (!clientSecret) {
            fetchClientSecret().catch(console.error);
        }
    }, [stripePromise]);

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#005f61',
            colorText: '#41467c',
        },
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div className='register-student screen'>
            <div className='wizard-steps'>
                <div className='progress-steps-progress-icons-centered'>
                    <div className='list-steps'>
                        <div className='overlap-group'>
                            <div className='lines'>
                                <div className='line'></div>
                                <div className='line'></div>
                                <div className='line'></div>
                            </div>
                            <div className='step-base-1'>
                                <div className='step-icon-base-1'>
                                    <div className='step-dot-filled'>
                                        <div className='dot'></div>
                                    </div>
                                </div>
                                <div className='step-content'>
                                    <div className='step-text inter-medium-oxford-blue-14px'>
                                        Renouvellement
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='register-student-title'>Renouvellement d'adhésion</div>
            <div className='register-student-form'>
                <Form className='form' validateOnSubmit={true}>
                    {initialObjectState && (
                        <Input
                            id='year'
                            label='Vous allez renouveller votre adhésion à'
                            disabled='disabled'
                            value={initialObjectState && initialObjectState.org_name}
                            validate='required'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box input-disabled border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                    )}

                    <div className='text-and-supporting-text'>
                        <p className='stripe-infos inter-normal-oxford-blue-14px'>
                            <span className='inter-normal-oxford-blue-14px'>
                                Le montant de l'adhésion à votre association est de
                            </span>
                            <span className='inter-medium-oxford-blue-14px'>&nbsp;</span>
                            <span className='stripe-price'>10€/an </span>
                        </p>
                    </div>
                </Form>
                {clientSecret && stripePromise && (
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm />
                    </Elements>
                )}
            </div>
        </div>
    );
}
