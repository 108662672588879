import React, { useState, useEffect } from 'react';
import { useAuthState } from '../../context';

import LoginForm from '../LoginForm/LoginForm';
import AssociationList from '../Association/list';
import AssociationProfil from '../Association/profil';
import EtudiantProfil from '../Etudiant/profil';
import EntrepriseProfil from '../Entreprise/profil';

export default function Dispatch() {
    const currentUser = useAuthState();

    return (
        <div>
            {currentUser.function === '' && <LoginForm />}
            {currentUser.function === 'Jobers' && <AssociationList />}
            {currentUser.function === 'Student' && <EtudiantProfil id='me' />}
            {currentUser.function === 'Compte entreprise' && <EntrepriseProfil id='me' />}
            {currentUser.function === 'Compte association' && <AssociationProfil id='me' />}
            {currentUser.function === 'Président' && <AssociationProfil id='me' />}
        </div>
    );
}
