import './ForgotPasswordForm.css';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import axios from 'axios';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

export default function ForgotPasswordForm(props) {
    const { register, handleSubmit, formState, getValues, setValue, setError } = useForm();
    const [userEmail, setUserEmail] = useState(0);
    const [userPassword, setUserPassword] = useState(0);
    const [validToken, setValidToken] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const fetchToken = async () => {
            let data = false;
            let operation = 'check_token';

            let fields = {
                token: searchParams.get('token'),
            };

            data = new URLSearchParams();
            data.append('operation', operation);
            data.append('data', JSON.stringify(fields));

            let config = {
                method: 'post',
                url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json',
                },
                data: data,
            };

            let result = await axios(config);

            if (result.data.code === 418) {
                setValidToken(true);
            }

            return result;
        };

        if (searchParams.get('token')) {
            fetchToken();
        }
    }, [validToken]);

    const sendResetEmail = async email => {
        let data = false;
        let operation = 'send_reset_notification';

        let fields = {
            email: email,
        };

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(fields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        return await axios(config);
    };

    const resetPassword = async password => {
        let data = false;
        let operation = 'reset_password';

        let fields = {
            token: searchParams.get('token'),
            password: password,
        };

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(fields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        return await axios(config);
    };

    const onSubmit = data => {
        if (data.emailInput) {
            sendResetEmail(data.emailInput).then(response => {
                setUserEmail(data.emailInput);
            });
        }

        if (data.confirmpasswordInput != '') {
            let passwordFormat = () => /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

            if (data.confirmpasswordInput != data.passwordInput) {
                setError('confirmpasswordInput', {
                    type: 'custom',
                    message: 'Les mots de passe ne correspondent pas',
                });
                return;
            }

            resetPassword(data.confirmpasswordInput).then(response => {
                setUserPassword(data.confirmpasswordInput);
            });
        }
    };

    const ForgotPasswordConfirmation = props => {
        const [isPasswordRequired, setIsPasswordRequired] = useState(true);
        const [isEmailRequired, setIsEmailRequired] = useState(true);

        return (
            <div className='forgot-content'>
                <div className='login-header'>
                    <div className='forgot-icon'>
                        <img
                            className='forgot-key'
                            alt='forgot-key'
                            src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62fa71758bec3ae4a7a2917d/img/mail@2x.svg'
                        />
                    </div>
                    <div className='login-form-header'>
                        <div className='login-title'>Email envoyé</div>
                        <p className='subtitle inter-normal-pale-sky-16px'>
                            <span className='inter-normal-pale-sky-16px'>
                                Nous avons envoyé un lien de réinitialisation à <br />
                            </span>
                            <span className='underline inter-normal-pale-sky-16px'>
                                {userEmail}
                            </span>
                        </p>
                    </div>
                </div>
                <div className='row'></div>
                <div className='row'>
                    <p className='resend-text'>Vous n’avez pas reçu l’email ?</p>
                    <div className='resend-box'>
                        <div className='button-base'>
                            <Link className='resend-link' to='/forgot-password'>
                                Cliquer ici pour en renvoyer un à nouveau
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='forgot-to-connect'>
                    <div>
                        <img
                            className='forgot-arrow-left'
                            alt='arrow-left'
                            src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3e454124ebb80b2e319c4/img/arrow-left@2x.svg'
                        />
                        <Link to='/'>Retourner à la connexion</Link>
                    </div>
                </div>
            </div>
        );
    };

    const ForgotPasswordForm = props => {
        const [isEmailRequired, setIsEmailRequired] = useState(true);
        const [isPasswordRequired, setIsPasswordRequired] = useState(true);

        return (
            <div className='login-page screen'>
                <div className='login-panel'>
                    <div className='login-header'>
                        <div className='forgot-icon'>
                            <img
                                className='forgot-key'
                                alt='forgot-key'
                                src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3e454124ebb80b2e319c4/img/key@2x.svg'
                            />
                        </div>
                        <div className='login-form-header'>
                            <div className='login-title'>Mot de passe oublié ?</div>
                            <p className='subtitle inter-normal-pale-sky-16px'>
                                Nous allons vous envoyer les instructions de réinitialisation de mot
                                de passe
                            </p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(data => onSubmit(data))}>
                        <div className='form'>
                            <div className='input-field'>
                                <div className='input'>
                                    <div className='input'>
                                        <label
                                            className='label inter-medium-oxford-blue-14px'
                                            htmlFor='email'>
                                            {isEmailRequired ? (
                                                <>
                                                    {'Adresse email '}{' '}
                                                    <span className='red-value'> *</span>
                                                </>
                                            ) : (
                                                'Adresse email'
                                            )}
                                        </label>
                                        <input
                                            type='email'
                                            className={
                                                'input-box input-hover inter-normal-pale-sky-16px' +
                                                (formState.errors.emailInput ? ' input-error' : '')
                                            }
                                            id='email'
                                            placeholder='mail@mail.com'
                                            {...register('emailInput', {
                                                onChange: e => {
                                                    let bool = e.target.value == '' ? true : false;
                                                    setIsEmailRequired(bool);
                                                },
                                                required:
                                                    'Veuillez renseigner une adresse email valide.',
                                            })}
                                        />
                                    </div>
                                    {formState.errors.emailInput && (
                                        <p className='error-text'>
                                            {formState.errors.emailInput.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <button type='submit' className='primary-submit'>
                                Réinitialiser le mot de passe
                            </button>
                        </div>
                    </form>
                    <div className='forgot-to-connect'>
                        <div>
                            <img
                                className='forgot-arrow-left'
                                alt='arrow-left'
                                src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3e454124ebb80b2e319c4/img/arrow-left@2x.svg'
                            />
                            <Link to='/'>Retourner à la connexion</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const ResetPasswordForm = props => {
        const [isPasswordRequired, setIsPasswordRequired] = useState(true);
        const [isPasswordConfirmRequired, setIsPasswordConfirmRequired] = useState(true);

        return (
            <div className='forgot-content'>
                <div className='login-header'>
                    <div className='forgot-icon'>
                        <img
                            className='forgot-key'
                            alt='forgot-key'
                            src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3e454124ebb80b2e319c4/img/key@2x.svg'
                        />
                    </div>
                    <div className='login-form-header'>
                        <div className='login-title'>Nouveau mot de passe</div>
                        <p className='subtitle inter-normal-pale-sky-16px'>
                            Votre nouveau mot de passe doit être différent de ceux utilisés
                            précédemment.
                        </p>
                    </div>
                </div>
                <form onSubmit={handleSubmit(data => onSubmit(data))}>
                    <div className='form'>
                        <div className='input-field'>
                            <div className='input'>
                                <div className='input'>
                                    <label
                                        className='label inter-medium-oxford-blue-14px'
                                        htmlFor='password'>
                                        {isPasswordRequired ? (
                                            <>
                                                {'Mot de passe '}{' '}
                                                <span className='red-value'> *</span>
                                            </>
                                        ) : (
                                            'Mot de passe'
                                        )}
                                    </label>
                                    <input
                                        type='password'
                                        className={
                                            'input-box input-hover inter-normal-pale-sky-16px' +
                                            (formState.errors.passwordInput ? ' input-error' : '')
                                        }
                                        id='password'
                                        placeholder='•••••••••'
                                        {...register('passwordInput', {
                                            onChange: e => {
                                                let bool = e.target.value == '' ? true : false;
                                                setIsPasswordRequired(bool);
                                            },
                                            required: 'Veuillez saisir un mot de passe.',
                                            pattern: {
                                                value: /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                message:
                                                    'Veuillez saisir un mot de passe avec au moins 8 caractères, dont un caractère spécial.',
                                            },
                                        })}
                                    />
                                </div>
                                {formState.errors.passwordInput && (
                                    <p className='error-text'>
                                        {formState.errors.passwordInput.message}
                                    </p>
                                )}
                                <p className='hint-text'>
                                    Doit être au moins de 8 caractères et avoir un caractère spécial
                                    (@$!%*#?&)
                                </p>
                            </div>
                        </div>
                        <div className='input-field'>
                            <div className='input'>
                                <div className='input'>
                                    <label
                                        className='label inter-medium-oxford-blue-14px'
                                        htmlFor='confirmpassword'>
                                        {isPasswordConfirmRequired ? (
                                            <>
                                                {'Confirmer le mot de passe '}{' '}
                                                <span className='red-value'> *</span>
                                            </>
                                        ) : (
                                            'Confirmer le mot de passe'
                                        )}
                                    </label>
                                    <input
                                        type='password'
                                        className={
                                            'input-box input-hover inter-normal-pale-sky-16px' +
                                            (formState.errors.confirmpasswordInput
                                                ? ' input-error'
                                                : '')
                                        }
                                        id='confirmpassword'
                                        placeholder='•••••••••'
                                        {...register('confirmpasswordInput', {
                                            onChange: e => {
                                                let bool = e.target.value == '' ? true : false;
                                                setIsPasswordConfirmRequired(bool);
                                            },
                                            required: 'Les mots de passe doivent être identiques.',
                                            pattern: {
                                                value: /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                message:
                                                    'Veuillez saisir un mot de passe avec au moins 8 caractères, dont un caractère spécial.',
                                            },
                                        })}
                                    />
                                </div>
                                {formState.errors.confirmpasswordInput && (
                                    <p className='error-text'>
                                        {formState.errors.confirmpasswordInput.message}
                                    </p>
                                )}
                            </div>
                        </div>
                        <button type='submit' className='primary-submit'>
                            Valider
                        </button>
                    </div>
                </form>
                <div className='forgot-to-connect'>
                    <div>
                        <img
                            className='forgot-arrow-left'
                            alt='arrow-left'
                            src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3e454124ebb80b2e319c4/img/arrow-left@2x.svg'
                        />
                        <Link to='/'>Retourner à la connexion</Link>
                    </div>
                </div>
            </div>
        );
    };

    const ResetPasswordFormConfirmation = props => {
        const [isPasswordRequired, setIsPasswordRequired] = useState(true);
        const [isPasswordConfirmRequired, setIsPasswordConfirmRequired] = useState(true);

        return (
            <div className='forgot-content'>
                <div className='login-header'>
                    <div className='forgot-icon'>
                        <img
                            className='forgot-key'
                            alt='forgot-key'
                            src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62fb714ab4c0bacfeb1c1087/img/check-circle@2x.svg'
                        />
                    </div>
                    <div className='login-form-header'>
                        <div className='login-title'>Mot de passe réinitialisé</div>
                        <p className='subtitle inter-normal-pale-sky-16px'>
                            Votre mot de passe a été modifié avec succès, cliquer sur le bouton
                            ci-dessous pour vous connecter à la plateforme.
                        </p>
                    </div>
                </div>
                <Link className='primary-submit' to='/'>
                    Se connecter
                </Link>
            </div>
        );
    };

    return (
        <div className='container-center-horizontal'>
            {userEmail !== 0 && !searchParams.get('token') && ForgotPasswordConfirmation(props)}
            {userEmail === 0 && !searchParams.get('token') && ForgotPasswordForm(props)}
            {searchParams.get('token') && !validToken && ForgotPasswordForm(props)}
            {searchParams.get('token') &&
                userPassword === 0 &&
                validToken &&
                ResetPasswordForm(props)}
            {userPassword !== 0 && validToken && ResetPasswordFormConfirmation(props)}
        </div>
    );
}
