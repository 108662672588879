import '../../../RegisterCompanyForm/RegisterCompanyForm.css';
import './CompanyEdit.css';

import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import Form from '../../../Utils/Form';
import Input from '../../../Utils/Input';
import SelectCountry from '../../../Utils/SelectCountry';

import { useAuthState } from '../../../../context/context';

import CompanyDataService from '../../../../services/company.service';
import PersonDataService from '../../../../services/person.service';
import LocationDataService from '../../../../services/location.service';
import Sidebar from '../../Sidebar/Sidebar';

export default function CompanyEdit(props) {
    let { id } = useParams();
    const [initialObjectState, setInitialObjectState] = useState(null);
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });
    const [submitted, setSubmitted] = useState(false);

    const [newCompanyInfo, setNewCompanyInfo] = useState({
        logo: {},
    });
    const [selfEdit, setSelfEdit] = useState(false);

    const currentUser = useAuthState();

    const updateUploadedFiles = files => {
        setNewCompanyInfo({ ...newCompanyInfo, logo: files });
    };

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                name: '',
                siret: '',
                email: '',
                phonenumber: '',
                location_id: 0,
                status: 'pending',
                address: '',
                city: '',
                country: '',
                contact_id: 0,
                contact_first_name: '',
                contact_last_name: '',
                contact_email: '',
                persons_list: [],
            };
            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (id) {
                let fields =
                    'id, name, status, siret, location_id, location_city, location_postal_code, location_country, location_address, persons_list';
                let key = id;

                CompanyDataService.get(key, fields).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };
                    setInitialObjectState(objectData);
                });
            }
        };

        if (typeof props.id !== 'undefined' && props.id === 'me') {
            id =
                'SELECT Company AS A JOIN Person AS P ON P.org_id = A.id WHERE P.id = ' +
                currentUser.id;
            setSelfEdit(true);
        }

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState, selfEdit]);

    async function createCompanyAccount(iOrg, key, aFields) {
        try {
            let fields = {
                org_id: iOrg,
                name: aFields.siret.value,
                first_name: aFields.name.value,
                email: aFields.email.value,
                phone: aFields.phonenumber.value,
                function: 'Compte entreprise',
            };
            let output_fields = 'id, friendlyname';

            PersonDataService.upsert(key, fields, output_fields).then(response => {
                if ((response.code = '0')) {
                    return true;
                }
            });
        } catch (err) {
            console.log(err.message);
        }
    }

    async function createCompanyContact(iOrg, key, aFields) {
        try {
            let fields = {
                org_id: iOrg,
                name: aFields.contact_last_name.value,
                first_name: aFields.contact_first_name.value,
                email: aFields.contact_email.value,
                function: 'Représentant légal',
            };
            let output_fields = 'id, friendlyname';

            PersonDataService.upsert(key, fields, output_fields).then(response => {
                if ((response.code = '0')) {
                    return true;
                }
            });
        } catch (err) {
            console.log(err.message);
        }
    }

    async function createLocation(iOrg, aFields) {
        try {
            let fields = {
                org_id: iOrg,
                name: aFields.name.value,
                address: aFields.address.value,
                postal_code: aFields.postcode.value,
                city: aFields.city.value,
                country: aFields.country.value,
                status: 'active',
            };
            let output_fields = 'id, friendlyname';
            let key = initialObjectState.location_id;

            LocationDataService.upsert(key, fields, output_fields).then(response => {
                if ((response.code = '0')) {
                    let personKey =
                        'SELECT Person WHERE function IN ("Compte entreprise", "Représentant légal") AND org_id = ' +
                        iOrg;
                    let personFields = 'id, name, first_name, phone, email, function';
                    PersonDataService.getAll(personKey, personFields).then(response => {
                        console.log(response);
                        let company_account_id = 0;
                        let company_contact_id = 0;
                        Object.keys(response).forEach(key => {
                            if (response[key].function == 'Représentant légal') {
                                company_contact_id = response[key].id;
                            }
                            if (response[key].function == 'Compte entreprise') {
                                company_account_id = response[key].id;
                            }
                        });
                        createCompanyAccount(iOrg, company_account_id, aFields);
                        createCompanyContact(iOrg, company_contact_id, aFields);
                    });
                    return true;
                }
            });
        } catch (err) {
            console.log(err.message);
        }
    }

    async function createCompany(formFields) {
        try {
            let fields = {
                parent_id: 'SELECT Organization WHERE name = "Jobers"',
                name: formFields.name.value,
                status: 'active',
                siret: formFields.siret.value,
            };
            let output_fields = 'id, friendlyname';

            CompanyDataService.upsert(id, fields, output_fields).then(response => {
                if ((response.code = '0')) {
                    createLocation(response.key, formFields);
                }
            });
        } catch (err) {
            console.log(err.message);
        }
    }

    const onSubmit = (fields, errors) => {
        setFormData({
            fields,
            errors,
        });
        createCompany(fields);
        setSubmitted(true);
    };

    return (
        <div className='missionView screen'>
            <div className='flex-col'>
                <Sidebar />
            </div>
            <div className='flex-col'>
                <div className='flex-row'>
                    {initialObjectState && (
                        <Form
                            className='form'
                            onSubmit={onSubmit}
                            fields={formData.fields}
                            errors={formData.errors}
                            validateOnSubmit={true}>
                            <Input
                                id='name'
                                label='Nom de l’entreprise'
                                placeholder='Nom de l’entreprise'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.name
                                        : ''
                                }
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            {currentUser.function === 'Jobers' && (
                                <Input
                                    id='status'
                                    label='Statut'
                                    defaultValue='pending'
                                    value={
                                        initialObjectState && id !== 'undefined'
                                            ? initialObjectState.status
                                            : ''
                                    }
                                    type='select'
                                    options={[
                                        { value: 'active', label: 'Active' },
                                        { value: 'inactive', label: 'Inactive' },
                                        { value: 'pending', label: 'En attente' },
                                    ]}
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                            )}

                            <Input
                                accept='.jpg,.png,.jpeg,.pdf'
                                label='Logo de l’entreprise'
                                updateFilesCb={updateUploadedFiles}
                                id='logo'
                                type='file'
                                files={newCompanyInfo.logo}
                            />
                            <Input
                                id='siret'
                                label='Numéro de SIRET'
                                placeholder='123 568 941 00056'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.siret
                                        : ''
                                }
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='email'
                                label='Adresse email associée au compte'
                                placeholder='contact@agence.fr'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.email
                                        : ''
                                }
                                validate='required|email'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='phonenumber'
                                label='Numéro de téléphone'
                                placeholder='+33 6 11 22 33 44'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.phone
                                        : ''
                                }
                                validate='required|phone'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='password'
                                label='Mot de passe'
                                placeholder='•••••••••'
                                validate='password'
                                type='password'
                                hintmessage='Doit être au moins de 8 caractères et avoir un caractère spécial'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass: 'input-box inter-normal-pale-sky-16px',
                                }}
                            />
                            <div className='contact-container'>
                                <Input
                                    id='address'
                                    label='Adresse'
                                    placeholder='Place de l’Hôtel-de-Ville'
                                    value={
                                        initialObjectState && id !== 'undefined'
                                            ? initialObjectState.address
                                            : ''
                                    }
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                                <div className='div-2-col'>
                                    <Input
                                        id='city'
                                        label='Ville'
                                        placeholder='Paris'
                                        value={
                                            initialObjectState && id !== 'undefined'
                                                ? initialObjectState.city
                                                : ''
                                        }
                                        validate='required'
                                        classes={{
                                            contClass: 'input-col-1',
                                            errorClass: 'error-text',
                                            fieldClass:
                                                'input-1col border-1px-mischka inter-normal-mirage-16px',
                                        }}
                                    />
                                    <Input
                                        id='postcode'
                                        label='Code postal'
                                        placeholder='75004'
                                        value={
                                            initialObjectState && id !== 'undefined'
                                                ? initialObjectState.postcode
                                                : ''
                                        }
                                        validate='required'
                                        classes={{
                                            contClass: 'input-col-1',
                                            errorClass: 'error-text',
                                            fieldClass:
                                                'input-1col border-1px-mischka inter-normal-mirage-16px',
                                        }}
                                    />
                                </div>
                                <SelectCountry
                                    id='country'
                                    label='Pays'
                                    validate='required'
                                    value={
                                        initialObjectState && id !== 'undefined'
                                            ? initialObjectState.country
                                            : 'France'
                                    }
                                    defaultValue='France'
                                    classes={{
                                        contClass: 'input-field',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-mirage-16px',
                                    }}
                                />
                            </div>
                            <div className='contact-container'>
                                <div className='contact-panel'>
                                    <div className='contact-title'>Référent mission</div>
                                </div>
                                <div className='div-2-col'>
                                    <Input
                                        id='contact_last_name'
                                        label='Nom'
                                        placeholder='Doe'
                                        value={
                                            initialObjectState && id !== 'undefined'
                                                ? initialObjectState.contact_last_name
                                                : ''
                                        }
                                        validate='required'
                                        classes={{
                                            contClass: 'input-col-1',
                                            errorClass: 'error-text',
                                            fieldClass:
                                                'input-1col border-1px-mischka inter-normal-mirage-16px',
                                        }}
                                    />
                                    <Input
                                        id='contact_first_name'
                                        label='Prénom'
                                        placeholder='John'
                                        value={
                                            initialObjectState && id !== 'undefined'
                                                ? initialObjectState.contact_first_name
                                                : ''
                                        }
                                        validate='required'
                                        classes={{
                                            contClass: 'input-col-1',
                                            errorClass: 'error-text',
                                            fieldClass:
                                                'input-1col border-1px-mischka inter-normal-mirage-16px',
                                        }}
                                    />
                                </div>
                                <Input
                                    id='contact_email'
                                    label='Adresse email'
                                    placeholder='contact@agence.fr'
                                    value={
                                        initialObjectState && id !== 'undefined'
                                            ? initialObjectState.contact_email
                                            : ''
                                    }
                                    validate='required|email'
                                    classes={{
                                        contClass: 'input-field',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                            </div>
                            <div className='bottom-actions'>
                                <button type='submit' className='primary-submit'>
                                    {initialObjectState && id !== 'undefined'
                                        ? "Mettre à jour l'entreprise"
                                        : "Créer l'entreprise"}
                                </button>
                                {submitted && !selfEdit && (
                                    <Navigate to='/listCompany' replace={true} />
                                )}
                                {submitted && selfEdit && (
                                    <Navigate to='/company/me' replace={true} />
                                )}
                            </div>
                            <div className='flex-row' />
                        </Form>
                    )}
                </div>
            </div>
        </div>
    );
}
