const validations = {
    required: {
        rule: () => /\S/,
        formatter(fieldName) {
            return `Ce champ est obligatoire.`;
        },
    },
    numeric: {
        rule: () => /^\d+$/,
        formatter(fieldName) {
            return `${fieldName} ne peut contenir que des nombres.`;
        },
    },
    password: {
        rule: () => /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        formatter(fieldName) {
            return `Veuillez saisir un mot de passe avec au moins 8 caractères, dont un caractère spécial.`;
        },
    },
    confirm_password: {
        rule: () => /^(?=.*[A-Za-z])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        formatter(fieldName) {
            return `Veuillez saisir un mot de passe avec au moins 8 caractères, dont un caractère spécial.`;
        },
    },
    email_equal: {
        rule: () => /^$|^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
        formatter(fieldName) {
            return `Veuillez renseigner une adresse email valide.`;
        },
    },
    school_required: {
        rule: () => /^$/,
        formatter(fieldName) {
            return `Veuillez préciser en dessous le nom et la ville de votre école.`;
        },
    },
    iban: {
        rule: () =>
            /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/,
        formatter(fieldName) {
            return `Veuillez saisir un IBAN valide`;
        },
    },
    bic: {
        rule: () => /^[A-Z]{6,6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3,3}){0,1}$/,
        formatter(fieldName) {
            return `Veuillez saisir un BIC valide`;
        },
    },
    ssn: {
        rule: () => /^[12][0-9]{2}(0[1-9]|1[0-2])(2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}([0-9]{2})$/,
        formatter(fieldName) {
            return `Veuillez saisir un numéro de sécurité sociale valide`;
        },
    },
    url: {
        rule: () => /^$|^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
        formatter(fieldName) {
            return `Veuillez saisir une URL valide`;
        },
    },
    email: {
        rule: () => /^$|^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
        formatter(fieldName) {
            return `Veuillez renseigner une adresse email valide.`;
        },
    },
    phone: {
        rule: () => /^$|^(0[1-9])(?:[ _.-]?(\d{2})){4}$/,
        formatter(fieldName) {
            return `Veuillez renseigner un numéro de téléphone valide.`;
        },
    },
};

export default validations;
