import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';

import Form from '../Utils/Form';
import Input2 from '../Utils/Input2';
import AlertBox from '../Utils/AlertBox';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import MissionDataService from '../../services/mission.service';
import AttachmentDataService from '../../services/attachment.service';
import StudentDataService from '../../services/student.service';
import CompanyDataService from '../../services/company.service';
import MissionTermDataService from '../../services/missionterm.service';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
    label,
    item_id,
    type,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const currentUser = useAuthState();
    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    const sendFiles = async files => {
        let data = false;
        let b64 = false;
        let attachment = false;
        let filename = false;
        let operation = 'add_association_document';

        if (files.length > 0) {
            let file = files[0];
            b64 = await convertBase64(file);
            data = b64.substring(b64.indexOf('base64,') + 7);
            filename = 'RapportMission.' + file.name.split('.').pop();
            attachment = {
                item_class: 'Mission',
                item_id: item_id,
                temp_id: filename,
                type: 'RapportMission',
                item_org_id: currentUser.org_id,
                creation_date: new Date(),
                contents: {
                    data: data,
                    filename: filename,
                    mimetype: file.type,
                },
            };
        }

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(attachment));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        return await axios(config);
    };

    const handleNewFileUpload = async e => {
        const { files: newFiles } = e.target;
        if (newFiles.length > 0) {
            let response = await sendFiles(newFiles);
            if (response.data.code === 0) {
                window.location.reload(false);
            }
        }
    };

    return (
        <section>
            <div className='list-table-link' onClick={e => handleUploadBtnClick(e)}>
                <span>{label}</span>
            </div>
            <input
                type='file'
                ref={fileInputField}
                onChange={handleNewFileUpload}
                title=''
                value=''
                {...otherProps}
            />
        </section>
    );
};

export default function MissionProfil(props) {
    let navigate = useNavigate();
    const [initialObjectState, setInitialObjectState] = useState(null);
    const [data, setData] = useState(null);
    const [company, setCompany] = useState(null);
    const [student, setStudent] = useState(null);
    const [editMode, setEditMode] = useState(0);
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });
    const { id, student_id } = useParams();
    const currentUser = useAuthState();
    const [alertBox, setAlertBox] = useState({
        message: '',
        classes: '',
    });
    const [confirmBox, setConfirmBox] = useState({
        message: '',
        classes: '',
    });

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                mission_terms: [],
                last_update: moment().format('YYYY-MM-DD HH:mm:ss'),
            };
            if (id) {
                MissionDataService.get(id).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    AttachmentDataService.getAll(
                        'SELECT Attachment WHERE item_class = "Mission" AND item_id = ' + id,
                        'contents'
                    ).then(fileresponse => {
                        objectData.files = fileresponse;
                        objectData.files.forEach(file => {
                            // if (file.filename.includes('CV.')) {
                            //     objectData.cv = file;
                            // }
                        });
                        if (objectData.company_id > 0) {
                            CompanyDataService.get(objectData.company_id).then(company => {
                                setCompany(company);
                                setInitialObjectState(objectData);
                                if (objectData.student_id > 0) {
                                    StudentDataService.get(objectData.student_id).then(student => {
                                        MissionTermDataService.getAll(
                                            'SELECT MissionTerm WHERE mission_id = ' + id
                                        ).then(terms => {
                                            objectData.mission_terms = terms;
                                            setStudent(student);
                                            setInitialObjectState(objectData);

                                            return true;
                                        });
                                    });
                                }
                                if (student_id > 0) {
                                    StudentDataService.get(student_id).then(student => {
                                        setStudent(student);
                                        setInitialObjectState(objectData);
                                        return true;
                                    });
                                }
                                if (objectData.student_id === '0') {
                                    objectData.students_list.forEach(item => {
                                        if (item.status === 'accepted') {
                                            StudentDataService.get(item.student_id).then(
                                                student => {
                                                    setStudent(student);
                                                    setInitialObjectState(objectData);
                                                    return true;
                                                }
                                            );
                                        }
                                    });
                                }
                                return true;
                            });
                        }

                        objectData.terms = new Array(18).fill(0);

                        // loop over terms_list
                        objectData.terms_list.forEach(item => {
                            let index = item.name.charCodeAt(0);
                            let arrIndex = index - 65;
                            objectData.terms[arrIndex] = parseFloat(item.nb_je);
                            if (item.nb_je == '0.00') {
                                objectData.terms[arrIndex + '_empty'] = true;
                            }
                            objectData.terms[arrIndex + '_status'] = item.status;
                            objectData.terms[arrIndex + '_net'] = item.net_student;
                        });
                        objectData.start_date = moment(objectData.start_date).toDate();
                        objectData.end_date = moment(objectData.end_date).toDate();

                        if (objectData.price_ht < 1 && objectData.status == 'ongoing') {
                            setAlertBox({
                                message: 'Veuillez renseigner : € HT / jour étude',
                                classes: 'alert alert-danger',
                            });
                        }

                        setInitialObjectState(objectData);
                    });
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
        if (initialObjectState) {
            calculateCalendar(initialObjectState.start_date, initialObjectState.end_date);
        }
    }, [initialObjectState]);

    const onSubmit = async (fields, errors) => {
        setFormData({
            fields,
            errors,
        });

        let key = id;
        let aDataFields = {};
        let terms = [];
        let total_je = 0;

        let start_date = new Date(initialObjectState.start_date);
        let end_date = new Date(initialObjectState.end_date);

        for (let i = 1; i <= 18; i++) {
            if (typeof data[i - 1] !== 'undefined') {
                total_je = parseFloat(data[i - 1].nb_je);
            }
            if (initialObjectState.terms[i - 1] > 0 && typeof data[i - 1] !== 'undefined') {
                let currMonth = data[i - 1];

                if (currMonth.name !== 'Total') {
                    terms.push({
                        name: currMonth.name,
                        nb_je: initialObjectState.terms[i - 1],
                        start_date: currMonth.start_date,
                        end_date: currMonth.end_date,
                        ht: currMonth.ht,
                        tva: currMonth.tva,
                        ttc: currMonth.ttc,
                    });
                }
            }
        }

        aDataFields = {
            id: key,
            name: fields.name.value,
            description:
                typeof fields.description !== 'undefined'
                    ? fields.description.value
                    : initialObjectState.description,
            start_date: moment(start_date).format('MM/DD/YYYY'),
            end_date: moment(end_date).format('MM/DD/YYYY'),
            student_id: student_id > 0 ? student_id : initialObjectState.student_id,
            price_ht: initialObjectState.price_ht,
            je: total_je,
            terms: terms,
        };

        let oData = new URLSearchParams();
        oData.append('operation', 'upsert_mission');
        oData.append('data', JSON.stringify(aDataFields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: oData,
        };

        let response = await axios(config);

        if (response.data.code === 0) {
            if (student_id > 0) {
                navigate('/mission/' + id + '/profil');
            }
            window.location.reload(false);
        }

        if (response.data.code !== 0) {
            console.log(response.data.message);
        }
    };

    const confirmAllTerms = () => {
        initialObjectState.terms.forEach((item, index) => {
            if (
                (typeof initialObjectState.terms[index + '_status'] !== 'undefined' &&
                    initialObjectState.terms[index + '_status'] !== 'terminated') ||
                (typeof initialObjectState.terms[index + '_status'] === 'undefined' &&
                    initialObjectState.terms[index] > 0)
            ) {
                let aDataFields = {
                    status: 'pending_student',
                    mission_id: initialObjectState.id,
                    term_name: String.fromCharCode(index + 65),
                    term_je: initialObjectState.terms[index],
                };

                if (typeof initialObjectState.terms[index + '_status'] === 'undefined') {
                    if (typeof data[index] !== 'undefined') {
                        aDataFields.term_start_date = data[index].start_date;
                        aDataFields.term_end_date = data[index].end_date;
                    }
                }

                let termData = new URLSearchParams();
                termData.append('operation', 'confirm_term');
                termData.append('data', JSON.stringify(aDataFields));
                let config = {
                    method: 'post',
                    url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Accept: 'application/json',
                    },
                    data: termData,
                };
                axios(config)
                    .then(function (response) {
                        if (response.data.code === 0) {
                            setConfirmBox({
                                message: response.data.message,
                                classes: 'alert alert-success',
                            });
                            setTimeout(() => {
                                window.location.reload(false);
                            }, 5000);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        });
    };

    const confirmTerm = (row, index) => {
        if (
            initialObjectState &&
            ((typeof initialObjectState.terms[index + '_status'] !== 'undefined' &&
                initialObjectState.terms[index + '_status'] !== 'terminated') ||
                (typeof initialObjectState.terms[index + '_status'] === 'undefined' &&
                    initialObjectState.terms[index] > 0))
        ) {
            let aDataFields = {
                status: isCompany() ? 'pending_student' : 'terminated',
                mission_id: initialObjectState.id,
                term_name: row.name,
                term_je: initialObjectState.terms[index],
            };

            let data = new URLSearchParams();
            data.append('operation', 'confirm_term');
            data.append('data', JSON.stringify(aDataFields));

            let config = {
                method: 'post',
                url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Accept: 'application/json',
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    if (response.data.code === 0) {
                        setConfirmBox({
                            message: response.data.message,
                            classes: 'alert alert-success',
                        });
                        setTimeout(() => {
                            window.location.reload(false);
                        }, 5000);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    const isCompany = () => {
        if (initialObjectState) {
            if (
                currentUser.function === 'Compte entreprise' &&
                initialObjectState.company_id === currentUser.org_id
            ) {
                return true;
            }
            if (
                currentUser.function === 'Représentant légal' &&
                initialObjectState.company_id === currentUser.org_id
            ) {
                return true;
            }
            if (
                currentUser.function === 'Personne de contact' &&
                initialObjectState.company_id === currentUser.org_id
            ) {
                return true;
            }
        }
        return false;
    };

    const isStudent = () => {
        if (currentUser.function === 'Student') {
            return true;
        }
        return false;
    };

    const displayTerm = (row, index) => {
        let canEdit = (editMode === 2 || typeof student_id !== 'undefined') && row.name != 'Total';
        let momentDate = moment();
        let currDate = moment().format('YYYY-MM-DD');
        let rowDate = moment(row.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');

        if (
            isCompany() &&
            ((momentDate.format('D') > 25 && moment(rowDate).isSame(currDate, 'month')) ||
                (moment(rowDate).isBefore(currDate) &&
                    !moment(rowDate).isSame(currDate, 'month'))) &&
            initialObjectState.terms[index + '_status'] !== 'terminated' &&
            initialObjectState.status == 'ongoing' &&
            row.name != 'Total'
        ) {
            canEdit = true;
        }

        if (canEdit) {
            return (
                <input
                    key={index}
                    name={'nb_je_' + row.name}
                    type='text'
                    className='small-profil-value'
                    value={initialObjectState.terms[index]}
                    onChange={e => {
                        let terms = initialObjectState.terms;
                        terms[index] = e.target.value;
                        if (e.target.value > 31) {
                            terms[index] = 31;
                        }
                        setInitialObjectState({
                            ...initialObjectState,
                            terms,
                        });
                    }}
                />
            );
        }

        return (
            <>
                <span>{row.name === 'Total' ? row.nb_je : initialObjectState.terms[index]}</span>
            </>
        );
    };

    const displayField = (label, value, options) => {
        let readOnlyFields = [
            'org_id',
            'legal_contact',
            'company_address',
            'company_postcode',
            'company_city',
            'siret',
            'domain',
            'nb_je_Total',
            'student',
            'ssn',
            'student_address',
            'student_postalcode',
            'student_city',
        ];
        let confirmFields = ['student_id', 'name', 'price_ht', 'start_date', 'end_date'];
        if (true) {
            if (
                (editMode == 2 && readOnlyFields.indexOf(options.name) == -1) ||
                (typeof student_id !== 'undefined' && confirmFields.indexOf(options.name) > -1) ||
                (typeof student_id !== 'undefined' &&
                    options.name.indexOf('nb_je_') > -1 &&
                    readOnlyFields.indexOf(options.name) == -1)
            ) {
                return (
                    <>
                        <Input2 {...options} />
                    </>
                );
            }
            if (options.name.indexOf('nb_je_') > -1) {
                return (
                    <>
                        <span>{value}</span>
                    </>
                );
            }

            if (label === 'Description mission') {
                return (
                    <>
                        <div className='list-flex-child no-flex profil-flex-container'>
                            <div className='profil-flex-child'>
                                <div className='profil-label'>{label}</div>
                            </div>
                            <div className='profil-flex-child student-description-flex'>
                                <div
                                    className='profil-value profil-description-value gray-value'
                                    dangerouslySetInnerHTML={{
                                        __html: value ? value : '',
                                    }}></div>
                            </div>
                        </div>
                    </>
                );
            }

            return (
                <>
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'>
                            <div className='profil-label'>{label}</div>
                        </div>
                        <div className='profil-flex-child'>
                            <div className='profil-value gray-value'>{value}</div>
                        </div>
                    </div>
                </>
            );
        }
    };

    const canApply = () => {
        let status = false;
        if (
            initialObjectState &&
            initialObjectState.student_id === '0' &&
            currentUser.function === 'Student'
        ) {
            status = 1;
        }
        initialObjectState &&
            initialObjectState.students_list.length > 0 &&
            initialObjectState.students_list.forEach(item => {
                if (initialObjectState.student_id === '0' && item.student_id === currentUser.id) {
                    status = 2;
                }
                if (item.status === 'accepted' && item.student_id === currentUser.id) {
                    status = 3;
                }
            });
        return status;
    };

    const canConfirmApplication = () => {
        let status = false;
        initialObjectState &&
            initialObjectState.students_list.length > 0 &&
            initialObjectState.students_list.forEach(item => {
                if (
                    item.status === 'accepted' &&
                    item.student_id === currentUser.id &&
                    initialObjectState.status == 'pending'
                ) {
                    status = true;
                }
            });
        return status;
    };

    const confirmApplication = () => {
        let fields = {
            mission_id: initialObjectState.id,
            student_id: currentUser.id,
        };

        let data = new URLSearchParams();
        data.append('operation', 'confirm_application');
        data.append('data', JSON.stringify(fields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.code === 0) {
                    window.location.reload(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const deleteOffer = () => {
        let aDataFields = {
            mission_id: id,
        };

        let data = new URLSearchParams();
        data.append('operation', 'delete_offer');
        data.append('token', currentUser.token);
        data.append('data', JSON.stringify(aDataFields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.code === 0) {
                    return navigate('/mission/list');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const canEdit = () => {
        let status = false;
        if (
            initialObjectState &&
            currentUser &&
            currentUser.org_id === initialObjectState.company_id &&
            initialObjectState.status === 'pending'
        ) {
            status = true;
        }
        return status;
    };

    const hasBeenAccepted = () => {
        let status = false;
        if (initialObjectState && initialObjectState.students_list.length > 0) {
            initialObjectState.students_list.forEach(item => {
                if (item.status === 'student_confirmed') {
                    status = true;
                }
            });
        }
        return status;
    };

    const displayDocuments = terms => {
        let str = [];
        terms.forEach(term => {
            str.push(
                <div className='profil-flex-container' key={term.name}>
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'>
                            {(currentUser.function === 'Jobers' ||
                                currentUser.org_id === initialObjectState.company_id) && (
                                <div className='profil-label'>Facture {term.name}</div>
                            )}
                        </div>
                        <div className='profil-flex-child'>
                            {(currentUser.function === 'Jobers' ||
                                currentUser.org_id === initialObjectState.company_id) && (
                                <div
                                    className={
                                        term.facture_id > 0
                                            ? 'profil-value'
                                            : 'profil-value gray-value'
                                    }>
                                    {term.facture_id > 0 ? (
                                        <a
                                            className='list-table-link'
                                            href={
                                                'https://itop.jobers.fr/pdf_f.php?mission_term_id=' +
                                                term.id
                                            }
                                            target='_blank'>
                                            {term.facture_id_friendlyname}
                                        </a>
                                    ) : (
                                        'F' + initialObjectState.ref + term.name
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'>
                            {(currentUser.function === 'Jobers' ||
                                (currentUser.function === 'Student' &&
                                    initialObjectState.student_id === currentUser.id)) && (
                                <div className='profil-label'>BV {term.name}</div>
                            )}
                        </div>
                        <div className='profil-flex-child'>
                            {(currentUser.function === 'Jobers' ||
                                (currentUser.function === 'Student' &&
                                    initialObjectState.student_id === currentUser.id)) && (
                                <div
                                    className={
                                        term.facture_id > 0
                                            ? 'profil-value'
                                            : 'profil-value gray-value'
                                    }>
                                    {term.facture_id > 0 ? (
                                        <a
                                            className='list-table-link'
                                            href={
                                                'https://itop.jobers.fr/pdf_bv.php?mission_term_id=' +
                                                term.id
                                            }
                                            target='_blank'>
                                            {term.payment_id_friendlyname}
                                        </a>
                                    ) : (
                                        'BV' + initialObjectState.ref + term.name
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        });

        let i = 0;
        terms.forEach(term => {
            if (term.payment_id > 0) {
                i++;
            }
        });

        if (i == terms.length) {
            str.push(
                <div className='profil-flex-container' key='rapport'>
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'></div>
                        <div className='profil-flex-child'></div>
                    </div>
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'>
                            {(currentUser.function === 'Jobers' ||
                                (currentUser.function === 'Student' &&
                                    initialObjectState.student_id === currentUser.id)) && (
                                <div className='profil-label'>Rapport de mission</div>
                            )}
                        </div>
                        <div className='profil-flex-child'>
                            {(currentUser.function === 'Jobers' ||
                                (currentUser.function === 'Student' &&
                                    initialObjectState.student_id === currentUser.id)) && (
                                <div className='profil-value'>
                                    <a
                                        className='list-table-link'
                                        href={
                                            'https://itop.jobers.fr/pdf_rm.php' +
                                            '?mission_id=' +
                                            initialObjectState.id
                                        }
                                        target='_blank'>
                                        Télécharger
                                    </a>{' '}
                                    /{' '}
                                    {/* <a
                                        className='list-table-link'
                                        href={'https://itop.jobers.fr/pdf/Rapport.pdf'}
                                        target='_blank'>
                                        Charger
                                    </a> */}
                                    <FileUpload
                                        type='RapportMission'
                                        item_id={initialObjectState.id}
                                        accept='.pdf'
                                        label='Charger'
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        return str;
    };

    let columns = [];
    if (currentUser.function == 'Student') {
        columns = [
            {
                name: 'Echéance',
                selector: row => row.name,
            },
            {
                name: 'Début',
                selector: row => row.start_date,
            },
            {
                name: 'Fin',
                selector: row => row.end_date,
            },
            {
                name: 'Nb JE / mois',
                selector: (row, index) => displayTerm(row, index),
            },
        ];

        if (
            student &&
            // student.id_stripe !== '' &&
            initialObjectState &&
            initialObjectState.student_id === currentUser.id &&
            initialObjectState.status === 'ongoing'
        ) {
            columns = [
                ...columns,
                ...[
                    {
                        name: 'Confirmer',
                        selector: (row, index) => {
                            let momentDate = moment();
                            let currDate = moment().format('YYYY-MM-DD');
                            let rowDate = moment(row.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');

                            return initialObjectState.terms[index + '_status'] ==
                                'pending_student' &&
                                isStudent() &&
                                ((momentDate.format('D') > 25 &&
                                    moment(rowDate).isSame(currDate, 'month')) ||
                                    (moment(rowDate).isBefore(currDate) &&
                                        !moment(rowDate).isSame(currDate, 'month'))) &&
                                currDate >= rowDate ? (
                                <span
                                    className='list-table-link'
                                    onClick={() => {
                                        confirmTerm(row, index);
                                    }}>
                                    Confirmer
                                </span>
                            ) : (
                                ''
                            );
                        },
                    },
                ],
            ];
        }

        columns = [
            ...columns,
            ...[
                {
                    name: 'Net étudiant €',
                    selector: row => row.student_net,
                },
            ],
        ];
    }

    if (currentUser.function !== 'Student') {
        columns = [
            {
                name: 'Echéance',
                selector: row => row.name,
            },
            {
                name: 'Début',
                selector: row => row.start_date,
            },
            {
                name: 'Fin',
                selector: row => row.end_date,
            },
            {
                name: 'Nb JE / mois',
                selector: (row, index) => displayTerm(row, index),
            },
        ];

        if (initialObjectState && initialObjectState.status === 'ongoing') {
            columns = [
                ...columns,
                ...[
                    {
                        name: 'Confirmer',
                        selector: (row, index) => {
                            let momentDate = moment();
                            let currDate = moment().format('YYYY-MM-DD');
                            let rowDate = moment(row.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');

                            return initialObjectState.terms[index + '_status'] !== 'terminated' &&
                                isCompany() &&
                                ((momentDate.format('D') > 25 &&
                                    moment(rowDate).isSame(currDate, 'month')) ||
                                    (moment(rowDate).isBefore(currDate) &&
                                        !moment(rowDate).isSame(currDate, 'month'))) &&
                                currDate >= rowDate ? (
                                <span
                                    className='list-table-link'
                                    onClick={() => {
                                        confirmTerm(row, index);
                                    }}>
                                    Confirmer
                                </span>
                            ) : (
                                ''
                            );
                        },
                    },
                ],
            ];
        }

        columns = [
            ...columns,
            ...[
                {
                    name: 'HT €',
                    selector: row => row.ht,
                },
                {
                    name: 'TVA (20%) €',
                    selector: row => row.tva,
                },
                {
                    name: 'TTC €',
                    selector: row => row.ttc,
                },
                {
                    name: 'Net étudiant €',
                    selector: row => row.student_net,
                },
            ],
        ];
    }

    const createDocumentLink = file => {
        if (file) {
            return (
                'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=display_document&filename=' +
                file.filename +
                '&id=' +
                file.id +
                '&obj=Person::' +
                id
            );
        }
    };

    const calculateNetStudent = ht_je => {
        let value = Number(ht_je);
        switch (true) {
            case value <= 80:
                return 0.51729;
            case value >= 80 && value < 90:
                return 0.51729;
            case value >= 90 && value < 100:
                return 0.5362;
            case value >= 100 && value < 110:
                return 0.55133;
            case value >= 110 && value < 120:
                return 0.56379;
            case value >= 120 && value < 130:
                return 0.5741;
            case value >= 130 && value < 140:
                return 0.58282;
            case value >= 140 && value < 150:
                return 0.59036;
            case value >= 150 && value < 160:
                return 0.59683;
            case value >= 160 && value < 170:
                return 0.6025;
            case value >= 170 && value < 180:
                return 0.60755;
            case value >= 180 && value < 190:
                return 0.61199;
            case value >= 190 && value < 200:
                return 0.61596;
            case value >= 200 && value < 210:
                return 0.61958;
            case value >= 210 && value < 220:
                return 0.62282;
            case value >= 220:
                return 0.62576;

            default:
                break;
        }
    };

    const calculateCalendar = (start_date, end_date, options = {}) => {
        let arrMonthletters = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
        let months = [];
        let start = moment(start_date);
        let end = moment(end_date);
        let month = start.clone();
        let total_je = 0;
        let total_tva = 0;
        let total_ht = 0;
        let total_ttc = 0;
        let total_student_net = 0;
        let index = 0;
        let price_ht = options.price_ht ? options.price_ht : initialObjectState.price_ht;

        while (month.isSameOrBefore(end)) {
            let currMonth = {};
            let startOfMonth, endOfMonth;
            let status = 'pending';

            currMonth.name = arrMonthletters[index];
            currMonth.month = month.month() + 1;

            startOfMonth = moment(month).startOf('month');
            if (index == 0) {
                startOfMonth = moment(start_date);
            }

            endOfMonth = moment(month).endOf('month');
            if (month.month() + 1 == end.month() + 1) {
                endOfMonth = moment(end_date);
            }

            let nbDays = endOfMonth.diff(startOfMonth, 'days') + 1;

            if (nbDays > 21) {
                nbDays = 21;
            }

            if (initialObjectState && initialObjectState.terms[index]) {
                nbDays = initialObjectState.terms[index];
            }

            if (
                initialObjectState &&
                typeof initialObjectState.terms[index + '_empty'] !== 'undefined'
            ) {
                nbDays = 0;
            }

            if (
                initialObjectState &&
                typeof initialObjectState.terms[index + '_status'] !== 'undefined'
            ) {
                status = initialObjectState.terms[index + '_status'];
            }

            if (
                initialObjectState &&
                initialObjectState.terms[index] === 0 &&
                !options.nb_je_name
            ) {
                initialObjectState.terms[index] = Number(nbDays);
            }

            currMonth.start_date = startOfMonth.format('DD-MM-YYYY');
            currMonth.end_date = endOfMonth.format('DD-MM-YYYY');

            if (options.nb_je_name && options.nb_je_name === currMonth.name) {
                // nbDays = Number(nb_je_value) > nbDays ? nbDays : Number(nb_je_value);
                nbDays = Number(options.nb_je_value);
                initialObjectState.terms[index] = Number(nbDays);
            }

            currMonth.mission_start_date = start_date;
            currMonth.mission_end_date = end_date;
            currMonth.status = status;
            currMonth.nb_je = Number(nbDays);
            currMonth.ht = initialObjectState.terms[index] * price_ht;
            currMonth.ht = currMonth.ht.toFixed(2);
            currMonth.tva = parseFloat(currMonth.ht * 0.2).toFixed(2);
            currMonth.ttc = parseFloat(Number(currMonth.ht) + Number(currMonth.tva)).toFixed(2);

            let studentTx = calculateNetStudent(price_ht);
            currMonth.student_net = parseFloat(currMonth.ttc * studentTx).toFixed(2);

            if (
                initialObjectState &&
                status === 'terminated' &&
                typeof initialObjectState.terms[index + '_net'] !== 'undefined'
            ) {
                currMonth.student_net = initialObjectState.terms[index + '_net'];
            }

            if (currMonth.student_net == '') {
                currMonth.student_net = parseFloat(0.0).toFixed(2);
            }

            total_je = parseFloat(total_je) + parseFloat(currMonth.nb_je);
            total_tva = parseFloat(total_tva) + parseFloat(currMonth.tva);
            total_ht = parseFloat(total_ht) + parseFloat(currMonth.ht);
            total_ttc = parseFloat(total_ttc) + parseFloat(currMonth.ttc);
            total_student_net = parseFloat(total_student_net) + parseFloat(currMonth.student_net);

            months.push(currMonth);
            month.add(1, 'month');
            index++;
        }

        let totalMonth = {
            name: 'Total',
            start_date: '',
            end_date: '',
            mission_start_date: start_date,
            mission_end_date: end_date,
            nb_je: total_je,
            tva: parseFloat(total_tva).toFixed(2),
            ht: parseFloat(total_ht).toFixed(2),
            ttc: parseFloat(total_ttc).toFixed(2),
            student_net: parseFloat(total_student_net).toFixed(2),
        };
        months.push(totalMonth);

        setInitialObjectState(initialObjectState);
        setData(months);
    };

    const apply = (student_id, mission_id) => {
        let aDataFields = {
            mission_id: mission_id,
            student_id: currentUser.id,
        };

        let data = new URLSearchParams();
        data.append('operation', 'apply_to_mission');
        data.append('data', JSON.stringify(aDataFields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                if (response.data.code === 0) {
                    window.location.reload(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    if (!company || Object.keys(company).length === 0 || !initialObjectState) {
        // if (!initialObjectState) {
        return (
            <div className='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

    return (
        <div className='backend-container'>
            <Sidebar />
            <Form
                onSubmit={onSubmit}
                fields={formData.fields}
                errors={formData.errors}
                validateOnSubmit={true}>
                {id > 0 && (
                    <div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child flex-4'>
                                {initialObjectState && (
                                    <h1 className='list-container-title'>
                                        {initialObjectState.name}
                                    </h1>
                                )}
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child'>
                                {typeof student_id === 'undefined' && (
                                    <p className='list-container-subtitle list-tab-subtitle'>
                                        Vous trouverez ici les informations et les documents
                                        relatifs à cette mission.
                                    </p>
                                )}
                                {initialObjectState && student && student_id > 0 && (
                                    <p className='list-container-subtitle list-tab-subtitle'>
                                        Merci de compléter les informations ci-dessous pour créer la
                                        mission avec {student.first_name}.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            {canEdit() && editMode == 2 && (
                                <div className='profil-container-submitbutton'>
                                    <span className='list-container-submitlabel'>
                                        <button type='submit' className='primary-submit'>
                                            Mettre à jour
                                        </button>
                                    </span>
                                </div>
                            )}
                            {canEdit() && editMode < 2 && typeof student_id === 'undefined' && (
                                <div className='profil-container-wipbutton fixbutton'>
                                    <span className='list-container-addlabel'>
                                        <a
                                            onClick={() => {
                                                editMode === 1 ? setEditMode(0) : setEditMode(1);
                                            }}>
                                            +
                                        </a>
                                    </span>
                                </div>
                            )}
                            {canEdit() && editMode == 1 && (
                                <div className='more_actions'>
                                    <div
                                        className='more_actions_action'
                                        onClick={() => setEditMode(2)}>
                                        {/* display ham-menu icon */}
                                        <div className='more_actions_icon'>
                                            <img
                                                className='ham_menu'
                                                src='/img/ham-menu.svg'
                                                alt='more actions'
                                            />
                                        </div>
                                        <div className='more_actions_item'>Modifier</div>
                                    </div>
                                    {initialObjectState &&
                                        initialObjectState.status == 'pending' && (
                                            <div
                                                className='more_actions_action'
                                                onClick={() => deleteOffer()}>
                                                {/* display ham-menu icon */}
                                                <div className='more_actions_icon'>
                                                    <img
                                                        className='ham_menu'
                                                        src='/img/ham-menu.svg'
                                                        alt='more actions'
                                                    />
                                                </div>
                                                <div className='more_actions_item'>Supprimer</div>
                                            </div>
                                        )}
                                </div>
                            )}
                        </div>
                        {canApply() == 1 && (
                            <div className='profil-container-submitbutton'>
                                <span className='list-container-submitlabel'>
                                    <button
                                        type='button'
                                        className='primary-submit'
                                        onClick={() =>
                                            apply(currentUser.id, initialObjectState.id)
                                        }>
                                        Postuler
                                    </button>
                                </span>
                            </div>
                        )}
                        {canApply() == 2 && (
                            <div className='profil-container-submitbutton profil-container-disabledbutton'>
                                <span className='list-container-submitlabel'>
                                    <button
                                        type='button'
                                        className='primary-submit gray-value disabled'>
                                        Candidature envoyée
                                    </button>
                                </span>
                            </div>
                        )}
                        {canConfirmApplication() && (
                            <div className='profil-container-submitbutton'>
                                <span className='list-container-submitlabel'>
                                    <button
                                        type='button'
                                        className='primary-submit'
                                        onClick={() => confirmApplication()}>
                                        Accepter la mission
                                    </button>
                                </span>
                            </div>
                        )}

                        {student_id && student_id > 0 && (
                            <div className='list-flex-container'>
                                <div className='profil-container-cancelbutton'>
                                    <span className='list-container-submitlabel'>
                                        <Link
                                            className='primary-submit'
                                            to={'/mission/application'}>
                                            Annuler
                                        </Link>
                                    </span>
                                </div>
                                {initialObjectState && initialObjectState.price_ht > 0 && (
                                    <div className='profil-container-submitbutton'>
                                        <span className='list-container-submitlabel'>
                                            <button type='submit' className='primary-submit'>
                                                Confirmer
                                            </button>
                                        </span>
                                    </div>
                                )}
                                {initialObjectState && initialObjectState.price_ht < 1 && (
                                    <div className='profil-container-submitbutton profil-container-disabledbutton'>
                                        <span className='list-container-submitlabel'>
                                            <button
                                                type='submit'
                                                disabled={true}
                                                className='primary-submit disabled'>
                                                Confirmer
                                            </button>
                                        </span>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child profil-flex-container'></div>
                            <div className='list-flex-child profil-flex-container'></div>
                        </div>
                    </div>
                )}

                {alertBox && alertBox.message !== '' && (
                    <AlertBox message={alertBox.message} classes={alertBox.classes} />
                )}

                {id > 0 && (
                    <div
                        className='list-flex-container'
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        {company &&
                            displayField('Entreprise', company.name, {
                                id: 'org_id',
                                name: 'org_id',
                                label: 'Entreprise',
                                placeholder: 'Entreprise',
                                type: 'text',
                                value: company.name,
                                validate: 'required',
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                        {initialObjectState &&
                            initialObjectState.student_id == '0' &&
                            typeof student_id === 'undefined' && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'></div>
                                    </div>
                                    <div className='profil-flex-child'></div>
                                </div>
                            )}
                        {student &&
                            displayField('Etudiant', student.first_name + ' ' + student.name, {
                                id: 'student',
                                name: 'student',
                                label: 'Etudiant',
                                placeholder: 'John Doe',
                                type: 'text',
                                value: student.first_name + ' ' + student.name,
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                    </div>
                )}

                {id > 0 && (
                    <div
                        className='list-flex-container'
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        {company &&
                            displayField(
                                'Représentant légal',
                                company.legal_first_name + ' ' + company.legal_last_name,
                                {
                                    id: 'legal_contact',
                                    name: 'legal_contact',
                                    label: 'Représentant légal',
                                    placeholder: 'Représentant légal',
                                    type: 'text',
                                    value: company.legal_first_name + ' ' + company.legal_last_name,
                                    validate: 'required',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                }
                            )}
                        {initialObjectState &&
                            initialObjectState.student_id == '0' &&
                            typeof student_id === 'undefined' && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'></div>
                                    </div>
                                    <div className='profil-flex-child'></div>
                                </div>
                            )}
                        {student &&
                            displayField('N° de sécurité sociale', student.ssn, {
                                id: 'ssn',
                                name: 'ssn',
                                label: 'N° de sécurité sociale',
                                placeholder: '1234567890123',
                                type: 'text',
                                value: student.ssn,
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                    </div>
                )}

                {id > 0 && (
                    <div
                        className='list-flex-container'
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        {company &&
                            displayField('SIRET', company.siret, {
                                id: 'siret',
                                name: 'siret',
                                label: 'SIRET',
                                placeholder: '12345678901234',
                                type: 'text',
                                value: company.siret,
                                validate: 'required',
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                        {initialObjectState &&
                            initialObjectState.student_id == '0' &&
                            typeof student_id === 'undefined' && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'></div>
                                    </div>
                                    <div className='profil-flex-child'></div>
                                </div>
                            )}
                        {student &&
                            displayField('Adresse', student.address, {
                                id: 'student_address',
                                name: 'student_address',
                                label: 'Adresse',
                                placeholder: '1 rue de la paix',
                                type: 'text',
                                value: student.address,
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                    </div>
                )}

                {id > 0 && (
                    <div
                        className='list-flex-container'
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        {company &&
                            displayField('Adresse', company.address, {
                                id: 'company_address',
                                name: 'company_address',
                                label: 'Adresse',
                                placeholder: '1 rue de la paix',
                                type: 'text',
                                value: company.address,
                                validate: 'required',
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                        {initialObjectState &&
                            initialObjectState.student_id == '0' &&
                            typeof student_id === 'undefined' && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'></div>
                                    </div>
                                    <div className='profil-flex-child'></div>
                                </div>
                            )}
                        {student &&
                            displayField('Code postal', student.postcode, {
                                id: 'student_postalcode',
                                name: 'student_postalcode',
                                label: 'Code postal',
                                placeholder: '75000',
                                type: 'text',
                                value: student.postcode,
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                    </div>
                )}

                {id > 0 && (
                    <div
                        className='list-flex-container'
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        {company &&
                            displayField('Code postal', company.postcode, {
                                id: 'company_postcode',
                                name: 'company_postcode',
                                label: 'Code postal',
                                placeholder: '75000',
                                type: 'text',
                                value: company.postcode,
                                validate: 'required',
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                        {initialObjectState &&
                            initialObjectState.student_id == '0' &&
                            typeof student_id === 'undefined' && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'></div>
                                    </div>
                                    <div className='profil-flex-child'></div>
                                </div>
                            )}
                        {student &&
                            displayField('Ville', student.city, {
                                id: 'student_city',
                                name: 'student_city',
                                label: 'Ville',
                                placeholder: 'Paris',
                                type: 'text',
                                value: student.city,
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                    </div>
                )}
                {id > 0 && (
                    <div
                        className='list-flex-container'
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        {company &&
                            displayField('Ville', company.city, {
                                id: 'company_city',
                                name: 'company_city',
                                label: 'Code postal',
                                placeholder: 'Paris',
                                type: 'text',
                                value: company.city,
                                validate: 'required',
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                        <div className='list-flex-child profil-flex-container'></div>
                    </div>
                )}
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    <div className='profil-detail-divider' />
                </div>

                {id > 0 && (
                    <div
                        className='list-flex-container'
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        {initialObjectState &&
                            editMode !== 2 &&
                            typeof student_id === 'undefined' && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'>Date début</div>
                                    </div>
                                    <div className='profil-flex-child'>
                                        <div className='profil-value gray-value'>
                                            {moment(initialObjectState.start_date).format(
                                                'DD/MM/YYYY'
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        {initialObjectState &&
                            (editMode === 2 || typeof student_id !== 'undefined') && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'>
                                            <label htmlFor='start_date'>Date début</label>
                                        </div>
                                    </div>
                                    <div className='profil-flex-child'>
                                        <DatePicker
                                            className='profil-value'
                                            dateFormat='dd/MM/yyyy'
                                            selected={initialObjectState.start_date}
                                            onChange={date => {
                                                if (initialObjectState.end_date < date) {
                                                    setInitialObjectState({
                                                        ...initialObjectState,
                                                        start_date: date,
                                                        end_date: date,
                                                    });
                                                    return;
                                                }
                                                setInitialObjectState({
                                                    ...initialObjectState,
                                                    start_date: date,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        {initialObjectState &&
                            editMode !== 2 &&
                            typeof student_id === 'undefined' && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'>Date fin</div>
                                    </div>
                                    <div className='profil-flex-child'>
                                        <div className='profil-value gray-value'>
                                            {moment(initialObjectState.end_date).format(
                                                'DD/MM/YYYY'
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        {initialObjectState &&
                            (editMode === 2 || typeof student_id !== 'undefined') && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'>
                                            <label htmlFor='end_date'>Date fin</label>
                                        </div>
                                    </div>
                                    <div className='profil-flex-child'>
                                        <DatePicker
                                            className='profil-value'
                                            dateFormat='dd/MM/yyyy'
                                            selected={initialObjectState.end_date}
                                            minDate={new Date(initialObjectState.start_date)}
                                            onChange={date => {
                                                setInitialObjectState({
                                                    ...initialObjectState,
                                                    end_date: date,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                )}
                {id > 0 && (
                    <div
                        className='list-flex-container'
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        {initialObjectState &&
                            displayField('Titre mission', initialObjectState.name, {
                                id: 'name',
                                name: 'name',
                                label: 'Titre mission',
                                placeholder: 'Titre mission',
                                type: 'text',
                                value: initialObjectState.name,
                                validate: 'required',
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            })}
                        {initialObjectState &&
                            editMode !== 2 &&
                            initialObjectState.student_id > 0 &&
                            typeof student_id === 'undefined' && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'>€ HT / jour étude</div>
                                    </div>
                                    <div className='profil-flex-child'>
                                        <div className='profil-value gray-value'>
                                            {initialObjectState.price_ht}
                                        </div>
                                    </div>
                                </div>
                            )}
                        {initialObjectState &&
                            initialObjectState.student_id === '0' &&
                            typeof student_id === 'undefined' && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'></div>
                                    </div>
                                    <div className='profil-flex-child'></div>
                                </div>
                            )}
                        {initialObjectState &&
                            ((editMode === 2 && initialObjectState.student_id > 0) ||
                                typeof student_id !== 'undefined') && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'>€ HT / jour étude</div>
                                    </div>
                                    <div className='profil-flex-child'>
                                        <input
                                            key={'price_ht'}
                                            name={'price_ht'}
                                            type='text'
                                            className='profil-value'
                                            value={initialObjectState.price_ht}
                                            onChange={e => {
                                                setInitialObjectState({
                                                    ...initialObjectState,
                                                    price_ht: e.target.value,
                                                });
                                                if (e.target.value > 0) {
                                                    setAlertBox({
                                                        ...alertBox,
                                                        message: '',
                                                    });
                                                }
                                                if (e.target.value < 1) {
                                                    setAlertBox({
                                                        ...alertBox,
                                                        message:
                                                            'Veuillez renseigner : € HT / jour étude',
                                                        classes: 'alert alert-danger',
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                    </div>
                )}
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    {initialObjectState &&
                        displayField('Description mission', initialObjectState.description, {
                            id: 'description',
                            name: 'description',
                            label: 'Description mission',
                            placeholder: 'Description des tâches qui vont être confiées',
                            type: 'textarea',
                            value: initialObjectState.description,
                            events: {
                                onKeyDown: e => {
                                    // e.key === 'Enter' && e.preventDefault();
                                },
                            },
                            classes: {
                                contClass: 'list-flex-child no-flex profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value profil-description-value',
                            },
                        })}
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'>
                            <div className='profil-label'></div>
                        </div>
                        <div className='profil-flex-child'></div>
                    </div>
                </div>
                {id > 0 && (
                    <div
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child'>
                                {student && currentUser.function !== 'Student' && (
                                    <p className='list-container-subtitle list-tab-subtitle'>
                                        L'échéancier ci-dessous est théorique, merci de{' '}
                                        <span className='list-table-link'>valider</span> à chaque
                                        échéance le nombre de jours travaillés par{' '}
                                        {student.first_name} pour permettre son règlement. <br />
                                        Chaque échéance est pré remplie avec 21 jours travaillés par
                                        mois. <br />
                                        Ce tableau ne sera visible pour l’étudiant une fois la
                                        mission confirmée.
                                    </p>
                                )}
                                {student && currentUser.function == 'Student' && (
                                    <p className='list-container-subtitle list-tab-subtitle'>
                                        Echéancier théorique : l'agence déclare les jours
                                        travaillés, à vous de{' '}
                                        <span className='list-table-link'>confirmer</span> chaque
                                        mois, on s'occupe du règlement juste après !
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child'>
                                {/* {initialObjectState &&
                                    (initialObjectState.student_id == '0' || student_id > 0) &&
                                    currentUser.function == 'Compte entreprise' && (
                                        <p className='list-container-subtitle list-tab-subtitle'>
                                            L'échéancier ci-dessous est théorique, le calcul exact
                                            sera mis en place une fois l'étudiant choisi. <br />
                                            Vous pouvez modifier les valeurs à l'aide du bouton « +
                                            » en haut à droite de cette page. <br />
                                            <span className='error-text'>
                                                Ce tableau n'est pas visible par les étudiants qui
                                                postulent
                                            </span>
                                        </p>
                                    )} */}
                            </div>
                        </div>

                        {initialObjectState &&
                            (initialObjectState.student_id !== '0' || student_id > 0) &&
                            (currentUser.function == 'Jobers' ||
                                currentUser.function == 'Compte entreprise' ||
                                (currentUser.function == 'Student' &&
                                    initialObjectState.student_id == currentUser.id)) && (
                                <div className='list-flex-container'>
                                    <div className='list-flex-child list-table-container flex-4'>
                                        {data && (
                                            <DataTable
                                                noDataComponent={
                                                    <span className='rdt_noresults'>
                                                        Aucune échéance pour cette mission.
                                                    </span>
                                                }
                                                columns={columns}
                                                data={data}
                                                persistTableHead
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                    </div>
                )}
                {confirmBox && confirmBox.message !== '' && (
                    <AlertBox message={confirmBox.message} classes={confirmBox.classes} />
                )}
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    <div className='list-flex-child profil-flex-container'></div>
                    <div className='list-flex-child profil-flex-container'></div>
                </div>
                {!canApply() &&
                    initialObjectState &&
                    initialObjectState.studyagreement_id > 0 &&
                    hasBeenAccepted() && (
                        <div
                            onClick={() => {
                                if (editMode === 1) {
                                    setEditMode(0);
                                }
                            }}>
                            <div className='list-flex-container'>
                                {initialObjectState && (
                                    <div className='list-flex-child profil-flex-container'>
                                        <div className='profil-flex-child'>
                                            {(currentUser.function === 'Jobers' ||
                                                currentUser.org_id ===
                                                    initialObjectState.company_id) && (
                                                <div className='profil-label'>Convention</div>
                                            )}
                                        </div>
                                        <div className='profil-flex-child'>
                                            {(currentUser.function === 'Jobers' ||
                                                currentUser.org_id ===
                                                    initialObjectState.company_id) && (
                                                <div className='profil-value'>
                                                    <a
                                                        className='list-table-link'
                                                        href={
                                                            'https://itop.jobers.fr/pdf_c.php?mission_id=' +
                                                            initialObjectState.id
                                                        }
                                                        target='_blank'>
                                                        {
                                                            initialObjectState.studyagreement_id_friendlyname
                                                        }
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {initialObjectState && (
                                    <div className='list-flex-child profil-flex-container'>
                                        <div className='profil-flex-child'>
                                            {(currentUser.function === 'Jobers' ||
                                                (currentUser.function === 'Student' &&
                                                    initialObjectState.student_id ===
                                                        currentUser.id)) && (
                                                <div className='profil-label'>Acc. de mission</div>
                                            )}
                                        </div>
                                        <div className='profil-flex-child'>
                                            {(currentUser.function === 'Jobers' ||
                                                (currentUser.function === 'Student' &&
                                                    initialObjectState.student_id ===
                                                        currentUser.id)) && (
                                                <div className='profil-value'>
                                                    <a
                                                        className='list-table-link'
                                                        href={
                                                            'https://itop.jobers.fr/pdf_am.php?mission_id=' +
                                                            initialObjectState.id
                                                        }
                                                        target='_blank'>
                                                        {
                                                            initialObjectState.studentmission_id_friendlyname
                                                        }
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {initialObjectState &&
                                initialObjectState.mission_terms &&
                                displayDocuments(initialObjectState.mission_terms)}
                        </div>
                    )}
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    <div className='list-flex-child profil-flex-container profil-footer'></div>
                </div>
            </Form>
        </div>
    );
}
