import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Form from '../Utils/Form';
import Input2 from '../Utils/Input2';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import MissionTermDataService from '../../services/missionterm.service';
import AttachmentDataService from '../../services/attachment.service';
import OrganizationDataService from '../../services/organization.service'; // Import du service

export default function JobersCompta(props) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [associations, setAssociations] = useState([]); // État pour les associations
    const currentUser = useAuthState();
    const [editMode, setEditMode] = useState(0);
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    const [status, setStatus] = useState('previ');
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });

    const [assoId, setAssoId] = useState(0);
    const [year, setYear] = useState(2022);
    const [month, setMonth] = useState(0);

    const [totalTva, setTotalTva] = useState(0);
    const [totalHt, setTotalHt] = useState(0);
    const [totalTtc, setTotalTtc] = useState(0);

    useEffect(() => {
        OrganizationDataService.getAll(
            'SELECT Association',
            'id, friendlyname, school_name, started_at'
        )
            .then(response => {
                console.log('Réponse des associations:', response);
                setAssociations(response);
                setLoading(false);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des associations :', error);
                setLoading(false);
            });
    }, [loading]);

    useEffect(() => {
        if (typeof props.status !== 'undefined') {
            setStatus(props.status);
        }

        let key = 'SELECT MissionTerm AS MT WHERE facture_id > 0';

        if (assoId > 0) {
            key += ' AND mission_id_student_org_id = ' + assoId;
        }

        if (month > 0) {
            key += ' AND MONTH(end_date) = ' + month;
        }

        if (year > 0) {
            key += ' AND YEAR(end_date) = ' + year;
        }

        MissionTermDataService.getAll(key).then(response => {
            let responseData = response;
            // init array
            let totalArray = [];

            totalArray['facture_id'] = '0';
            totalArray['facture_id_friendlyname'] = 'Total';
            totalArray['mission_id_company_id'] = '0';
            totalArray['mission_id_company_name'] = response.length;
            totalArray['tva'] = 0;
            totalArray['ht'] = 0;
            totalArray['ttc'] = 0;
            totalArray['facture_status'] = 'Réglées';

            // loop through response
            responseData.forEach(function (item) {
                totalArray['tva'] = Number(totalArray['tva']) + Number(item.tva);
                totalArray['ht'] = Number(totalArray['ht']) + Number(item.ht);
                totalArray['ttc'] = Number(totalArray['ttc']) + Number(item.ttc);

                if (item.facture_status === 'draft') {
                    totalArray['facture_status'] = 'draft';
                }
            });

            // add total to response
            responseData.push(totalArray);

            setTotalHt(totalArray['ht']);
            setTotalTva(totalArray['tva']);
            setTotalTtc(totalArray['ttc']);

            if (status === 'previ') {
                setData(responseData);
            }

            if (status === 'export') {
                // use month and year to generate key on year-month-01 and year-month-31 or 30
                let monthKey = month;
                if (month === 0) {
                    monthKey = '%';
                }
                // SELECT Attachment WHERE temp_id LIKE "year-monthKey-%year-monthKey-%_%"
                let key =
                    'SELECT Attachment WHERE temp_id LIKE "' +
                    year +
                    '-' +
                    monthKey +
                    '-%_' +
                    year +
                    '-' +
                    monthKey +
                    '-%_%"';
                if (assoId > 0) {
                    key += ' AND item_class = "Association" AND item_id = ' + assoId;
                }
                if (key) {
                    AttachmentDataService.getAll(key, []).then(response => {
                        setData(response);
                    });
                }
            }
        });
    }, [status, assoId, year, month]);

    let columns = [];

    if (status === 'previ') {
        columns = [
            {
                name: 'Facture',
                cell: row =>
                    row.facture_id > 0 ? (
                        <a
                            className='list-table-link'
                            href={'https://itop.jobers.fr/pdf_f.php?mission_term_id=' + row.id}
                            target='_blank'>
                            {row.facture_id_friendlyname}
                        </a>
                    ) : (
                        row.facture_id_friendlyname
                    ),
                filterable: true,
                sortable: true,
                sortFunction: (a, b) => {
                    if (a.facture_id_friendlyname < b.facture_id_friendlyname) {
                        return -1;
                    }
                    if (a.facture_id_friendlyname > b.facture_id_friendlyname) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                name: 'Entreprise',
                cell: row =>
                    row.mission_id_company_id > 0 ? (
                        <Link
                            className='list-table-link'
                            to={'/entreprise/' + row.mission_id_company_id + '/profil'}>
                            {row.mission_id_company_name}
                        </Link>
                    ) : (
                        row.mission_id_company_name
                    ),
                filterable: true,
                sortable: true,
                sortFunction: (a, b) => {
                    if (a.mission_id_company_name < b.mission_id_company_name) {
                        return -1;
                    }
                    if (a.mission_id_company_name > b.mission_id_company_name) {
                        return 1;
                    }
                    return 0;
                },
            },
            {
                name: 'TVA €',
                selector: row => row.tva,
                filterable: true,
                sortable: true,
            },
            {
                name: 'HT €',
                selector: row => row.ht,
                filterable: true,
                sortable: true,
            },
            {
                name: 'TTC €',
                selector: row => row.ttc,
                filterable: true,
                sortable: true,
            },
            {
                name: 'Statut',
                cell: row =>
                    row.facture_status === 'draft' ? (
                        <span className='badge badge-warning'>En attente</span>
                    ) : (
                        <span className='badge badge-success'>Réglée</span>
                    ),
                filterable: true,
                sortable: true,
                sortFunction: (a, b) => {
                    if (a.facture_status < b.facture_status) {
                        return -1;
                    }
                    if (a.facture_status > b.facture_status) {
                        return 1;
                    }
                    return 0;
                },
            },
        ];
    }

    if (status === 'export') {
        columns = [
            {
                name: 'Nom',
                selector: row => row.filename,
                filterable: true,
                sortable: true,
            },
            {
                name: 'Date chargement',
                selector: row => <span>{moment(row.creation_date).format('DD-MM-YYYY')}</span>,
                filterable: true,
                sortable: true,
            },
            {
                name: 'Télécharger',
                selector: row => {
                    return (
                        <a
                            className='list-table-link'
                            href={createDocumentLink(row)}
                            target='_blank'>
                            Télécharger
                        </a>
                    );
                },
                filterable: true,
                sortable: true,
            },
        ];
    }

    const onSubmit = e => {
        e.preventDefault();
        setStatus('previ');
    };

    const createDocumentLink = file => {
        if (file) {
            return (
                'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=display_document&filename=' +
                file.filename +
                '&id=' +
                file.id +
                '&obj=Association::' +
                file.item_id
            );
        }
    };

    const createExportDsnLink = assoId => {
        if (assoId) {
            let startDateString = year + '-' + month + '-01';
            let endDateString = year + '-' + month + '-31';
            if (month === 2) {
                endDateString = year + '-' + month + '-29';
            }
            if (month === 4 || month === 6 || month === 9 || month === 11) {
                endDateString = year + '-' + month + '-30';
            }

            let assoName = associations.find(asso => asso.id === assoId)?.name || '';

            return (
                'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=dsn_export_v2&assoName=' +
                assoName +
                '&start_date=' +
                startDateString +
                '&end_date=' +
                endDateString
            );
        }
    };

    const convertArrayOfObjectsToCSV = array => {
        let result = '';
        let exportableFields = [];
        let translatedFields = [];

        if (status === 'previ') {
            exportableFields = [
                'facture_id_friendlyname',
                'mission_id_company_name',
                'tva',
                'ht',
                'ttc',
                'facture_status',
            ];
            translatedFields = ['Facture', 'Entreprise', 'TVA', 'HT', 'TTC', 'Statut'];
        }

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = exportableFields;

        result = '\uFEFF';
        result += translatedFields.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                let value = item[key];

                if (typeof value === 'object') {
                    if (
                        typeof value.props !== 'undefined' &&
                        typeof value.props.children !== 'undefined'
                    ) {
                        value = value.props.children;
                    }
                }

                result += value;

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        let filenameStatus = status === 'published' ? 'en_attente' : 'archivees';
        const filename = `jobers_app_compta_${filenameStatus}_${moment().format(
            'YYYY-MM-DD_HH-mm-ss'
        )}.csv`;

        csv = 'data:text/csv;charset=utf-8,' + encodeURI(csv);

        link.setAttribute('href', csv);
        link.setAttribute('download', filename);
        link.click();
    };

    if (currentUser.function == 'Jobers')
        return (
            <div
                className='backend-container'
                onClick={() => {
                    if (editMode === 1) {
                        setEditMode(0);
                    }
                }}>
                <Sidebar />
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4'>
                        <h1 className='list-container-title'>Comptabilité</h1>
                    </div>
                    <div className='list-flex-child'>
                        <span className='list-container-counter'>
                            {filteredItems && filteredItems.length - 1}
                        </span>
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4 list-container-tabs'>
                        <Link
                            to={'/compta/previ'}
                            className='profil-tabs'
                            onClick={() => setStatus('previ')}>
                            Prévisionnels
                        </Link>
                        <Link
                            to={'/compta/dsn'}
                            className='profil-tabs'
                            onClick={() => setStatus('export')}>
                            Export DSN
                        </Link>
                        <Link to={'/compta/export'} className='profil-tabs'>
                            Export comptable
                        </Link>
                    </div>
                    <div className='list-flex-child list-flex-container'>
                        <div className='list-container-searchbar searchbar-with-button'>
                            <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                            <input
                                id='search'
                                className='list-container-searchinput'
                                type='text'
                                placeholder='Rechercher'
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                            />
                        </div>
                        {editMode < 2 && (
                            <div className='profil-container-wipbutton'>
                                <span className='list-container-addlabel'>
                                    <a
                                        onClick={() => {
                                            editMode === 1 ? setEditMode(0) : setEditMode(1);
                                        }}>
                                        +
                                    </a>
                                </span>
                            </div>
                        )}
                        {editMode == 1 && (
                            <div className='more_actions'>
                                <div
                                    className='more_actions_action'
                                    onClick={() => {
                                        downloadCSV(filteredItems);
                                    }}>
                                    {/* display ham-menu icon */}
                                    <div className='more_actions_icon'>
                                        <img
                                            className='ham_menu'
                                            src='/img/ham-menu.svg'
                                            alt='more actions'
                                        />
                                    </div>
                                    {(assoId > 0 && month > 0 && year > 0 && (
                                        <a
                                            className='more_actions_item'
                                            href={createExportDsnLink(assoId)}
                                            onClick={() => {
                                                window.location.reload(false);
                                            }}>
                                            Exporter
                                        </a>
                                    )) || (
                                        <div className='more_actions_item disabled'>Exporter</div>
                                    )}
                                </div>
                                <div className='more_actions_action' onClick={() => setEditMode(1)}>
                                    {/* display ham-menu icon */}
                                    <div className='more_actions_icon'>
                                        <img
                                            className='ham_menu'
                                            src='/img/ham-menu.svg'
                                            alt='more actions'
                                        />
                                    </div>
                                    <div className='more_actions_item disabled'>Filtrer</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div
                        className={
                            status === 'previ'
                                ? 'list-container-divider-1'
                                : 'list-container-divider-2'
                        }
                    />
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child'>
                        {status == 'previ' && (
                            <p className='list-container-subtitle list-tab-subtitle'>
                                Flux de facturation mensuel pour les associations du réseau Jobers.
                            </p>
                        )}
                        {status == 'export' && (
                            <p className='list-container-subtitle list-tab-subtitle'>
                                Historique des exports comptables DSN par association et par mois.
                            </p>
                        )}
                    </div>
                </div>
                <Form
                    onSubmit={onSubmit}
                    fields={formData.fields}
                    errors={formData.errors}
                    validateOnSubmit={true}>
                    {loading == false && (
                        <div className='list-flex-container'>
                            <Input2
                                id='association'
                                label='Association'
                                type='select'
                                placeholder='Sélectionner une association'
                                classes={{
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                }}
                                options={
                                    loading
                                        ? [{ value: '', label: 'Chargement...' }]
                                        : [
                                              {
                                                  value: '0',
                                                  label: '--- Choisir une association ---',
                                              },
                                              ...associations.map(asso => ({
                                                  value: asso.id,
                                                  label: asso.name,
                                              })),
                                          ]
                                }
                                events={{
                                    onChange: e => {
                                        setAssoId(e.value);
                                    },
                                }}
                            />
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'>
                                    <div className='profil-label'>Total TVA €</div>
                                </div>
                                <div className='profil-flex-child'>
                                    <div className='profil-value gray-value'>
                                        {totalTva.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className='list-flex-container'>
                        <Input2
                            id='year'
                            label='Année'
                            type='select'
                            placeholder='2022'
                            classes={{
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            }}
                            options={[
                                { value: '2022', label: '2022' },
                                { value: '2023', label: '2023' },
                                { value: '2024', label: '2024' },
                            ]}
                            events={{
                                onChange: e => {
                                    setYear(e.value);
                                },
                            }}
                        />
                        <div className='list-flex-child profil-flex-container'>
                            <div className='profil-flex-child'>
                                <div className='profil-label'>Total HT €</div>
                            </div>
                            <div className='profil-flex-child'>
                                <div className='profil-value gray-value'>{totalHt.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                    <div className='list-flex-container'>
                        <Input2
                            id='month'
                            label='Mois'
                            type='select'
                            placeholder='Sélectionner un mois'
                            classes={{
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            }}
                            options={[
                                { value: '00', label: '' },
                                { value: '01', label: 'Janvier' },
                                { value: '02', label: 'Février' },
                                { value: '03', label: 'Mars' },
                                { value: '04', label: 'Avril' },
                                { value: '05', label: 'Mai' },
                                { value: '06', label: 'Juin' },
                                { value: '07', label: 'Juillet' },
                                { value: '08', label: 'Août' },
                                { value: '09', label: 'Septembre' },
                                { value: '10', label: 'Octobre' },
                                { value: '11', label: 'Novembre' },
                                { value: '12', label: 'Décembre' },
                            ]}
                            events={{
                                onChange: e => {
                                    setMonth(e.value);
                                },
                            }}
                        />
                        <div className='list-flex-child profil-flex-container'>
                            <div className='profil-flex-child'>
                                <div className='profil-label'>Total TTC €</div>
                            </div>
                            <div className='profil-flex-child'>
                                <div className='profil-value gray-value'>{totalTtc.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </Form>
                <div className='list-flex-container'>
                    <div className='list-flex-child list-table-container flex-4'>
                        <DataTable
                            noDataComponent={
                                <span className='rdt_noresults'>Aucune facture trouvée.</span>
                            }
                            columns={columns}
                            data={filteredItems}
                            persistTableHead
                        />
                    </div>
                </div>
            </div>
        );
}
