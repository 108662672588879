import React, { Component } from "react";
import './IconSize.css';

class Disconnect extends Component {
    render() {
        return (
            <div className='icon-size'>
                <img
                className='img'
                alt='go-back'
                src='https://i.postimg.cc/25R77tJX/logout.png'
                />
            </div>
           
        )
    }    
}

export default Disconnect