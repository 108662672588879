import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Form from '../Utils/Form';
import Input2 from '../Utils/Input2';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import CompanyDataService from '../../services/company.service';
import OrganizationDataService from '../../services/organization.service';
import StudentDataService from '../../services/student.service';

export default function MissionNew(props) {
    let navigate = useNavigate();
    const [initialObjectState, setInitialObjectState] = useState(null);
    const [company, setCompany] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const [students, setStudents] = useState([]);
    const [isHMONP, setIsHMONP] = useState(false);
    const [missionTarget, setMissionTarget] = useState('');
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });
    const currentUser = useAuthState();
    const skills = [
        'Word',
        'Excel',
        'PowerPoint',
        'Access',
        'PowerBI',
        'Photoshop',
        'InDesign',
        'Illustrator',
        'Lightroom',
        'Première',
        'After Effect',
        'Substance',
        'Autocad',
        'Rhinoceros3D',
        'Sketchup',
        'Revit',
        'Archicad',
        '3dsMax',
        'Cinéma4D',
        'Maya',
        'Blender',
        'Vray',
        'Octane',
        'Lumion',
        'Twinmotion',
        'Enscape',
    ];

    useEffect(() => {
        const fetchObject = async () => {
            let start_date = new Date();
            let end_date = new Date();
            let objectData = {
                id: null,
                name: '',
                description: '',
                start_date: start_date,
                end_date: new Date(end_date.setMonth(end_date.getMonth() + 1)),
                mission_type: 'normal',
                order_num: '',
            };

            CompanyDataService.get(currentUser.org_id).then(company => {
                let subkey = 'SELECT Association';
                let subfields = 'id, friendlyname, school_name, started_at, persons_list';
                OrganizationDataService.getAll(subkey, subfields)
                    .then(organizations => {
                        let orgs = [];
                        organizations.forEach(org => {
                            orgs.push(org.name);
                        });
                        orgs.sort();

                        subkey = 'SELECT Person WHERE function = "Student"';
                        subfields =
                            'id, name, status, first_name, email, phone, org_name, org_id, started_at';
                        StudentDataService.getAll(subkey, subfields).then(students => {
                            let studs = [];
                            students.forEach(student => {
                                studs.push(student.first_name + ' ' + student.name);
                            });
                            studs.sort();
                            setStudents(studs);
                            setOrganizations(orgs);
                            setCompany(company);
                            setInitialObjectState(objectData);
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    });
            });
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    const displayField = (label, value, options) => {
        let readOnlyFields = ['org_id', 'address', 'postcode', 'city', 'domain', 'nb_je_Total'];
        if (true) {
            if (readOnlyFields.indexOf(options.name) == -1) {
                return (
                    <>
                        <Input2 {...options} />
                    </>
                );
            }
            return (
                <>
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'>
                            <div className='profil-label'>{label}</div>
                        </div>
                        <div className='profil-flex-child'>
                            <div className='profil-value gray-value'>{value}</div>
                        </div>
                    </div>
                </>
            );
        }
    };

    const string_to_slug = str => {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
        var to = 'aaaaeeeeiiiioooouuuunc------';
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    };

    const str_to_studylevel = str => {
        switch (str) {
            case '1':
                return '1ère année';
            case '2':
                return '2ème année';
            case '3':
                return '3ème année';
            case '4':
                return '4ème année';
            case '5':
                return '5ème année';
            case 'HMONP':
                return 'HMONP';
            default:
                return '1ère année';
        }
    };

    const onSubmit = async (fields, errors) => {
        setFormData({
            fields,
            errors,
        });

        // for 1 to 4 skills
        let skills = [];
        for (let i = 1; i <= 4; i++) {
            if (fields['skill_' + i].value != '') {
                skills.push({
                    label: fields['skill_' + i].value,
                    value: string_to_slug(fields['skill_' + i].value),
                });
            }
        }

        let etudiants = [];
        for (let i = 1; i <= 4; i++) {
            if (fields['student_' + i] && fields['student_' + i].value != '') {
                etudiants.push({
                    label: fields['student_' + i].value,
                    value: string_to_slug(fields['student_' + i].value),
                });
            }
        }

        let associations = [];
        for (let i = 1; i <= 4; i++) {
            if (fields['association_' + i] && fields['association_' + i].value != '') {
                associations.push({
                    label: fields['association_' + i].value,
                    value: string_to_slug(fields['association_' + i].value),
                });
            }
        }

        fields.study_level_1.value = fields.study_level_1.value ? fields.study_level_1.value : '1';
        fields.study_level_2.value = fields.study_level_2.value ? fields.study_level_2.value : '1';

        let studyLevels = [];
        if (fields.mission_type.value !== 'HMONP') {
            for (let i = fields.study_level_1.value; i <= fields.study_level_2.value; i++) {
                studyLevels.push({
                    label: str_to_studylevel(i.toString()),
                    value: i,
                });
            }
        }

        if (fields.mission_type.value == 'HMONP') {
            studyLevels.push({
                label: 'HMONP',
                value: 'HMONP',
            });
        }

        if (studyLevels.length == 0) {
            studyLevels.push({
                label: str_to_studylevel(1),
                value: 1,
            });
        }

        let aDataFields = {
            name: fields.name.value,
            company_id: currentUser.org_id,
            mission_type: fields.mission_type.value,
            description:
                fields.description.value != '' ? fields.description.value : fields.name.value,
            start_date: initialObjectState.start_date.toISOString().split('T')[0],
            end_date: initialObjectState.end_date.toISOString().split('T')[0],
            skills: JSON.stringify(skills),
            study_levels: JSON.stringify(studyLevels),
            secondary_skills: JSON.stringify([]),
            students: JSON.stringify(etudiants),
            associations: JSON.stringify(associations),
        };

        let arrMonthletters = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase().split('');
        let months = [];
        let start = moment(aDataFields.start_date);
        let end = moment(aDataFields.end_date);
        let month = start.clone();
        let index = 0;

        while (month.isSameOrBefore(end)) {
            let currMonth = {};
            let startOfMonth, endOfMonth;
            currMonth.name = arrMonthletters[index];
            currMonth.month = month.month() + 1;

            startOfMonth = moment(month).startOf('month');
            if (index == 0) {
                startOfMonth = moment(aDataFields.start_date);
            }

            endOfMonth = moment(month).endOf('month');
            if (month.month() + 1 == end.month() + 1) {
                endOfMonth = moment(aDataFields.end_date);
            }

            currMonth.start_date = startOfMonth.format('DD-MM-YYYY');
            currMonth.end_date = endOfMonth.format('DD-MM-YYYY');
            currMonth.nb_je = Number(endOfMonth.diff(startOfMonth, 'days') + 1);

            if (currMonth.nb_je > 21) {
                currMonth.nb_je = 21;
            }

            currMonth.ht = currMonth.nb_je * 0;
            currMonth.ht = currMonth.ht.toFixed(2);
            currMonth.tva = parseFloat(currMonth.ht * 0.2).toFixed(2);
            currMonth.ttc = parseFloat(Number(currMonth.ht) + Number(currMonth.tva)).toFixed(2);
            currMonth.student_net = parseFloat(currMonth.ht * 0.62).toFixed(2);

            months.push(currMonth);
            month.add(1, 'month');
            index++;
        }
        aDataFields.terms = months;

        let oData = new URLSearchParams();
        oData.append('operation', 'create_mission');
        oData.append('data', JSON.stringify(aDataFields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: oData,
        };

        let response = await axios(config);

        if (response.data.code === 0 && response.data.key > 0) {
            return navigate('/mission/' + response.data.key + '/profil');
        }

        if (response.data.code !== 0) {
            console.log(response.data.message);
        }
    };

    if (Object.keys(organizations).length === 0) {
        return (
            <div class='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

    return (
        <div className='backend-container'>
            <Sidebar />
            <Form
                onSubmit={onSubmit}
                fields={formData.fields}
                errors={formData.errors}
                validateOnSubmit={true}>
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4'>
                        {company && <h1 className='list-container-title'>{company.name}</h1>}
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4 list-container-tabs'>
                        <Link to={'/mission/new/profil'} className='profil-tabs'>
                            Création
                        </Link>
                        <Link to={'/mission/application'} className='profil-tabs'>
                            Candidats
                        </Link>
                        <Link to={'/mission/signatures'} className='profil-tabs'>
                            Signatures
                        </Link>
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div className='profil-container-divider-1' />
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child'>
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Nous sommes prêts, la suite s'affiche ci-dessous !
                        </p>
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child'>
                        <div
                            className={
                                missionTarget == 'public'
                                    ? 'container-mission-target container-mission-active'
                                    : 'container-mission-target'
                            }>
                            <p
                                className='list-container-subtitle list-tab-subtitle'
                                onClick={() => setMissionTarget('public')}>
                                Je souhaite proposer une mission publique
                            </p>
                        </div>
                        <span id='bo_mission_public_tooltip'>
                            <div className='icon-size'>
                                <img
                                    className='icon-img'
                                    alt='go-back'
                                    src='/img/10_QUESTION_MARK.svg'
                                />
                            </div>
                            <Tooltip
                                anchorId='bo_mission_public_tooltip'
                                content="Une offre publique est visible par l'ensemble des étudiants du réseau Jobers."
                                place='right'
                                style={{
                                    backgroundColor: '#fff',
                                    color: 'gray',
                                    width: '20vw',
                                    border: '1px solid #ccc',
                                    'margin-top': '10px',
                                    'margin-left': '-30px',
                                    'border-radius': '8px',
                                    'font-family': 'Mont-Book',
                                    'font-size': '11pt',
                                    'letter-spacing': 0,
                                }}
                            />
                        </span>
                        <div
                            className={
                                missionTarget == 'private'
                                    ? 'container-mission-target container-mission-active'
                                    : 'container-mission-target'
                            }>
                            <p
                                className='list-container-subtitle list-tab-subtitle'
                                onClick={() => setMissionTarget('private')}>
                                Je souhaite proposer une mission ciblée
                            </p>
                        </div>
                        <span id='bo_mission_private_tooltip'>
                            <div className='icon-size'>
                                <img
                                    className='icon-img'
                                    alt='go-back'
                                    src='/img/10_QUESTION_MARK.svg'
                                />
                            </div>
                            <Tooltip
                                anchorId='bo_mission_private_tooltip'
                                content='Une offre ciblée est visible uniquement par certains étudiants du réseau Jobers.'
                                place='right'
                                style={{
                                    backgroundColor: '#fff',
                                    color: 'gray',
                                    width: '20vw',
                                    border: '1px solid #ccc',
                                    'margin-top': '10px',
                                    'margin-left': '-30px',
                                    'border-radius': '8px',
                                    'font-family': 'Mont-Book',
                                    'font-size': '11pt',
                                    'letter-spacing': 0,
                                }}
                            />
                        </span>
                    </div>
                </div>
                <div className='list-flex-container'>
                    <div className='profil-detail-divider' />
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child'>
                        {missionTarget === 'public' && (
                            <p
                                className='list-container-subtitle list-tab-subtitle'
                                onClick={() => setMissionTarget('public')}>
                                Une offre publique est visible par l'ensemble des étudiants du
                                réseau Jobers. <br />
                                Nous avons besoin de quelques informations complémentaires pour
                                publier votre offre.
                            </p>
                        )}
                        {missionTarget === 'private' && (
                            <p
                                className='list-container-subtitle list-tab-subtitle'
                                onClick={() => setMissionTarget('private')}>
                                Une offre ciblée est visible uniquement par certains étudiants du
                                réseau Jobers. <br />
                                Nous avons besoin de quelques informations complémentaires pour
                                publier votre offre.
                            </p>
                        )}
                    </div>
                </div>
                {missionTarget !== '' && (
                    <div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Titre mission', initialObjectState.name, {
                                    id: 'name',
                                    name: 'name',
                                    label: 'Titre mission',
                                    placeholder: 'Que proposez-vous ?',
                                    type: 'text',
                                    validate: 'required',
                                    value: initialObjectState.name,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {company &&
                                displayField('Entreprise', company.name, {
                                    id: 'org_id',
                                    name: 'org_id',
                                    label: 'Entreprise',
                                    placeholder: 'Entreprise',
                                    type: 'text',
                                    value: company.name,
                                    validate: 'required',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'>
                                    <div className='profil-label'></div>
                                </div>
                                <div className='profil-flex-child'></div>
                            </div>
                            {company &&
                                displayField('Adresse', company.address, {
                                    id: 'address',
                                    name: 'address',
                                    label: 'Adresse',
                                    placeholder: '1 rue de la paix',
                                    type: 'text',
                                    value: company.address,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                company &&
                                displayField('Type de mission ?', initialObjectState.mission_type, {
                                    id: 'mission_type',
                                    name: 'mission_type',
                                    label: 'Type de mission ?',
                                    placeholder: 'Ordinaire',
                                    type: 'select',
                                    validate: 'required',
                                    value: initialObjectState.mission_type,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            company.domain === 'architecture'
                                                ? 'profil-value'
                                                : 'profil-value gray-value',
                                    },
                                    options:
                                        company.domain === 'architecture'
                                            ? [
                                                  {
                                                      value: 'normal',
                                                      label: 'Convention temps complet',
                                                  },
                                                  {
                                                      value: 'parttime',
                                                      label: 'Convention temps partiel',
                                                  },
                                                  { value: 'HMONP', label: 'Convention HMONP' },
                                              ]
                                            : [
                                                  {
                                                      value: 'normal',
                                                      label: 'Convention temps complet',
                                                  },
                                                  {
                                                      value: 'parttime',
                                                      label: 'Convention temps partiel',
                                                  },
                                              ],
                                    events: {
                                        onChange: e => {
                                            setIsHMONP(e.value === 'HMONP');
                                        },
                                    },
                                })}
                            {company &&
                                displayField('Code postal', company.postcode, {
                                    id: 'postcode',
                                    name: 'postcode',
                                    label: 'Code postal',
                                    placeholder: '75000',
                                    type: 'text',
                                    value: company.postcode,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'>
                                            <label htmlFor='start_date'>Date début</label>
                                        </div>
                                    </div>
                                    <div className='profil-flex-child'>
                                        <DatePicker
                                            className='profil-value'
                                            dateFormat='dd/MM/yyyy'
                                            selected={initialObjectState.start_date}
                                            onChange={date => {
                                                if (initialObjectState.end_date < date) {
                                                    setInitialObjectState({
                                                        ...initialObjectState,
                                                        start_date: date,
                                                        end_date: date,
                                                    });
                                                    return;
                                                }
                                                setInitialObjectState({
                                                    ...initialObjectState,
                                                    start_date: date,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {company &&
                                displayField('Ville', company.city, {
                                    id: 'city',
                                    name: 'city',
                                    label: 'Ville',
                                    placeholder: 'Paris',
                                    type: 'text',
                                    value: company.city,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'>
                                            <label htmlFor='end_date'>Date fin</label>
                                        </div>
                                    </div>
                                    <div className='profil-flex-child'>
                                        <DatePicker
                                            className='profil-value'
                                            dateFormat='dd/MM/yyyy'
                                            minDate={new Date(initialObjectState.start_date)}
                                            selected={initialObjectState.end_date}
                                            onChange={date => {
                                                setInitialObjectState({
                                                    ...initialObjectState,
                                                    end_date: date,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            {company &&
                                displayField(
                                    'Domaine',
                                    company.domain.charAt(0).toUpperCase() +
                                        company.domain.slice(1),
                                    {
                                        id: 'domain',
                                        name: 'domain',
                                        label: 'Domaine',
                                        placeholder: 'Architecture',
                                        type: 'text',
                                        value:
                                            company.domain.charAt(0).toUpperCase() +
                                            company.domain.slice(1),
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>

                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Compétences', '', {
                                    id: 'skill_1',
                                    name: 'skill_1',
                                    label: 'Compétences',
                                    placeholder: '',
                                    type: 'autocomplete',
                                    suggestions: skills,
                                    validate: '',
                                    value: '',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                !isHMONP &&
                                displayField("Niveau d'études minimum", '', {
                                    id: 'study_level_1',
                                    name: 'study_level_1',
                                    label: "Niveau d'études minimum",
                                    placeholder: '1ère année',
                                    type: 'select',
                                    value: '',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                    options: [
                                        { value: '1', label: '1ère année' },
                                        { value: '2', label: '2ème année' },
                                        { value: '3', label: '3ème année' },
                                        { value: '4', label: '4ème année' },
                                        { value: '5', label: '5ème année' },
                                        { value: 'HMONP', label: 'HMONP' },
                                    ],
                                })}
                            {initialObjectState &&
                                isHMONP &&
                                displayField("Niveau d'études minimum", '', {
                                    id: 'study_level_1',
                                    name: 'study_level_1',
                                    label: "Niveau d'études minimum",
                                    placeholder: '1ère année',
                                    type: 'select',
                                    disabled: true,
                                    value: 'HMONP',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                    options: [{ value: 'HMONP', label: 'HMONP' }],
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('', '', {
                                    id: 'skill_2',
                                    name: 'skill_2',
                                    label: '',
                                    placeholder: '',
                                    type: 'autocomplete',
                                    suggestions: skills,
                                    value: '',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                !isHMONP &&
                                displayField("Niveau d'études maximum", '', {
                                    id: 'study_level_2',
                                    name: 'study_level_2',
                                    label: "Niveau d'études maximum",
                                    placeholder: '1ère année',
                                    type: 'select',
                                    value: '',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                    options: [
                                        { value: '1', label: '1ère année' },
                                        { value: '2', label: '2ème année' },
                                        { value: '3', label: '3ème année' },
                                        { value: '4', label: '4ème année' },
                                        { value: '5', label: '5ème année' },
                                        { value: 'HMONP', label: 'HMONP' },
                                    ],
                                })}
                            {initialObjectState &&
                                isHMONP &&
                                displayField("Niveau d'études maximum", '', {
                                    id: 'study_level_2',
                                    name: 'study_level_2',
                                    label: "Niveau d'études maximum",
                                    placeholder: '1ère année',
                                    type: 'select',
                                    disabled: true,
                                    value: 'HMONP',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                    options: [{ value: 'HMONP', label: 'HMONP' }],
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('', '', {
                                    id: 'skill_3',
                                    name: 'skill_3',
                                    label: '',
                                    placeholder: '',
                                    type: 'autocomplete',
                                    suggestions: skills,
                                    value: '',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'>
                                    <div className='profil-label'></div>
                                </div>
                                <div className='profil-flex-child'></div>
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('', '', {
                                    id: 'skill_4',
                                    name: 'skill_4',
                                    label: '',
                                    placeholder: '',
                                    type: 'autocomplete',
                                    suggestions: skills,
                                    value: '',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'>
                                    <div className='profil-label'></div>
                                </div>
                                <div className='profil-flex-child'></div>
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>

                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField(
                                    'Description mission',
                                    initialObjectState.description,
                                    {
                                        id: 'description',
                                        name: 'description',
                                        label: 'Description mission',
                                        placeholder:
                                            'Description des tâches qui vont être confiées',
                                        type: 'textarea',
                                        value: initialObjectState.description,
                                        events: {
                                            onKeyDown: e => {
                                                // e.key === 'Enter' && e.preventDefault();
                                            },
                                        },
                                        classes: {
                                            contClass:
                                                'list-flex-child no-flex profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value profil-description-value',
                                        },
                                    }
                                )}
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'>
                                    <div className='profil-label'></div>
                                </div>
                                <div className='profil-flex-child'></div>
                            </div>
                        </div>
                        {initialObjectState && isHMONP && (
                            <div>
                                <div className='list-flex-container'>
                                    <div className='profil-detail-divider' />
                                </div>
                                <div className='list-flex-container'>
                                    <div className='list-flex-child'>
                                        <p className='list-container-subtitle list-tab-subtitle'>
                                            Vous souhaitez déposer une offre pour un nétudiant en
                                            HMONP. <br />
                                            Nous avons besoin de votre numéro d'inscription à
                                            l'ordre des architectes pour publier cette offre.
                                        </p>
                                    </div>
                                </div>
                                <div className='list-flex-container'>
                                    {initialObjectState &&
                                        displayField(
                                            'N° inscription',
                                            initialObjectState.order_num,
                                            {
                                                id: 'order_num',
                                                name: 'order_num',
                                                label: 'N° inscription',
                                                placeholder: '25145856578',
                                                type: 'text',
                                                value: initialObjectState.order_num,
                                                validate: 'required',
                                                classes: {
                                                    contClass:
                                                        'list-flex-child profil-flex-container',
                                                    errorClass: 'error-text',
                                                    fieldClass: 'profil-value',
                                                },
                                            }
                                        )}
                                    <div className='list-flex-child profil-flex-container'>
                                        <div className='profil-flex-child'>
                                            <div className='profil-label'></div>
                                        </div>
                                        <div className='profil-flex-child'></div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {missionTarget == 'private' && (
                            <div>
                                <div className='list-flex-container'>
                                    <div className='profil-detail-divider' />
                                </div>
                                <div className='list-flex-container'>
                                    <div className='list-flex-child'>
                                        <p className='list-container-subtitle list-tab-subtitle'>
                                            Je souhaite filtrer les membres du réseau étudiant
                                            Jobers qui auront accès à cette offre de mission
                                        </p>
                                    </div>
                                </div>
                                <div className='list-flex-container'>
                                    {initialObjectState &&
                                        displayField('Par association', '', {
                                            id: 'association_1',
                                            name: 'association_1',
                                            label: 'Par association',
                                            placeholder: '...',
                                            type: 'autocomplete',
                                            value: '',
                                            classes: {
                                                contClass: 'list-flex-child profil-flex-container',
                                                errorClass: 'error-text',
                                                fieldClass: 'profil-value',
                                            },
                                            suggestions: organizations,
                                        })}
                                    {initialObjectState &&
                                        displayField('Par étudiant', '', {
                                            id: 'student_1',
                                            name: 'student_1',
                                            label: 'Par étudiant',
                                            placeholder: '...',
                                            type: 'autocomplete',
                                            value: '',
                                            classes: {
                                                contClass: 'list-flex-child profil-flex-container',
                                                errorClass: 'error-text',
                                                fieldClass: 'profil-value',
                                            },
                                            suggestions: students,
                                        })}
                                </div>
                                <div className='list-flex-container'>
                                    {initialObjectState &&
                                        displayField('', '', {
                                            id: 'association_2',
                                            name: 'association_2',
                                            label: '',
                                            placeholder: '...',
                                            type: 'autocomplete',
                                            value: '',
                                            classes: {
                                                contClass: 'list-flex-child profil-flex-container',
                                                errorClass: 'error-text',
                                                fieldClass: 'profil-value',
                                            },
                                            suggestions: organizations,
                                        })}
                                    {initialObjectState &&
                                        displayField('', '', {
                                            id: 'student_2',
                                            name: 'student_2',
                                            label: '',
                                            placeholder: '...',
                                            type: 'autocomplete',
                                            value: '',
                                            classes: {
                                                contClass: 'list-flex-child profil-flex-container',
                                                errorClass: 'error-text',
                                                fieldClass: 'profil-value',
                                            },
                                            suggestions: students,
                                        })}
                                </div>
                                <div className='list-flex-container'>
                                    {initialObjectState &&
                                        displayField('', '', {
                                            id: 'association_3',
                                            name: 'association_3',
                                            label: '',
                                            placeholder: '...',
                                            type: 'autocomplete',
                                            value: '',
                                            classes: {
                                                contClass: 'list-flex-child profil-flex-container',
                                                errorClass: 'error-text',
                                                fieldClass: 'profil-value',
                                            },
                                            suggestions: organizations,
                                        })}
                                    {initialObjectState &&
                                        displayField('', '', {
                                            id: 'student_3',
                                            name: 'student_3',
                                            label: '',
                                            placeholder: '...',
                                            type: 'autocomplete',
                                            value: '',
                                            classes: {
                                                contClass: 'list-flex-child profil-flex-container',
                                                errorClass: 'error-text',
                                                fieldClass: 'profil-value',
                                            },
                                            suggestions: students,
                                        })}
                                </div>
                                <div className='list-flex-container'>
                                    {initialObjectState &&
                                        displayField('', '', {
                                            id: 'association_4',
                                            name: 'association_4',
                                            label: '',
                                            placeholder: '...',
                                            type: 'autocomplete',
                                            value: '',
                                            classes: {
                                                contClass: 'list-flex-child profil-flex-container',
                                                errorClass: 'error-text',
                                                fieldClass: 'profil-value',
                                            },
                                            suggestions: organizations,
                                        })}
                                    {initialObjectState &&
                                        displayField('', '', {
                                            id: 'student_4',
                                            name: 'student_4',
                                            label: '',
                                            placeholder: '...',
                                            type: 'autocomplete',
                                            value: '',
                                            classes: {
                                                contClass: 'list-flex-child profil-flex-container',
                                                errorClass: 'error-text',
                                                fieldClass: 'profil-value',
                                            },
                                            suggestions: students,
                                        })}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {missionTarget !== '' && (
                    <div className='list-flex-container'>
                        <div className='profil-container-submitbutton new-mission-submitbutton'>
                            <span className='list-container-submitlabel'>
                                <button type='submit' className='primary-submit'>
                                    Confirmer
                                </button>
                            </span>
                        </div>
                    </div>
                )}
                <div className='list-flex-container'>
                    <div className='list-flex-child profil-flex-container profil-footer'></div>
                </div>
            </Form>
        </div>
    );
}
