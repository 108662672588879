import './Form.css';
import React, { useState, useEffect, createContext } from 'react';
import validations from './validations';

export const FormCtx = createContext({
    fields: {},
    errors: {},
});

export default function Form(props) {
    const [state, setState] = useState({
        fields: {},
        errors: {},
    });

    const setFields = (event, { id, value }) => {
        if (typeof event.persist === 'function') {
            event.persist();
        }

        const { fields } = state;
        const field = fields[id];

        if (field.type == 'file') {
            if (typeof event.target !== 'undefined' && event.target.files.length > 0) {
                if (event.target.files[0].size > 2097152) {
                    setState(prevState => {
                        return {
                            ...prevState,
                            errors: {
                                ...prevState.errors,
                                [id]: 'La taille du fichier ne doit pas dépasser 2 Mo',
                            },
                        };
                    });
                    return;
                }
            }
        }

        addField({
            field: {
                ...field,
                value: typeof event.target !== 'undefined' ? event.target.value : value,
            },
        });
    };

    const addField = ({ field }) => {
        const { id } = field;

        field = {
            value: '',
            ...field,
        };

        if (id) {
            setState(prevState => {
                return {
                    ...prevState,
                    fields: {
                        ...prevState.fields,
                        [id]: field,
                    },
                };
            });

            return;
        }

        throw new Error(`please add 'id' field to the input: ${field}`);
    };

    const validateField = id => {
        let error = '';

        let {
            value: fieldValue,
            validate,
            validatemessage,
            displayName,
            customRules = {},
        } = state.fields[id];
        const rules = validate ? validate.split('|') : '';

        if (rules.length) {
            for (const rule in rules) {
                const ruleName = rules[rule];
                const validation = validations[ruleName] || customRules[ruleName];

                if (typeof state.fields[id].type !== 'undefined') {
                    if (state.fields[id].files && state.fields[id].files.length > 0) {
                        fieldValue = 'OK';
                    }
                }

                const isRuleSatisfied = validation.rule().test(fieldValue.toString());
                const canBeEmpty = rules.indexOf('required') === -1;

                if (
                    !isRuleSatisfied &&
                    (!(fieldValue != '' && canBeEmpty) || !(fieldValue == '' && !canBeEmpty))
                ) {
                    error = validation.formatter.apply(null, [displayName || id]);
                    if (validatemessage) {
                        error = validatemessage;
                    }
                }

                if (ruleName === 'school_required') {
                    if (fieldValue !== '') {
                        error = '';
                    }
                    if (fieldValue == '' && state.fields['otherschool'].value == '') {
                        error = 'Veuillez préciser en dessous le nom et la ville de votre école.';
                    }
                }

                if (ruleName === 'email_equal') {
                    let value = state.fields['email'].value;
                    if (fieldValue === value) {
                        error =
                            'Votre adresse email de contact doit être différente de votre adresse email de connexion.';
                    }
                }

                if (ruleName === 'confirm_password') {
                    let value =
                        typeof state.fields['new_password'] !== 'undefined'
                            ? state.fields['new_password'].value
                            : state.fields['password'].value;
                    if (fieldValue !== value) {
                        error = 'Les mots de passe ne correspondent pas.';
                    }
                    if (
                        typeof state.fields['current_password'] !== 'undefined' &&
                        fieldValue === state.fields['current_password'].value
                    ) {
                        error = 'Le nouveau mot de passe doit être différent du précédent.';
                    }
                }

                if (error !== '') {
                    break;
                }
            }

            setState(prevState => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    [id]: error,
                },
            }));
        }

        return error;
    };

    const formCtx = {
        fields: state.fields,
        errors: state.errors,
        addField: data => {
            addField(data);
        },
        validateField,
        doValidate: props.validateOnSubmit ? false : true,
        setFields,
    };

    const doSubmit = event => {
        event.preventDefault();

        if (!props.validate) {
            // props.onSubmit(state.fields, state.errors);
        }

        let errors = [];

        if (props.validateOnSubmit) {
            for (const id in state.fields) {
                let validateRes = validateField(id);
                if (validateRes) {
                    errors[id] = validateRes;
                }
            }
        }

        if (Object.keys(errors).length === 0) {
            props.onSubmit(state.fields, state.errors);
        }
    };

    return (
        <form
            action=''
            autoComplete='off'
            className={props.className}
            onSubmit={doSubmit}
            onKeyPress={e => {
                e.target.type !== 'textarea' && e.key === 'Enter' && doSubmit(e);
            }}>
            <FormCtx.Provider value={formCtx}>{props.children}</FormCtx.Provider>
        </form>
    );
}
