import './StudentView.css';
import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import Sidebar from '../../Sidebar/Sidebar';

import { useAuthState } from '../../../../context';

import PersonDataService from '../../../../services/student.service';
import { initialState } from '../../../../context/reducer';
import StudentDataService from '../../../../services/student.service';
import AttachmentDataService from '../../../../services/attachment.service';

const StudentView = props => {
    let { id } = useParams();
    const [initialObjectState, setInitialObjectState] = useState(null);
    const currentUser = useAuthState();

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                first_name: '',
                name: '',
                email: '',
                phone: '',
                started_at: '',
                joined_at: '',
                nationality: '',
                year: '',
                school_year: '',
                school: '',
                ssn: '',
                bank: '',
                iban: '',
                bic: '',
                book_url: '',
                files: [],
            };

            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (currentUser.id !== '0' && props.id == 'me') {
                id = currentUser.id;
                console.log(id);
            }
            if (id) {
                StudentDataService.get(id).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    AttachmentDataService.getAll(
                        'SELECT Attachment WHERE item_class = "Person" AND item_id = ' + id,
                        'contents'
                    ).then(fileresponse => {
                        objectData.files = fileresponse;

                        setInitialObjectState(objectData);
                    });
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    const onSubmit = (fields, errors) => {};

    const handleChange = event => {};

    return (
        <div className='studentView screen'>
            <div className='flex-container'>
                <div className='flex-child'>
                    <Sidebar />
                </div>
                <div className='flex-child'>
                    <div className='flex-col'>
                        <div className='flex-row'>
                            {/* <img
                        className='arrow-left'
                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3dde03eda8a1cb03a287a/img/arrow-left@2x.svg'
                    /> */}
                            <div className='frame-1'>
                                {initialObjectState && (
                                    <div className='text-14'>
                                        {initialObjectState.first_name} {initialObjectState.name}
                                    </div>
                                )}

                                <div className='badge'>
                                    <div className='badge-base'>
                                        <div className='text inter-medium-salem-12px'>
                                            Disponible
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='actions'>
                        <div className='button-1'>
                            <div className='button-base-1'>
                                <div className='text-15 inter-medium-gable-green-14px'>
                                    Autres actions
                                </div>
                            </div>
                        </div>
                    </div> */}
                        </div>
                        <div className='horizontal-tabs'>
                            <div className='overlap-group'>
                                <div className='divider-2'></div>
                                <div className='tabs'>
                                    <div className='tab-button-base'>
                                        <div className='content-1'>
                                            <div className='text-16 inter-medium-gable-green-14px'>
                                                Informations
                                            </div>
                                        </div>
                                        <div className='bottom-border'></div>
                                    </div>
                                    <div className='tab-button-base'>
                                        <div className='content-1'>
                                            <div className='text-17'>Missions</div>
                                        </div>
                                        <div className='bottom-border-1'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='section'>
                            <div className='container'>
                                {initialObjectState && currentUser.function === 'Jobers' && (
                                    <div className='section-header'>
                                        <div className='content-2'>
                                            <div className='text-and-supporting-text'>
                                                <div className='text-1 inter-medium-mirage-18px'>
                                                    Justificatifs
                                                </div>
                                                <p className='supporting-text inter-normal-pale-sky-14px'>
                                                    Tous les justificatifs de l’étudiant
                                                </p>
                                            </div>
                                        </div>
                                        <div className='studentDivider'></div>
                                    </div>
                                )}
                                {initialObjectState && currentUser.function === 'Jobers' && (
                                    <div className='form'>
                                        <div className='content-3'>
                                            <div className='text-2 inter-medium-oxford-blue-14px'>
                                                Images justificatifs
                                            </div>
                                            {initialObjectState &&
                                                initialObjectState.files.map(file => (
                                                    <div className='frame-2' key={file.filename}>
                                                        <img
                                                            className='rectangle-2'
                                                            src={
                                                                'data:' +
                                                                file.mimetype +
                                                                ';base64,' +
                                                                file.data
                                                            }
                                                        />
                                                        <div className='label inter-medium-oxford-blue-14px'>
                                                            {file.filename}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                        <div className='studentDivider'></div>
                                        {initialObjectState && (
                                            <div className='content-3'>
                                                <div className='text-2 inter-medium-oxford-blue-14px'>
                                                    Numéro de sécurité sociale
                                                </div>
                                                <div className='text-3 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.ssn}
                                                </div>
                                            </div>
                                        )}
                                        <div className='studentDivider'></div>
                                        <div className='content-3'>
                                            <div className='text-2 inter-medium-oxford-blue-14px'>
                                                RIB
                                            </div>
                                            <div className='frame-2-1'>
                                                {initialObjectState && (
                                                    <div className='input-with-label'>
                                                        <div className='label-2 inter-medium-oxford-blue-14px'>
                                                            Banque
                                                        </div>
                                                        <div className='text-4 inter-normal-pale-sky-16px'>
                                                            {initialObjectState.bank}
                                                        </div>
                                                    </div>
                                                )}
                                                {initialObjectState && (
                                                    <div className='input-with-label'>
                                                        <div className='label-3 inter-medium-oxford-blue-14px'>
                                                            RIB
                                                        </div>
                                                        <p className='text-18 inter-normal-pale-sky-16px'>
                                                            {initialObjectState.iban}
                                                        </p>
                                                    </div>
                                                )}
                                                {initialObjectState && (
                                                    <div className='input-with-label'>
                                                        <div className='label-4 inter-medium-oxford-blue-14px'>
                                                            BIC
                                                        </div>
                                                        <div className='text-4 inter-normal-pale-sky-16px'>
                                                            {initialObjectState.bic}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='studentDivider'></div>
                                    </div>
                                )}
                                <div className='section-header'>
                                    <div className='content-2'>
                                        <div className='text-and-supporting-text'>
                                            <div className='text-1 inter-medium-mirage-18px'>
                                                Informations personnelle
                                            </div>
                                            <p className='supporting-text inter-normal-pale-sky-14px'>
                                                Toutes les informations personnelles de l’étudiant
                                            </p>
                                        </div>
                                    </div>
                                    <div className='studentDivider'></div>
                                </div>
                                <div className='form'>
                                    <div className='content-3'>
                                        <div className='text-2 inter-medium-oxford-blue-14px'>
                                            Prénom et nom
                                        </div>
                                        {initialObjectState && (
                                            <div className='input-with-label-1'>
                                                <div className='label-5 inter-medium-oxford-blue-14px'>
                                                    Nom
                                                </div>
                                                <div className='text-5 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.name}
                                                </div>
                                            </div>
                                        )}
                                        {initialObjectState && (
                                            <div className='input-with-label-1'>
                                                <div className='label-6 inter-medium-oxford-blue-14px'>
                                                    Prénom
                                                </div>
                                                <div className='text-5 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.first_name}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='studentDivider'></div>
                                    <div className='content-3'>
                                        <div className='text-2 inter-medium-oxford-blue-14px'>
                                            Adresse email
                                        </div>
                                        {initialObjectState && (
                                            <div className='text-3 inter-normal-pale-sky-16px'>
                                                {initialObjectState.email}
                                            </div>
                                        )}
                                    </div>
                                    <div className='studentDivider'></div>
                                    {initialObjectState && (
                                        <div className='content-3'>
                                            <div className='text-2 inter-medium-oxford-blue-14px'>
                                                Date d’inscription
                                            </div>
                                            <div className='text-3 inter-normal-pale-sky-16px'>
                                                {initialObjectState.started_at}
                                            </div>
                                        </div>
                                    )}
                                    <div className='studentDivider'></div>
                                    {initialObjectState && (
                                        <div className='content-3'>
                                            <div className='text-2 inter-medium-oxford-blue-14px'>
                                                Téléphone
                                            </div>
                                            <p className='text-3 inter-normal-pale-sky-16px'>
                                                {initialObjectState.phone}
                                            </p>
                                        </div>
                                    )}

                                    <div className='studentDivider'></div>
                                    <div className='content-3'>
                                        <div className='text-2 inter-medium-oxford-blue-14px'>
                                            Adresse complète
                                        </div>
                                        <div className='frame-2-1'>
                                            {initialObjectState && (
                                                <div className='input-with-label-1'>
                                                    <div className='label-7 inter-medium-oxford-blue-14px'>
                                                        Adresse
                                                    </div>
                                                    <div className='text-5 inter-normal-pale-sky-16px'>
                                                        {initialObjectState.address}
                                                    </div>
                                                </div>
                                            )}

                                            <div className='frame-30'>
                                                {initialObjectState && (
                                                    <div className='input-with-label-1'>
                                                        <div className='label-8 inter-medium-oxford-blue-14px'>
                                                            Ville
                                                        </div>
                                                        <div className='text-5 inter-normal-pale-sky-16px'>
                                                            {initialObjectState.city}
                                                        </div>
                                                    </div>
                                                )}

                                                {initialObjectState && (
                                                    <div className='input-with-label-2'>
                                                        <div className='label-9 inter-medium-oxford-blue-14px'>
                                                            Code postal
                                                        </div>
                                                        <div className='text-5 inter-normal-pale-sky-16px'>
                                                            {initialObjectState.postcode}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            {initialObjectState && (
                                                <div className='input-with-label-1'>
                                                    <div className='label-10 inter-medium-oxford-blue-14px'>
                                                        Pays
                                                    </div>
                                                    <div className='text-5 inter-normal-pale-sky-16px'>
                                                        {initialObjectState.country}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='studentDivider'></div>
                                </div>
                                <div className='section-header'>
                                    <div className='content-2'>
                                        <div className='text-and-supporting-text'>
                                            <div className='text-1 inter-medium-mirage-18px'>
                                                Études
                                            </div>
                                            <p className='supporting-text inter-normal-pale-sky-14px'>
                                                Toutes les informations relatives à l’étude
                                            </p>
                                        </div>
                                    </div>
                                    <div className='studentDivider'></div>
                                </div>
                                {initialObjectState && (
                                    <div className='content-3'>
                                        <div className='text-2 inter-medium-oxford-blue-14px'>
                                            Année scolaire
                                        </div>
                                        <div className='text-3 inter-normal-pale-sky-16px'>
                                            {initialObjectState.year}
                                        </div>
                                    </div>
                                )}
                                <div className='studentDivider'></div>
                                {initialObjectState && (
                                    <div className='content-3'>
                                        <div className='text-2 inter-medium-oxford-blue-14px'>
                                            École
                                        </div>
                                        <div className='text-3 inter-normal-pale-sky-16px'>
                                            {initialObjectState.school}
                                        </div>
                                    </div>
                                )}

                                <div className='form'>
                                    <div className='studentDivider'></div>
                                    {initialObjectState && (
                                        <div className='content-3'>
                                            <div className='text-2 inter-medium-oxford-blue-14px'>
                                                Niveau d’étude
                                            </div>
                                            <div className='text-3 inter-normal-pale-sky-16px'>
                                                {initialObjectState.school_year}
                                            </div>
                                        </div>
                                    )}

                                    <div className='studentDivider'></div>
                                </div>
                                <div className='section-header'>
                                    <div className='content-2'>
                                        <div className='text-and-supporting-text'>
                                            <div className='text-1 inter-medium-mirage-18px'>
                                                Association
                                            </div>
                                            <p className='supporting-text inter-normal-pale-sky-14px'>
                                                Toutes les informations relatives à l’association
                                            </p>
                                        </div>
                                    </div>
                                    <div className='studentDivider'></div>
                                </div>
                                <div className='form'>
                                    <div className='content-3'>
                                        <div className='text-2 inter-medium-oxford-blue-14px'>
                                            Association
                                        </div>
                                        <div className='frame-31'>
                                            <div className='badge'>
                                                <div className='badge-base'>
                                                    <div className='text inter-medium-salem-12px'>
                                                        Adhésion réglée
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-19 inter-normal-pale-sky-16px'>
                                                Association 1 – École
                                            </div>
                                        </div>
                                    </div>
                                    <div className='studentDivider'></div>
                                </div>
                                {initialObjectState && (
                                    <div className='content-3'>
                                        <div className='text-2 inter-medium-oxford-blue-14px'>
                                            Date d’adhésion
                                        </div>
                                        <div className='text-3 inter-normal-pale-sky-16px'>
                                            {initialObjectState.joined_at}
                                        </div>
                                    </div>
                                )}
                                <div className='studentDivider'></div>
                            </div>
                            <div className='section-1'>
                                <div className='container'>
                                    <div className='section-header'>
                                        <div className='content-2'>
                                            <div className='text-and-supporting-text'>
                                                <div className='text-1 inter-medium-mirage-18px'>
                                                    Profil
                                                </div>
                                                <p className='supporting-text inter-normal-pale-sky-14px'>
                                                    Toutes les informations sur le projet de
                                                    l’étudiant
                                                </p>
                                            </div>
                                        </div>
                                        <div className='studentDivider'></div>
                                    </div>
                                    <div className='form'>
                                        {initialObjectState && (
                                            <div className='content-3'>
                                                <div className='text-2 inter-medium-oxford-blue-14px'>
                                                    Lien du book
                                                </div>
                                                <div className='text-3 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.book_url}
                                                </div>
                                            </div>
                                        )}
                                        <div className='studentDivider'></div>
                                        <div className='content-3'>
                                            <div className='text-2 inter-medium-oxford-blue-14px'>
                                                Compétences
                                            </div>
                                            <div className='frame-34'>
                                                <div className='frame-3'>
                                                    <div className='input-with-label-1'>
                                                        <div className='label-11 inter-medium-oxford-blue-14px'>
                                                            Adobe photoshop
                                                        </div>
                                                        <div className='text-5 inter-normal-pale-sky-16px'>
                                                            Expert
                                                        </div>
                                                    </div>
                                                    <div className='input-with-label-1'>
                                                        <div className='label-12 inter-medium-oxford-blue-14px'>
                                                            Illustrator
                                                        </div>
                                                        <div className='text-5 inter-normal-pale-sky-16px'>
                                                            Avancée
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='frame-3'>
                                                    <div className='input-with-label-1'>
                                                        <div className='label-13 inter-medium-oxford-blue-14px'>
                                                            InDesign
                                                        </div>
                                                        <div className='text-20 inter-normal-pale-sky-16px'>
                                                            Débutant
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default StudentView;
