import React from 'react';
import LoginForm from '../components/LoginForm/LoginForm';

import Dispatch from '../components/Backend/Dispatch';

import ForgotPasswordForm from '../components/ForgotPasswordForm/ForgotPasswordForm';
import RegisterForm from '../components/RegisterForm/RegisterForm';
import RegisterOrganizationForm from '../components/RegisterOrganizationForm/RegisterOrganizationForm';
import RegisterCompanyForm from '../components/RegisterCompanyForm/RegisterCompanyForm';
import RegisterStudentForm from '../components/RegisterStudentForm/RegisterStudentForm';
import RegisterStudentRenew from '../components/RegisterStudentForm/RegisterStudentRenew';
import RegisterStudentConfirmationForm from '../components/RegisterStudentForm/RegisterStudentConfirmation';

import OrganizationList from '../components/Backend/Jobers/Organization/OrganizationList';
import OrganizationEdit from '../components/Backend/Jobers/Organization/OrganizationEdit';
import OrganizationView from '../components/Backend/Jobers/Organization/OrganizationView';

import CompanyList from '../components/Backend/Jobers/Company/CompanyList';
import CompanyEdit from '../components/Backend/Jobers/Company/CompanyEdit';
import CompanyView from '../components/Backend/Jobers/Company/CompanyView';

import MissionView from '../components/Backend/Jobers/Mission/MissionView';
import MissionEdit from '../components/Backend/Jobers/Mission/MissionEdit';

import StudentList2 from '../components/Backend/Jobers/Student/StudentList2';
import StudentView from '../components/Backend/Jobers/Student/StudentView';

import DocumentList from '../components/Backend/Jobers/Document/DocumentList';

import StudyAgreement from '../components/Backend/Documents/StudyAgreement/StudyAgreement';
import StudentMission from '../components/Backend/Documents/StudentMission/StudentMission';
import Facture from '../components/Backend/Documents/Facture/Facture';
import PaySlip from '../components/Backend/Documents/PaySlip/PaySlip';

import MyDashboard from '../components/Backend/MyDashboard';

import AssociationList from '../components/Association/list';
import AssociationProfil from '../components/Association/profil';
import AssociationMissionList from '../components/Association/mission';
import AssociationDocumentList from '../components/Association/document';
import AssociationGestion from '../components/Association/gestion';
import AssociationStatistique from '../components/Association/statistique';

import EtudiantList from '../components/Etudiant/list';
import EtudiantProfil from '../components/Etudiant/profil';
import EtudiantMissionList from '../components/Etudiant/mission';
import EtudiantDocumentList from '../components/Etudiant/document';
import EtudiantGestion from '../components/Etudiant/gestion';
import EtudiantStatistique from '../components/Etudiant/statistique';

import EntrepriseList from '../components/Entreprise/list';
import EntrepriseProfil from '../components/Entreprise/profil';
import EntrepriseMissionList from '../components/Entreprise/mission';
import EntrepriseDocumentList from '../components/Entreprise/document';
import EntrepriseStatistique from '../components/Entreprise/statistique';

import OffreList from '../components/Offre/list';
import OffrePublic from '../components/Offre/public';

import MissionList from '../components/Mission/list';
import MissionListCompleted from '../components/Mission/list_completed';
import MissionListOngoing from '../components/Mission/list_ongoing';
import MissionListPending from '../components/Mission/list_pending';
import MissionNew from '../components/Mission/new';
import MissionProfil from '../components/Mission/profil';
import MissionApplicationList from '../components/Mission/application';
import MissionSignatureList from '../components/Mission/signature';

import FactureList from '../components/Facture/list';
import PaymentList from '../components/BV/list';
import JobersCompta from '../components/Compta/list';
import JobersRate from '../components/Compta/rate';
import JobersComptaExport from '../components/Compta/export';

import NotificationTemplate from '../components/Notification/template';

import Parameter from '../components/Backend/Parameter/parameter';

const routes = [
    {
        path: '/notification',
        element: <NotificationTemplate />,
        isPrivate: true,
    },
    {
        path: '/parameter',
        element: <Parameter />,
        isPrivate: true,
    },
    {
        path: '/rates',
        element: <JobersRate />,
        isPrivate: true,
    },
    {
        path: '/rates/:association_id',
        element: <JobersRate />,
        isPrivate: true,
    },
    {
        path: '/association/list',
        element: <AssociationList />,
        isPrivate: true,
    },
    {
        path: '/association/:id/profil',
        element: <AssociationProfil />,
        isPrivate: true,
    },
    {
        path: '/association/:id/mission',
        element: <AssociationMissionList />,
        isPrivate: true,
    },
    {
        path: '/association/:id/document',
        element: <AssociationDocumentList />,
        isPrivate: true,
    },
    {
        path: '/association/:id/gestion',
        element: <AssociationGestion />,
        isPrivate: true,
    },
    {
        path: '/association/:id/statistique',
        element: <AssociationStatistique />,
        isPrivate: true,
    },
    {
        path: '/etudiant/list',
        element: <EtudiantList />,
        isPrivate: true,
    },
    {
        path: '/etudiant/:id/profil',
        element: <EtudiantProfil />,
        isPrivate: true,
    },
    {
        path: '/etudiant/:id/mission',
        element: <EtudiantMissionList />,
        isPrivate: true,
    },
    {
        path: '/etudiant/:id/document',
        element: <EtudiantDocumentList />,
        isPrivate: true,
    },
    {
        path: '/etudiant/:id/gestion',
        element: <EtudiantGestion />,
        isPrivate: true,
    },
    {
        path: '/etudiant/:id/statistique',
        element: <EtudiantStatistique />,
        isPrivate: true,
    },
    {
        path: '/entreprise/list',
        element: <EntrepriseList />,
        isPrivate: true,
    },
    {
        path: '/entreprise/:id/profil',
        element: <EntrepriseProfil />,
        isPrivate: true,
    },
    {
        path: '/entreprise/:id/mission',
        element: <EntrepriseMissionList />,
        isPrivate: true,
    },
    {
        path: '/entreprise/:id/document',
        element: <EntrepriseDocumentList />,
        isPrivate: true,
    },
    {
        path: '/entreprise/:id/statistique',
        element: <EntrepriseStatistique />,
        isPrivate: true,
    },
    {
        path: '/offre/list',
        element: <OffreList />,
        isPrivate: true,
    },
    {
        path: '/offre/public',
        element: <OffrePublic />,
        isPrivate: false,
    },
    {
        path: '/mission/list',
        element: <MissionList />,
        isPrivate: true,
    },
    {
        path: '/mission/application',
        element: <MissionApplicationList />,
        isPrivate: true,
    },
    {
        path: '/mission/signatures',
        element: <MissionSignatureList />,
        isPrivate: true,
    },
    {
        path: '/mission/list/ongoing',
        element: <MissionListOngoing />,
        isPrivate: true,
    },
    {
        path: '/mission/list/completed',
        element: <MissionListCompleted />,
        isPrivate: true,
    },
    {
        path: '/mission/list/pending',
        element: <MissionListPending />,
        isPrivate: true,
    },
    {
        path: '/mission/new',
        element: <MissionNew />,
        isPrivate: true,
    },
    {
        path: '/mission/:id/profil',
        element: <MissionProfil />,
        isPrivate: true,
    },
    {
        path: '/mission/:id/profil/:student_id',
        element: <MissionProfil />,
        isPrivate: true,
    },
    {
        path: '/facture/list',
        element: <FactureList />,
        isPrivate: true,
    },
    {
        path: '/bv/list',
        element: <PaymentList />,
        isPrivate: true,
    },
    {
        path: '/compta',
        element: <JobersCompta />,
        isPrivate: true,
    },
    {
        path: '/compta/previ',
        element: <JobersCompta status='previ' />,
        isPrivate: true,
    },
    {
        path: '/compta/dsn',
        element: <JobersCompta status='export' />,
        isPrivate: true,
    },
    {
        path: '/compta/export',
        element: <JobersComptaExport />,
        isPrivate: true,
    },
    {
        path: '/',
        element: <Dispatch />,
        isPrivate: true,
    },
    {
        path: '/login',
        element: <LoginForm />,
        isPrivate: false,
    },
    {
        path: '/login/:message',
        element: <LoginForm />,
        isPrivate: false,
    },
    {
        path: '/forgot-password',
        element: <ForgotPasswordForm />,
        isPrivate: false,
    },
    {
        path: '/register',
        element: <RegisterForm />,
        isPrivate: false,
    },
    {
        path: '/register-organization',
        element: <RegisterOrganizationForm />,
        isPrivate: false,
    },
    {
        path: '/register-company',
        element: <RegisterCompanyForm />,
        isPrivate: false,
    },
    {
        path: '/register-student',
        element: <RegisterStudentForm />,
        isPrivate: false,
    },
    {
        path: '/register-student-confirmation',
        element: <RegisterStudentConfirmationForm />,
        isPrivate: false,
    },
    {
        path: '/register-student-renew',
        element: <RegisterStudentRenew />,
        isPrivate: false,
    },
    {
        path: '/document/studyagreement/:id',
        element: <StudyAgreement />,
        isPrivate: true,
    },
    {
        path: '/document/studentmission/:id',
        element: <StudentMission />,
        isPrivate: true,
    },
    {
        path: '/document/payslip/:id',
        element: <PaySlip />,
        isPrivate: true,
    },
    {
        path: '/document/facture/:id',
        element: <Facture />,
        isPrivate: true,
    },
    {
        path: '/listDocument/:type',
        element: <DocumentList />,
        isPrivate: true,
    },
    {
        path: '/me',
        element: <MyDashboard />,
        isPrivate: true,
    },
];

export default routes;
