import http from '../http-common';
import vars from '../config/vars';
const getAll = async (key, fields) => {
    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Attachment',
        output_fields: '*',
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0') && response.data.objects) {
        Object.keys(response.data.objects).forEach(key => {
            objects.push({
                id: response.data.objects[key].key,
                filename: response.data.objects[key].fields.contents.filename,
                data: response.data.objects[key].fields.contents.data,
                mimetype: response.data.objects[key].fields.contents.mimetype,
                type: response.data.objects[key].fields.type,
                creation_date: response.data.objects[key].fields.creation_date,
                item_id: response.data.objects[key].fields.item_id,
                item_class: response.data.objects[key].fields.item_class,
            });
        });
    }

    return objects;
};
const upsert = async (key, fields, output_fields) => {
    let returnValue = false;

    // BUILD POST PARAMS
    let data = {
        operation: 'core/create',
        comment: "Création par l'utilisateur",
        class: 'Attachment',
        fields: fields,
        output_fields: output_fields,
    };

    // UPDATE CASE
    if (key) {
        data.operation = 'core/update';
        data.key = key;
    }

    // BUILD PARAMS ARRAY
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // RETURN WRITTEN OBJECT
    Object.keys(response.data.objects).forEach(key => {
        returnValue = response.data.objects[key];
    });

    return returnValue;
};
const AttachmentDataService = {
    getAll,
    upsert,
};
export default AttachmentDataService;
