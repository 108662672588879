import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import Form from '../Utils/Form';
import Input2 from '../Utils/Input2';

import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';

import StudentDataService from '../../services/student.service';
import AttachmentDataService from '../../services/attachment.service';
import OrganizationDataService from '../../services/organization.service';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
    label,
    value,
    type,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const currentUser = useAuthState();
    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };
    const [error, setError] = useState(null);

    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    const sendFiles = async files => {
        let data = false;
        let b64 = false;
        let attachment = false;
        let filename = false;
        let operation = 'add_association_document';

        if (type == 'CV') {
            filename = 'CV.';
        }
        if (type == 'PI') {
            filename = "Pièce d'identité.";
        }
        if (type == 'AA') {
            filename = "Attestation d'assurance.";
        }
        if (type == 'CS') {
            filename = 'Certificat de Scolarité.';
        }

        if (files.length > 0) {
            let file = files[0];

            if (file.size > 2097152) {
                setError('La taille du fichier ne doit pas dépasser 2 Mo');
                return;
            }

            filename = filename + file.name.split('.').pop();
            b64 = await convertBase64(file);
            data = b64.substring(b64.indexOf('base64,') + 7);
            attachment = {
                item_class: 'Person',
                item_id: currentUser.id,
                temp_id: filename,
                type: type,
                item_org_id: currentUser.org_id,
                creation_date: new Date(),
                contents: {
                    data: data,
                    filename: filename,
                    mimetype: file.type,
                },
            };
        }

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(attachment));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        return await axios(config);
    };

    const handleNewFileUpload = async e => {
        const { files: newFiles } = e.target;
        if (newFiles.length > 0) {
            let response = await sendFiles(newFiles);
            if (response.data.code === 0) {
                // window.location.reload(false);
            }
        }
    };

    return (
        <div className='list-flex-child profil-flex-container'>
            <div className='profil-flex-child'>
                <div className='profil-label'>{label}</div>
            </div>
            <div className='profil-flex-child'>
                <div
                    className='profil-value list-table-link'
                    onClick={e => handleUploadBtnClick(e)}>
                    {value}
                </div>
                <input
                    type='file'
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=''
                    value=''
                    {...otherProps}
                />
                <p className='error-text'>{error}</p>
            </div>
        </div>
    );
};

export default function EtudiantProfil(props) {
    const [tabClass, setTabClass] = useState('profil-container-divider-1');
    const [initialObjectState, setInitialObjectState] = useState(null);
    const [editMode, setEditMode] = useState(0);
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });
    const [associations, setAssociations] = useState([]);

    let { id } = useParams();
    const currentUser = useAuthState();

    const skills = [
        'Word',
        'Excel',
        'PowerPoint',
        'Access',
        'PowerBI',
        'Photoshop',
        'InDesign',
        'Illustrator',
        'Lightroom',
        'Première',
        'After Effect',
        'Substance',
        'Autocad',
        'Rhinoceros3D',
        'Sketchup',
        'Revit',
        'Archicad',
        '3dsMax',
        'Cinéma4D',
        'Maya',
        'Blender',
        'Vray',
        'Octane',
        'Lumion',
        'Twinmotion',
        'Enscape',
    ];

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                org_id: null,
                first_name: '',
                name: '',
                domain: 'architecture',
                email: '',
                phone: '',
                started_at: '',
                joined_at: '',
                nationality: '',
                year: '',
                school_year: '',
                school: '',
                ssn: '',
                // id_stripe: '',
                bank: '',
                iban: '',
                bic: '',
                book_url: '',
                skills_1: '',
                skills_2: '',
                skills_3: '',
                skills_4: '',
                files: [],
            };

            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (currentUser.id !== '0' && (id == 'me' || props.id == 'me')) {
                id = currentUser.id;
            }
            if (id) {
                StudentDataService.get(id).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    let i = 1;
                    objectData.skills.forEach(skill => {
                        objectData['skills_' + i] = skill.label;
                        i++;
                    });

                    AttachmentDataService.getAll(
                        'SELECT Attachment WHERE item_class = "Person" AND item_id = ' + id,
                        'contents'
                    ).then(fileresponse => {
                        objectData.files = fileresponse;

                        objectData.files.forEach(file => {
                            if (file.filename.includes('CV.') || file.type === 'CV') {
                                objectData.cv = file;
                            }
                            if (
                                file.filename.includes("Pièce d'identité.") ||
                                file.filename.includes('Pièce d’identité.') ||
                                file.type === "Pièce d'identité" ||
                                file.type === 'PI'
                            ) {
                                objectData.id_certif = file;
                            }
                            if (
                                file.filename.includes("Attestation d'assurance.") ||
                                file.filename.includes('Attestation d’assurance.') ||
                                file.type === "Attestation d'assurance"
                            ) {
                                objectData.insurance_certif = file;
                            }
                            if (
                                file.filename.includes('Certificat de Scolarité.') ||
                                file.type === 'Certificat de Scolarité'
                            ) {
                                objectData.school_certif = file;
                            }
                        });

                        OrganizationDataService.getAll(
                            'SELECT Association',
                            'id, friendlyname, school_name, started_at, persons_list'
                        )
                            .then(response => {
                                setInitialObjectState(objectData);
                                let arrAssociations = [];
                                response.forEach(association => {
                                    arrAssociations.push({
                                        value: association.id,
                                        label: association.name,
                                    });
                                });
                                setAssociations(arrAssociations);
                            })
                            .catch(e => {
                                console.log(e);
                            });
                    });
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    const string_to_slug = str => {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
        var to = 'aaaaeeeeiiiioooouuuunc------';
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str
            .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    };

    const onSubmit = async (fields, errors) => {
        setFormData({
            fields,
            errors,
        });

        // for 1 to 4 skills
        let skills = [];
        for (let i = 1; i <= 4; i++) {
            if (fields['skills_' + i].value != '') {
                skills.push({
                    label: fields['skills_' + i].value,
                    value: string_to_slug(fields['skills_' + i].value),
                });
            }
        }

        let key = null;
        if (id !== '0' && id !== 'me') {
            key = id;
        }
        if (currentUser.id !== '0' && (id == 'me' || props.id == 'me')) {
            key = currentUser.id;
        }

        let aDataFields = {
            id: key,
            name: fields.name.value,
            // domain: fields.domain.value,
            first_name: fields.first_name.value,
            // school: fields.school.value,
            // started_at: fields.started_at.value,
            // org_id: fields.org_id.value,
            // school_year: fields.school_year.value,
            book_url: fields.book_url.value,
            email: fields.email.value,
            phone: fields.phone.value,
            description: fields.description.value,
            birthday: fields.birthday.value,
            birth_city: fields.birth_city.value,
            birth_dept: fields.birth_dept.value,
            birth_country: fields.birth_country.value,
            ssn: fields.ssn.value,
            // gender: fields.gender.value,
            address: fields.address.value,
            postcode: fields.postal_code.value,
            city: fields.city.value,
            // id_stripe: fields.id_stripe.value,
            bank: fields.bank.value,
            iban: fields.iban.value,
            bic: fields.bic.value,
            // joined_at: fields.joined_at.value,
            skills: JSON.stringify(skills),
        };

        let data = new URLSearchParams();
        data.append('operation', 'upsert_etudiant');
        data.append('data', JSON.stringify(aDataFields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        let response = await axios(config);

        if (response.data.code === 0) {
            window.location.reload(false);
        }

        if (response.data.code !== 0) {
            console.log(response.data.message);
            if (response.data.message.includes('est déjà utilisée par un autre utilisateur')) {
                alert('Cette adresse email est déjà utilisée par un autre utilisateur');
            }
        }
    };

    const handleTab = className => {
        setTabClass(className);
    };

    const confirm = async e => {
        let aDataFields = {
            item_class: 'Person',
            item_id: initialObjectState.id,
        };
        let data = new URLSearchParams();
        data.append('operation', 'confirm_item');
        data.append('data', JSON.stringify(aDataFields));
        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };
        let response = await axios(config);
        if (response.data.code === 0) {
            window.location.reload(false);
        }
        if (response.data.code !== 0) {
            console.log(response.data.message);
        }
    };

    const createDocumentLink = file => {
        if (file) {
            return (
                'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=display_document&filename=' +
                file.filename +
                '&id=' +
                file.id +
                '&obj=Person::' +
                initialObjectState.id
            );
        }
    };

    const displaySchoolYear = school_year => {
        switch (school_year) {
            case '1':
                return 'Première année';
            case '2':
                return 'Deuxième année';
            case '3':
                return 'Troisième année';
            case '4':
                return 'Quatrième année';
            case '5':
                return 'Cinquième année';
            case 'HMONP':
                return 'HMONP';
            default:
                return 'Information non disponible';
        }
    };

    const displayField = (label, value, options) => {
        let readOnlyFields = [
            'domain',
            'gender',
            'school',
            'school_year',
            'started_at',
            'org_id',
            'display_status',
            'joined_at',
        ];
        let fileuploadfields = ['curriculum_vitae', 'id_card', 'school_certif', 'civil_certif'];
        if (true) {
            if (editMode == 2 && fileuploadfields.indexOf(options.id) > -1) {
                if (options.id == 'curriculum_vitae') {
                    return (
                        <>
                            <FileUpload
                                type='CV'
                                accept='.jpg,.png,.jpeg,.pdf'
                                label='Curriculum Vitae'
                            />
                        </>
                    );
                }
                if (options.id == 'id_card') {
                    return (
                        <>
                            <FileUpload
                                type='PI'
                                accept='.jpg,.png,.jpeg,.pdf'
                                label="Pièce d'identité"
                                value="(Re)charger pièce d'identité"
                            />
                        </>
                    );
                }
                if (options.id == 'school_certif') {
                    return (
                        <>
                            <FileUpload
                                type='CS'
                                accept='.jpg,.png,.jpeg,.pdf'
                                label='Certif. de scolarité'
                                value='(Re)charger certif. de scolarité'
                            />
                        </>
                    );
                }
                if (options.id == 'civil_certif') {
                    return (
                        <>
                            <FileUpload
                                type='AA'
                                accept='.jpg,.png,.jpeg,.pdf'
                                label='Resp. Civile'
                                value='(Re)charger resp. Civile'
                            />
                        </>
                    );
                }
            }
            if (editMode == 2 && readOnlyFields.indexOf(options.name) == -1) {
                return (
                    <>
                        <Input2 {...options} />
                    </>
                );
            }

            if (label === 'Présentez-vous') {
                return (
                    <>
                        <div className='list-flex-child no-flex profil-flex-container'>
                            <div className='profil-flex-child'>
                                <div className='profil-label'>{label}</div>
                            </div>
                            <div className='profil-flex-child student-description-flex'>
                                <div className='profil-value profil-description-value gray-value'>
                                    {value}
                                </div>
                            </div>
                        </div>
                    </>
                );
            }

            return (
                <>
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'>
                            <div className='profil-label'>{label}</div>
                        </div>
                        <div className='profil-flex-child'>
                            {/* if label === Cotisation and value is before today or in next 30 days then display in red */}
                            {label === 'Cotisation' &&
                                value &&
                                moment(value, 'DD-MM-YYYY') < moment().add(30, 'days') && (
                                    <div className='profil-value red-value'>{value}</div>
                                )}
                            {label === 'Cotisation' &&
                                value &&
                                moment(value, 'DD-MM-YYYY') > moment().add(30, 'days') && (
                                    <div className='profil-value gray-value'>{value}</div>
                                )}
                            {label !== 'Cotisation' && (
                                <div className='profil-value gray-value'>{value}</div>
                            )}
                            {label === 'Genre' && (
                                <span id='gender_tooltip' className='tooltip'>
                                    <div className='icon-size'>
                                        <img
                                            className='icon-img'
                                            alt='go-back'
                                            src='/img/10_QUESTION_MARK.svg'
                                        />
                                    </div>
                                    <Tooltip
                                        anchorId='gender_tooltip'
                                        content="Cette mention pourrait ne pas refléter vos choix personnels, malheureusement les systèmes comptables l'exigent sous cette forme. Nous avons déduit cette donnée de votre numéro de sécurité sociale."
                                        place='bottom'
                                        style={{
                                            backgroundColor: '#fff',
                                            color: 'gray',
                                            width: '20vw',
                                            border: '1px solid #ccc',
                                            marginTop: '10px',
                                            marginLeft: '-30px',
                                            borderRadius: '8px',
                                            fontFamily: 'Mont-Book',
                                            fontSize: '11pt',
                                            letterSpacing: 0,
                                        }}
                                    />
                                </span>
                            )}
                        </div>
                    </div>
                </>
            );
        }
    };

    const isCompany = () => {
        if (currentUser.function === 'Compte entreprise') {
            return true;
        }
        if (currentUser.function === 'Représentant légal') {
            return true;
        }
        if (currentUser.function === 'Personne de contact') {
            return true;
        }
        return false;
    };

    const isConnected = () => {
        if (isCompany() && initialObjectState) {
            let isFound = false;
            initialObjectState.missions_list.forEach(mission => {
                if (mission.company_id === currentUser.org_id) {
                    isFound = true;
                }
            });
            return isFound;
        }
    };

    const canEdit = () => {
        if (currentUser.id == initialObjectState.id && currentUser.function === 'Student') {
            return true;
        }
        if (currentUser.function === 'Jobers') {
            return false;
        }
        return false;
    };

    if (!initialObjectState || (initialObjectState && initialObjectState.id === null)) {
        return (
            <div className='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

    return (
        <div className='backend-container'>
            <Sidebar />
            <Form
                onSubmit={onSubmit}
                fields={formData.fields}
                errors={formData.errors}
                validateOnSubmit={true}>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    <div className='list-flex-child flex-4'>
                        {initialObjectState && (
                            <h1 className='list-container-title'>
                                {initialObjectState.first_name} {initialObjectState.name}
                            </h1>
                        )}
                    </div>
                </div>
                {!isCompany() && (
                    <div className='list-flex-container'>
                        {initialObjectState && id === null && (
                            <div className='list-flex-child flex-4 list-container-tabs'>
                                <Link
                                    to={'#'}
                                    className='profil-tabs'
                                    onMouseOver={() => handleTab('profil-container-divider-1')}>
                                    Profil
                                </Link>
                            </div>
                        )}
                        {currentUser.function === 'Jobers' ||
                        (initialObjectState &&
                            currentUser.function === 'Student' &&
                            currentUser.id === initialObjectState.id) ||
                        (initialObjectState &&
                            (currentUser.function === 'Président' ||
                                currentUser.function === 'Compte association') &&
                            currentUser.org_id === initialObjectState.org_id) ||
                        (isCompany() && isConnected()) ? (
                            <div className='list-flex-child flex-4 list-container-tabs'>
                                <Link
                                    to={'/etudiant/' + initialObjectState.id + '/profil'}
                                    className='profil-tabs'
                                    onMouseOver={() => handleTab('profil-container-divider-1')}>
                                    Profil
                                </Link>
                                <Link
                                    to={'/etudiant/' + initialObjectState.id + '/mission'}
                                    className='profil-tabs'
                                    onMouseOver={() => handleTab('profil-container-divider-2')}
                                    onMouseOut={() => handleTab('profil-container-divider-1')}>
                                    Missions
                                </Link>
                                <Link
                                    to={'/etudiant/' + initialObjectState.id + '/document'}
                                    className='profil-tabs'
                                    onMouseOver={() => handleTab('profil-container-divider-3')}
                                    onMouseOut={() => handleTab('profil-container-divider-1')}>
                                    Documents
                                </Link>
                            </div>
                        ) : (
                            <div className='list-flex-child flex-4 list-container-tabs'>
                                {initialObjectState && (
                                    <Link
                                        to={'/etudiant/' + initialObjectState.id + '/profil'}
                                        className='profil-tabs'
                                        onMouseOver={() => handleTab('profil-container-divider-1')}>
                                        Profil
                                    </Link>
                                )}
                            </div>
                        )}
                        {((currentUser.function === 'Jobers' &&
                            initialObjectState.status === 'pending') ||
                            (initialObjectState &&
                                currentUser.function === 'Student' &&
                                currentUser.id === initialObjectState.id)) && (
                            <div className='list-flex-container'>
                                {editMode == 2 && (
                                    <div className='profil-container-submitbutton'>
                                        <span className='list-container-submitlabel'>
                                            <button type='submit' className='primary-submit'>
                                                {initialObjectState && id !== 'undefined'
                                                    ? 'Mettre à jour'
                                                    : 'Créer'}
                                            </button>
                                            {/* <Link to={'/association/' + id + '/profil'}>Enregistrer</Link> */}
                                        </span>
                                    </div>
                                )}
                                {editMode < 2 && (
                                    <div className='profil-container-wipbutton'>
                                        <span className='list-container-addlabel'>
                                            <a
                                                onClick={() => {
                                                    editMode === 1
                                                        ? setEditMode(0)
                                                        : setEditMode(1);
                                                }}>
                                                +
                                            </a>
                                        </span>
                                    </div>
                                )}
                                {editMode == 1 &&
                                    currentUser.function === 'Jobers' &&
                                    initialObjectState.status == 'pending' && (
                                        <div className='more_actions'>
                                            <div
                                                className='more_actions_action'
                                                onClick={() => confirm()}>
                                                {/* display ham-menu icon */}
                                                <div className='more_actions_icon'>
                                                    <img
                                                        className='ham_menu'
                                                        src='/img/ham-menu.svg'
                                                        alt='more actions'
                                                    />
                                                </div>
                                                <div className='more_actions_item'>Confirmer</div>
                                            </div>
                                        </div>
                                    )}
                                {editMode === 1 && canEdit() && (
                                    <div className='more_actions'>
                                        <div
                                            className='more_actions_action'
                                            onClick={() => setEditMode(2)}>
                                            {/* display ham-menu icon */}
                                            <div className='more_actions_icon'>
                                                <img
                                                    className='ham_menu'
                                                    src='/img/ham-menu.svg'
                                                    alt='more actions'
                                                />
                                            </div>
                                            <div className='more_actions_item'>Modifier</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    <div className='list-flex-child'>
                        {initialObjectState && isCompany() && isConnected() && (
                            <p className='list-container-subtitle'>
                                Voici les informations publiques à propos de{' '}
                                {initialObjectState.first_name}.
                            </p>
                        )}
                        {initialObjectState && isCompany() && !isConnected() && (
                            <p className='list-container-subtitle'>
                                Vous n'avez pas encore travaillé avec{' '}
                                {initialObjectState.first_name}.
                            </p>
                        )}
                    </div>
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    {!isCompany() && <div className={tabClass} />}
                    {isCompany() && <div className='profil-detail-divider' />}
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    <div className='list-flex-child'>
                        {!isCompany() && (
                            <p className='list-container-subtitle list-tab-subtitle'>
                                Ces informations sont visibles par les membres du réseau Jobers.
                            </p>
                        )}
                    </div>
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    {initialObjectState &&
                        displayField('Nom', initialObjectState.name, {
                            id: 'name',
                            name: 'name',
                            label: 'Nom',
                            placeholder: 'Nom',
                            type: 'text',
                            value: initialObjectState.name,
                            validate: 'required',
                            classes: {
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            },
                        })}
                    {initialObjectState &&
                        displayField(
                            'Domaine',
                            initialObjectState.domain.charAt(0).toUpperCase() +
                                initialObjectState.domain.slice(1),
                            {
                                id: 'domain',
                                name: 'domain',
                                label: 'Domaine',
                                placeholder: 'Architecture',
                                type: 'select',
                                value:
                                    initialObjectState.domain.charAt(0).toUpperCase() +
                                    initialObjectState.domain.slice(1),
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                                options: [
                                    { value: 'architecture', label: 'Architecture' },
                                    { value: 'paysage', label: 'Paysage' },
                                    { value: 'commerce', label: 'Commerce' },
                                    { value: 'communication', label: 'Communication' },
                                    { value: 'ingenierie', label: 'Ingenierie' },
                                ],
                            }
                        )}
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    {initialObjectState &&
                        displayField('Prénom', initialObjectState.first_name, {
                            id: 'first_name',
                            name: 'first_name',
                            label: 'Prénom',
                            placeholder: 'Prénom',
                            type: 'text',
                            value: initialObjectState.first_name,
                            validate: 'required',
                            classes: {
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            },
                        })}
                    {initialObjectState &&
                        displayField('École', initialObjectState.school, {
                            id: 'school',
                            name: 'school',
                            label: 'École',
                            placeholder: 'Nom de l’école',
                            type: 'autocomplete',
                            value: initialObjectState.school,
                            classes: {
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            },
                            suggestions: [
                                'Paris ESA',
                                'Strasbourg',
                                'Versailles',
                                'Bordeaux',
                                'Clermont-Ferrand',
                                'Grenoble',
                                'Lille',
                                'Lyon',
                                'Marne la Vallée',
                                'Marseille',
                                'Montpellier',
                                'Nancy',
                                'Nantes',
                                'Normandie',
                                'Paris Belleville',
                                'Paris Malaquais',
                                'Paris Val de Seine',
                                'Paris Villette',
                                'Rennes',
                                'Rouen',
                                'St Etienne',
                                'Toulouse',
                            ],
                        })}
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    {initialObjectState &&
                        displayField(
                            "Date d'adhésion",
                            initialObjectState.started_at &&
                                moment(initialObjectState.started_at).format('DD-MM-YYYY'),
                            {
                                id: 'started_at',
                                name: 'started_at',
                                label: "Date d'adhésion",
                                placeholder: '2022-01-01',
                                type: 'text',
                                value:
                                    initialObjectState.started_at != '' &&
                                    moment(initialObjectState.started_at).format('DD-MM-YYYY'),
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            }
                        )}
                    {initialObjectState &&
                        displayField(
                            'Association',
                            <Link
                                className='list-table-link'
                                to={'/association/' + initialObjectState.org_id + '/profil'}>
                                {initialObjectState.org_name}
                            </Link>,
                            {
                                id: 'org_id',
                                name: 'org_id',
                                label: 'Association',
                                placeholder: 'Association',
                                type: 'select',
                                value: initialObjectState.org_id,
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                                options: associations,
                            }
                        )}
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    {initialObjectState &&
                        displayField('Statut', initialObjectState.display_status, {
                            id: 'display_status',
                            name: 'display_status',
                            label: 'Statut',
                            placeholder: 'Inactif',
                            type: 'text',
                            value: initialObjectState.display_status,
                            classes: {
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            },
                        })}
                    {initialObjectState &&
                        displayField(
                            "Niveau d'études",
                            displaySchoolYear(initialObjectState.school_year),
                            {
                                id: 'school_year',
                                name: 'school_year',
                                label: "Niveau d'études",
                                placeholder: "Niveau d'études",
                                type: 'select',
                                value: initialObjectState.school_year,
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                                options: [
                                    { value: '1', label: 'Première année' },
                                    { value: '2', label: 'Deuxième année' },
                                    { value: '3', label: 'Troisième année' },
                                    { value: '4', label: 'Quatrième année' },
                                    { value: '5', label: 'Cinquième année' },
                                    { value: 'HMONP', label: 'HMONP' },
                                ],
                            }
                        )}
                </div>
                {(currentUser.function === 'Jobers' ||
                    (initialObjectState &&
                        currentUser.function === 'Student' &&
                        currentUser.id === initialObjectState.id) ||
                    (initialObjectState &&
                        currentUser.function !== 'Student' &&
                        currentUser.org_id === initialObjectState.org_id) ||
                    isCompany()) && (
                    <div
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child'>
                                <p className='list-container-subtitle list-tab-subtitle'>
                                    Les informations complètes seront transmises à l'agence lorsque
                                    l'étudiant postule à une mission.
                                </p>
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Compétences', initialObjectState.skills_1, {
                                    id: 'skills_1',
                                    name: 'skills_1',
                                    label: 'Compétences',
                                    placeholder: 'Photoshop',
                                    type: 'autocomplete',
                                    suggestions: skills,
                                    value: initialObjectState.skills_1,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField(
                                    'Curriculum vitae',
                                    isCompany() && !isConnected() ? (
                                        '**********'
                                    ) : initialObjectState.cv ? (
                                        <a
                                            className='list-table-link'
                                            href={createDocumentLink(initialObjectState.cv)}
                                            target='_blank'>
                                            Afficher
                                        </a>
                                    ) : (
                                        'Aucun CV'
                                    ),
                                    {
                                        id: 'curriculum_vitae',
                                        name: 'curriculum_vitae',
                                        label: 'Mon CV',
                                        placeholder: 'Mon CV',
                                        type: 'text',
                                        value: 'moncv.pdf',
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('', initialObjectState.skills_2, {
                                    id: 'skills_2',
                                    name: 'skills_2',
                                    label: '',
                                    placeholder: 'Indesign',
                                    type: 'autocomplete',
                                    suggestions: skills,
                                    value: initialObjectState.skills_2,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField(
                                    'Portfolio',
                                    isCompany() && !isConnected() ? (
                                        '**********'
                                    ) : (
                                        <a
                                            href={
                                                initialObjectState.book_url != ''
                                                    ? initialObjectState.book_url
                                                    : '#'
                                            }
                                            target={
                                                initialObjectState.book_url == '' ? '' : '_blank'
                                            }
                                            className={
                                                initialObjectState.book_url == ''
                                                    ? 'gray-value'
                                                    : 'list-table-link'
                                            }>
                                            {initialObjectState.book_url != ''
                                                ? initialObjectState.book_url
                                                : 'Aucun portfolio'}
                                        </a>
                                    ),
                                    {
                                        id: 'book_url',
                                        name: 'book_url',
                                        label: 'Portfolio',
                                        placeholder: 'https://www.monsite.com',
                                        type: 'text',
                                        value: initialObjectState.book_url,
                                        validate: 'url',
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('', initialObjectState.skills_3, {
                                    id: 'skills_3',
                                    name: 'skills_3',
                                    label: '',
                                    placeholder: 'Sketchup',
                                    type: 'autocomplete',
                                    suggestions: skills,
                                    value: initialObjectState.skills_3,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField(
                                    'Téléphone',
                                    isCompany() && !isConnected()
                                        ? '**********'
                                        : initialObjectState.phone,
                                    {
                                        id: 'phone',
                                        name: 'phone',
                                        label: 'Téléphone',
                                        placeholder: '06 00 00 00 00',
                                        type: 'text',
                                        validate: 'phone',
                                        value: initialObjectState.phone,
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('', initialObjectState.skills_4, {
                                    id: 'skills_4',
                                    name: 'skills_4',
                                    label: '',
                                    placeholder: 'Photoshop',
                                    type: 'autocomplete',
                                    suggestions: skills,
                                    value: initialObjectState.skills_4,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField(
                                    'Email',
                                    isCompany() && !isConnected()
                                        ? '**********'
                                        : initialObjectState.email,
                                    {
                                        id: 'email',
                                        name: 'email',
                                        label: 'Email',
                                        placeholder: 'john.doe@gmail.com',
                                        type: 'text',
                                        value: initialObjectState.email,
                                        validate: 'required|email',
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Présentez-vous', initialObjectState.description, {
                                    id: 'description',
                                    name: 'description',
                                    label: 'Présentez-vous',
                                    placeholder: 'Dites quelques mots',
                                    type: 'textarea',
                                    value: initialObjectState.description,
                                    classes: {
                                        contClass: 'list-flex-child no-flex profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value profil-description-value',
                                    },
                                })}
                        </div>
                    </div>
                )}
                {(currentUser.function === 'Jobers' ||
                    (initialObjectState &&
                        currentUser.function === 'Student' &&
                        currentUser.id === initialObjectState.id) ||
                    (initialObjectState &&
                        currentUser.function === 'Président' &&
                        currentUser.org_id === initialObjectState.org_id)) && (
                    <div
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child'>
                                <p className='list-container-subtitle list-tab-subtitle'>
                                    Ces informations restent privées et ne sont visibles que par les
                                    admins Jobers et les gérants de votre association.
                                </p>
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField(
                                    'Date de naissance',
                                    moment(initialObjectState.birthday).format('DD-MM-YYYY'),
                                    {
                                        id: 'birthday',
                                        name: 'birthday',
                                        label: 'Date de naissance',
                                        placeholder: '01-01-1990',
                                        type: 'text',
                                        value: initialObjectState.birthday,
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                            {initialObjectState &&
                                displayField(
                                    "Pièce d'identité",
                                    initialObjectState.id_certif ? (
                                        <a
                                            className='list-table-link'
                                            href={createDocumentLink(initialObjectState.id_certif)}
                                            target='_blank'>
                                            Afficher
                                        </a>
                                    ) : (
                                        'Aucun document'
                                    ),
                                    {
                                        id: 'id_card',
                                        name: 'id_card',
                                        label: "Pièce d'identité",
                                        placeholder: 'Télécharger',
                                        type: 'text',
                                        value: 'Afficher',
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Ville de naissance', initialObjectState.birth_city, {
                                    id: 'birth_city',
                                    name: 'birth_city',
                                    label: 'Ville de naissance',
                                    placeholder: 'Paris',
                                    type: 'text',
                                    value: initialObjectState.birth_city,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField(
                                    'Certif. de scolarité',
                                    initialObjectState.school_certif ? (
                                        <a
                                            className='list-table-link'
                                            href={createDocumentLink(
                                                initialObjectState.school_certif
                                            )}
                                            target='_blank'>
                                            Afficher
                                        </a>
                                    ) : (
                                        'Aucun document'
                                    ),
                                    {
                                        id: 'school_certif',
                                        name: 'school_certif',
                                        label: 'Certif. de scolarité',
                                        placeholder: 'Télécharger',
                                        type: 'text',
                                        value: 'Afficher',
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField(
                                    'Département de naissance',
                                    initialObjectState.birth_dept,
                                    {
                                        id: 'birth_dept',
                                        name: 'birth_dept',
                                        label: 'Département de naissance',
                                        placeholder: '75',
                                        type: 'text',
                                        value: initialObjectState.birth_dept,
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                            {initialObjectState &&
                                displayField(
                                    'Resp. Civile',
                                    initialObjectState.insurance_certif ? (
                                        <a
                                            className='list-table-link'
                                            href={createDocumentLink(
                                                initialObjectState.insurance_certif
                                            )}
                                            target='_blank'>
                                            Afficher
                                        </a>
                                    ) : (
                                        'Aucun document'
                                    ),
                                    {
                                        id: 'civil_certif',
                                        name: 'civil_certif',
                                        label: 'Resp. Civile',
                                        placeholder: 'Télécharger',
                                        type: 'text',
                                        value: 'Afficher',
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField(
                                    'Pays de naissance',
                                    initialObjectState.birth_country,
                                    {
                                        id: 'birth_country',
                                        name: 'birth_country',
                                        label: 'Pays de naissance',
                                        placeholder: 'France',
                                        type: 'text',
                                        value: initialObjectState.birth_country,
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                            {initialObjectState &&
                                displayField(
                                    'Genre',
                                    initialObjectState.gender === 'male' ? 'Homme' : 'Femme',
                                    {
                                        id: 'gender',
                                        name: 'gender',
                                        label: 'Genre',
                                        placeholder: 'Femme',
                                        type: 'select',
                                        value: initialObjectState.gender,
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                        options: [
                                            { value: 'male', label: 'Homme' },
                                            { value: 'female', label: 'Femme' },
                                        ],
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('N° sécurité sociale', initialObjectState.ssn, {
                                    id: 'ssn',
                                    name: 'ssn',
                                    label: 'N° sécurité sociale',
                                    placeholder: '123456789',
                                    type: 'text',
                                    value: initialObjectState.ssn,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'></div>
                        </div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Adresse résidence', initialObjectState.address, {
                                    id: 'address',
                                    name: 'address',
                                    label: 'Adresse résidence',
                                    placeholder: '1 rue de la paix',
                                    type: 'text',
                                    value: initialObjectState.address,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('Banque', initialObjectState.bank, {
                                    id: 'bank',
                                    name: 'bank',
                                    label: 'Banque',
                                    placeholder: '',
                                    type: 'text',
                                    value: initialObjectState.bank,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Code postal', initialObjectState.postcode, {
                                    id: 'postal_code',
                                    name: 'postal_code',
                                    label: 'Code postal',
                                    placeholder: '75000',
                                    type: 'text',
                                    value: initialObjectState.postcode,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('IBAN', initialObjectState.iban, {
                                    id: 'iban',
                                    name: 'iban',
                                    label: 'IBAN',
                                    placeholder: 'IBAN',
                                    type: 'text',
                                    value: initialObjectState.iban,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {/* <div className='list-flex-child profil-flex-container'></div> */}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Ville', initialObjectState.city, {
                                    id: 'city',
                                    name: 'city',
                                    label: 'Ville',
                                    placeholder: 'Paris',
                                    type: 'text',
                                    value: initialObjectState.city,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {/* <div className='list-flex-child profil-flex-container'></div> */}
                            {initialObjectState &&
                                displayField('BIC', initialObjectState.bic, {
                                    id: 'bic',
                                    name: 'bic',
                                    label: 'BIC',
                                    placeholder: 'BIC',
                                    type: 'text',
                                    value: initialObjectState.bic,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField(
                                    'Cotisation',
                                    moment(initialObjectState.joined_at).format('DD-MM-YYYY'),
                                    {
                                        id: 'joined_at',
                                        name: 'joined_at',
                                        label: 'Cotisation',
                                        placeholder: '01-09-2022',
                                        type: 'text',
                                        value: initialObjectState.joined_at,
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                            <div className='list-flex-child profil-flex-container'></div>
                        </div>
                    </div>
                )}
                <div className='list-flex-container'>
                    <div className='list-flex-child profil-flex-container profil-footer'></div>
                </div>
            </Form>
        </div>
    );
}
