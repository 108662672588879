import React from 'react';
import Parameter from './Parameter';
import Disconnect from './Disconnect';
import User from './User';
import Avatar from '../Icon/Avatar';
import './Sidebar.css';

import { useAuthState } from '../../../context';

import { SidebarData } from './SidebarData';
import { AssoData } from './AssoData';
import { StudentData } from './StudentData';
import { CompanyData } from './CompanyData';

export default function Sidebar() {
    const currentUser = useAuthState();

    return (
        <nav className='contain'>
            <header>
                <div className='sidebar'>
                    <div className='contain-logo'>
                        <img src='/img/J_Logotype_BlancPointFluo.svg' width='150px' height='36px' />
                    </div>
                    <ul className='sidebarUl'>
                        {currentUser.function === 'Jobers' &&
                            SidebarData.map((val, key) => {
                                return (
                                    <li
                                        key={key}
                                        className={
                                            window.location.pathname === val.link ||
                                            window.location.pathname.includes(val.link) ||
                                            (window.location.pathname == '/' &&
                                                val.link == '/association/list')
                                                ? 'sidebarLiActive sidebarLi'
                                                : 'sidebarLi'
                                        }
                                        onClick={() => {
                                            window.location.pathname = val.link;
                                        }}>
                                        <div> {val.icon} </div>{' '}
                                        <div className='text-li'> {val.title} </div>
                                    </li>
                                );
                            })}
                        {currentUser.function === 'Student' &&
                            StudentData.map((val, key) => {
                                return (
                                    <li
                                        key={key}
                                        className={
                                            window.location.pathname === val.link ||
                                            window.location.pathname.includes(val.link) ||
                                            (window.location.pathname == '/' &&
                                                val.link == '/etudiant/me/profil')
                                                ? 'sidebarLiActive sidebarLi'
                                                : 'sidebarLi'
                                        }
                                        onClick={() => {
                                            window.location.pathname = val.link;
                                        }}>
                                        <div> {val.icon} </div>{' '}
                                        <div className='text-li'> {val.title} </div>
                                    </li>
                                );
                            })}
                        {currentUser.function === 'Compte entreprise' &&
                            CompanyData.map((val, key) => {
                                return (
                                    <li
                                        key={key}
                                        className={
                                            window.location.pathname === val.link ||
                                            window.location.pathname.includes(val.link) ||
                                            (window.location.pathname == '/' &&
                                                val.link == '/entreprise/me/profil') ||
                                            (val.link == '/mission/new' &&
                                                (window.location.pathname ==
                                                    '/mission/application' ||
                                                    window.location.pathname ==
                                                        '/mission/signatures'))
                                                ? 'sidebarLiActive sidebarLi'
                                                : 'sidebarLi'
                                        }
                                        onClick={() => {
                                            window.location.pathname = val.link;
                                        }}>
                                        <div> {val.icon} </div>{' '}
                                        <div className='text-li'> {val.title} </div>
                                    </li>
                                );
                            })}
                        {(currentUser.function === 'Président' ||
                            currentUser.function === 'Compte association') &&
                            AssoData.map((val, key) => {
                                return (
                                    <li
                                        key={key}
                                        className={
                                            window.location.pathname === val.link
                                                ? 'sidebarLiActive sidebarLi'
                                                : 'sidebarLi'
                                        }
                                        onClick={() => {
                                            window.location.pathname = val.link;
                                        }}>
                                        <div> {val.icon} </div>{' '}
                                        <div className='text-li'> {val.title} </div>
                                    </li>
                                );
                            })}
                    </ul>
                    <div className='contain-parameter'>
                        <Parameter />
                    </div>
                    <div className='contain-disconnect'>
                        <Disconnect />
                    </div>
                    <div className='contain-user'>
                        <div className='separator'></div>
                        <div className='content-user'>
                            <div>
                                <div className='user-logo'>
                                    <Avatar />
                                </div>
                            </div>
                            <User />
                        </div>
                    </div>
                </div>
            </header>
        </nav>
    );
}
