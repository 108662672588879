import http from '../http-common';
import vars from '../config/vars';
const getAll = async (key, fields) => {
    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Person',
        output_fields: fields,
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if (response.data.code == '0' && response.data.objects) {
        Object.keys(response.data.objects).forEach(key => {
            objects.push({
                id: response.data.objects[key].fields.id,
                name: response.data.objects[key].fields.name,
                first_name: response.data.objects[key].fields.first_name,
                phone: response.data.objects[key].fields.phone,
                email: response.data.objects[key].fields.email,
                function: response.data.objects[key].fields.function,
            });
        });
    }

    return objects;
};
const get = async (key, fields) => {
    let returnValue = {};

    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Person',
        output_fields: fields,
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        Object.keys(response.data.objects).forEach(key => {
            returnValue.id = response.data.objects[key].fields.id;
            returnValue.name = response.data.objects[key].fields.name;
            returnValue.first_name = response.data.objects[key].fields.first_name;
            returnValue.phone = response.data.objects[key].fields.phone;
            returnValue.email = response.data.objects[key].fields.email;
            returnValue.address = response.data.objects[key].fields.location_address;
            returnValue.postcode = response.data.objects[key].fields.location_postal_code;
            returnValue.city = response.data.objects[key].fields.location_city;
            returnValue.country = response.data.objects[key].fields.location_country;
            returnValue.function = response.data.objects[key].fields.function;
        });
    }

    return returnValue;
};
const upsert = async (key, fields, output_fields) => {
    let returnValue = false;

    // BUILD POST PARAMS
    let data = {
        operation: 'core/create',
        comment: 'Création par Jobers',
        class: 'Person',
        fields: fields,
        output_fields: output_fields,
    };

    // UPDATE CASE
    if (key) {
        data.operation = 'core/update';
        data.key = key;
    }

    // BUILD PARAMS ARRAY
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // RETURN WRITTEN OBJECT
    Object.keys(response.data.objects).forEach(key => {
        returnValue = response.data.objects[key];
    });

    return returnValue;
};
const PersonDataService = {
    getAll,
    get,
    upsert,
};
export default PersonDataService;
