import './App.css';
import React from 'react';
import { Routes, Redirect, Route } from 'react-router-dom';

import { AuthProvider, useAuthState } from './context';
import routes from './config/routes';
import LoginForm from './components/LoginForm/LoginForm';
import RenewForm from './components/RegisterStudentForm/RegisterStudentRenew';

import moment from 'moment';

const RenderElement = route => {
    const userDetails = useAuthState();
    if (route.isPrivate && !Boolean(userDetails.email)) {
        return <LoginForm path={route.path} />;
    }
    if (typeof userDetails.joined_at === 'undefined') {
        return <LoginForm path={route.path} />;
    }
    if (
        route.isPrivate &&
        userDetails.function == 'Student' &&
        moment(userDetails.joined_at).isBefore('2024-08-31')
    ) {
        return <RenewForm path={route.path} />;
    }
    return route.element;
};

function JobersRouter() {
    return (
        <Routes>
            {routes.map(route => (
                <Route path={route.path} key={route.path} element={RenderElement(route)} />
            ))}
        </Routes>
    );
}

function App() {
    return (
        <AuthProvider>
            <JobersRouter key='master' />
        </AuthProvider>
    );
}

export default App;
