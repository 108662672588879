import './RegisterForm.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';

export default function RegisterForm() {
    const [student, setStudent] = React.useState('/img/student_nohover.svg');
    const [company, setCompany] = React.useState('/img/company_nohover.svg');
    const [organization, setOrganization] = React.useState('/img/organization_nohover.svg');

    return (
        <div className='container-center-horizontal'>
            <div className='register-panel screen'>
                <Link to='/'>
                    <img
                        className='arrow-left'
                        alt='button-home'
                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3dde03eda8a1cb03a287a/img/arrow-left@2x.svg'
                    />
                </Link>
                <Logo />
                <div className='who-are-you'>Qui êtes vous ?</div>
                <div className='choice-panel'>
                    <Link
                        to='/register-student'
                        onMouseOver={e => setStudent('/img/student_hover.svg')}
                        onMouseOut={e => setStudent('/img/student_nohover.svg')}>
                        <div className='choice-item'>
                            <img src={student} />
                            <div className='student choice-title'>Étudiant</div>
                            {/* <p className='choice-subtitle inter-normal-pale-sky-12px'>
                                Vous souhaitez travailler pour des agences
                            </p> */}
                        </div>
                    </Link>
                    <Link
                        to='/register-company'
                        onMouseOver={e => setCompany('/img/company_hover.svg')}
                        onMouseOut={e => setCompany('/img/company_nohover.svg')}>
                        <div className='choice-item'>
                            <img src={company} />
                            <div className='compagny choice-title'>Entreprise</div>
                            {/* <p className='choice-subtitle inter-normal-pale-sky-12px'>
                                Vous souhaitez effectuer des missions avec des étudiants
                            </p> */}
                        </div>
                    </Link>
                    <Link
                        to='/register-organization'
                        onMouseOver={e => setOrganization('/img/organization_hover.svg')}
                        onMouseOut={e => setOrganization('/img/organization_nohover.svg')}>
                        <div className='choice-item'>
                            <img src={organization} />
                            <div className='asso choice-title'>Association</div>
                            {/* <p className='choice-subtitle inter-normal-pale-sky-12px'>
                                Vous souhaitez étendre le réseau de votre association
                            </p> */}
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}
