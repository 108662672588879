import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Img from '../RenderImg/Render';
import './PaySlip.css';

import DocumentDataService from '../../../../services/document.service';

export default function PaySlip() {
    const { id } = useParams();
    const [initialObjectState, setInitialObjectState] = useState(null);

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                started_at: '13/09/2022',
                company: {
                    name: 'Test Alan',
                    siret: '1098 765 4321',
                    tva: '145789932555',
                    address: 'Place de la mairie',
                    postcode: 72000,
                    city: 'Le mans',
                    contact: 'Juste Leblanc',
                },
                association: {
                    name: 'Architectonic',
                    siret: '1234 567 8910',
                    school: 'Ecole fictive',
                    address: 'Place de la mairie',
                    postcode: 61000,
                    city: 'Alençon',
                    contact: 'Marlène Sasseur',
                },
                student: {
                    name: 'Alan BADIER',
                    address: 'Pas place de la mairie',
                    postcode: 33000,
                    city: 'Bordeaux',
                    ssn: '2 34 93 0000000',
                },
                mission: {
                    start_date: '13/09/2022',
                    end_date: '21/01/2023',
                    name: 'Création de plans',
                    je: 15,
                    price_ht: 250,
                    total_ht: 15 * 250,
                    tva: 15 * 250 * 0.2,
                    total_ttc: 15 * 250 + 15 * 250 * 0.2,
                    student_price: 100,
                },
            };
            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (id) {
                DocumentDataService.get(
                    'SELECT DocumentNote WHERE documenttype_name = "Bon de versement" AND id = ' +
                        id
                ).then(response => {
                    objectData = {
                        ...objectData,
                        ...response.description,
                    };

                    objectData.mission.asso_com = objectData.mission.total_ht * 0.115;
                    objectData.mission.base_ht = 4 * 10.57;

                    let first_part = objectData.mission.total_ht - objectData.mission.asso_com;
                    let second_part =
                        objectData.mission.je * (objectData.mission.base_ht * 0.22696);
                    let third_part = first_part - second_part;

                    objectData.mission.raw_ht = third_part / 1.042;
                    objectData.mission.raw_ht = objectData.mission.raw_ht.toFixed(2);
                    objectData.mission.cotisation_base =
                        objectData.mission.base_ht * objectData.mission.je;

                    // Cotisations
                    objectData.mission.csgd = (objectData.mission.cotisation_base * 0.068).toFixed(
                        2
                    );
                    objectData.mission.csgnd = (objectData.mission.cotisation_base * 0.024).toFixed(
                        2
                    );
                    objectData.mission.crds = (objectData.mission.cotisation_base * 0.005).toFixed(
                        2
                    );

                    // BLANK
                    objectData.mission.am = (objectData.mission.cotisation_base * 0.07).toFixed(2);
                    objectData.mission.csa = (objectData.mission.cotisation_base * 0.003).toFixed(
                        2
                    );
                    objectData.mission.avd = (objectData.mission.cotisation_base * 0.019).toFixed(
                        2
                    );
                    objectData.mission.avp = (objectData.mission.cotisation_base * 0.0855).toFixed(
                        2
                    );
                    objectData.mission.eavd = (objectData.mission.cotisation_base * 0.004).toFixed(
                        2
                    );
                    objectData.mission.eavp = (objectData.mission.cotisation_base * 0.069).toFixed(
                        2
                    );
                    objectData.mission.adt = (objectData.mission.cotisation_base * 0.0122).toFixed(
                        2
                    );
                    objectData.mission.af = (objectData.mission.cotisation_base * 0.0345).toFixed(
                        2
                    );
                    objectData.mission.fnal = (objectData.mission.cotisation_base * 0.001).toFixed(
                        2
                    );
                    objectData.mission.ac = (objectData.mission.raw_ht * 0.0405).toFixed(2);
                    objectData.mission.ags = (objectData.mission.raw_ht * 0.0015).toFixed(2);
                    objectData.mission.cos = (objectData.mission.cotisation_base * 0.00016).toFixed(
                        2
                    );

                    objectData.mission.total_cop = (
                        Number(objectData.mission.am) +
                        Number(objectData.mission.csa) +
                        Number(objectData.mission.avd) +
                        Number(objectData.mission.avp) +
                        Number(objectData.mission.adt) +
                        Number(objectData.mission.af) +
                        Number(objectData.mission.fnal) +
                        Number(objectData.mission.ac) +
                        Number(objectData.mission.ags) +
                        Number(objectData.mission.cos)
                    ).toFixed(2);

                    objectData.mission.total_coe = (
                        Number(objectData.mission.csgd) +
                        Number(objectData.mission.csgnd) +
                        Number(objectData.mission.crds) +
                        Number(objectData.mission.eavd) +
                        Number(objectData.mission.eavp)
                    ).toFixed(2);

                    objectData.mission.total_ret = (
                        Number(objectData.mission.total_cop) +
                        Number(objectData.mission.total_coe) +
                        Number(objectData.mission.asso_com)
                    ).toFixed(2);
                    objectData.mission.rem_net = (
                        Number(objectData.mission.je) * Number(objectData.mission.price_ht) -
                        Number(objectData.mission.total_ret)
                    ).toFixed(2);
                    objectData.mission.net_impo = (
                        Number(objectData.mission.rem_net) +
                        Number(objectData.mission.csgnd) +
                        Number(objectData.mission.crds)
                    ).toFixed(2);

                    objectData.mission.net_pay = objectData.mission.rem_net;

                    setInitialObjectState(objectData);
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    return (
        <div className='container'>
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                {initialObjectState && (
                    <div className='header'>
                        <div className='content-head-info'>
                            <div className='content-logo'>
                                <Img />
                            </div>
                            <div className='content-address'>
                                <div className='content-text-address'>
                                    <p>
                                        <span className='bolder'>Adresse : </span>
                                        {initialObjectState.association.address}
                                    </p>
                                    <p>
                                        <span className='bolder'>CP Ville : </span>
                                        {initialObjectState.association.postcode}{' '}
                                        {initialObjectState.association.city}
                                    </p>
                                    <p>
                                        <span className='bolder'>Téléphone : </span>
                                        {initialObjectState.association.phone}
                                    </p>
                                    <p>
                                        <span className='bolder'>Mail : </span>
                                        {initialObjectState.association.email}
                                    </p>
                                </div>
                            </div>
                            <div className='content-siret'>
                                <div className='content-text-siret'>
                                    <p>
                                        <span className='bolder'>SIRET : </span>
                                        {initialObjectState.association.siret}
                                    </p>
                                    <p>
                                        <span className='bolder'>Code APE : </span>
                                        {initialObjectState.association.ape}
                                    </p>
                                    <p>
                                        <span className='bolder'>URSSAF :</span>
                                        {initialObjectState.association.urssaf}
                                    </p>
                                    <p>
                                        <span className='bolder'>TVA :</span>
                                        {initialObjectState.association.tva}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='content-title'>
                    {initialObjectState && (
                        <p className='bill-title'>BV N° {initialObjectState.name}</p>
                    )}
                </div>
                <div className='content-body'>
                    <div className='margin-body'>
                        <div className='main-date'>
                            {initialObjectState && (
                                <div className='content-date'>
                                    <p>
                                        <span className='bolder'>Période du: </span>
                                        {initialObjectState.mission.start_date} au{' '}
                                        {initialObjectState.mission.end_date}
                                    </p>
                                    <p>
                                        <span className='bolder'>Réalisé par: </span>
                                        {initialObjectState.student.name}
                                    </p>
                                    <p>
                                        <span className='bolder'>Pour le Client: </span>
                                        {initialObjectState.company.name}
                                    </p>
                                    <p>
                                        <span className='bolder'>SIRET du Client: </span>
                                        {initialObjectState.company.siret}
                                    </p>
                                    <p>
                                        <span className='bolder'>N° TVA du Client: </span>{' '}
                                        {initialObjectState.company.tva}
                                    </p>
                                    <p className='bolder'>
                                        Association à vocation pédagogique et économique
                                    </p>
                                </div>
                            )}
                            <div className='content-details'>
                                <div className='detail-title'>COORDONNÉES</div>
                                {initialObjectState && (
                                    <div className='detail-text'>
                                        <div className='detail-text-address bolder'>
                                            <p>{initialObjectState.student.name}</p>
                                            <p>{initialObjectState.student.address}</p>
                                            <p>
                                                {initialObjectState.student.postcode}{' '}
                                                {initialObjectState.student.city}
                                            </p>
                                        </div>
                                        <div className='detail-text-nature bolder'>
                                            <p className='bolder'>
                                                NATURE DE L'EMPLOI ET QUALIFICATION :
                                            </p>
                                            <p>CHARGE D'ETUDE</p>
                                            <p>MODE DE PAIEMENT: Virement</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {initialObjectState && (
                            <div className='dateOfDay'>
                                <p className='bolder'>PARIS, le {initialObjectState.started_at}</p>
                            </div>
                        )}
                        <table>
                            <tbody>
                                <tr>
                                    <th className='bg-color blank-border-bottom' colspan='1'>
                                        Forfait Jour-Etude H.T.
                                    </th>
                                    {initialObjectState && (
                                        <th
                                            className='bg-color bolder th-size-col-2 blank-border-bottom'
                                            colspan='2'>
                                            {initialObjectState.mission.price_ht} €
                                        </th>
                                    )}
                                    <th className='blank-border' colspan='3'></th>
                                </tr>
                                <tr>
                                    <th colspan='1' className='blank-border-bottom'>
                                        N = Nombre de Jour(s)-Etude
                                    </th>
                                    {initialObjectState && (
                                        <th
                                            className='bolder th-size-col-2 blank-border-bottom'
                                            colspan='2'>
                                            {initialObjectState.mission.je}
                                        </th>
                                    )}

                                    <th className='blank-border' colspan='3'></th>
                                </tr>
                                <tr>
                                    <th className='bg-color blank-border-bottom' colspan='1'>
                                        Base de calcul de l'indemnisation H.T.
                                    </th>
                                    {initialObjectState && (
                                        <th
                                            className='bg-color bolder th-size-col-2 blank-border-bottom'
                                            colspan='2'>
                                            {initialObjectState.mission.total_ht}
                                        </th>
                                    )}

                                    <th className='blank-border' colspan='3'></th>
                                </tr>
                                <tr>
                                    <th colspan='1' className='blank-border-bottom'>
                                        Prélèvement de 12,00 %(frais de fonctionnement de
                                        l'association)
                                    </th>
                                    {initialObjectState && (
                                        <th
                                            className='bolder th-size-col-2 blank-border-bottom'
                                            colspan='2'>
                                            {initialObjectState.mission.asso_com}
                                        </th>
                                    )}
                                    <th className='blank-border' colspan='3'></th>
                                </tr>
                                <tr>
                                    <th className='bg-color' colspan='1'>
                                        Base de calcul de l'indemnisation H.T.
                                    </th>
                                    {initialObjectState && (
                                        <th className='bg-color bolder th-size-col-2' colspan='2'>
                                            {initialObjectState.mission.raw_ht}
                                        </th>
                                    )}

                                    <th className='blank-border' colspan='3'></th>
                                </tr>
                                {initialObjectState && (
                                    <tr>
                                        <td colspan='1'>Base de calcul des cotisations</td>
                                        <td className='bolder td-size-col-2' colspan='2'>
                                            {initialObjectState.mission.cotisation_base}
                                        </td>
                                        <td className='bolder td-size-col-3' colspan='3'>
                                            N x {initialObjectState.mission.base_ht} ={' '}
                                            {initialObjectState.mission.cotisation_base}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className='content-table-two'>
                            <table className='content-table-two'>
                                <thead>
                                    <tr className='content-th'>
                                        <th
                                            className='th-size-tab2-col1 bg-color blank-border-bottom'
                                            colSpan='1'>
                                            Désignation
                                        </th>
                                        <th
                                            className='th-size-tab2-col2 bg-color blank-border-bottom'
                                            colSpan='2'>
                                            Base
                                        </th>
                                        <th className='th-size-tab2-col3 bg-color' colSpan='3'>
                                            Cotisations patronales
                                        </th>
                                        <th className='th-size-tab2-col3 bg-color' colSpan='3'>
                                            Cotisations étudiantes
                                        </th>
                                    </tr>
                                    <tr className='content-th'>
                                        <th
                                            className='th-size-tab-col-second-1 bg-color'
                                            colSpan='1'></th>
                                        <th
                                            className='th-size-tab-col-second-2 bg-color'
                                            colSpan='2'></th>
                                        <th
                                            className='th-size-tab-col-second-3 bg-color'
                                            colSpan='1'>
                                            Taux
                                        </th>
                                        <th
                                            className='th-size-tab-col-second-3 bg-color'
                                            colSpan='1'>
                                            Montant
                                        </th>
                                        <th
                                            className='th-size-tab-col-second-3 bg-color'
                                            colSpan='2'>
                                            Taux
                                        </th>
                                        <th
                                            className='th-size-tab-col-second-3 bg-color'
                                            colSpan='1'>
                                            Montant
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='blank-border-bottom' colSpan='1'>
                                            C.S.G. déductible
                                        </td>

                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'></td>
                                        <td className='blank-border-bottom bolder' colSpan='2'></td>
                                        <td className='blank-border-bottom bolder' colSpan='1'>
                                            6,80
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.csgd}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom bg-color' colSpan='1'>
                                            C.S.G. non déductible
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='2'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'>
                                            2,40
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.csgnd}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom' colSpan='1'>
                                            C.R.D.S.
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'></td>
                                        <td className='blank-border-bottom bolder' colSpan='2'></td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='1'>
                                                0,50
                                            </td>
                                        )}
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.crds}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td
                                            className='blank-border-bottom bg-color'
                                            colSpan='1'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='2'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='2'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='2'>
                                            &nbsp;
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom' colSpan='1'>
                                            Assurance maladie
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'>
                                            7,00
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.am}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'></td>
                                        <td className='blank-border-bottom bolder' colSpan='2'></td>
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom bg-color' colSpan='1'>
                                            Contribution solidarité autonome{' '}
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'>
                                            0,30
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.csa}
                                            </td>
                                        )}
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='2'></td>
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom' colSpan='1'>
                                            Assurance vieillesse déplafonnée
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'>
                                            1,90
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.avd}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'>
                                            0,40
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.eavd}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom bg-color' colSpan='1'>
                                            Assurance vieillesse plafonnée{' '}
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'>
                                            8,55
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.avp}
                                            </td>
                                        )}
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'>
                                            6,90
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.eavp}
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom' colSpan='1'>
                                            Accident du travail{' '}
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'>
                                            1,22
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.adt}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'></td>
                                        <td className='blank-border-bottom bolder' colSpan='2'></td>
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom bg-color' colSpan='1'>
                                            Allocations familliales
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'>
                                            3,45
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.af}
                                            </td>
                                        )}
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='2'></td>
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom' colSpan='1'>
                                            F.N.A.L
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'>
                                            0,10
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.fnal}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'></td>
                                        <td className='blank-border-bottom bolder' colSpan='2'></td>
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom bg-color' colSpan='1'>
                                            Assurance chômage
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.raw_ht}
                                            </td>
                                        )}
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'>
                                            4,05
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='blank-border-bottom bolder bg-color'
                                                colSpan='2'>
                                                {initialObjectState.mission.ac}
                                            </td>
                                        )}
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='1'></td>
                                        <td
                                            className='blank-border-bottom bolder bg-color'
                                            colSpan='2'></td>
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom' colSpan='1'>
                                            AGS
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.raw_ht}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'>
                                            0,15
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='2'>
                                                {initialObjectState.mission.ags}
                                            </td>
                                        )}
                                        <td className='blank-border-bottom bolder' colSpan='1'></td>
                                        <td className='blank-border-bottom bolder' colSpan='2'></td>
                                    </tr>
                                    <tr>
                                        <td className='bg-color' colSpan='1'>
                                            Contribution organisations syndicales
                                        </td>
                                        {initialObjectState && (
                                            <td className='bolder bg-color' colSpan='2'>
                                                {initialObjectState.mission.cotisation_base}
                                            </td>
                                        )}
                                        <td className='bolder bg-color' colSpan='1'>
                                            0,016
                                        </td>
                                        {initialObjectState && (
                                            <td className='bolder bg-color' colSpan='2'>
                                                {initialObjectState.mission.cos}
                                            </td>
                                        )}
                                        <td className=' bolder bg-color' colSpan='1'></td>
                                        <td className=' bolder bg-color' colSpan='2'></td>
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom' colSpan='4'>
                                            Total cotisations patronales
                                        </td>
                                        {initialObjectState && (
                                            <td className='blank-border-bottom bolder' colSpan='4'>
                                                {initialObjectState.mission.total_cop} €
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='bg-color' colSpan='4'>
                                            Total cotisations étudiantes
                                        </td>
                                        {initialObjectState && (
                                            <td className='bolder bg-color' colSpan='4'>
                                                {initialObjectState.mission.total_coe} €
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom' colSpan='4'>
                                            Total des retenues
                                        </td>
                                        {initialObjectState && (
                                            <td className='bolder blank-border-bottom' colSpan='4'>
                                                {initialObjectState.mission.total_ret} €
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='blank-border-bottom bg-color' colSpan='4'>
                                            Rémunération nette
                                        </td>
                                        {initialObjectState && (
                                            <td
                                                className='bolder blank-border-bottom bg-color'
                                                colSpan='4'>
                                                {initialObjectState.mission.rem_net} €
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='' colSpan='4'>
                                            Net imposable
                                        </td>
                                        {initialObjectState && (
                                            <td className='' colSpan='4'>
                                                {initialObjectState.mission.net_impo} €
                                            </td>
                                        )}
                                    </tr>
                                    <tr>
                                        <td className='bolder bg-color' colSpan='4'>
                                            Net à payer
                                        </td>
                                        {initialObjectState && (
                                            <td className='bolder bg-color' colSpan='4'>
                                                {initialObjectState.mission.net_pay} €
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
