import http from '../http-common';
import vars from '../config/vars';
const getAll = async (key, fields) => {
    const aFields = [
        'id',
        'mission_id',
        'mission_name',
        'student_id',
        'student_id_friendlyname',
        'status',
    ];
    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'lnkMissionToStudent',
        output_fields: aFields.join(','),
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if (response.data.code == '0' && response.data.objects) {
        Object.keys(response.data.objects).forEach(key => {
            let currentObject = {};
            currentObject.id = response.data.objects[key].key;
            currentObject.mission_id = response.data.objects[key].fields.mission_id;
            currentObject.mission_name = response.data.objects[key].fields.mission_name;
            currentObject.student_id = response.data.objects[key].fields.student_id;
            currentObject.student_id_friendlyname =
                response.data.objects[key].fields.student_id_friendlyname;
            currentObject.status = response.data.objects[key].fields.status;
            objects.push(currentObject);
        });
    }

    return objects;
};
const get = async key => {
    let returnValue = {};

    const aFields = [
        'id',
        'mission_id',
        'mission_name',
        'student_id',
        'status',
        'student_id_friendlyname',
    ];

    // BUILD POST PARAMS
    let data = {
        operation: 'core/get',
        class: 'lnkMissionToStudent',
        output_fields: aFields.join(','),
        key: key,
    };
    let params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if (response.data.code == '0' && response.data.objects) {
        Object.keys(response.data.objects).forEach(async key => {
            returnValue.id = response.data.objects[key].fields.id;
            returnValue.mission_id = response.data.objects[key].fields.mission_id;
            returnValue.mission_name = response.data.objects[key].fields.mission_name;
            returnValue.student_id = response.data.objects[key].fields.student_id;
            returnValue.student_id_friendlyname =
                response.data.objects[key].fields.student_id_friendlyname;
            returnValue.status = response.data.objects[key].fields.status;
        });
    }

    return returnValue;
};
const ApplicationDataService = {
    getAll,
    get,
};
export default ApplicationDataService;
