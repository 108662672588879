import http from '../http-common';
import vars from '../config/vars';
const getAll = async key => {
    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Company',
        output_fields:
            'id, name, domain, status, display_status, siret, location_city, started_at, persons_list, missions_list',
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        Object.keys(response.data.objects).forEach(key => {
            let currentCompany = {};
            currentCompany.missions_list = [];

            Object.keys(response.data.objects[key].fields.missions_list).forEach(subkey => {
                currentCompany.missions_list.push(
                    response.data.objects[key].fields.missions_list[subkey].student_org_id
                );
            });

            Object.keys(response.data.objects[key].fields.persons_list).forEach(subkey => {
                if (
                    response.data.objects[key].fields.persons_list[subkey].function ==
                    'Compte entreprise'
                ) {
                    currentCompany.phone =
                        response.data.objects[key].fields.persons_list[subkey].phone;
                    currentCompany.email =
                        response.data.objects[key].fields.persons_list[subkey].email;
                }
                if (
                    response.data.objects[key].fields.persons_list[subkey].function ==
                    'Représentant légal'
                ) {
                    currentCompany.legal_first_name =
                        response.data.objects[key].fields.persons_list[subkey].first_name;
                    currentCompany.legal_last_name =
                        response.data.objects[key].fields.persons_list[subkey].name;
                    currentCompany.legal_email =
                        response.data.objects[key].fields.persons_list[subkey].email;
                }
                if (
                    response.data.objects[key].fields.persons_list[subkey].function ==
                    'Personne de contact'
                ) {
                    currentCompany.contact_first_name =
                        response.data.objects[key].fields.persons_list[subkey].first_name;
                    currentCompany.contact_last_name =
                        response.data.objects[key].fields.persons_list[subkey].name;
                    currentCompany.contact_email =
                        response.data.objects[key].fields.persons_list[subkey].email;
                }
            });

            currentCompany.id = response.data.objects[key].fields.id;
            currentCompany.domain = response.data.objects[key].fields.domain;
            currentCompany.name = response.data.objects[key].fields.name;
            currentCompany.siret = response.data.objects[key].fields.siret;
            currentCompany.status = response.data.objects[key].fields.status;
            currentCompany.display_status = response.data.objects[key].fields.display_status;
            currentCompany.location_city = response.data.objects[key].fields.location_city;
            currentCompany.started_at = response.data.objects[key].fields.started_at;
            objects.push(currentCompany);
        });
    }

    return objects;
};
const get = async (key, fields) => {
    let returnValue = {};

    let aFields = [
        'id',
        'picture',
        'name',
        'started_at',
        'status',
        'domain',
        'display_status',
        'siret',
        'location_id',
        'location_address',
        'location_city',
        'location_postal_code',
        'location_country',
        'persons_list',
        'missions_list',
    ];

    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Company',
        output_fields: aFields.join(','),
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        Object.keys(response.data.objects).forEach(key => {
            Object.keys(response.data.objects[key].fields.persons_list).forEach(subkey => {
                if (
                    response.data.objects[key].fields.persons_list[subkey].function ==
                    'Compte entreprise'
                ) {
                    returnValue.phone =
                        response.data.objects[key].fields.persons_list[subkey].phone;
                    returnValue.email =
                        response.data.objects[key].fields.persons_list[subkey].email;
                }
                if (
                    response.data.objects[key].fields.persons_list[subkey].function ==
                    'Représentant légal'
                ) {
                    returnValue.legal_first_name =
                        response.data.objects[key].fields.persons_list[subkey].first_name;
                    returnValue.legal_last_name =
                        response.data.objects[key].fields.persons_list[subkey].name;
                    returnValue.legal_email =
                        response.data.objects[key].fields.persons_list[subkey].email;
                }
                if (
                    response.data.objects[key].fields.persons_list[subkey].function ==
                    'Personne de contact'
                ) {
                    returnValue.contact_first_name =
                        response.data.objects[key].fields.persons_list[subkey].first_name;
                    returnValue.contact_last_name =
                        response.data.objects[key].fields.persons_list[subkey].name;
                    returnValue.contact_email =
                        response.data.objects[key].fields.persons_list[subkey].email;
                }
            });

            returnValue.id = response.data.objects[key].key;
            returnValue.picture = response.data.objects[key].fields.picture;
            returnValue.name = response.data.objects[key].fields.name;
            returnValue.status = response.data.objects[key].fields.status;
            returnValue.domain = response.data.objects[key].fields.domain;
            returnValue.display_status = response.data.objects[key].fields.display_status;
            returnValue.siret = response.data.objects[key].fields.siret;
            returnValue.location_id = response.data.objects[key].fields.location_id;
            returnValue.address = response.data.objects[key].fields.location_address;
            returnValue.city = response.data.objects[key].fields.location_city;
            returnValue.postcode = response.data.objects[key].fields.location_postal_code;
            returnValue.country = response.data.objects[key].fields.location_country;
            returnValue.started_at = response.data.objects[key].fields.started_at;
            returnValue.missions_list = response.data.objects[key].fields.missions_list;
            returnValue.persons_list = response.data.objects[key].fields.persons_list;
        });
    }

    return returnValue;
};
const upsert = async (key, fields, output_fields) => {
    let returnValue = false;

    // BUILD POST PARAMS
    let data = {
        operation: 'core/create',
        comment: 'Création par Jobers',
        class: 'Company',
        fields: fields,
        output_fields: output_fields,
    };

    // UPDATE CASE
    if (key) {
        data.operation = 'core/update';
        data.key = key;
    }

    // BUILD PARAMS ARRAY
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // RETURN WRITTEN OBJECT
    Object.keys(response.data.objects).forEach(key => {
        returnValue = response.data.objects[key];
    });

    return returnValue;
};
const CompanyDataService = {
    getAll,
    get,
    upsert,
};
export default CompanyDataService;
