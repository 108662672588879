import './StudentList2.css';
import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import StudentDataService from '../../../../services/student.service';

import Sidebar from '../../Sidebar/Sidebar';

export default function StudentList2() {
    const [data, setData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    useEffect(() => {
        const key = 'SELECT Person WHERE function = "Student"';
        const fields =
            'id, first_name, name, email, phone, function, location_address, location_postal_code, location_city, location_country, started_at, joined_at, nationality, year, school_year, school, ssn, bank, iban, bic, book_url, status, org_name';
        StudentDataService.getAll(key, fields).then(response => {
            setData(response);
        });
    }, [data, filterText]);

    const handleAction = (e, id) => {
        setCurrentIndex(id);
    };

    function StatusComponent(row) {
        let className = 'rdt_StatusCell';
        if (row.display_status == 'Disponible') {
            className = 'rdt_StatusCell rdt_StatusAvailable';
        }
        if (row.display_status == 'En cours de mission') {
            className = 'rdt_StatusCell rdt_StatusOngoing';
        }
        if (row.display_status == 'En attente') {
            className = 'rdt_StatusCell rdt_StatusWaiting';
        }
        return (
            <span className={className} onClick={e => handleAction(e, row.id)}>
                {row.display_status}
            </span>
        );
    }

    const columns = [
        {
            name: 'Prénom',
            selector: row => <span onClick={e => handleAction(e, row.id)}>{row.first_name}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Nom',
            selector: row => <span onClick={e => handleAction(e, row.id)}>{row.name}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Statut',
            selector: row => StatusComponent(row),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Association',
            selector: row => row.org_name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Adresse email',
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {row.email}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Numéro de téléphone',
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {row.phone}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Numéro de sécurité sociale',
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {row.ssn}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
        {
            name: "Date d'inscription",
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {row.started_at}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
    ];

    const convertArrayOfObjectsToCSV = array => {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(filteredItems[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    return (
        <div>
            <div className='flex-container'>
                <div className='flex-child'>
                    <Sidebar />
                </div>

                <div className='flex-child flex-list'>
                    <div className='dashboard-topbar-list'>
                        <div className='dashboard-title-subtitle-container'>
                            <div className='dashboard-title'>Étudiants</div>
                            <p className='dashboard-subtitle'>
                                Cet onglet permet de voir toutes les associations associées à Jobers
                            </p>
                        </div>
                        <div class='dashboard-topbar-element'>
                            <img class='dashboard-icon' src='/img/search.svg' alt='search' />
                            <input
                                id='search'
                                className='dashboard-search-label inter-normal-pale-sky-16px'
                                type='text'
                                placeholder='Rechercher'
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                            />
                        </div>
                        <div class='dashboard-topbar-element dashboard-export-bg'>
                            <img
                                class='dashboard-icon'
                                src='/img/export.svg'
                                alt='download-cloud'
                            />
                            <div
                                class='dashboard-topbar-label'
                                onClick={() => {
                                    downloadCSV(data);
                                }}>
                                Export
                            </div>
                        </div>
                        {/* <div class='dashboard-topbar-element dashboard-filter-bg'>
                            <img class='dashboard-icon' src='/img/filter.svg' alt='Filters lines' />
                            <div class='dashboard-topbar-label dashboard-filter-label'>Filtres</div>
                        </div> */}
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        persistTableHead
                    />
                    {currentIndex > 0 && (
                        <Navigate to={'/student/' + currentIndex} replace={true} />
                    )}
                </div>
            </div>
        </div>
    );
}
