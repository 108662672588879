import React from 'react';
import Association from '../Icon/Association';
import Etudiant from '../Icon/Etudiant';
import Entreprise from '../Icon/Entreprise';
import Mission from '../Icon/Mission';
import Compta from '../Icon/Compta';
import Statistique from '../Icon/Statistique';

export const StudentData = [
    {
        title: 'Espace perso',
        icon: <Etudiant />,
        link: '/etudiant/me/profil',
    },
    {
        title: 'Entreprises',
        icon: <Entreprise />,
        link: '/entreprise/list',
    },
    {
        title: 'Offres de mission',
        icon: <Mission />,
        link: '/offre/list',
    },
    {
        title: 'Gestion',
        icon: <Compta />,
        link: '/etudiant/me/gestion',
    },
    {
        title: 'Statistiques',
        icon: <Statistique />,
        link: '/etudiant/me/statistique',
    },
];
