import '../Mission/MissionView.css';
import '../Organization/OrganizationView.css';
import './CompanyView.css';

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useAuthState } from '../../../../context/context';

import CompanyDataService from '../../../../services/company.service';
import Sidebar from '../../Sidebar/Sidebar';

const CompanyView = props => {
    const [initialObjectState, setInitialObjectState] = useState(null);
    const { id } = useParams();
    const currentUser = useAuthState();

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                name: 'Nom non communiqué',
                siret: 'Pas de numéro de SIRET',
                email: 'Aucune adresse email connue',
                phone: 'Pas de téléphone',
                started_at: '3999-01-01',
                contact: 'Pas de contact connu',
            };
            if (typeof currentUser.id === '0') {
                setInitialObjectState(objectData);
            }
            let key = null;
            if (id !== '0' && id !== 'me') {
                key = id;
            }
            if (currentUser.id !== '0' && props.id == 'me') {
                key =
                    'SELECT Company AS C JOIN Person AS P ON P.org_id = C.id WHERE P.id = ' +
                    currentUser.id;
            }
            if (key) {
                CompanyDataService.get(key).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    objectData.contact = currentUser.userDetails;

                    setInitialObjectState(objectData);
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    function displayStatus(status) {
        let className = 'rdt_StatusCell';
        if (status == 'Disponible') {
            className = 'rdt_StatusCell rdt_StatusAvailable';
        }
        if (status == 'En cours de mission') {
            className = 'rdt_StatusCell rdt_StatusOngoing';
        }
        if (status == 'En attente') {
            className = 'rdt_StatusCell rdt_StatusWaiting';
        }
        return <span className={className}>{status}</span>;
    }

    return (
        <div>
            <div className='flex-container'>
                <div className='flex-child'>
                    <Sidebar />
                </div>
                <div className='flex-child'>
                    <div class='flex-row'>
                        <div class='company-header'>
                            {initialObjectState && (
                                <div className='company-name'>{initialObjectState.name}</div>
                            )}
                            {initialObjectState && displayStatus(initialObjectState.display_status)}
                        </div>
                        {initialObjectState && props.id === 'me' && (
                            <div className='actions'>
                                <div className='button'>
                                    <div className='button-base-3'>
                                        <div className='company-self-edit'>
                                            <Link to='/company/me/edit'>
                                                Modifier mes informations
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div class='horizontal-tabs'>
                        <div class='overlap-group'>
                            <div class='tabs'>
                                <div class='tab-button-base'>
                                    <div class='tab-content'>
                                        <div class='tab-active-label inter-medium-gable-green-14px'>
                                            Informations
                                        </div>
                                    </div>
                                    <div class='bottom-border-active'></div>
                                </div>
                                <div class='tab-button-base'>
                                    <div class='tab-content'>
                                        <div class='tab-inactive-label'>Missions</div>
                                    </div>
                                    <div class='bottom-border-inactive'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='company-header-infos'>
                        <div class='company-info-title inter-medium-mirage-18px'>
                            Informations de l’entreprise
                        </div>
                        <p class='company-info-subtitle inter-normal-pale-sky-14px'>
                            Toutes les informations relatives à l’entreprise
                        </p>
                    </div>
                    <div class='divider'></div>
                    <div class='company-field'>
                        <div class='company-field-label inter-medium-oxford-blue-14px'>
                            Nom de l’entreprise
                        </div>
                        {initialObjectState && (
                            <div class='company-field-value inter-normal-pale-sky-16px'>
                                {initialObjectState.name}
                            </div>
                        )}
                    </div>
                    <div class='divider'></div>
                    <div class='company-field'>
                        <div class='company-field-label inter-medium-oxford-blue-14px'>
                            Numéro de SIRET
                        </div>
                        {initialObjectState && (
                            <div class='company-field-value inter-normal-pale-sky-16px'>
                                {initialObjectState.siret}
                            </div>
                        )}
                    </div>
                    <div class='divider'></div>
                    <div class='company-field'>
                        <div class='company-field-label inter-medium-oxford-blue-14px'>
                            Adresse email
                        </div>
                        {initialObjectState && (
                            <div class='company-field-value inter-normal-pale-sky-16px'>
                                {initialObjectState.email}
                            </div>
                        )}
                    </div>
                    <div class='divider'></div>
                    <div class='company-field'>
                        <div class='company-field-label inter-medium-oxford-blue-14px'>
                            Téléphone
                        </div>
                        {initialObjectState && (
                            <div class='company-field-value inter-normal-pale-sky-16px'>
                                {initialObjectState.phone}
                            </div>
                        )}
                    </div>
                    <div class='divider'></div>
                    <div class='company-field'>
                        <div class='company-field-label inter-medium-oxford-blue-14px'>
                            Date d'inscription
                        </div>
                        {initialObjectState && (
                            <div class='company-field-value inter-normal-pale-sky-16px'>
                                {initialObjectState.started_at}
                            </div>
                        )}
                    </div>
                    <div class='divider'></div>
                    <div class='company-field'>
                        <div class='company-field-label inter-medium-oxford-blue-14px'>
                            Adresse complète
                        </div>
                        {initialObjectState && (
                            <div class='company-frame'>
                                <div class='input-with-label'>
                                    <div class='company-field-label inter-medium-oxford-blue-14px'>
                                        Adresse
                                    </div>
                                    <div class='company-field-value inter-normal-pale-sky-16px'>
                                        {initialObjectState.address}
                                    </div>
                                </div>
                                <div class='frame-30'>
                                    <div class='input-with-label'>
                                        <div class='company-field-label inter-medium-oxford-blue-14px'>
                                            Ville
                                        </div>
                                        <div class='company-field-value inter-normal-pale-sky-16px'>
                                            {initialObjectState.city}
                                        </div>
                                    </div>
                                    <div class='input-with-label-1'>
                                        <div class='company-field-label inter-medium-oxford-blue-14px'>
                                            Code postal
                                        </div>
                                        <div class='company-field-value inter-normal-pale-sky-16px'>
                                            {initialObjectState.postcode}
                                        </div>
                                    </div>
                                </div>
                                <div class='input-with-label'>
                                    <div class='company-field-label inter-medium-oxford-blue-14px'>
                                        Pays
                                    </div>
                                    <div class='company-field-value inter-normal-pale-sky-16px'>
                                        {initialObjectState.country}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div class='divider'></div>
                    <div class='section-header'>
                        <div class='content-2'>
                            <div class='text-and-supporting-text'>
                                <div class='text inter-medium-mirage-18px'>Représentant légal</div>
                                <p class='supporting-text inter-normal-pale-sky-14px'>
                                    Toutes les informations relative au représentant légal
                                </p>
                            </div>
                        </div>
                        <div class='divider'></div>
                    </div>
                    <div class='form'>
                        <div class='content-3'>
                            <div class='text-1 inter-medium-oxford-blue-14px'>
                                Représentant légal
                            </div>
                            <div class='input-with-label'>
                                <div class='label-4 inter-medium-oxford-blue-14px'>Nom</div>
                                {initialObjectState && (
                                    <div class='text-3 inter-normal-pale-sky-16px'>
                                        {initialObjectState.contact_last_name}
                                    </div>
                                )}
                            </div>
                            <div class='input-with-label'>
                                <div class='label-5 inter-medium-oxford-blue-14px'>Prénom</div>
                                {initialObjectState && (
                                    <div class='text-3 inter-normal-pale-sky-16px'>
                                        {initialObjectState.contact_first_name}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div class='divider'></div>
                        <div class='content-3'>
                            <div class='text-1 inter-medium-oxford-blue-14px'>
                                Adresse email référent
                            </div>
                            {initialObjectState && (
                                <div class='text-3 inter-normal-pale-sky-16px'>
                                    {initialObjectState.email}
                                </div>
                            )}
                        </div>
                        <div class='divider'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CompanyView;
