import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Img from '../RenderImg/Render';
import './StudentMission.css';
import './StudentMissionSecond.css';

import DocumentDataService from '../../../../services/document.service';

export default function StudentMission() {
    const { id } = useParams();
    const [initialObjectState, setInitialObjectState] = useState(null);

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                started_at: '13/09/2022',
                company: {
                    name: 'Test Alan',
                    siret: '1098 765 4321',
                    tva: '145789932555',
                    address: 'Place de la mairie',
                    postcode: 72000,
                    city: 'Le mans',
                    contact: 'Juste Leblanc',
                },
                association: {
                    name: 'Architectonic',
                    siret: '1234 567 8910',
                    school: 'Ecole fictive',
                    address: 'Place de la mairie',
                    postcode: 61000,
                    city: 'Alençon',
                    contact: 'Marlène Sasseur',
                },
                student: {
                    name: 'Alan BADIER',
                    address: 'Pas place de la mairie',
                    postcode: 33000,
                    city: 'Bordeaux',
                    ssn: '2 34 93 0000000',
                },
                mission: {
                    start_date: '13/09/2022',
                    end_date: '21/01/2023',
                    name: 'Création de plans',
                    je: 15,
                    price_ht: 250,
                    total_ht: 15 * 250,
                    tva: 15 * 250 * 0.2,
                    total_ttc: 15 * 250 + 15 * 250 * 0.2,
                    student_price: 100,
                },
            };
            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (id) {
                DocumentDataService.get(
                    'SELECT DocumentNote WHERE documenttype_name = "Acceptation de mission" AND id = ' +
                        id
                ).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    setInitialObjectState(objectData.description);
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    return (
        <div className='document-container'>
            {/************** page 1 *****************/}
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                {initialObjectState && (
                    <div className='header'>
                        <div className='content-head-info'>
                            <div className='content-logo'>
                                <Img />
                            </div>
                            <div className='content-address'>
                                <div className='content-text-address'>
                                    <p>
                                        <span className='bolder'>Adresse : </span>
                                        {initialObjectState.association.address}
                                    </p>
                                    <p>
                                        <span className='bolder'>CP Ville : </span>
                                        {initialObjectState.association.postcode}{' '}
                                        {initialObjectState.association.city}
                                    </p>
                                    <p>
                                        <span className='bolder'>Téléphone : </span>
                                        {initialObjectState.association.phone}
                                    </p>
                                    <p>
                                        <span className='bolder'>Mail : </span>
                                        {initialObjectState.association.email}
                                    </p>
                                </div>
                            </div>
                            <div className='content-siret'>
                                <div className='content-text-siret'>
                                    <p>
                                        <span className='bolder'>SIRET : </span>
                                        {initialObjectState.association.siret}
                                    </p>
                                    <p>
                                        <span className='bolder'>Code APE : </span>
                                        {initialObjectState.association.ape}
                                    </p>
                                    <p>
                                        <span className='bolder'>URSSAF :</span>
                                        {initialObjectState.association.urssaf}
                                    </p>
                                    <p>
                                        <span className='bolder'>TVA :</span>
                                        {initialObjectState.association.tva}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='content-title-accept'>
                    <p className='accept-title'>ACCEPTATION DE MISSION - ÉTUDIANT</p>
                    <p className='bolder'>CONVENTION N° 20210920</p>
                </div>
                <div className='content-body'>
                    <div className='margin-body'>
                        <div className='main-date'>
                            <div className='content-date'></div>
                            <div className='content-details-accept'>
                                <div className='detail-title'>COORDONNÉES</div>
                                <div className='detail-text'>
                                    <div className='detail-text-accept bolder'>
                                        {initialObjectState && (
                                            <p>{initialObjectState.student.name}</p>
                                        )}
                                        {initialObjectState && (
                                            <p>{initialObjectState.student.address}</p>
                                        )}
                                        {initialObjectState && (
                                            <p>
                                                {initialObjectState.student.postcode}{' '}
                                                {initialObjectState.student.city}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {initialObjectState && (
                            <div className='dateOfDay-accept'>
                                <p className='bolder'>PARIS, le {initialObjectState.started_at}</p>
                            </div>
                        )}
                        <div className='content-boxes'>
                            <div className='content-box-one'>
                                <div className='title-box-one'>ENTRE: </div>
                                <div className='text-box-one'>
                                    {initialObjectState && (
                                        <p>L'association {initialObjectState.association.name}</p>
                                    )}
                                    <p>Association Loi 1901</p>
                                    {initialObjectState && (
                                        <p>N° SIRET: {initialObjectState.association.siret}</p>
                                    )}
                                    <p>dont le siège social est situé</p>
                                    {initialObjectState && (
                                        <p>{initialObjectState.association.address}</p>
                                    )}
                                    {initialObjectState && (
                                        <p>
                                            {initialObjectState.association.postcode}{' '}
                                            {initialObjectState.association.city}
                                        </p>
                                    )}
                                    {initialObjectState && (
                                        <p>{initialObjectState.association.contact}</p>
                                    )}
                                    {initialObjectState && (
                                        <p>
                                            Ci-après désignée {initialObjectState.association.name}
                                        </p>
                                    )}
                                    <p>ou l'«Association»</p>
                                    <p className='bolder mb-2'>D'une part et</p>
                                    {initialObjectState && (
                                        <p>L'étudiant {initialObjectState.student.name}</p>
                                    )}
                                    {initialObjectState && (
                                        <p>
                                            Etudiant(e) de {initialObjectState.association.school}{' '}
                                        </p>
                                    )}
                                    {initialObjectState && <p>et membre actif de l'association </p>}
                                    {initialObjectState && (
                                        <p>{initialObjectState.association.name}</p>
                                    )}
                                    <p>Demeurant au</p>
                                    {initialObjectState && (
                                        <p>{initialObjectState.student.address}</p>
                                    )}
                                    {initialObjectState && (
                                        <p>
                                            {initialObjectState.student.postcode}{' '}
                                            {initialObjectState.student.city}
                                        </p>
                                    )}
                                    <p>N°sécurité sociale :</p>
                                    {initialObjectState && <p>{initialObjectState.student.ssn}</p>}
                                    <p>ci-après désigné l'«Étudiant(e)»</p>
                                    <p className='bolder mb-2'>D'autre part</p>

                                    <p>Ci-après dénommées individuellement ou</p>
                                    <p>collectivement "la partie" ou "les parties"</p>
                                </div>
                            </div>
                            <div className='content-box-two'>
                                <div className='title-box-two'>DESCRIPTION MISSION:</div>
                                <div className='text-box-two'>
                                    <p className='bolder'>Société Cliente :</p>
                                    {initialObjectState && (
                                        <p className='mb-2 text-school'>
                                            {initialObjectState.company.name}
                                        </p>
                                    )}
                                    <p className='bolder'>Objet de l'étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>
                                            {initialObjectState.student.name}
                                            {' : '}
                                            {initialObjectState.mission.name}
                                        </p>
                                    )}
                                    <p className='bolder'>Période de l'étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>
                                            du {initialObjectState.mission.start_date} au{' '}
                                            {initialObjectState.mission.end_date}
                                        </p>
                                    )}
                                    <p className='bolder'>Prix HT l'étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>
                                            {initialObjectState.mission.total_ht} €
                                        </p>
                                    )}
                                    <p className='bolder'>TVA :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>{initialObjectState.mission.tva} €</p>
                                    )}
                                    <p className='bolder'>Prix TTC de l'étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>
                                            {initialObjectState.mission.total_ttc} €
                                        </p>
                                    )}
                                    <p className='bolder'>Nombre de jours étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>{initialObjectState.mission.je}</p>
                                    )}
                                    <p className='bolder'>Honoraires NETS de l'étudiant :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>
                                            {initialObjectState.mission.student_price} €
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/************** CSS number page 1/3 *****************/}
                        <div className='page-number bolder line-black'>
                            <div className='line-green-one'>
                                <div></div>
                            </div>
                            <div className='line-green-two'>
                                <div></div>
                            </div>
                            <p className='num-page'>1/3</p>
                            <div className='line-black-one'>
                                <div></div>
                            </div>
                            <div className='line-black-two'>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/************** page 2 *****************/}
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                <div className='content-student-body'>
                    <div className='margin-student-body'>
                        <div className='content-box'>
                            <div className='box-title'>ÉTANT PRÉALABLEMENT RAPPELÉ QUE:</div>
                            <div className='box-text'>
                                <div className='content-text-box'>
                                    {initialObjectState && (
                                        <p>
                                            {' '}
                                            A titre préliminaire, il est rappelé que l'Association
                                            {initialObjectState.association.name} est une
                                            association Loi 1901, composée essentiellement
                                            d'étudiants d'école d'architecture.
                                            <br></br>
                                            L'Association {initialObjectState.association.name}{' '}
                                            exerce une activité de junior conseil qui a pour but
                                            exclusif de proposer à des entreprises clientes de faire
                                            réaliser certaines études par les étudiants de l'Ecole
                                            d'Architecture {initialObjectState.association.school}.
                                            <br></br>
                                            L'Association créée par des étudiants a pour but
                                            exclusif de réaliser dans le cadre de l'enseignement
                                            dispensé par l'école, des études à caractère pédagogique
                                            pour le compte de sociétés tierces clientes de
                                            l'association {initialObjectState.association.name}{' '}
                                            moyennant rémunération.
                                            <br></br>
                                            Les Etudiants participant à la réalisation de missions
                                            confiées à l'Association continuent à bénéficier de la
                                            Protection Sociale étudiante.
                                            <br></br>
                                            Ainsi, par le biais de l'association{' '}
                                            {initialObjectState.association.name}, les Etudiants
                                            peuvent compléter la formation théorique dispensée par
                                            l'école par des applications pratiques en entreprises.
                                            <br></br>
                                            L'Etudiant a une inscription active à l'école et est
                                            membre actif à l'association{' '}
                                            {initialObjectState.association.name}.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='text-intro'>
                            <p className='text-title'>IL A ÉTÉ CONVENU ET ARRÊTÉ CE QUI SUIT:</p>
                        </div>
                        <div className='paragraphe-container'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>01</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>
                                    IDENTIFICATION DE LA MISSION D'ÉTUDE CONFIÉE À L'ETUDIANT
                                </p>
                            </div>
                            <div className='paragraphe-text paragraphe-first-text'>
                                {initialObjectState && (
                                    <p>
                                        L'Association {initialObjectState.association.name} confie à
                                        l'Etudiant qui l'accepte la réalisation de la mission
                                        d'étude suivante pour la société précisée dans le Descriptif
                                        de mission, ci après « le Client ». L'objet de l'étude
                                        confiée par le Client à l'Association, ci-après « l'Etude »,
                                        est précisé dans le Descriptif de mission. L'Association{' '}
                                        {initialObjectState.association.name} confie la réalisation
                                        de l'Etude à l'Etudiant qui l'accepte. L'Etudiant sera le
                                        seul étudiant chargé de la réalisation de cette Etude.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container '>
                            <div className='paragraphe-number'>
                                <span className='green-number'>02</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>DURÉE DE L'ÉTUDE CONFIÉE À L'ÉTUDIANT</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    L'Etude doit être réalisée au cours de la période précisée dans
                                    le Descriptif de mission. L'Etudiant reconnait être disponible
                                    pour pouvoir procéder à la réalisation de l'Etude confiée au
                                    cours de cette période
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container mt--40'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>03</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>RAPPORT DE MISSION</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        L'Etudiant s'engage à remettre à l'Association{' '}
                                        {initialObjectState.association.name} un rapport de mission,
                                        dans le délai de 30 jours de la fin de l'Etude. En l'absence
                                        de ce rapport de mission, l'Etudiant ne pourra prétendre à
                                        aucune rémunération, ni contrepartie
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container mt--45'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>04</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>HONORAIRES</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    {' '}
                                    Sous réserve de la réalisation de l'intégralité de l'Etude et
                                    dans les délais convenus, il sera versé à l'Etudiant une
                                    rémunération sous forme d'honoraires à hauteur du montant NET
                                    précisé dans le Descriptif de mission soit le nombre de jours
                                    étude précisé dans le Descriptif de mission au coût unitaire HT
                                    précisé dans le Descriptif de mission. Les honoraires dûs ne
                                    seront versés à l'Etudiant que lorsque le Client aura réglé
                                    l'intégralité du prix TTC de l'Etude à l'Association. Aucune
                                    rémunération ne sera en tout état de cause versée à l'Etudiant
                                    tant que ce dernier n'aura pas remis son rapport d'Etude à
                                    l'Association.
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container mt-40'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>05</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>ENGAGEMENT DE L'ÉTUDIANT</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        {' '}
                                        L'Etudiant s'engage à mener à bien l'Etude qui lui est
                                        confiée. L'Etudiant, du fait de son adhésion à l'association{' '}
                                        {initialObjectState.association.name}, a connaissance des
                                        modalités de bonne exécution de sa mission définie dans la
                                        Convention d'Etude conclue entre l'Association et le Client.
                                        Une copie de la Convention signée entre l'Association et le
                                        Client pour la réalisation de l'Etude confiée à l'Etudiant
                                        est annexée à la présente Convention. L'Etudiant s'engage à
                                        se conformer au contenu de cette Convention, qui fait partie
                                        intégrante des présentes.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>06</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>ENGAGEMENTS DIVERS</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    {' '}
                                    L'Etudiant s'engage à mener à bien la réalisation de l'Etude
                                    avec une obligation de moyen et s'engage à ne pas apporter
                                    unilatéralement de modification à la mission convenue.
                                    L'Etudiant s'engage à faire part à l'Association de toutes
                                    difficultés rencontrées dans le cadre de l'exécution de sa
                                    mission, de toute indisponibilité. L'Etudiant s'engage à agir au
                                    mieux des intérêts du Client. L'Etudiant s'engage à remettre à
                                    l'issue de l'Etude les documents et modèles qui lui auraient été
                                    remis par le Client. L'Etudiant s'engage à se rendre chez le
                                    Client en adoptant une bonne présentation, à respecter les
                                    dispositions du règlement intérieur et du règlement d'hygiène et
                                    de sécurité du Client, et s'abstenir de perturber l'activité
                                    normale du personnel du Client ou l'exploitation de celui-ci.
                                    L'Etudiant s'engage à garder strictement confidentielles et à ne
                                    pas divulguer ou communiquer à des tiers toutes informations ou
                                    tous documents reçus du Client dans le cadre de l'Etude définie.
                                    Par tiers, on entendra toute personne autre que les parties
                                    soussignées et le Client ou ses préposés. L'Etudiant s'engage à
                                    n'utiliser les informations reçues du Client que pour les
                                    besoins et dans le cadre de l'objet de l'Etude. L'Etudiant
                                    s'engage à garder strictement confidentiels et à ne pas
                                    divulguer ou à communiquer à des tiers les résultats de l'Etude.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/************** CSS number page 2/3 *****************/}
                    <div className='page-number-position-2 bolder line-black'>
                        <div className='line-green-one'>
                            <div></div>
                        </div>
                        <div className='line-green-two'>
                            <div></div>
                        </div>
                        <p className='num-page'>2/3</p>
                        <div className='line-black-one'>
                            <div></div>
                        </div>
                        <div className='line-black-two'>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            {/************** page 3 *****************/}
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                <div className='content-student-body'>
                    <div className='margin-student-body'>
                        <div className='paragraphe-container mt-55'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>06</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>ENGAGEMENTS DIVERS - SUITE</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    Ne seront pas considérées comme confidentielles les informations
                                    pour lesquelles il pourra être prouvé : - Qu'elles sont entrées
                                    dans le domaine public préalablement à leur divulgation ou après
                                    celle-ci, mais dans ce cas, en l'absence de toute faute de
                                    l'Etudiant, - Qu'elles ont été reçues de tiers de manière
                                    licite, sans restriction ni violation du présent accord, -
                                    Qu'elles ont été publiées sans violer les dispositions du
                                    présent accord, - Que leur utilisation ou divulgation ont été
                                    autorisées par écrit, par la Partie dont elles émanent.
                                    L'obligation de confidentialité sera maintenue pendant toute la
                                    durée du présent contrat, et 2 ans, à compter de l'extinction du
                                    présent contrat quel qu'en soit la cause. L'Etudiant reconnait
                                    que la propriété de l'Etude réalisée deviendra la propriété
                                    exclusive du Client dès que ce dernier en aura réglé le prix à
                                    l'Association. A ce titre, l'Etudiant reconnait que le Client
                                    sera le titulaire exclusif des droits de diffusion, de
                                    représentation, d'adaptation, de reproduction et de
                                    commercialisation des créations protégeables par le droit
                                    d'auteur, des documentations et plans produits dans le cadre de
                                    l'Etude, pour la durée de ses droits, et sans limitation
                                    d'étendue ni de destination, telles que cartographies et
                                    documents de présentation. L'Etudiant déclare en conséquence
                                    expressément renoncer à tous les droits cités ci-dessus, quelle
                                    qu'en soit la nature, issus de l'exécution de l'Etude et ce en
                                    contrepartie du paiement de la rémunération prévue à l'Article
                                    4. L'Etudiant déclare avoir connaissance que ce contrat encadre
                                    une mission d'étude et reconnait par conséquent l'inégalité aux
                                    mesures d'accompagnem
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container mt-200'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>07</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>RESPONSABILITÉ</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    Conformément aux principes régissant les associations,
                                    l'Etudiant assume le risque financier de sa participation, et
                                    renonce à cet égard à tout recours contre l'Association, à
                                    raison de toutes conséquences dommageables, notamment
                                    financières pouvant se révéler ultérieurement concernant la
                                    conclusion, l'exécution ou l'extinction de la présente
                                    Convention. L'Etude confiée est exclusive d'un travail réalisé à
                                    titre indépendant conformément aux principes régissant
                                    l'Association.
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container '>
                            <div className='paragraphe-number'>
                                <span className='green-number'>08</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>LITIGES ET DROIT APPLICABLE</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    {' '}
                                    Si un différend survenait à l'occasion de l'existence, de
                                    l'interprétation ou de l'exécution de la présente Convention,
                                    les Parties s'efforceraient de le régler à l'amiable,
                                    préalablement à toute action en justice. En cas de litige
                                    persistant, le litige sera porté devant les Tribunaux de Paris,
                                    seuls compétents. Il sera fait application du droit français
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>05</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>ENGAGEMENT DE L'ÉTUDIANT</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        {' '}
                                        L'Etudiant s'engage à mener à bien l'Etude qui lui est
                                        confiée. L'Etudiant, du fait de son adhésion à l'association
                                        {initialObjectState.association.name}, a connaissance des
                                        modalités de bonne exécution de sa mission définie dans la
                                        Convention d'Etude conclue entre l'Association et le Client.
                                        Une copie de la Convention signée entre l'Association et le
                                        Client pour la réalisation de l'Etude confiée à l'Etudiant
                                        est annexée à la présente Convention. L'Etudiant s'engage à
                                        se conformer au contenu de cette Convention, qui fait partie
                                        intégrante des présentes.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>09</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>NON-RENONCIATION</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    {' '}
                                    Aucune renonciation totale ou partielle par une des Parties à se
                                    prévaloir de tout ou partie des engagements de l'autre Partie au
                                    titre des présentes, ne pourra être interprétée comme une
                                    renonciation de sa part à s'en prévaloir, à moins qu'elle n'est
                                    été effectuée par écrit et auquel cas cette renonciation devra
                                    être interprétée restrictivement.
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>10</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>NULLITÉ D'UNE CLAUSE</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    Dans le cas où l'une quelconque disposition des présentes serait
                                    déclarée nulle ou inapplicable par toute autorité judiciaire ou
                                    extrajudiciaire, ou par une sentence arbitrale, les Parties
                                    s'efforceraient de la remplacer par une disposition valide, la
                                    plus proche possible de la disposition d'origine, et les autres
                                    dispositions n'en subsisteraient pas moins et conserveraient
                                    leur pleine validité entre les Parties, à moins que, en
                                    l'absence de ladite disposition, la présente Convention devenait
                                    sans objet.
                                </p>
                            </div>
                        </div>
                        <div className='container-sign mt-500'>
                            {initialObjectState && (
                                <div className='date-sign'>
                                    <p>
                                        Fait en 2 exemplaires à PARIS le{' '}
                                        {initialObjectState.started_at} .
                                    </p>
                                </div>
                            )}
                            <div className='main-box-sign'>
                                <div className='content-sign-society'>
                                    {initialObjectState && (
                                        <div className='society-title'>
                                            SIGNATURE DE L'ETUDIANT{' '}
                                            {initialObjectState.student.name}
                                        </div>
                                    )}
                                    {initialObjectState && (
                                        <div className='society-subtitle'>
                                            <p>PRÉCÉDÉE DE LA MENTION «LU ET APPROUVÉ»</p>
                                        </div>
                                    )}
                                </div>
                                <div className='content-sign-society'>
                                    {initialObjectState && (
                                        <div className='association-title'>
                                            SIGNATURE {initialObjectState.association.name}
                                        </div>
                                    )}
                                    {initialObjectState && (
                                        <div className='association-subtitle'>
                                            <p>
                                                REPRÉSENTÉE PAR SON PRÉSIDENT{' '}
                                                {initialObjectState.association.contact}
                                            </p>
                                            <p>PRÉCÉDÉE DE LA MENTION «LU ET APPROUVÉ»</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/************** CSS number page 3/3 *****************/}
                    <div className='page-number-position-3 bolder line-black'>
                        <div class='line-green-one'>
                            <div></div>
                        </div>
                        <div class='line-green-two'>
                            <div></div>
                        </div>
                        <p className='num-page'>3/3</p>
                        <div class='line-black-one'>
                            <div></div>
                        </div>
                        <div class='line-black-two'>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
