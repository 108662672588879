import React from 'react';
import { useAuthState } from '../../../context';
import './Sidebar.css';

const User = () => {
    const currentUser = useAuthState();

    // if currentUser.userDetails begins with "Compte ", remove it
    if (currentUser.userDetails.startsWith('Compte ')) {
        currentUser.userDetails = currentUser.userDetails.slice(7);
    }

    return (
        <div>
            <div className='userDetails'> {currentUser.userDetails} </div>
        </div>
    );
};

export default User;
