import React from 'react';
import { Link } from 'react-router-dom';

import Sidebar from '../Backend/Sidebar/Sidebar';

export default function EtudiantStatistique(props) {
    return (
        <div className='backend-container'>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>Statistiques</h1>
                </div>
            </div>
            {/* <div className='list-flex-container'>
                <div className='list-flex-child flex-4 list-container-tabs'>
                    <Link to='#' className='profil-tabs'>
                        Onglet 1
                    </Link>
                    <Link to='#' className='profil-tabs'>
                        Onglet 2
                    </Link>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-container-divider-1' />
            </div> */}
            <div className='list-flex-container'>
                <div className='list-flex-child'>
                    <p className='list-container-subtitle list-tab-subtitle'>
                        Coming soon comme disent les anglais.
                    </p>
                </div>
            </div>
        </div>
    );
}
