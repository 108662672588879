import React, { Component } from 'react';
import './IconSize.css';

class BullVer extends Component {
    render() {
        return (
            <div className='icon-size'>
                <img className='icon-img' alt='go-back' src='/img/07_BULL VERS.svg' />
            </div>
        );
    }
}

export default BullVer;
