import './LoginForm.css';
import React, { useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import Logo from '../Logo/Logo';
import Form from '../Utils/Form';
import Input from '../Utils/Input';

import { loginUser, useAuthDispatch } from '../../context';

export default function LoginForm() {
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });
    const { message } = useParams();
    const location = useLocation();
    const dispatch = useAuthDispatch();
    const onSubmit = (fields, errors) => {
        handleLogin(fields, errors);
    };

    const handleLogin = async (fields, errors) => {
        try {
            let payload = {
                operation: 'core/check_credentials',
                user: fields.email.value,
                password: fields.password.value,
            };
            let response = await loginUser(dispatch, payload);
            if (response.authorized === true && location.pathname == '/login') {
                window.location.href = 'https://app.jobers.fr/';
            }
            if (response.authorized === true && location.pathname == '/login/renewed') {
                window.location.href = 'https://app.jobers.fr/';
            }
            if (
                response.authorized === true &&
                location.pathname !== '/login' &&
                location.pathname !== '/login/renewed'
            ) {
                window.location.reload(false);
            }
            if (response.authorized !== true) {
                errors.password = 'Invalid Credentials';
            }
        } catch (error) {
            console.log(error);
        }
        setFormData({
            fields,
            errors,
        });
    };

    const LoginForm = () => {
        return (
            <div className='login-page screen'>
                <div className='login-panel'>
                    <div className='login-header'>
                        <Logo />
                        <div className='login-form-header'>
                            <div className='login-title'>Connexion à votre compte</div>
                            <p className='subtitle inter-normal-pale-sky-16px'>
                                Bienvenue, veuillez s’il vous plaît rentrer vos informations de
                                connexion
                            </p>
                            {message && message == 'renewed' && (
                                <p className='subtitle inter-medium-gable-green-14px'>
                                    Votre cotisation a bien été renouvelée. Vous pouvez maintenant
                                    vous connecter.
                                </p>
                            )}
                        </div>
                    </div>
                    <Form
                        className='form'
                        onSubmit={onSubmit}
                        fields={formData.fields}
                        errors={formData.errors}
                        validateOnSubmit={true}>
                        <Input
                            id='email'
                            label='Adresse email'
                            placeholder='mail@mail.com'
                            validate='required|email'
                            value={formData.fields.email ? formData.fields.email.value : ''}
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    formData.errors.password && formData.errors.password != ''
                                        ? 'input-error input-box inter-normal-pale-sky-16px'
                                        : 'input-box inter-normal-pale-sky-16px',
                            }}
                        />
                        <Input
                            id='password'
                            label='Mot de passe'
                            placeholder='•••••••••'
                            validate='required'
                            type='password'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    formData.errors.password && formData.errors.password != ''
                                        ? 'input-error input-box inter-normal-pale-sky-16px'
                                        : 'input-box inter-normal-pale-sky-16px',
                            }}
                        />
                        {formData.errors.password &&
                            formData.errors.password == 'Invalid Credentials' && (
                                <p class='error-text'>Identifiants invalides.</p>
                            )}
                        <div className='row'>
                            <div className='checkbox'>
                                <div className='checkbox-1'>
                                    <input
                                        type='checkbox'
                                        className='checkbox-base border-1px-mischka'
                                        id='remember_me'
                                    />
                                </div>
                                <label
                                    className='login-remember-me inter-medium-oxford-blue-14px'
                                    htmlFor='remember_me'>
                                    Se rappeler de moi
                                </label>
                            </div>
                            <div className='login-forgot-button'>
                                <div className='button-base'>
                                    <Link className='login-forgot-link' to='/forgot-password'>
                                        Mot de passe oublié
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <button type='submit' className='primary-submit'>
                            Se connecter
                        </button>
                    </Form>
                    <div className='login-register-panel'>
                        <p className='login-no-account'>Pas encore de compte ?</p>
                        <div className='login-register-button'>
                            <div className='login-register-button-base'>
                                <Link className='login-register-link' to='/register'>
                                    S'inscrire
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='container-center-horizontal'>
            <LoginForm />
        </div>
    );
}
