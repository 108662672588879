import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Img from '../RenderImg/Render';
import './StudyAgreement.css';

import DocumentDataService from '../../../../services/document.service';

export default function StudyAgreement() {
    const { id } = useParams();
    const [initialObjectState, setInitialObjectState] = useState(null);

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                started_at: '13/09/2022',
                company: {
                    name: 'Test Alan',
                    siret: '1098 765 4321',
                    tva: '145789932555',
                    address: 'Place de la mairie',
                    postcode: 72000,
                    city: 'Le mans',
                    contact: 'Juste Leblanc',
                },
                association: {
                    name: 'Architectonic',
                    siret: '1234 567 8910',
                    address: 'Place de la mairie',
                    postcode: 61000,
                    city: 'Alençon',
                    contact: 'Marlène Sasseur',
                },
                student: {
                    name: 'Alan BADIER',
                    address: 'Pas place de la mairie',
                    postcode: 33000,
                    city: 'Bordeaux',
                    ssn: '2 34 93 0000000',
                },
                mission: {
                    start_date: '13/09/2022',
                    end_date: '21/01/2023',
                    name: 'Création de plans',
                    je: 15,
                    price_ht: 250,
                    total_ht: 15 * 250,
                    tva: 15 * 250 * 0.2,
                    total_ttc: 15 * 250 + 15 * 250 * 0.2,
                    student_price: 100,
                },
            };
            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (id) {
                DocumentDataService.get(
                    'SELECT DocumentNote WHERE documenttype_name = "Convention d\'études" AND id = ' +
                        id
                ).then(response => {
                    objectData = {
                        ...objectData,
                        ...response.description,
                    };

                    let numberOfMonths =
                        getMonthDifference(
                            parseDate(objectData.mission.start_date),
                            parseDate(objectData.mission.end_date)
                        ) + 1;

                    let endingDates = dateRange(
                        parseDate(objectData.mission.start_date),
                        parseDate(objectData.mission.end_date)
                    );

                    let numberOfInvoices = ~~(objectData.mission.je / numberOfMonths);
                    let lastInvoice = objectData.mission.je % numberOfMonths;
                    let arrayOfInvoices = [];
                    let currentInvoice = {};

                    for (let i = 1; i <= numberOfMonths; i++) {
                        let je_invoice =
                            i == numberOfMonths
                                ? Number(numberOfInvoices + lastInvoice)
                                : numberOfInvoices;

                        let je_ht = Number(objectData.mission.price_ht) * Number(je_invoice);
                        let je_tva = je_ht * 0.2;
                        let je_ttc = Number(je_ht) + Number(je_tva);
                        let pay_date = endingDates[i - 1].split(' ');

                        currentInvoice = {
                            name: 'Echéance ' + (i + 9).toString(36).toUpperCase(),
                            je: je_invoice,
                            je_ht: je_ht,
                            je_tva: je_tva,
                            je_ttc: je_ttc,
                            pay_date: pay_date[0],
                        };

                        arrayOfInvoices.push(currentInvoice);
                    }

                    const invoices = [];

                    arrayOfInvoices.forEach(data => {
                        invoices.push(
                            <tr className='underline-price'>
                                <td className='blank-border-left-right' colSpan='1'>
                                    {data.name}
                                </td>
                                <td className='blank-border-left-right' colSpan='1'>
                                    {data.je}
                                </td>
                                <td className='blank-border-left-right' colSpan='1'>
                                    {data.je_ht} €
                                </td>
                                <td className='blank-border-left-right' colSpan='1'>
                                    20,00 %
                                </td>
                                <td className='blank-border-left-right' colSpan='1'>
                                    {data.je_tva} €
                                </td>
                                <td className='blank-border-left-right' colSpan='1'>
                                    {data.je_ttc} €
                                </td>
                                <td className='blank-border-left-right' colSpan='1'>
                                    {data.pay_date}
                                </td>
                            </tr>
                        );
                    });

                    objectData.mission.invoices = invoices;

                    setInitialObjectState(objectData);
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    function dateRange(startDate, endDate) {
        startDate = startDate.toISOString().split('T')[0];
        endDate = endDate.toISOString().split('T')[0];

        var start = startDate.split('-');
        var end = endDate.split('-');
        var startYear = parseInt(start[0]);
        var endYear = parseInt(end[0]);
        var dates = [];

        for (var i = startYear; i <= endYear; i++) {
            var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
            var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
            for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                var month = j + 1;
                var displayMonth = month < 10 ? '0' + month : month;
                var d = new Date(i, displayMonth, 0);

                dates.push(d.toLocaleString('fr'));
            }
        }
        return dates;
    }

    function parseDate(date) {
        var parts = date.match(/(\d+)/g);

        return new Date(parts[2], parts[1] - 1, parts[0]);
    }

    function getMonthDifference(startDate, endDate) {
        var months = '';

        months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
        months -= startDate.getMonth();
        months += endDate.getMonth();

        return months <= 0 ? 0 : months;
    }

    return (
        <div className='container-study'>
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                {initialObjectState && (
                    <div className='header'>
                        <div className='content-head-info'>
                            <div className='content-logo'>
                                <Img />
                            </div>
                            <div className='content-address'>
                                <div className='content-text-address'>
                                    <p>
                                        <span className='bolder'>Adresse : </span>
                                        {initialObjectState.association.address}
                                    </p>
                                    <p>
                                        <span className='bolder'>CP Ville : </span>
                                        {initialObjectState.association.postcode}{' '}
                                        {initialObjectState.association.city}
                                    </p>
                                    <p>
                                        <span className='bolder'>Téléphone : </span>
                                        {initialObjectState.association.phone}
                                    </p>
                                    <p>
                                        <span className='bolder'>Mail : </span>
                                        {initialObjectState.association.email}
                                    </p>
                                </div>
                            </div>
                            <div className='content-siret'>
                                <div className='content-text-siret'>
                                    <p>
                                        <span className='bolder'>SIRET : </span>
                                        {initialObjectState.association.siret}
                                    </p>
                                    <p>
                                        <span className='bolder'>Code APE : </span>
                                        {initialObjectState.association.ape}
                                    </p>
                                    <p>
                                        <span className='bolder'>URSSAF :</span>
                                        {initialObjectState.association.urssaf}
                                    </p>
                                    <p>
                                        <span className='bolder'>TVA :</span>
                                        {initialObjectState.association.tva}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className='content-title-accept'>
                    <p className='accept-title'>CONVENTION D'ÉTUDE - ENTREPRISE</p>
                    <p className='bolder'>CONVENTION N° {id}</p>
                </div>
                <div className='content-body'>
                    <div className='margin-body'>
                        <div className='main-date'>
                            <div className='content-date'></div>
                            <div className='content-details-accept'>
                                <div className='detail-title'>COORDONNÉES</div>
                                <div className='detail-text'>
                                    <div className='detail-text-accept bolder'>
                                        {initialObjectState && (
                                            <p>{initialObjectState.company.name}</p>
                                        )}
                                        {initialObjectState && (
                                            <p>{initialObjectState.company.address}</p>
                                        )}
                                        {initialObjectState && (
                                            <p>
                                                {initialObjectState.company.postcode}{' '}
                                                {initialObjectState.company.city}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {initialObjectState && (
                            <div className='dateOfDay-accept'>
                                <p className='bolder'>PARIS, le {initialObjectState.started_at}</p>
                            </div>
                        )}

                        <div className='content-boxes'>
                            <div className='content-box-one'>
                                <div className='title-box-one'>ENTRE: </div>
                                <div className='text-box-one'>
                                    {initialObjectState && (
                                        <p>L'association {initialObjectState.association.name}</p>
                                    )}
                                    <p>Association Loi 1901</p>
                                    {initialObjectState && (
                                        <p>N° SIRET: {initialObjectState.association.siret}</p>
                                    )}
                                    <p>dont le siège social est situé</p>
                                    {initialObjectState && (
                                        <p>{initialObjectState.association.address}</p>
                                    )}
                                    {initialObjectState && (
                                        <p>
                                            {initialObjectState.association.postcode}{' '}
                                            {initialObjectState.association.city}
                                        </p>
                                    )}
                                    <p>représentée par son Président </p>
                                    {initialObjectState && (
                                        <p>{initialObjectState.association.contact}</p>
                                    )}
                                    {initialObjectState && (
                                        <p>
                                            Ci-après désignée {initialObjectState.association.name}
                                        </p>
                                    )}
                                    <p>ou l'«Association»</p>
                                    <p className='bolder mb-2'>D'une part et</p>
                                    {initialObjectState && (
                                        <p>La société {initialObjectState.company.name}</p>
                                    )}
                                    {initialObjectState && (
                                        <p>N° SIRET: {initialObjectState.company.siret}</p>
                                    )}
                                    {initialObjectState && (
                                        <p>
                                            de l'association {initialObjectState.association.name}
                                        </p>
                                    )}
                                    <p>dont le siège social est situé au</p>
                                    {initialObjectState && (
                                        <p>{initialObjectState.company.address}</p>
                                    )}
                                    {initialObjectState && (
                                        <p>
                                            {initialObjectState.company.postcode}{' '}
                                            {initialObjectState.company.city}
                                        </p>
                                    )}
                                    {initialObjectState && (
                                        <p>représentée par {initialObjectState.company.contact}</p>
                                    )}
                                    <p>ayant tous les pouvoirs aux effets</p>
                                    <p>des présentes, ci-après désigné le "Client"</p>
                                    <p className='bolder mb-2'>D'autre part</p>

                                    <p>Ci-après dénommées individuellement ou</p>
                                    <p>collectivement "la partie" ou "les parties"</p>
                                </div>
                            </div>
                            <div className='content-box-two'>
                                <div className='title-box-two'>DESCRIPTION MISSION:</div>
                                <div className='text-box-two'>
                                    <p className='bolder'>Société Cliente :</p>
                                    {initialObjectState && (
                                        <p className='mb-2 text-school'>
                                            {initialObjectState.company.name}
                                        </p>
                                    )}
                                    <p className='bolder'>Objet de l'étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>{initialObjectState.mission.name}</p>
                                    )}
                                    <p className='bolder'>Période de l'étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>
                                            du {initialObjectState.mission.start_date} au{' '}
                                            {initialObjectState.mission.end_date}
                                        </p>
                                    )}
                                    <p className='bolder'>Prix HT l'étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>
                                            {initialObjectState.mission.total_ht} €
                                        </p>
                                    )}
                                    <p className='bolder'>TVA :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>{initialObjectState.mission.tva} €</p>
                                    )}
                                    <p className='bolder'>Prix TTC de l'étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>
                                            {initialObjectState.mission.total_ttc} €
                                        </p>
                                    )}
                                    <p className='bolder'>Nombre de jours étude :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>{initialObjectState.mission.je}</p>
                                    )}
                                    <p className='bolder'>Honoraires NETS de l'étudiant :</p>
                                    {initialObjectState && (
                                        <p className='mb-2'>
                                            {initialObjectState.mission.student_price} €
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        {/************** CSS number page 1/5 *****************/}
                        <div className='page-number bolder line-black'>
                            <div className='line-green-one'>
                                <div></div>
                            </div>
                            <div className='line-green-two'>
                                <div></div>
                            </div>
                            <p className='num-page'>1/5</p>
                            <div className='line-black-one'>
                                <div></div>
                            </div>
                            <div className='line-black-two'>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='pagebreak'> </div>
            {/************** page 2 *****************/}
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                <div className='content-student-body'>
                    <div className='margin-student-body'>
                        <div className='content-box'>
                            <div className='box-title'>ÉTANT PRÉALABLEMENT RAPPELÉ QUE:</div>
                            <div className='box-text'>
                                <div className='content-text-box'>
                                    {initialObjectState && (
                                        <p>
                                            {' '}
                                            A titre préliminaire, il est rappelé que l'Association
                                            {initialObjectState.association.name} est une
                                            association Loi 1901, composée essentiellement
                                            d'étudiants d'école d'architecture.
                                            <br></br>
                                            L'Association {initialObjectState.association.name}{' '}
                                            exerce une activité de junior conseil qui a pour but
                                            exclusif de proposer à des entreprises clientes de faire
                                            réaliser certaines études par les étudiants de l'Ecole
                                            d'Architecture {initialObjectState.association.school}.
                                            <br></br>
                                            L'Association créée par des étudiants a pour but
                                            exclusif de réaliser dans le cadre de l'enseignement
                                            dispensé par l'école, des études à caractère pédagogique
                                            pour le compte de sociétés tierces clientes de
                                            l'association {initialObjectState.association.name}{' '}
                                            moyennant rémunération.
                                            <br></br>
                                            Les Etudiants participant à la réalisation de missions
                                            confiées à l'Association continuent à bénéficier de la
                                            Protection Sociale étudiante.
                                            <br></br>
                                            Ainsi, par le biais de l'association{' '}
                                            {initialObjectState.association.name}, les Etudiants
                                            peuvent compléter la formation théorique dispensée par
                                            l'école par des applications pratiques en entreprises.
                                            <br></br>
                                            L'Etudiant a une inscription active à l'école et est
                                            membre actif à l'association{' '}
                                            {initialObjectState.association.name}.
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='text-intro'>
                            <p className='bolder'>IL A ÉTÉ CONVENU ET ARRÊTÉ CE QUI SUIT:</p>
                        </div>
                        <div className='paragraphe-container'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>01</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>OBJET DE L'ÉTUDE</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        Le Client confie à l'Association{' '}
                                        {initialObjectState.association.name} la réalisation d'une
                                        étude dont l'objet est précisé dans le Description de
                                        mission, ci-après l'Etude.Le Client ne pourra pas modifier
                                        l'objet de l'Etude tant dans son étendue que dans ses
                                        modalités de réalisation. Toute modification ne pourra
                                        intervenir que d'un commun accord et par la signature d'un
                                        avenant à la présente Convention
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container mt--35'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>02</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>DURÉE DE L'ÉTUDE CONFIÉE À L'ÉTUDIANT</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    L'Etude doit être réalisée au cours de la période précisée dans
                                    le Descriptif de mission.L'Etudiant reconnait être disponible
                                    pour pouvoir procéder à la réalisation de l'Etude confiée au
                                    cours de cette période.
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container mt--60'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>03</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>PRIX DE L'ÉTUDE</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        D'un commun accord entre les Parties, l'Association{' '}
                                        {initialObjectState.association.name}
                                        facturera le prix de l'Etude réalisée à un prix total HT
                                        précisé dans le descriptif de mission; soit un prix total
                                        TTC précisé dans le descriptif de mission. Le montant
                                        correspond à un total de nombre de jours études précisé dans
                                        le Descriptif de mission pour un montant par jour étude
                                        précisé dans le Descriptif de mission.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container mt--35'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>04</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>MODALITÉS DE PAIEMENT</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        {' '}
                                        L'Etude sera facturée selon les modalités suivantes :
                                        paiement de la totalité du prix TTC le dernier jour de la
                                        Période de l'Etude. Le règlement du prix de l'Etude sera
                                        établi par chèque bancaire ou virement à l'ordre de
                                        l'Association {initialObjectState.association.name}. Les
                                        Parties conviennent d'un règlement au plus tard 15 jours
                                        après la fin de l'étude. Les Parties conviennent de
                                        pénalités en cas de retard de paiement (sauf accord entre
                                        les Parties lequel doit faire l'objet d'un écrit signé des
                                        Parties). Ainsi, sauf accord express de report de paiement
                                        accordé par l'Association{' '}
                                        {initialObjectState.association.name}, tout retard de
                                        paiement de tout ou partie d'une somme due à son échéance,
                                        entrainera, sans mise en demeure préalable, la facturation à
                                        compter de l'échéance susmentionnée d'une pénalité, pour
                                        retard de paiement calculée par application à l'intégralité
                                        des sommes restant dues, d'un taux d'intérêt égal à trois
                                        fois le taux d'intérêt légal et application d'une indemnité
                                        forfaitaire pour frais de recouvrement de quarante euros.
                                        Les Parties conviennent que ce taux est calculé prorata
                                        temporis par perte d'un mois calendaire et que chaque mois
                                        entamé est comptabilisé comme mois entier. Ces intérêts
                                        seront dus sans qu'une mise en demeure préalable ne soit
                                        nécessaire.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container mt-80'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>05</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>MODALITÉS DE RÉALISATION DE L'ÉTUDE</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        {' '}
                                        La société fournira à l'Association{' '}
                                        {initialObjectState.association.name} à la signature de la
                                        présente Convention le matériel et les documents nécessaires
                                        à la bonne marche de l'Etude, réalisée dans les locaux du
                                        Client. A l'expiration de l'Etude, l'Association{' '}
                                        {initialObjectState.association.name}
                                        remettra au Client le matériel et les documents que celui-ci
                                        lui avait remis pour la réalisation de l'Etude. L'étudiant
                                        mandaté par l'Association se rendra dans les locaux du
                                        Client pour mener à bien sa mission. Il pourra accéder à ses
                                        locaux aux jour et heure d'ouverture de ses bureaux. Chez le
                                        Client, l'interlocuteur privilégié de l'Association en
                                        charge de l'Etude est précisé dans le Descriptif de mission,
                                        ci-après "l'Etudiant". L'Etudiant agira exclusivement sur
                                        les instructions et sous la responsabilité de l'Association
                                        {initialObjectState.association.name}. Lorsque L'Etudiant se
                                        rendra dans les locaux du Client, il devra adopter une bonne
                                        présentation, une bonne tenue, respecter le Règlement
                                        Intérieur et le règlement d'hygiène et de sécurité de
                                        l'entreprise (dont le contenu devra lui être communiqué),
                                        s'abstenir de perturber l'activité normale du personnel du
                                        Client ou l'exploitation de celui-ci et observer la plus
                                        stricte confidentialité. L'Association apportera à
                                        l'exécution de la mission qui lui est confiée tous ses
                                        efforts et tous ses soins, dans la limite d'une obligation
                                        de moyens. Elle s'engage à agir au mieux des intérêts du
                                        Client, dans la limite des moyens énoncés par la présente
                                        Convention. Le Client et l'Association{' '}
                                        {initialObjectState.association.name} s'engagent à
                                        entretenir une étroite et loyale collaboration afin de
                                        vérifier aussi souvent que l'une ou l'autre des Parties le
                                        jugera nécessaire de l'adéquation entre la prestation faite
                                        et la demande du Client telle que définie à l'objet de la
                                        présente Convention. Dans l'hypothèse où l'une ou l'autre
                                        des Parties considère que la mission ne s'exécute pas
                                        conformément aux conditions initiales, elles conviennent de
                                        se rapprocher afin d'examiner la possibilité d'adaptation
                                        amiable de la présente Convention. En cas de désaccord entre
                                        les Parties rendant indispensable une résiliation du
                                        contrat, alors le contrat pourra être rompu dans les
                                        conditions prévues dans l'Article consacré à la résiliation
                                        du contrat.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    {/************** CSS number page 2/5 *****************/}
                    <div className='page-number bolder line-black top-page-2'>
                        <div className='line-green-one'>
                            <div></div>
                        </div>
                        <div className='line-green-two'>
                            <div></div>
                        </div>
                        <p className='num-page'>2/5</p>
                        <div className='line-black-one'>
                            <div></div>
                        </div>
                        <div className='line-black-two'>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            {/************** page 3 *****************/}
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                <div className='content-student-body mt-55'>
                    <div className='margin-student-body'>
                        <div className='paragraphe-container '>
                            <div className='paragraphe-number'>
                                <span className='green-number'>06</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>MODIFICATION ET RÉSILIATION DU CONTRAT</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        Toute modification apportée aux dispositions de la présente
                                        Convention fera l'objet d'un avenant préalablement signé par
                                        les Parties. Le Client se réserve le droit de résilier la
                                        présente Convention dans le cas où il estimera que
                                        l'Association {initialObjectState.association.name}{' '}
                                        n'effectue pas sa mission avec toute la diligence nécessaire
                                        ou en cas de force majeure. Par ailleurs, l'Association
                                        {initialObjectState.association.name} pourra demander la
                                        résiliation de la présente Convention si en cas de force
                                        majeure ou du fait d'une cause légitime, l'Association se
                                        trouve alors dans l'impossibilité d'exécuter l'Etude qui lui
                                        a été confiée. Toute résiliation se fera par Lettre
                                        Recommandée avec avis de réception. En cas de résiliation,
                                        l'Association {initialObjectState.association.name} remettra
                                        un rapport de l'Etude des travaux à la date de leur
                                        interruption. L'Association{' '}
                                        {initialObjectState.association.name} facturera le paiement
                                        du prix au prorata du travail effectué jusqu'à la date de
                                        rupture de la Convention.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container mt-50'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>07</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>AUTEUR DE L'ÉTUDE</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        La réalisation de l'Etude confiée par le Client à
                                        l'Association {initialObjectState.association.name} sera
                                        elle-même confiée par l'Association{' '}
                                        {initialObjectState.association.name} à un ou plusieurs
                                        Etudiants de l’école à laquelle l’étudiant est rattachée,
                                        qu'elle mandatera à cet effet. L'Etudiant est choisi par
                                        l'Association pour des critères de compétences, motivation
                                        et disponibilités. L'Association choisit l'Etudiant ou les
                                        Etudiants qui seront le(s) plus à même de réaliser l'Etude.
                                        L'Association s'efforcera de ne pas changer l'Etudiant en
                                        cours de mission sauf demande expresse préalable et dûment
                                        motivée du Client, ou indisponibilité de l'Etudiant.
                                        L'Etudiant chargé de la réalisation de l'Etude établira un
                                        rapport d'Etude. Ce rapport sera remis au Client, dans le
                                        délai de 30 jours à compter de la fin de l'Etude, et fera
                                        l'objet de la signature d'un PV de remise signé par
                                        l'Etudiant, l'Association et le Client. Lors de la remise du
                                        rapport au Client, l'Association pourra remettre au Client
                                        un questionnaire qualité qui pourra être utilisé par
                                        l'Association {initialObjectState.association.name} comme
                                        référence auprès des tiers.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container mt-70'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>08</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>PROPRIÉTÉ DE L'ÉTUDE RÉALISÉE</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        {' '}
                                        L'ensemble des informations rassemblées et des méthodes
                                        conçues pour la réalisation de l'Etude demeurent la
                                        propriété du Client. Une fois le paiement global effectué,
                                        le rapport d'Etude devient la propriété exclusive du Client.
                                        Sauf clause contraire express de la part du Client prévue à
                                        ladite Convention, le Client autorise l'Association{' '}
                                        {initialObjectState.association.name} à utiliser le nom du
                                        Client à titre de référence. L'Association reconnait que le
                                        Client sera le titulaire exclusif des droits de diffusion,
                                        de représentation, d'adaptation, de reproduction et de
                                        commercialisation des créations protégeables par le droit
                                        d'auteur, des documentations et plans produits dans le cadre
                                        de l'Etude, pour la durée de ses droits, et sans limitation
                                        d'étendue ni de destination, telles que cartographies et
                                        documents de présentation. L'Association déclare en
                                        conséquence expressément renoncer à tous les droits cités
                                        ci-dessus, quelle qu'en soit la nature, issus de l'exécution
                                        de l'Etude et ce en contrepartie du complet paiement de la
                                        rémunération prévue à l'Article 3.
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className='paragraphe-container mt-70'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>09</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>CONFIDENTIALITÉ</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    {' '}
                                    L'Association s'engage à garder strictement confidentielles et à
                                    ne pas divulguer ou communiquer à des tiers toutes informations
                                    ou tous documents reçus du Client dans le cadre de l'Etude. Par
                                    tiers, on entendra toute personne autre que les parties
                                    soussignées et l'Etudiant. L'Association s'engage à n'utiliser
                                    les informations reçues du Client que pour les besoins et dans
                                    le cadre de l'objet de l'Etude. L'Association s'engage à garder
                                    strictement confidentiels et à ne pas divulguer ou à communiquer
                                    à des tiers les résultats de l'Etude. Ne seront pas considérées
                                    comme confidentielles les informations pour lesquelles il pourra
                                    être prouvé : - Qu'elles sont entrées dans le domaine public
                                    préalablement à leur divulgation ou après celle-ci, mais dans ce
                                    cas, en l'absence de toute faute de l'Etudiant, - Qu'elles ont
                                    été reçues de tiers de manière licite, sans restriction ni
                                    violation du présent accord, - Qu'elles ont été publiées sans
                                    violer les dispositions du présent accord, - Que leur
                                    utilisation ou divulgation ont été autorisées par écrit, par la
                                    Partie dont elles émanent. L'obligation de confidentialité sera
                                    maintenue pendant toute la durée du présent contrat, et deux
                                    ans, à compter de l'extinction du présent contrat quel qu'en
                                    soit la cause.
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container mt-100'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>10</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>RESPONSABILITÉ</p>
                            </div>
                            <div className='paragraphe-text'>
                                {initialObjectState && (
                                    <p>
                                        {' '}
                                        L'Association {initialObjectState.association.name} s'engage
                                        à mettre tous les moyens en ouvre pour une bonne réalisation
                                        de l'Etude. Le Client s'engage pour sa part à collaborer
                                        afin de vérifier régulièrement que la mission s'exécute
                                        conformément aux conditions fixées. En tout état de cause,
                                        la présente Convention constitue pour l'Association{' '}
                                        {initialObjectState.association.name}
                                        et l'Etudiant en charge de l'Etude, une simple obligation de
                                        moyen et en aucun cas une obligation de résultat. Les
                                        Etudiants affectés à la réalisation de l'Etude, restent sous
                                        la responsabilité entière et exclusive de l'Association
                                        {initialObjectState.association.name} qui est seule
                                        habilitée à lui adresser des directives et instructions.
                                        L'Association {initialObjectState.association.name} s'engage
                                        à respecter les délais convenus sur la base d'une obligation
                                        de moyens. Le Client renonce à cet égard à tout recours
                                        contre l'Association, à raison de toutes conséquences
                                        dommageables, notamment financières pouvant se révéler
                                        ultérieurement concernant la conclusion, l'exécution ou
                                        l'extinction de la présente Convention.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    {/************** CSS number page 3/5 *****************/}
                    <div className='page-number bolder line-black top-page-3'>
                        <div className='line-green-one'>
                            <div></div>
                        </div>
                        <div className='line-green-two'>
                            <div></div>
                        </div>
                        <p className='num-page'>3/5</p>
                        <div className='line-black-one'>
                            <div></div>
                        </div>
                        <div className='line-black-two'>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            {/************** page 4 *****************/}
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                <div className='content-student-body mt-55'>
                    <div className='margin-student-body'>
                        <div className='paragraphe-container '>
                            <div className='paragraphe-number'>
                                <span className='green-number'>11</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>LITIGES ET DROIT APPLICABLE</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    {' '}
                                    Si un différend survenait à l'occasion de l'existence, de
                                    l'interprétation ou de l'exécution de la présente Convention,
                                    les Parties s'efforceraient de le régler à l'amiable,
                                    préalablement à toute action en justice. En cas de litige
                                    persistant, le litige sera porté devant les Tribunaux de Paris,
                                    seuls compétents. Il sera fait application du droit français.
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container mt--30'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>12</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>NON-RENONCIATION</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    Aucune renonciation totale ou partielle par une des Parties à se
                                    prévaloir de tout ou partie des engagements de l'autre Partie au
                                    titre des présentes, ne pourra être interprétée comme une
                                    renonciation de sa part à s'en prévaloir, à moins qu'elle n'est
                                    été effectuée par écrit et auquel cas cette renonciation devra
                                    être interprétée restrictivement.
                                </p>
                            </div>
                        </div>
                        <div className='paragraphe-container mt--45'>
                            <div className='paragraphe-number'>
                                <span className='green-number'>13</span>
                            </div>
                            <div className='paragraphe-title'>
                                <p className='text-title'>NULLITÉ D'UNE CLAUSE</p>
                            </div>
                            <div className='paragraphe-text'>
                                <p>
                                    {' '}
                                    Dans le cas où l'une quelconque disposition des présentes serait
                                    déclarée nulle ou inapplicable par toute autorité judiciaire ou
                                    extrajudiciaire, ou par une sentence arbitrale, les Parties
                                    s'efforceraient de la remplacer par une disposition valide, la
                                    plus proche possible de la disposition d'origine, et les autres
                                    dispositions n'en subsisteraient pas moins et conserveraient
                                    leur pleine validité entre les Parties, à moins que, en
                                    l'absence de ladite disposition, la présente Convention devenait
                                    sans objet.
                                </p>
                            </div>
                        </div>
                        <div className='container-sign mt-500'>
                            {initialObjectState && (
                                <div className='date-sign'>
                                    <p>
                                        Fait en 2 exemplaires à PARIS le{' '}
                                        {initialObjectState.started_at} .
                                    </p>
                                </div>
                            )}
                            <div className='main-box-sign'>
                                <div className='content-sign-society'>
                                    {initialObjectState && (
                                        <div className='society-title'>
                                            SIGNATURE DE LA SOCIÉTÉ{' '}
                                            {initialObjectState.company.name}
                                        </div>
                                    )}
                                    {initialObjectState && (
                                        <div className='society-subtitle'>
                                            <p>PRÉCÉDÉE DE LA MENTION «LU ET APPROUVÉ»</p>
                                        </div>
                                    )}
                                </div>
                                <div className='content-sign-society'>
                                    {initialObjectState && (
                                        <div className='association-title'>
                                            SIGNATURE {initialObjectState.association.name}
                                        </div>
                                    )}
                                    {initialObjectState && (
                                        <div className='association-subtitle'>
                                            <p>
                                                REPRÉSENTÉE PAR SON PRÉSIDENT{' '}
                                                {initialObjectState.association.contact}
                                            </p>
                                            <p>PRÉCÉDÉE DE LA MENTION «LU ET APPROUVÉ»</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/************** CSS number page 4/5 *****************/}
                    <div className='page-number bolder line-black top-page-4'>
                        <div className='line-green-one'>
                            <div></div>
                        </div>
                        <div className='line-green-two'>
                            <div></div>
                        </div>
                        <p className='num-page'>4/5</p>
                        <div className='line-black-one'>
                            <div></div>
                        </div>
                        <div className='line-black-two'>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            {/************** page 5 *****************/}
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                <div className='content-student-body mt-55'>
                    <div className='margin-student-body'>
                        <div className='content-box-annexe mt-0'>
                            <div className='box-title box-width-annexe'>ANNEXE A CONVENTION</div>
                            <div className='box-text'>
                                <div className='content-text-box'>
                                    {initialObjectState && (
                                        <p>
                                            {' '}
                                            L'exécution de l'étude et les prestations en découlant
                                            sont effectuées en collaboration avec{' '}
                                            {initialObjectState.association.name} selon les
                                            modalités suivantes :<br></br>
                                            <br></br>
                                            <p>Cahier des charges :</p>
                                            <p>{initialObjectState.student.name}</p>
                                            <p>{initialObjectState.association.name}</p>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <table className='content-table'>
                            <thead>
                                <tr className='head-table'>
                                    <th colSpan='3'>NOMBRE DE JOURS ÉTUDE</th>
                                    <th colSpan='3'>FORFAIT JE HT</th>
                                    <th colSpan='3'>MONTANT HT</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                <tr className='underline-price'>
                                    {initialObjectState && (
                                        <td className='blank-border-left-right' colSpan='3'>
                                            {initialObjectState.mission.je}
                                        </td>
                                    )}
                                    {initialObjectState && (
                                        <td className='blank-border-left-right' colSpan='3'>
                                            {initialObjectState.mission.price_ht}
                                        </td>
                                    )}
                                    {initialObjectState && (
                                        <td className='blank-border-left-right' colSpan='3'>
                                            {initialObjectState.mission.total_ht}
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    <td className='blank-border' colSpan='3'></td>
                                    <td className='blank-border' colSpan='3'>
                                        TOTAL HT
                                    </td>
                                    {initialObjectState && (
                                        <td className='blank-border' colSpan='3'>
                                            {initialObjectState.mission.total_ht} €
                                        </td>
                                    )}
                                </tr>
                                <tr>
                                    {initialObjectState && (
                                        <td className='blank-border text-align-start' colSpan='3'>
                                            <p>DATE DE DÉBUT DE L'ÉTUDE:</p>
                                            <p>{initialObjectState.started_at}</p>
                                        </td>
                                    )}
                                    <td className='blank-border' colSpan='3'>
                                        TVA 20,00%
                                    </td>
                                    {initialObjectState && (
                                        <td className='blank-border' colSpan='3'>
                                            {initialObjectState.mission.tva} €
                                        </td>
                                    )}
                                </tr>
                                <tr className='content-price'>
                                    {initialObjectState && (
                                        <td className='blank-border text-align-start' colSpan='3'>
                                            <p>DATE DE REMISE DE L'ÉTUDE:</p>
                                            <p>{initialObjectState.mission.end_date}</p>
                                        </td>
                                    )}
                                    <td className='text-price blank-border-right' colSpan='3'>
                                        MONTANT TOTAL TTC
                                    </td>
                                    {initialObjectState && (
                                        <td className='total-price' colSpan='3'>
                                            {initialObjectState.mission.total_ttc} €
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                        <div className='content-modality'>
                            <p className='bolder text-modality'>
                                MODALITÉS DE RÈGLEMENT - ÉCHÉANCIER THÉORIQUE
                            </p>
                            {initialObjectState && (
                                <p>
                                    NOMBRE D'ÉCHÉANCES: {initialObjectState.mission.invoices.length}
                                </p>
                            )}
                            <br />
                            <p className='bolder'>
                                Les jours facturés sont calculés au temps réel travaillé et déclaré
                            </p>
                        </div>
                        <table className='table-width'>
                            <thead>
                                <tr className='head-table-SCHEDULES'>
                                    <th colSpan='1'>ÉCHÉANCES</th>
                                    <th colSpan='1'>NOMBRE DE JOURS ÉTUDES</th>
                                    <th colSpan='1'>MONTANT HT</th>
                                    <th colSpan='1'>TAUX TVA</th>
                                    <th colSpan='1'>MONTANT TVA</th>
                                    <th colSpan='1'>MONTANT TTC</th>
                                    <th colSpan='1'>DATE</th>
                                </tr>
                            </thead>
                            {initialObjectState && (
                                <tbody className='table-body-page-5'>
                                    {initialObjectState.mission.invoices}
                                </tbody>
                            )}
                        </table>
                    </div>
                    {/************** CSS number page 5/5 *****************/}
                    <div className='page-number bolder line-black top-page-5'>
                        <div className='line-green-one'>
                            <div></div>
                        </div>
                        <div className='line-green-two'>
                            <div></div>
                        </div>
                        <p className='num-page'>5/5</p>
                        <div className='line-black-one'>
                            <div></div>
                        </div>
                        <div className='line-black-two'>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
