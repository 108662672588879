import React, { Component } from 'react';
import './Render.css';

class Img extends Component {
    render() {
        return (
            <img className='img' alt='go-back' src='https://i.postimg.cc/nzSFqp8c/PdfLogo.png' />
        );
    }
}

export default Img;
