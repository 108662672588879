import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import MissionDataService from '../../services/mission.service';
import CompanyDataService from '../../services/company.service';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
    label,
    row,
    type,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const currentUser = useAuthState();
    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    const sendFiles = async files => {
        let data = false;
        let b64 = false;
        let attachment = false;
        let filename = false;
        let operation = 'add_association_document';

        if (files.length > 0) {
            let file = files[0];
            b64 = await convertBase64(file);
            data = b64.substring(b64.indexOf('base64,') + 7);
            attachment = {
                item_class: 'Mission',
                item_id: row.id,
                temp_id: file.name,
                type: type,
                item_org_id: row.company_id,
                creation_date: new Date(),
                contents: {
                    data: data,
                    filename: filename,
                    mimetype: file.type,
                },
            };
        }

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(attachment));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        return await axios(config);
    };

    const handleNewFileUpload = async e => {
        const { files: newFiles } = e.target;
        if (newFiles.length > 0) {
            let response = await sendFiles(newFiles);
            if (response.data.code === 0) {
                window.location.reload(false);
            }
        }
    };

    return (
        <section>
            <a className='list-table-link' onClick={e => handleUploadBtnClick(e)}>
                {label}
            </a>
            <input
                type='file'
                ref={fileInputField}
                onChange={handleNewFileUpload}
                title=''
                value=''
                {...otherProps}
            />
        </section>
    );
};

export default function MissionApplicationList(props) {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const currentUser = useAuthState();
    const [entreprise, setEntreprise] = useState([]);

    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );

    useEffect(() => {
        const fetchObject = async () => {
            let sKey =
                'SELECT Mission AS M JOIN lnkMissionToStudent AS MS ON MS.mission_id = M.id JOIN DocumentNote AS D ON M.studyagreement_id = D.id WHERE M.student_id > 0 AND M.status = "pending" AND MS.status IN ("student_confirmed", "accepted") AND company_id = ' +
                currentUser.org_id +
                ' AND M.studentmission_id IN (SELECT DocumentNote AS DN WHERE DN.status != "published" AND DN.mission_id = M.id AND DN.org_id = M.company_id)';

            console.log(sKey);
            MissionDataService.getAll(sKey).then(response => {
                setData(response);
                CompanyDataService.get(currentUser.org_id).then(response => {
                    setEntreprise(response);
                });
            });
        };

        if (data.length === 0) {
            fetchObject();
        }
    }, [data]);

    const createDocumentLink = file => {
        if (file) {
            return '';
            // 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=display_document&filename=' +
            // file.filename +
            // '&id=' +
            // file.id +
            // '&obj=Association::' +
            // currentUser.org_id
        }
    };

    const columns = [
        {
            name: 'Référence mission',
            cell: row => (
                <Link className='list-table-link' to={'/mission/' + row.id + '/profil'}>
                    {row && row.name}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Date de début',
            selector: row => <span>{moment(row.start_date).format('DD-MM-YYYY')}</span>,
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.start_date < b.start_date) {
                    return -1;
                }
                if (a.start_date > b.start_date) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Télécharger',
            selector: row => {
                return (
                    <a
                        className='list-table-link'
                        href={'https://itop.jobers.fr/pdf_c.php?mission_id=' + row.id}
                        target='_blank'>
                        Télécharger
                    </a>
                );
            },
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.id < b.id) {
                    return -1;
                }
                if (a.id > b.id) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Charger',
            selector: row => (
                <FileUpload
                    row={row}
                    type="Convention d'études"
                    accept='.jpg,.png,.jpeg,.pdf'
                    label='Charger'
                />
            ),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Etat',
            selector: row =>
                row.studyagreement_id_status == 'published' &&
                row.studentmission_id_status !== 'published'
                    ? 'En attente signature étudiant'
                    : 'En attente signature entreprise',
            filterable: true,
            sortable: true,
        },
    ];

    const renderList = (list, additional_str, separator) => {
        let str = '';
        list.map((item, index) => {
            str += item.label + additional_str;
            if (index < list.length - 1) {
                str += separator;
            }
        });

        return str;
    };

    return (
        <div className='backend-container'>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>{entreprise && entreprise.name}</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4 list-container-tabs'>
                    <Link to={'/mission/new'} className='profil-tabs'>
                        Création
                    </Link>
                    <Link to={'/mission/application'} className='profil-tabs'>
                        Candidats
                    </Link>
                    <Link to={'/mission/signatures'} className='profil-tabs'>
                        Signatures
                    </Link>
                </div>
                <div className='list-container-searchbar'>
                    <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                    <input
                        id='search'
                        className='list-container-searchinput'
                        type='text'
                        placeholder='Rechercher'
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                    />
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='profil-container-divider-3' />
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child'>
                    <p className='list-container-subtitle list-tab-subtitle'>
                        Vous avez validé un étudiant pour une mission ? Les contrats correspondants
                        apparaissent ici. <br /> Vous pouvez{' '}
                        <span className='list-table-link'>Télécharger</span> le document et le{' '}
                        <span className='list-table-link'>Charger</span> après signature sur la
                        plateforme. On s'occupe du reste !
                    </p>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucun contrat trouvé.</span>
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    );
}
