import React, { Component } from 'react';
import './IconSize.css';

class Association extends Component {
    render() {
        return (
            <div className='icon-size'>
                <img className='icon-img' alt='go-back' src='/img/01_ASSOCIATIONS.svg' />
            </div>
        );
    }
}

export default Association;
