import React, { Component } from 'react';
import './RenderSign.css';

class ImgSign extends Component {
    render() {
        return (
            <img className='img' alt='go-back' src='https://i.postimg.cc/2y15QSKG/ImgSign.png' />
        );
    }
}

export default ImgSign;
