import './RegisterOrganizationForm.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Logo from '../Logo/Logo';
import Form from '../Utils/Form';
import Input from '../Utils/Input';

export default function RegisterOrganizationForm(props) {
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });

    const onSubmit = async (fields, errors) => {
        setFormData({
            fields,
            errors,
        });

        let dataFields = {
            email: fields.email.value,
            password: fields.password.value,
            name: fields.name.value,
            school_name: fields.school_id.value,
            domain: fields.domain.value,
            first_name: fields.first_name.value,
            last_name: fields.last_name.value,
            phonenumber: fields.phonenumber.value,
            message: fields.message.value,
        };

        let data = new URLSearchParams();
        data.append('operation', 'create_association');
        data.append('data', JSON.stringify(dataFields));

        var config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        let response = await axios(config);

        if (response.data.code !== 0) {
            console.log(response.data.message);
        }

        if (response.data.code === 0) {
            setSubmitted(true);
        }
    };

    const RegisterOrganizationForm = props => {
        return (
            <div className='register-organization screen'>
                <Link to='/register'>
                    <img
                        className='arrow-left'
                        alt='go-back'
                        src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f3dde03eda8a1cb03a287a/img/arrow-left@2x.svg'
                    />
                </Link>
                <Logo />
                <div className='register-organization-title'>Informations de l’association</div>
                <p className='register-organization-subtitle inter-normal-pale-sky-16px'>
                    Vous souhaitez rejoindre le réseau Jobers ou vous souhaitez plus d’informations
                    ?
                </p>
                <Form
                    className='form'
                    onSubmit={onSubmit}
                    fields={formData.fields}
                    errors={formData.errors}
                    validate={true}
                    validateOnSubmit={true}>
                    <div className='contact-panel'>
                        <div className='contact-title'>Informations de connexion</div>
                        <div className='login-divider' />
                    </div>
                    <Input
                        id='email'
                        label='Adresse email'
                        placeholder='contact@asso.fr'
                        validate='email|required'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box border-1px-mischka inter-normal-pale-sky-16px',
                        }}
                    />
                    <Input
                        id='password'
                        label='Mot de passe'
                        placeholder='•••••••••'
                        validate='password|required'
                        type='password'
                        hintmessage='Doit être au moins de 8 caractères et avoir un caractère spécial (@$!%*#?&)'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box inter-normal-pale-sky-16px',
                        }}
                    />
                    <Input
                        id='confirm_password'
                        name='confirm_password'
                        label='Confirmez mot de passe'
                        placeholder='•••••••••'
                        type='password'
                        validate='required|confirm_password'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box inter-normal-pale-sky-16px',
                        }}
                    />
                    <div className='contact-panel'>
                        <div className='contact-title'>Informations de l'association</div>
                        <div className='login-divider' />
                    </div>
                    <Input
                        id='name'
                        label='Nom de l’association'
                        placeholder='Nom de l’association'
                        type='text'
                        name='name'
                        validate='required'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box border-1px-mischka inter-normal-pale-sky-16px',
                        }}
                        // suggestions={[
                        //     'AERESA',
                        //     'TESAA',
                        //     'ARCHITEC’TONIC',
                        //     'ARCHI’MADE',
                        //     'CUTCH',
                        //     'ARCHIPEL',
                        //     'JCTPE',
                        //     'PREALAB',
                        //     'GRAINE D’ARCHI',
                        //     'ESQUISSE',
                        //     'ASSO B',
                        //     'WIP',
                        //     'JAUB',
                        //     'PASSERELLE V',
                        //     'TRAIT D’UNION',
                        //     'JAFAAR',
                        //     'VILLEBREQUIN',
                        //     'RHIZOME',
                        // ]}
                    />
                    <Input
                        id='school_id'
                        label='École'
                        placeholder='Nom de l’école'
                        type='autocomplete'
                        name='school_id'
                        validate='required'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box border-1px-mischka inter-normal-pale-sky-16px',
                        }}
                        suggestions={[
                            'Paris ESA',
                            'Strasbourg',
                            'Versailles',
                            'Bordeaux',
                            'Clermont-Ferrand',
                            'Grenoble',
                            'Lille',
                            'Lyon',
                            'Marne la Vallée',
                            'Marseille',
                            'Montpellier',
                            'Nancy',
                            'Nantes',
                            'Normandie',
                            'Paris Belleville',
                            'Paris Malaquais',
                            'Paris Val de Seine',
                            'Paris Villette',
                            'Rennes',
                            'Rouen',
                            'St Etienne',
                            'Toulouse',
                        ]}
                    />
                    <Input
                        id='phonenumber'
                        label='Numéro de téléphone'
                        placeholder='+33 6 11 22 33 44'
                        validate='phone|required'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box border-1px-mischka inter-normal-pale-sky-16px',
                        }}
                    />
                    <Input
                        id='domain'
                        label='Domaine'
                        type='select'
                        placeholder='Architecture'
                        value='architecture'
                        validate='required'
                        classes={{
                            contClass: 'input-field',
                            errorClass: 'error-text',
                            fieldClass: 'input-box border-1px-mischka inter-normal-pale-sky-16px',
                        }}
                        options={[
                            { value: 'architecture', label: 'Architecture' },
                            { value: 'paysage', label: 'Paysage' },
                            { value: 'commerce', label: 'Commerce' },
                            { value: 'communication', label: 'Communication' },
                            { value: 'ingenierie', label: 'Ingenierie' },
                        ]}
                    />
                    <div className='contact-container'>
                        <div className='contact-panel'>
                            <div className='contact-title'>Président</div>
                            <div className='login-divider' />
                        </div>
                        <div className='div-2-col'>
                            <Input
                                id='last_name'
                                label='Nom'
                                placeholder='Nom'
                                validate='required'
                                classes={{
                                    contClass: 'input-col-1',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-1col border-1px-mischka inter-normal-mirage-16px',
                                }}
                            />
                            <Input
                                id='first_name'
                                label='Prénom'
                                placeholder='Prénom'
                                validate='required'
                                classes={{
                                    contClass: 'input-col-1',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-1col border-1px-mischka inter-normal-mirage-16px',
                                }}
                            />
                        </div>
                        <Input
                            id='message'
                            label='Votre message'
                            type='textarea'
                            rows='5'
                            placeholder='Écrivez nous votre message'
                            classes={{
                                contClass: 'input-field',
                                errorClass: 'error-text',
                                fieldClass:
                                    'input-box border-1px-mischka inter-normal-pale-sky-16px',
                            }}
                        />
                        <div className='checkbox-group-item'>
                            <div className='checkbox'>
                                <div className='checkbox-1'>
                                    <input
                                        type='checkbox'
                                        required='required'
                                        className='checkbox-base checkbox-round border-1px-mischka'
                                        id='accept_checkbox'
                                    />
                                </div>
                                <label
                                    className='inter-medium-oxford-blue-14px'
                                    htmlFor='accept_checkbox'>
                                    J’accepte la gestion et le traitements de mes informations.
                                </label>
                            </div>
                        </div>
                        <div className='bottom-actions'>
                            <button type='submit' className='primary-submit'>
                                Envoyer le message
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    };

    const RegisterOrganizationConfirmation = props => {
        return (
            <div className='register-organization-submit screen'>
                <div className='content'>
                    <div className='header'>
                        <Logo />
                        <div className='text-and-supporting-text'>
                            <div className='text'>Merci pour votre message</div>
                            <p className='supporting-text'>
                                Nous reviendrons vers vous dès que possible
                            </p>
                        </div>
                    </div>
                    <Link to='/'>
                        <button type='submit' className='primary-submit'>
                            Revenir à l'accueil
                        </button>
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <div className='container-center-horizontal'>
            {submitted ? <RegisterOrganizationConfirmation /> : <RegisterOrganizationForm />}
        </div>
    );
}
