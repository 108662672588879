import http from '../http-common';
import vars from '../config/vars';

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

const getAll = async (key, fields) => {
    const aFields = [
        'id',
        'created_at',
        'name',
        'ref',
        'description',
        'student_id_friendlyname',
        'student_id',
        'student_org_id',
        'student_org_name',
        'company_id_friendlyname',
        'company_id',
        'domain',
        'display_status',
        'status',
        'start_date',
        'end_date',
        'studentmission_id',
        'studentmission_id_status',
        'studentmission_id_friendlyname',
        'studyagreement_id',
        'studyagreement_id_status',
        'studyagreement_id_friendlyname',
        'documents_list',
        'work_type',
        'mission_type',
        'je',
        'price_ht',
        'study_levels',
        'main_skills',
        'secondary_skills',
        'company_id_address',
        'company_id_city',
        'mission_type',
        'students_list',
        'terms_list',
    ];
    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Mission',
        output_fields: aFields.join(','),
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if (response.data.code == '0' && response.data.objects) {
        Object.keys(response.data.objects).forEach(key => {
            let currentObject = {};
            currentObject.id = response.data.objects[key].key;
            currentObject.created_at = response.data.objects[key].fields.created_at;
            currentObject.description = response.data.objects[key].fields.description;
            currentObject.name = response.data.objects[key].fields.name;
            currentObject.ref = response.data.objects[key].fields.ref;
            currentObject.display_status = response.data.objects[key].fields.display_status;
            currentObject.status = response.data.objects[key].fields.status;
            currentObject.domain = response.data.objects[key].fields.domain;
            currentObject.company = response.data.objects[key].fields.company_id_friendlyname;
            currentObject.company_id = response.data.objects[key].fields.company_id;
            currentObject.student = response.data.objects[key].fields.student_id_friendlyname;
            currentObject.student_id = response.data.objects[key].fields.student_id;
            currentObject.student_org_id = response.data.objects[key].fields.student_org_id;
            currentObject.student_org_name = response.data.objects[key].fields.student_org_name;
            currentObject.city = response.data.objects[key].fields.company_id_city;
            currentObject.address = response.data.objects[key].fields.company_id_address;
            currentObject.mission_type = response.data.objects[key].fields.mission_type;
            currentObject.start_date = response.data.objects[key].fields.start_date;
            currentObject.end_date = response.data.objects[key].fields.end_date;
            currentObject.je = response.data.objects[key].fields.je;
            currentObject.price_ht = response.data.objects[key].fields.price_ht;
            currentObject.study_levels =
                response.data.objects[key].fields.study_levels !== '' &&
                isJsonString(response.data.objects[key].fields.study_levels)
                    ? JSON.parse(response.data.objects[key].fields.study_levels)
                    : [];
            currentObject.main_skills =
                response.data.objects[key].fields.main_skills !== ''
                    ? JSON.parse(response.data.objects[key].fields.main_skills)
                    : [];
            currentObject.secondary_skills =
                response.data.objects[key].fields.secondary_skills !== ''
                    ? JSON.parse(response.data.objects[key].fields.secondary_skills)
                    : [];
            currentObject.documents_list = response.data.objects[key].fields.documents_list;
            currentObject.students_list = response.data.objects[key].fields.students_list;
            currentObject.studyagreement_id = response.data.objects[key].fields.studyagreement_id;
            currentObject.studyagreement_id_status =
                response.data.objects[key].fields.studyagreement_id_status;
            currentObject.studentmission_id = response.data.objects[key].fields.studentmission_id;
            currentObject.studentmission_id_status =
                response.data.objects[key].fields.studentmission_id_status;
            currentObject.terms_list = response.data.objects[key].fields.terms_list;

            objects.push(currentObject);
        });
    }

    // sort objects by start_date and "En cours" display status displayed first
    objects.sort((a, b) => {
        if (a.display_status === 'En cours' && b.display_status !== 'En cours') {
            return -1;
        } else if (a.display_status !== 'En cours' && b.display_status === 'En cours') {
            return 1;
        } else {
            return new Date(b.start_date) - new Date(a.start_date);
        }
    });

    return objects;
};
const get = async key => {
    let returnValue = {};

    const aFields = [
        'id',
        'name',
        'ref',
        'description',
        'student_id_friendlyname',
        'student_id',
        'student_org_id',
        'student_org_name',
        'company_id_friendlyname',
        'company_id',
        'display_status',
        'status',
        'start_date',
        'end_date',
        'studentmission_id',
        'studentmission_id_status',
        'studentmission_id_friendlyname',
        'studyagreement_id',
        'studyagreement_id_status',
        'studyagreement_id_friendlyname',
        'documents_list',
        'work_type',
        'mission_type',
        'je',
        'price_ht',
        'study_levels',
        'main_skills',
        'secondary_skills',
        'company_id_address',
        'company_id_city',
        'mission_type',
        'students_list',
        'terms_list',
    ];

    // BUILD POST PARAMS
    let data = {
        operation: 'core/get',
        class: 'Mission',
        output_fields: aFields.join(','),
        key: key,
    };
    let params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if (response.data.code == '0' && response.data.objects) {
        Object.keys(response.data.objects).forEach(async key => {
            returnValue.id = response.data.objects[key].fields.id;
            returnValue.name = response.data.objects[key].fields.name;
            returnValue.ref = response.data.objects[key].fields.ref;
            returnValue.display_status = response.data.objects[key].fields.display_status;
            returnValue.status = response.data.objects[key].fields.status;
            returnValue.description = response.data.objects[key].fields.description;
            returnValue.company = response.data.objects[key].fields.company_id_friendlyname;
            returnValue.company_id = response.data.objects[key].fields.company_id;
            returnValue.student = response.data.objects[key].fields.student_id_friendlyname;
            returnValue.student_id = response.data.objects[key].fields.student_id;
            returnValue.student_org_id = response.data.objects[key].fields.student_org_id;
            returnValue.student_org_name = response.data.objects[key].fields.student_org_name;
            returnValue.start_date = response.data.objects[key].fields.start_date;
            returnValue.end_date = response.data.objects[key].fields.end_date;
            returnValue.studentmission_id = response.data.objects[key].fields.studentmission_id;
            returnValue.studentmission_id_status =
                response.data.objects[key].fields.studentmission_id_status;
            returnValue.studentmission_id_friendlyname =
                response.data.objects[key].fields.studentmission_id_friendlyname;
            returnValue.studyagreement_id = response.data.objects[key].fields.studyagreement_id;
            returnValue.studyagreement_id_status =
                response.data.objects[key].fields.studyagreement_id_status;
            returnValue.studyagreement_id_friendlyname =
                response.data.objects[key].fields.studyagreement_id_friendlyname;
            returnValue.city = response.data.objects[key].fields.company_id_city;
            returnValue.address = response.data.objects[key].fields.company_id_address;
            returnValue.work_type = response.data.objects[key].fields.work_type;
            returnValue.mission_type = response.data.objects[key].fields.mission_type;
            returnValue.je = response.data.objects[key].fields.je;
            returnValue.price_ht = response.data.objects[key].fields.price_ht;
            returnValue.study_levels =
                response.data.objects[key].fields.study_levels != '' &&
                JSON.parse(response.data.objects[key].fields.study_levels);
            returnValue.main_skills =
                response.data.objects[key].fields.main_skills != '' &&
                JSON.parse(response.data.objects[key].fields.main_skills);
            returnValue.secondary_skills =
                response.data.objects[key].fields.secondary_skills != '' &&
                JSON.parse(response.data.objects[key].fields.secondary_skills);
            returnValue.documents_list = response.data.objects[key].fields.documents_list;
            returnValue.students_list = response.data.objects[key].fields.students_list;
            returnValue.terms_list = response.data.objects[key].fields.terms_list;
        });
    }

    return returnValue;
};
const upsert = async (key, fields, output_fields) => {
    let returnValue = false;

    // BUILD POST PARAMS
    let data = {
        operation: 'core/create',
        comment: "Création par l'agence",
        class: 'Mission',
        fields: fields,
        output_fields: output_fields,
    };

    // UPDATE CASE
    if (key) {
        data.operation = 'core/update';
        data.key = key;
    }

    // BUILD PARAMS ARRAY
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // RETURN WRITTEN OBJECT
    Object.keys(response.data.objects).forEach(key => {
        returnValue = response.data.objects[key];
    });

    return returnValue;
};
const MissionDataService = {
    getAll,
    get,
    upsert,
};
export default MissionDataService;
