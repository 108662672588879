import './list.css';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Sidebar from '../Backend/Sidebar/Sidebar';
import OrganizationDataService from '../../services/organization.service';

export default function AssociationList(props) {
    const [data, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                // if item['status'] == 'pending' then display them first
                item[k] !== null &&
                typeof item[k] !== 'object' &&
                typeof item[k] !== 'number' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    const [editMode, setEditMode] = useState(0);

    useEffect(() => {
        const key = 'SELECT Association';
        const fields = 'id, friendlyname, school_name, started_at, persons_list';
        OrganizationDataService.getAll(key, fields)
            .then(response => {
                response.sort((a, b) => {
                    if (a.status === 'pending') {
                        return -1;
                    }
                    if (b.status === 'pending') {
                        return 1;
                    }
                    return 0;
                });
                setData(response);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    const convertArrayOfObjectsToCSV = array => {
        let result;
        let exportableFields = [
            'name',
            'school_name',
            'nb_student',
            'nb_ongoing_missions',
            'nb_terminated_missions',
        ];
        let translatedFields = [
            'Nom',
            'Ecole',
            'Domaine',
            'Nombre de membres',
            'Missions en cours',
            'Missions terminées',
        ];

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = exportableFields;

        result = '';
        result += translatedFields.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;
                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    const columns = [
        {
            name: 'Nom',
            cell: row => (
                <Link
                    to={'/association/' + row.id + '/profil'}
                    className={
                        row.status === 'pending' ? 'red-value list-table-link' : 'list-table-link'
                    }>
                    {row.name}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Ecole',
            selector: row => row.school_name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Domaine',
            selector: row => row.domain.charAt(0).toUpperCase() + row.domain.slice(1),
            filterable: true,
            sortable: true,
            width: '10vw',
        },
        {
            name: 'Nombre de membres',
            selector: row => row.nb_student,
            filterable: true,
            sortable: true,
            width: '11vw',
        },
        {
            name: 'Missions en cours',
            selector: row => row.nb_ongoing_missions,
            filterable: true,
            sortable: true,
            width: '10vw',
        },
        {
            name: 'Missions terminées',
            selector: row => row.nb_terminated_missions,
            filterable: true,
            sortable: true,
            width: '10vw',
        },
    ];

    return (
        <div
            className='backend-container'
            onClick={() => {
                if (editMode === 1) {
                    setEditMode(0);
                }
            }}>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>Associations</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <p className='list-container-subtitle'>
                        Liste de toutes les associations du réseau Jobers.
                    </p>
                </div>
                <div className='list-flex-child list-flex-container'>
                    <div className='list-container-searchbar searchbar-with-button'>
                        <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                        <input
                            id='search'
                            className='list-container-searchinput'
                            type='text'
                            placeholder='Rechercher'
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                        />
                    </div>
                    {editMode < 2 && (
                        <div className='profil-container-wipbutton'>
                            <span className='list-container-addlabel'>
                                <a
                                    onClick={() => {
                                        editMode === 1 ? setEditMode(0) : setEditMode(1);
                                    }}>
                                    +
                                </a>
                            </span>
                        </div>
                    )}
                    {editMode == 1 && (
                        <div className='more_actions'>
                            <div
                                className='more_actions_action'
                                onClick={() => {
                                    downloadCSV(filteredItems);
                                }}>
                                {/* display ham-menu icon */}
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                <div className='more_actions_item'>Exporter</div>
                            </div>
                            <div className='more_actions_action'>
                                {/* display ham-menu icon */}
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                <div className='more_actions_item disabled'>Filtrer</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-container-divider'></div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucune association trouvée.</span>
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    );
}
