import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Img from '../RenderImg/Render';
import ImgSign from '../RenderImg/RenderSign';
import './FactureOne.css';

import DocumentDataService from '../../../../services/document.service';

export default function Facture() {
    const { id } = useParams();
    const [initialObjectState, setInitialObjectState] = useState(null);

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                started_at: '13/09/2022',
                company: {
                    name: 'Test Alan',
                    siret: '1098 765 4321',
                    tva: '145789932555',
                    address: 'Place de la mairie',
                    postcode: 72000,
                    city: 'Le mans',
                    contact: 'Juste Leblanc',
                },
                association: {
                    name: 'Architectonic',
                    siret: '1234 567 8910',
                    address: 'Place de la mairie',
                    postcode: 61000,
                    city: 'Alençon',
                    contact: 'Marlène Sasseur',
                },
                student: {
                    name: 'Alan BADIER',
                    address: 'Pas place de la mairie',
                    postcode: 33000,
                    city: 'Bordeaux',
                    ssn: '2 34 93 0000000',
                },
                mission: {
                    start_date: '13/09/2022',
                    end_date: '21/01/2023',
                    name: 'Création de plans',
                    je: 15,
                    price_ht: 250,
                    total_ht: 15 * 250,
                    tva: 15 * 250 * 0.2,
                    total_ttc: 15 * 250 + 15 * 250 * 0.2,
                    student_price: 100,
                },
            };
            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (id) {
                DocumentDataService.get(
                    'SELECT DocumentNote WHERE documenttype_name = "Facture" AND id = ' + id
                ).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    setInitialObjectState(objectData.description);
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);
    return (
        <div className='container'>
            <div className='container-page'>
                <div className='content-line'>
                    <div className='green-line-one'></div>
                    <div className='green-line-two'></div>
                </div>
                <div className='header'>
                    <div className='content-head-info'>
                        <div className='content-logo'>
                            <Img />
                        </div>
                        <div className='content-address'>
                            <div className='content-text-address'>
                                <p>
                                    <span className='bolder'>Adresse : </span>254 boulevard Raspail
                                </p>
                                <p>
                                    <span className='bolder'>CP Ville : </span>75014 PARIS
                                </p>
                                <p>
                                    <span className='bolder'>Téléphone : </span>01 40 47 04 04
                                </p>
                                <p>
                                    <span className='bolder'>Mail : </span>aeresa@jobers.f
                                </p>
                            </div>
                        </div>
                        <div className='content-siret'>
                            <div className='content-text-siret'>
                                <p>
                                    <span className='bolder'>SIRET : </span>334 077 260 00019
                                </p>
                                <p>
                                    <span className='bolder'>TVA : </span>FR50334077260
                                </p>
                                <p>
                                    <span className='bolder'>Banque : </span>CCM Versailles St Louis
                                </p>
                                <p>
                                    <span className='bolder'>IBAN : </span>FR76 1027 8060 3000 0204
                                    1460 176
                                </p>
                                <p>
                                    <span className='bolder'>BIC </span>CMCIFR2A
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {initialObjectState && (
                    <div className='content-title'>
                        <p className='bill-title'>FACTURE N° {initialObjectState.name}</p>
                    </div>
                )}

                <div className='content-body'>
                    <div className='margin-body'>
                        <div className='main-date'>
                            <div className='content-date'>
                                {initialObjectState && (
                                    <p>
                                        <span className='bolder'>Période du: </span>
                                        {initialObjectState.mission.start_date} au{' '}
                                        {initialObjectState.mission.end_date}
                                    </p>
                                )}
                                {initialObjectState && (
                                    <p>
                                        <span className='bolder'>Réalisé par: </span>
                                        {initialObjectState.student.name}
                                    </p>
                                )}
                                {initialObjectState && (
                                    <p>
                                        <span className='bolder'>Pour le Client: </span>
                                        {initialObjectState.company.name}
                                    </p>
                                )}

                                {initialObjectState && (
                                    <p>
                                        <span className='bolder'>SIRET du Client: </span>
                                        {initialObjectState.company.siret}
                                    </p>
                                )}

                                {initialObjectState && (
                                    <p>
                                        <span className='bolder'>N° TVA du Client: </span>
                                        {initialObjectState.company.tva}
                                    </p>
                                )}
                            </div>
                            <div className='content-details'>
                                <div className='detail-title'>COORDONNÉES</div>
                                <div className='detail-text'>
                                    <div className='detail-text-accept bolder'>
                                        {initialObjectState && (
                                            <p>{initialObjectState.company.name}</p>
                                        )}
                                        {initialObjectState && (
                                            <p>{initialObjectState.company.address}</p>
                                        )}
                                        {initialObjectState && (
                                            <p>
                                                {initialObjectState.company.postcode}{' '}
                                                {initialObjectState.company.city}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {initialObjectState && (
                            <div className='dateOfDay-accept'>
                                <p className='bolder'>PARIS, le {initialObjectState.started_at}</p>
                            </div>
                        )}
                        <table className='content-table'>
                            <thead>
                                <tr className='head-table'>
                                    <th colSpan='3'>NOMBRE DE JOURS ÉTUDE</th>
                                    <th colSpan='3'>FORFAIT JE HT</th>
                                    <th colSpan='3'>MONTANT HT</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                <tr className='underline-price'>
                                    {initialObjectState && (
                                        <td colSpan='3'>{initialObjectState.mission.je}</td>
                                    )}
                                    {initialObjectState && (
                                        <td colSpan='3'>{initialObjectState.mission.price_ht}</td>
                                    )}
                                    {initialObjectState && (
                                        <td colSpan='3'>{initialObjectState.mission.total_ht}</td>
                                    )}
                                </tr>
                                <tr>
                                    <td colSpan='3'></td>
                                    <td colSpan='3'>TOTAL HT</td>
                                    {initialObjectState && (
                                        <td colSpan='3'>{initialObjectState.mission.total_ht}</td>
                                    )}
                                </tr>
                                <tr>
                                    <td colSpan='3'></td>
                                    <td colSpan='3'>TVA 20,00%</td>
                                    {initialObjectState && (
                                        <td colSpan='3'>{initialObjectState.mission.tva}</td>
                                    )}
                                </tr>
                                <tr className='content-price'>
                                    <td colSpan='3'></td>
                                    <td className='text-price' colSpan='3'>
                                        MONTANT TOTAL TTC
                                    </td>
                                    {initialObjectState && (
                                        <td className='total-price' colSpan='3'>
                                            {initialObjectState.mission.total_ttc}
                                        </td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <div className='content-reminder'>
                            <div className='reminder-title'>
                                <span className='border-right-reminder'>RAPPEL</span>
                            </div>
                            <p>
                                Paiement à réception de la facture par virement Aucun paiement par
                                chèque n'est accepté Libellé du virement : N° de facture + Nom de
                                l'Etudiant + Mois de prestation <br />
                                Tout retard de paiement pourra donner lieu à des pénalités de retard
                                exigibles sans rappel, au taux de 10% de la facture totale par mois
                                de retard (Lutte contre les retards de paiement / article 53 de la
                                Loi NRE), ainsi qu'à une indemnité forfaitaire de 40€ (C. Com. art.
                                D441-5). <br />
                                Paiements par virement bancaire
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
