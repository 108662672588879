import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import CompanyDataService from '../../services/company.service';

export default function EntrepriseList(props) {
    const [data, setData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const currentUser = useAuthState();
    const [editMode, setEditMode] = useState(0);
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );

    useEffect(() => {
        let key = 'SELECT Company';

        if (currentUser.function === 'Student') {
            key = 'SELECT Company WHERE status != "pending"';
        }

        CompanyDataService.getAll(key)
            .then(response => {
                response.sort((a, b) => {
                    if (a.status === 'pending') {
                        return -1;
                    }
                    if (b.status === 'pending') {
                        return 1;
                    }
                    return 0;
                });
                setData(response);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    const convertArrayOfObjectsToCSV = array => {
        let result;
        let exportableFields = ['name', 'location_city', 'domain', 'started_at'];
        let translatedFields = ['Raison sociale', 'Ville', 'Domaine', "Date d'inscription"];

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = exportableFields;

        result = '';
        result += translatedFields.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                let value = item[key];
                if (key === 'start_date' || key === 'end_date' || key === 'created_at') {
                    value = moment(item[key]).format('DD-MM-YYYY');
                }
                if (key === 'domain') {
                    value =
                        item[key] != ''
                            ? item[key].charAt(0).toUpperCase() + item[key].slice(1)
                            : '';
                }

                result += value;

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    let columns = [
        {
            name: 'Raison sociale',
            cell: row =>
                currentUser.function !== 'Student' ? (
                    <Link className='list-table-link' to={'/entreprise/' + row.id + '/profil'}>
                        {row.name}
                    </Link>
                ) : (
                    row.name
                ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Ville',
            selector: row => row.location_city,
            filterable: true,
            sortable: true,
            width: '20vw',
        },
        {
            name: 'Domaine',
            cell: row => row.domain && row.domain.charAt(0).toUpperCase() + row.domain.slice(1),
            filterable: true,
            sortable: true,
            width: '8vw',
            sortFunction: (a, b) => {
                if (a.domain < b.domain) {
                    return -1;
                }
                if (a.domain > b.domain) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: "Date d'inscription",
            selector: row => moment(row.started_at).format('DD-MM-YYYY'),
            filterable: true,
            sortable: true,
            width: '10vw',
        },
    ];

    if (currentUser.function === 'Jobers') {
        // Add status column if user is Jobers
        columns.push({
            name: 'Statut',
            cell: row => (
                <span className={row.status === 'pending' ? 'red-value' : ''}>
                    {row.display_status}
                </span>
            ),
            filterable: true,
            sortable: true,
            width: '10vw',
            sortFunction: (a, b) => {
                if (a.display_status < b.display_status) {
                    return -1;
                }
                if (a.display_status > b.display_status) {
                    return 1;
                }
                return 0;
            },
        });
    }

    return (
        <div
            className='backend-container'
            onClick={() => {
                if (editMode === 1) {
                    setEditMode(0);
                }
            }}>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>Entreprises</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <p className='list-container-subtitle'>
                        Liste de toutes les entreprises du réseau Jobers.
                    </p>
                </div>
                <div className='list-flex-child list-flex-container'>
                    <div
                        className={
                            currentUser.function == 'Student'
                                ? 'list-container-searchbar'
                                : 'list-container-searchbar searchbar-with-button'
                        }>
                        <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                        <input
                            id='search'
                            className='list-container-searchinput'
                            type='text'
                            placeholder='Rechercher'
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                        />
                    </div>
                    {editMode < 2 && currentUser.function !== 'Student' && (
                        <div className='profil-container-wipbutton'>
                            <span className='list-container-addlabel'>
                                <a
                                    onClick={() => {
                                        editMode === 1 ? setEditMode(0) : setEditMode(1);
                                    }}>
                                    +
                                </a>
                            </span>
                        </div>
                    )}
                    {editMode == 1 && (
                        <div className='more_actions'>
                            <div
                                className='more_actions_action'
                                onClick={() => {
                                    downloadCSV(filteredItems);
                                }}>
                                {/* display ham-menu icon */}
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                <div className='more_actions_item'>Exporter</div>
                            </div>
                            <div
                                className='more_actions_action'
                                // onClick={() => setEditMode(2)}
                            >
                                {/* display ham-menu icon */}
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                <div className='more_actions_item disabled'>Filtrer</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-container-divider'></div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={<span className='rdt_noresults'>Chargement...</span>}
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    );
}
