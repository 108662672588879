import React from 'react';
import Association from '../Icon/Association';
import Etudiant from '../Icon/Etudiant';
import Entreprise from '../Icon/Entreprise';
import Mission from '../Icon/Mission';
import CreerMission from '../Icon/CreerMission';
import Compta from '../Icon/Compta';
import Statistique from '../Icon/Statistique';

export const CompanyData = [
    {
        title: 'Mon entreprise',
        icon: <Entreprise />,
        link: '/entreprise/me/profil',
    },
    {
        title: 'Réseau étudiant',
        icon: <Etudiant />,
        link: '/etudiant/list',
    },
    {
        title: 'Proposer un Job',
        icon: <CreerMission />,
        link: '/mission/new',
    },
    {
        title: 'Mes missions',
        icon: <Mission />,
        link: '/mission/list',
    },
    {
        title: 'Statistiques',
        icon: <Statistique />,
        link: '/entreprise/me/statistique',
    },
];
