import './RegisterStudentForm.css';
import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';

export default function StudentFormConfirmation() {
    const StudentFormConfirmation = () => {
        return (
            <div className='register-organization-submit screen'>
                <div className='content'>
                    <div className='header'>
                        <Logo />
                        <div className='text-and-supporting-text'>
                            <div className='text'>
                                Un peu de patience, votre dossier sera bientôt validé
                            </div>
                            <p className='supporting-text'>
                                Nous devons vérifier l'exactitude des informations complétées dans
                                votre formulaire.
                            </p>
                        </div>
                    </div>
                    <Link to='/'>
                        <button type='submit' className='primary-submit'>
                            Revenir à l'accueil
                        </button>
                    </Link>
                </div>
            </div>
        );
    };

    return (
        <div className='container-center-horizontal'>
            <StudentFormConfirmation />
        </div>
    );
}
