import '../../../RegisterOrganizationForm/RegisterOrganizationForm.css';
import './OrganizationEdit.css';

import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { useAuthState } from '../../../../context/context';

import Form from '../../../Utils/Form';
import Input from '../../../Utils/Input';

import OrganizationDataService from '../../../../services/organization.service';
import PersonDataService from '../../../../services/person.service';
import Sidebar from '../../Sidebar/Sidebar';

const OrganizationEdit = props => {
    let { id } = useParams();
    const currentUser = useAuthState();

    const [initialObjectState, setInitialObjectState] = useState(null);
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });

    const [submitted, setSubmitted] = useState(false);
    const [contact, setContact] = useState('president');
    const [selfEdit, setSelfEdit] = useState(false);

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                name: '',
                status: 'pending',
                siret: '',
                tva: '',
                urssaf: '',
                ape: '',
                bank: '',
                iban: '',
                bic: '',
                school_id: '',
                contact_id: 0,
                first_name: '',
                last_name: '',
                email: '',
                phonenumber: '',
                persons_list: [],
            };
            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (id) {
                let fields = 'id, name, status, school_name, siret, tva, persons_list';
                let key = id;
                OrganizationDataService.get(key)
                    .then(response => {
                        objectData = {
                            ...objectData,
                            ...response,
                        };
                    })
                    .then(() => {
                        key = 'SELECT Person WHERE org_id = ' + objectData.id;
                        fields = 'id, name, first_name, phone, email, function';
                        PersonDataService.getAll(key, fields).then(response => {
                            objectData.persons_list = response;

                            Object.keys(objectData.persons_list).forEach(key => {
                                if (objectData.persons_list[key].function != 'Student') {
                                    objectData.contact_id = objectData.persons_list[key].id;
                                    objectData.first_name = objectData.persons_list[key].first_name;
                                    objectData.last_name = objectData.persons_list[key].name;
                                    objectData.email = objectData.persons_list[key].email;
                                    objectData.phonenumber = objectData.persons_list[key].phone;
                                }
                            });

                            setInitialObjectState(objectData);
                        });
                    });
            }
        };

        if (typeof props.id !== 'undefined' && props.id === 'me') {
            id =
                'SELECT Association AS A JOIN Person AS P ON P.org_id = A.id WHERE P.id = ' +
                currentUser.id;
            setSelfEdit(true);
        }

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState, selfEdit]);

    async function createPerson(iOrg, aFields) {
        try {
            let fields = {
                org_id: iOrg,
                name: aFields.last_name.value,
                first_name: aFields.first_name.value,
                email: aFields.email.value,
                phone: aFields.phonenumber.value,
                status: 'active',
                function: 'Président',
            };
            let output_fields = 'id, friendlyname';
            let key = initialObjectState.contact_id;

            PersonDataService.upsert(key, fields, output_fields).then(response => {
                if ((response.code = '0')) {
                    return true;
                }
            });
        } catch (err) {
            console.log(err.message);
        }
    }

    async function createOrganization(formFields) {
        try {
            let fields = {
                parent_id: 'SELECT Organization WHERE name = "Jobers"',
                name: formFields.name.value,
                siret: formFields.siret.value,
                tva: formFields.tva.value,
                urssaf: formFields.urssaf.value,
                ape: formFields.ape.value,
                bank: formFields.bank.value,
                iban: formFields.iban.value,
                bic: formFields.bic.value,
                school_name: formFields.school_id.value,
            };

            if (typeof newAssociationLogo.logo[0] !== 'undefined') {
                let data = await convertBase64(newAssociationLogo.logo[0]);
                data = data.substring(data.indexOf('base64,') + 7);

                fields.picture = {
                    data: data,
                    filename: newAssociationLogo.logo[0].name,
                    mimetype: newAssociationLogo.logo[0].type,
                };
            }

            if (currentUser.function === 'Jobers') {
                fields.status = formFields.status.value;
            }

            let output_fields = 'id, friendlyname';

            OrganizationDataService.upsert(id, fields, output_fields).then(response => {
                if ((response.code = '0')) {
                    createPerson(response.key, formFields);
                }
            });
        } catch (err) {
            console.log(err.message);
        }
    }

    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    const onSubmit = (fields, errors) => {
        setFormData({
            fields,
            errors,
        });
        createOrganization(fields);
        setSubmitted(true);
    };

    const handleChange = event => {
        setContact(event.target[event.target.selectedIndex].value);
    };

    const [newAssociationLogo, setNewAssociationLogo] = useState({
        logo: {},
    });

    const updateUploadedFiles = files => {
        setNewAssociationLogo({ ...newAssociationLogo, logo: files });
    };

    return (
        <div className='missionView screen'>
            <div className='flex-col'>
                <Sidebar />
            </div>
            <div className='flex-col'>
                <div className='flex-row'>
                    <div className='frame-1'>
                        {initialObjectState && initialObjectState.picture.filename.length > 0 && (
                            <div className='frame-2' key={initialObjectState.picture.filename}>
                                <img
                                    width={'50'}
                                    height={'50'}
                                    src={
                                        'data:' +
                                        initialObjectState.picture.mimetype +
                                        ';base64,' +
                                        initialObjectState.picture.data
                                    }
                                />
                            </div>
                        )}
                        {initialObjectState && (
                            <div className='text-10'>{initialObjectState.name}</div>
                        )}
                    </div>
                </div>
                <div className='flex-row'></div>
                <div className='flex-row'>
                    {initialObjectState && (
                        <Form
                            className='form'
                            onSubmit={onSubmit}
                            fields={formData.fields}
                            errors={formData.errors}
                            validateOnSubmit={true}>
                            <Input
                                accept='.jpg,.png,.jpeg,.pdf'
                                label='Logo de l’entreprise'
                                updateFilesCb={updateUploadedFiles}
                                id='logo'
                                type='file'
                                files={newAssociationLogo.logo}
                            />
                            <Input
                                id='name'
                                label='Nom de l’association'
                                placeholder='Nom de l’association'
                                type='autocomplete'
                                name='name'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.name
                                        : ''
                                }
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                                suggestions={[
                                    'AERESA',
                                    'TESAA',
                                    'ARCHITEC’TONIC',
                                    'ARCHI’MADE',
                                    'CUTCH',
                                    'ARCHIPEL',
                                    'JCTPE',
                                    'PREALAB',
                                    'GRAINE D’ARCHI',
                                    'ESQUISSE',
                                    'ASSO B',
                                    'WIP',
                                    'JAUB',
                                    'PASSERELLE V',
                                    'TRAIT D’UNION',
                                    'JAFAAR',
                                    'VILLEBREQUIN',
                                    'RHIZOME',
                                ]}
                            />
                            <Input
                                id='school_id'
                                label='École'
                                placeholder='Nom de l’école'
                                type='autocomplete'
                                name='school_id'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.school_id
                                        : ''
                                }
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                                suggestions={[
                                    'Paris ESA',
                                    'Strasbourg',
                                    'Versailles',
                                    'Bordeaux',
                                    'Clermont-Ferrand',
                                    'Grenoble',
                                    'Lille',
                                    'Lyon',
                                    'Marne la Vallée',
                                    'Marseille',
                                    'Montpellier',
                                    'Nancy',
                                    'Nantes',
                                    'Normandie',
                                    'Paris Belleville',
                                    'Paris Malaquais',
                                    'Paris Val de Seine',
                                    'Paris Villette',
                                    'Rennes',
                                    'Rouen',
                                    'St Etienne',
                                    'Toulouse',
                                ]}
                            />
                            {currentUser.function === 'Jobers' && (
                                <Input
                                    id='status'
                                    label='Statut'
                                    defaultValue='pending'
                                    value={
                                        initialObjectState && id !== 'undefined'
                                            ? initialObjectState.status
                                            : ''
                                    }
                                    type='select'
                                    options={[
                                        { value: 'active', label: 'Active' },
                                        { value: 'inactive', label: 'Inactive' },
                                        { value: 'pending', label: 'En attente' },
                                    ]}
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                            )}
                            <Input
                                id='siret'
                                label='Numéro de SIRET'
                                placeholder='11122233300000'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.siret
                                        : ''
                                }
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='tva'
                                label='Numéro de TVA intracommunautaire'
                                placeholder='FR 32 123456789'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.tva
                                        : ''
                                }
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='urssaf'
                                label='URSSAF'
                                placeholder='3698'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.urssaf
                                        : ''
                                }
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='ape'
                                label='Code NAF ou APE'
                                placeholder='9499Z'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.ape
                                        : ''
                                }
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='bank'
                                label='Banque'
                                placeholder='Nom de votre banque'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.bank
                                        : ''
                                }
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='iban'
                                label='IBAN'
                                placeholder='FR7630003035409876543210925'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.iban
                                        : ''
                                }
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='bic'
                                label='BIC'
                                placeholder='SOGEFRPP'
                                value={
                                    initialObjectState && id !== 'undefined'
                                        ? initialObjectState.bic
                                        : ''
                                }
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                            />
                            <Input
                                id='contact_level'
                                label='Information de contact'
                                defaultValue='president'
                                type='select'
                                options={[
                                    { value: 'president', label: 'Président' },
                                    { value: 'secretary', label: 'Secrétaire' },
                                    { value: 'treasurer', label: 'Trésorier' },
                                ]}
                                validate='required'
                                classes={{
                                    contClass: 'input-field',
                                    errorClass: 'error-text',
                                    fieldClass:
                                        'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                }}
                                events={{
                                    onChange: handleChange,
                                }}
                            />
                            <div className='contact-container'>
                                <div className='div-2-col'>
                                    <Input
                                        id='last_name'
                                        label='Nom'
                                        placeholder='Nom'
                                        value={
                                            initialObjectState && id !== 'undefined'
                                                ? initialObjectState.last_name
                                                : ''
                                        }
                                        validate='required'
                                        classes={{
                                            contClass: 'input-col-1',
                                            errorClass: 'error-text',
                                            fieldClass:
                                                'input-1col border-1px-mischka inter-normal-mirage-16px',
                                        }}
                                    />
                                    <Input
                                        id='first_name'
                                        label='Prénom'
                                        placeholder='Prénom'
                                        value={
                                            initialObjectState && id !== 'undefined'
                                                ? initialObjectState.first_name
                                                : ''
                                        }
                                        validate='required'
                                        classes={{
                                            contClass: 'input-col-1',
                                            errorClass: 'error-text',
                                            fieldClass:
                                                'input-1col border-1px-mischka inter-normal-mirage-16px',
                                        }}
                                    />
                                </div>
                                <Input
                                    id='email'
                                    label='Adresse email'
                                    placeholder='contact@asso.fr'
                                    value={
                                        initialObjectState && id !== 'undefined'
                                            ? initialObjectState.email
                                            : ''
                                    }
                                    validate='required|email'
                                    classes={{
                                        contClass: 'input-field',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                                <Input
                                    id='phonenumber'
                                    label='Numéro de téléphone'
                                    placeholder='+33 6 11 22 33 44'
                                    value={
                                        initialObjectState && id !== 'undefined'
                                            ? initialObjectState.phonenumber
                                            : ''
                                    }
                                    validate='required|phone'
                                    classes={{
                                        contClass: 'input-field',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                                <div className='bottom-actions'>
                                    <button type='submit' className='primary-submit'>
                                        {initialObjectState && id !== 'undefined'
                                            ? "Mettre à jour l'association"
                                            : "Créer l'association"}
                                    </button>
                                    {submitted && currentUser.function === 'Jobers' && (
                                        <Navigate to='/listAssociation' replace={true} />
                                    )}
                                    {submitted && currentUser.function === 'Président' && (
                                        <Navigate to='/association/me' replace={true} />
                                    )}
                                </div>
                                <div className='flex-row'></div>
                            </div>
                        </Form>
                    )}
                </div>
            </div>
        </div>
    );
};
export default OrganizationEdit;
