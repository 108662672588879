import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

import Form from '../Utils/Form';
import Input2 from '../Utils/Input2';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';

import OrganizationDataService from '../../services/organization.service';
import AttachmentDataService from '../../services/attachment.service';
import StudentDataService from '../../services/student.service';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const FileUpload = ({
    label,
    item_id,
    type,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const currentUser = useAuthState();
    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    const sendFiles = async files => {
        let data = false;
        let b64 = false;
        let attachment = false;
        let filename = false;
        let operation = type == 'Signature' ? 'add_association_document' : 'add_association_logo';

        if (files.length > 0) {
            let file = files[0];

            filename = type == 'Signature' ? 'signature.' : 'logo.';
            if (otherProps.filename) {
                filename = otherProps.filename;
            }

            filename = filename + file.name.split('.').pop();
            b64 = await convertBase64(file);
            data = b64.substring(b64.indexOf('base64,') + 7);
            attachment = {
                item_class: 'Association',
                item_id: item_id,
                temp_id: filename,
                contents: {
                    data: data,
                    filename: filename,
                    mimetype: file.type,
                },
            };
        }

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(attachment));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        return await axios(config);
    };

    const handleNewFileUpload = async e => {
        const { files: newFiles } = e.target;
        if (newFiles.length > 0) {
            let response = await sendFiles(newFiles);
            if (response.data.code === 0) {
                // window.location.reload(false);
            }
        }
    };

    return (
        <div className='list-flex-child profil-flex-container'>
            <div className='profil-flex-child'>
                <div className='profil-label'>{label}</div>
            </div>
            <div className='profil-flex-child' onClick={e => handleUploadBtnClick(e)}>
                <div className='profil-value list-table-link'>
                    {type == 'Signature' ? '(Re)charger signature' : '(Re)charger logo'}
                </div>
            </div>
            <input
                ref={fileInputField}
                onChange={handleNewFileUpload}
                {...otherProps}
                type='file'
            />
        </div>
    );
};

export default function AssociationProfil(props) {
    const [tabClass, setTabClass] = useState('profil-container-divider-1');
    const [initialObjectState, setInitialObjectState] = useState(null);
    const [editMode, setEditMode] = useState(0);
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });

    const { id } = useParams();
    const currentUser = useAuthState();

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                name: 'Association test',
                school_id: 'Ecole',
                started_at: '2022-01-01',
                domain: 'Paysage',
                facebook: 'https://www.facebook.com/',
                instagram: 'https://www.instagram.com/',
                siret: '123 568 941 00056',
                tva: 'FR 32 123456789',
                urssaf: '3698',
                ape: '9499Z',
                bank: 'Société générale',
                signature: false,
                iban: 'FR7630003035409876543210925',
                bic: 'SOGEFRPP',
                contact: 'Jean Doe',
            };
            if (typeof currentUser.id === '0') {
                setInitialObjectState(objectData);
            }
            let key = null;
            if (id !== '0' && id !== 'me') {
                key = id;
            }
            if (currentUser.id !== '0' && props.id == 'me') {
                key =
                    'SELECT Association AS A JOIN Person AS P ON P.org_id = A.id WHERE P.id = ' +
                    currentUser.id;
            }
            if (currentUser.id !== '0') {
                OrganizationDataService.get(key).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    objectData.email = '';
                    objectData.phone = '';
                    objectData.address = '';
                    objectData.postalcode = '';
                    objectData.city = '';
                    objectData.country = '';
                    objectData.p_fullname = '';
                    objectData.p_email = '';
                    objectData.p_phone = '';
                    objectData.s_fullname = '';
                    objectData.s_email = '';
                    objectData.s_phone = '';
                    objectData.t_fullname = '';
                    objectData.t_email = '';
                    objectData.t_phone = '';

                    objectData.contact = currentUser.userDetails;

                    let subkey =
                        'SELECT Person WHERE function IN ("Compte association", "Président", "Secrétaire", "Trésorier") AND org_id = ' +
                        objectData.id;
                    StudentDataService.getAll(subkey).then(members => {
                        members.forEach(member => {
                            console.log(member);
                            if (member.function == 'Compte association') {
                                objectData.email = member.email;
                                objectData.phone = member.phone;
                                objectData.address = member.address;
                                objectData.postalcode = member.postcode;
                                objectData.city = member.city;
                                objectData.country = member.country;
                            }
                            if (member.function == 'Président') {
                                objectData.p_first_name = member.first_name;
                                objectData.p_last_name = member.name;
                                objectData.p_fullname =
                                    objectData.p_first_name + ' ' + objectData.p_last_name;
                                objectData.p_email = member.email;
                                objectData.p_phone = member.phone;
                            }
                            if (member.function == 'Secrétaire') {
                                objectData.s_first_name = member.first_name;
                                objectData.s_last_name = member.name;
                                objectData.s_fullname =
                                    objectData.s_first_name + ' ' + objectData.s_last_name;
                                objectData.s_email = member.email;
                                objectData.s_phone = member.phone;
                            }
                            if (member.function == 'Trésorier') {
                                objectData.t_first_name = member.first_name;
                                objectData.t_last_name = member.name;
                                objectData.t_fullname =
                                    objectData.t_first_name + ' ' + objectData.t_last_name;
                                objectData.t_email = member.email;
                                objectData.t_phone = member.phone;
                            }
                        });

                        AttachmentDataService.getAll(
                            'SELECT Attachment WHERE item_class = "Association" AND item_id = ' +
                                objectData.id,
                            'contents'
                        ).then(fileresponse => {
                            fileresponse.forEach(file => {
                                if (
                                    file.filename.includes('Signature ' + objectData.name) ||
                                    file.type === 'Signature'
                                ) {
                                    objectData.signature = file;
                                }
                            });

                            console.log(objectData);
                            setInitialObjectState(objectData);
                        });
                    });
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    const createDocumentLink = file => {
        if (file) {
            return (
                'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=display_document&filename=' +
                file.filename +
                '&id=' +
                file.id +
                '&obj=Association::' +
                initialObjectState.id
            );
        }
    };

    const onSubmit = async (fields, errors) => {
        setFormData({
            fields,
            errors,
        });

        let key = null;
        if (id !== '0' && id !== 'me') {
            key = id;
        }
        if (currentUser.id !== '0' && props.id == 'me') {
            key =
                'SELECT Association AS A JOIN Person AS P ON P.org_id = A.id WHERE P.id = ' +
                currentUser.id;
        }

        let aDataFields = {
            id: key,
            name: fields.name.value,
            // school_id: fields.school_id.value,
            // started_at: fields.started_at.value,
            // domain: fields.domain.value,
            facebook: fields.facebook.value,
            instagram: fields.instagram.value,
            urssaf: fields.urssaf.value,
            bank: fields.bank.value,
            ape: fields.ape.value,
            iban: fields.iban.value,
            tva: fields.tva.value,
            bic: fields.bic.value,
            siret: fields.siret.value,
            address: fields.address.value,
            postalcode: fields.postalcode.value,
            city: fields.city.value,
            email: fields.email.value,
            phone: fields.phone.value,
            p_fullname: fields.p_fullname.value,
            p_email: fields.p_email.value,
            p_phone: fields.p_phone.value,
            s_fullname: fields.s_fullname.value,
            s_email: fields.s_email.value,
            s_phone: fields.s_phone.value,
            t_fullname: fields.t_fullname.value,
            t_email: fields.t_email.value,
            t_phone: fields.t_phone.value,
        };

        let data = new URLSearchParams();
        data.append('operation', 'upsert_association');
        data.append('data', JSON.stringify(aDataFields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        let response = await axios(config);

        if (response.data.code === 0) {
            window.location.reload(false);
        }

        if (response.data.code !== 0) {
            if (response.data.message.includes('est déjà utilisée par un autre utilisateur')) {
                alert('Cette adresse email est déjà utilisée par un autre utilisateur');
            }
            console.log(response.data.message);
        }
    };

    const isAssociation = () => {
        if (currentUser.function === 'Compte association') {
            return true;
        }
        if (currentUser.function === 'Président') {
            return true;
        }
        return false;
    };

    const handleTab = className => {
        setTabClass(className);
    };

    const confirm = async e => {
        let aDataFields = {
            item_class: 'Association',
            item_id: initialObjectState.id,
        };
        let data = new URLSearchParams();
        data.append('operation', 'confirm_item');
        data.append('data', JSON.stringify(aDataFields));
        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };
        let response = await axios(config);
        if (response.data.code === 0) {
            window.location.reload(false);
        }
        if (response.data.code !== 0) {
            console.log(response.data.message);
        }
    };

    const displayField = (label, value, options) => {
        let readOnlyFields = ['started_at', 'domain', 'school_id'];
        let fileuploadfields = ['signature'];
        if (true) {
            if (editMode == 2 && fileuploadfields.indexOf(options.id) > -1) {
                if (options.id == 'signature') {
                    return (
                        <>
                            <FileUpload
                                item_id={initialObjectState.id}
                                type='Signature'
                                filename={'Signature ' + initialObjectState.name + '.'}
                                accept='.jpg,.png,.jpeg'
                                label='Signature'
                            />
                        </>
                    );
                }
            }
            if (editMode == 2 && readOnlyFields.indexOf(options.name) == -1) {
                return (
                    <>
                        <Input2 {...options} />
                    </>
                );
            }
            return (
                <>
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'>
                            <div className='profil-label'>{label}</div>
                        </div>
                        <div className='profil-flex-child'>
                            <div className='profil-value gray-value' title={value}>
                                {value}
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    };

    const downloadLogo = (filename, data) => {
        const image = new Image();
        if (data == null) return;
        image.src = data;

        var w = window.open('');
        w.document.write(image.outerHTML);
    };

    if (!initialObjectState) {
        return (
            <div className='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

    return (
        <div className='backend-container'>
            <Sidebar />
            <Form
                onSubmit={onSubmit}
                fields={formData.fields}
                errors={formData.errors}
                validateOnSubmit={true}>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    <div className='list-flex-child flex-4'>
                        {initialObjectState && (
                            <h1 className='list-container-title'>{initialObjectState.name}</h1>
                        )}
                    </div>
                </div>
                <div className='list-flex-container'>
                    {initialObjectState && id === null && (
                        <div className='list-flex-child flex-4 list-container-tabs'>
                            <Link
                                to={'#'}
                                className='profil-tabs'
                                onMouseOver={() => handleTab('profil-container-divider-1')}>
                                Profil
                            </Link>
                        </div>
                    )}
                    {(currentUser.function === 'Jobers' ||
                        (initialObjectState &&
                            currentUser.function !== 'Student' &&
                            currentUser.org_id === initialObjectState.id)) && (
                        <div className='list-flex-child flex-4 list-container-tabs'>
                            <Link
                                to={'/association/' + initialObjectState.id + '/profil'}
                                className='profil-tabs'
                                onMouseOver={() => handleTab('profil-container-divider-1')}>
                                Profil
                            </Link>
                            <Link
                                to={'/association/' + initialObjectState.id + '/mission'}
                                className='profil-tabs'>
                                Missions
                            </Link>
                            <Link
                                to={'/association/' + initialObjectState.id + '/document'}
                                className='profil-tabs'>
                                Documents
                            </Link>
                        </div>
                    )}
                    {!(
                        currentUser.function === 'Jobers' ||
                        (initialObjectState &&
                            currentUser.function !== 'Student' &&
                            currentUser.org_id === initialObjectState.id)
                    ) && (
                        <div className='list-flex-child flex-4 list-container-tabs'>
                            <Link
                                to={'/association/' + initialObjectState.id + '/profil'}
                                className='profil-tabs'
                                onMouseOver={() => handleTab('profil-container-divider-1')}>
                                Profil
                            </Link>
                        </div>
                    )}
                    <div className='list-flex-container'>
                        {editMode == 2 && (
                            <div className='profil-container-submitbutton'>
                                <span className='list-container-submitlabel'>
                                    <button type='submit' className='primary-submit'>
                                        {initialObjectState && id !== 'undefined'
                                            ? 'Mettre à jour'
                                            : 'Créer'}
                                    </button>
                                    {/* <Link to={'/association/' + id + '/profil'}>Enregistrer</Link> */}
                                </span>
                            </div>
                        )}
                        {(currentUser.function === 'Jobers' ||
                            (initialObjectState &&
                                currentUser.function !== 'Student' &&
                                currentUser.org_id === initialObjectState.id)) &&
                            editMode < 2 && (
                                <div className='profil-container-wipbutton'>
                                    <span className='list-container-addlabel'>
                                        <a
                                            onClick={() => {
                                                editMode === 1 ? setEditMode(0) : setEditMode(1);
                                            }}>
                                            +
                                        </a>
                                    </span>
                                </div>
                            )}
                        {editMode == 1 &&
                            currentUser.function === 'Jobers' &&
                            initialObjectState.status == 'pending' && (
                                <div className='more_actions'>
                                    <div className='more_actions_action' onClick={() => confirm()}>
                                        {/* display ham-menu icon */}
                                        <div className='more_actions_icon'>
                                            <img
                                                className='ham_menu'
                                                src='/img/ham-menu.svg'
                                                alt='more actions'
                                            />
                                        </div>
                                        <div className='more_actions_item'>Confirmer</div>
                                    </div>
                                </div>
                            )}
                        {editMode == 1 &&
                            (currentUser.function == 'Jobers' ||
                                currentUser.org_id == initialObjectState.id) && (
                                <div className='more_actions'>
                                    <div
                                        className='more_actions_action'
                                        onClick={() => setEditMode(2)}>
                                        {/* display ham-menu icon */}
                                        <div className='more_actions_icon'>
                                            <img
                                                className='ham_menu'
                                                src='/img/ham-menu.svg'
                                                alt='more actions'
                                            />
                                        </div>
                                        <div className='more_actions_item'>Modifier</div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    <div className={tabClass} />
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    <div className='list-flex-child'>
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Ces informations sont visibles par les membres du réseau Jobers.
                        </p>
                    </div>
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    {initialObjectState &&
                        displayField('Nom de l’association', initialObjectState.name, {
                            id: 'name',
                            name: 'name',
                            label: 'Nom de l’association',
                            placeholder: 'Nom de l’association',
                            type: 'autocomplete',
                            value: initialObjectState.name,
                            classes: {
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            },
                            suggestions: [
                                'AERESA',
                                'TESAA',
                                'ARCHITEC’TONIC',
                                'ARCHI’MADE',
                                'CUTCH',
                                'ARCHIPEL',
                                'JCTPE',
                                'PREALAB',
                                'GRAINE D’ARCHI',
                                'ESQUISSE',
                                'ASSO B',
                                'WIP',
                                'JAUB',
                                'PASSERELLE V',
                                'TRAIT D’UNION',
                                'JAFAAR',
                                'VILLEBREQUIN',
                                'RHIZOME',
                            ],
                        })}
                    {initialObjectState &&
                        displayField('École', initialObjectState.school_id, {
                            id: 'school_id',
                            name: 'school_id',
                            label: 'École',
                            placeholder: 'Nom de l’école',
                            type: 'autocomplete',
                            value: initialObjectState.school_id,
                            classes: {
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            },
                            suggestions: [
                                'Paris ESA',
                                'Strasbourg',
                                'Versailles',
                                'Bordeaux',
                                'Clermont-Ferrand',
                                'Grenoble',
                                'Lille',
                                'Lyon',
                                'Marne la Vallée',
                                'Marseille',
                                'Montpellier',
                                'Nancy',
                                'Nantes',
                                'Normandie',
                                'Paris Belleville',
                                'Paris Malaquais',
                                'Paris Val de Seine',
                                'Paris Villette',
                                'Rennes',
                                'Rouen',
                                'St Etienne',
                                'Toulouse',
                            ],
                        })}
                </div>
                <div
                    className='list-flex-container'
                    onClick={() => {
                        if (editMode === 1) {
                            setEditMode(0);
                        }
                    }}>
                    {initialObjectState &&
                        displayField(
                            "Date d'inscription",
                            moment(initialObjectState.started_at).format('DD-MM-YYYY'),
                            {
                                id: 'started_at',
                                name: 'started_at',
                                label: "Date d'inscription",
                                placeholder: '2022-01-01',
                                type: 'text',
                                value: moment(initialObjectState.started_at).format('DD-MM-YYYY'),
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            }
                        )}
                    {initialObjectState &&
                        displayField(
                            'Domaine',
                            initialObjectState.domain.charAt(0).toUpperCase() +
                                initialObjectState.domain.slice(1),
                            {
                                id: 'domain',
                                name: 'domain',
                                label: 'Domaine',
                                placeholder: 'Architecture',
                                type: 'select',
                                value:
                                    initialObjectState.domain.charAt(0).toUpperCase() +
                                    initialObjectState.domain.slice(1),
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                                options: [
                                    { value: 'architecture', label: 'Architecture' },
                                    { value: 'paysage', label: 'Paysage' },
                                    { value: 'commerce', label: 'Commerce' },
                                    { value: 'communication', label: 'Communication' },
                                    { value: 'ingenierie', label: 'Ingenierie' },
                                ],
                            }
                        )}
                </div>
                {(currentUser.function === 'Jobers' ||
                    (initialObjectState &&
                        currentUser.function !== 'Student' &&
                        currentUser.org_id === initialObjectState.id)) && (
                    <div
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Adresse', initialObjectState.address, {
                                    id: 'address',
                                    name: 'address',
                                    label: 'Adresse',
                                    placeholder: "Place de l'hotel de ville",
                                    type: 'text',
                                    value: initialObjectState.address,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('Email de connexion', initialObjectState.email, {
                                    id: 'email',
                                    name: 'email',
                                    label: 'Email de connexion',
                                    placeholder: 'xxxxx@gmail.com',
                                    type: 'text',
                                    value: initialObjectState.email,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Code postal', initialObjectState.postalcode, {
                                    id: 'postalcode',
                                    name: 'postalcode',
                                    label: 'Code postal',
                                    placeholder: '75000',
                                    type: 'text',
                                    value: initialObjectState.postalcode,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('Téléphone', initialObjectState.phone, {
                                    id: 'phone',
                                    name: 'phone',
                                    label: 'Téléphone',
                                    placeholder: '06 00 00 00 00',
                                    type: 'text',
                                    value: initialObjectState.phone,
                                    validate: 'phone',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Ville', initialObjectState.city, {
                                    id: 'city',
                                    name: 'city',
                                    label: 'Ville',
                                    placeholder: 'Paris',
                                    type: 'text',
                                    value: initialObjectState.city,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState && editMode !== 2 && (
                                <div className='list-flex-child profil-flex-container'>
                                    <div className='profil-flex-child'>
                                        <div className='profil-label'>Logo de l'association</div>
                                    </div>
                                    {initialObjectState &&
                                        initialObjectState.picture.filename.length === 0 && (
                                            <div className='profil-flex-child'>
                                                <div className='profil-value gray-value'>
                                                    Aucun document
                                                </div>
                                            </div>
                                        )}
                                    {initialObjectState &&
                                        initialObjectState.picture.filename.length > 0 && (
                                            <div id='logo_tooltip' className='profil-flex-child'>
                                                <div
                                                    className='profil-value list-table-link'
                                                    onClick={() =>
                                                        downloadLogo(
                                                            initialObjectState.picture.filename,
                                                            'data:' +
                                                                initialObjectState.picture
                                                                    .mimetype +
                                                                ';base64,' +
                                                                initialObjectState.picture.data
                                                        )
                                                    }>
                                                    {initialObjectState.picture.filename}
                                                </div>
                                                {/* <Tooltip
                                                    anchorId='logo_tooltip'
                                                    content={
                                                        <img
                                                            width='200px'
                                                            height='200px'
                                                            src={
                                                                'data:' +
                                                                initialObjectState.picture
                                                                    .mimetype +
                                                                ';base64,' +
                                                                initialObjectState.picture.data
                                                            }
                                                        />
                                                    }
                                                    place='left'
                                                /> */}
                                            </div>
                                        )}
                                </div>
                            )}
                            {initialObjectState && editMode === 2 && (
                                <FileUpload
                                    item_id={initialObjectState.id}
                                    type='Logo'
                                    filename='Logo.'
                                    accept='.jpg,.png,.jpeg'
                                    label="Logo de l'association"
                                />
                            )}
                        </div>
                    </div>
                )}
                {(currentUser.function === 'Jobers' ||
                    (initialObjectState &&
                        currentUser.function !== 'Student' &&
                        currentUser.org_id === initialObjectState.id)) && (
                    <div
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child'>
                                <p className='list-container-subtitle list-tab-subtitle'>
                                    Ces informations restent privées et ne sont visibles que par les
                                    admins Jobers.
                                </p>
                            </div>
                        </div>
                        {/* <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div> */}
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Président(e)', initialObjectState.p_fullname, {
                                    id: 'p_fullname',
                                    name: 'p_fullname',
                                    label: 'Président(e)',
                                    placeholder: 'John Doe',
                                    type: 'text',
                                    value: initialObjectState.p_fullname,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('Secrétaire', initialObjectState.s_fullname, {
                                    id: 's_fullname',
                                    name: 's_fullname',
                                    label: 'Secrétaire',
                                    placeholder: 'John Doe',
                                    type: 'text',
                                    value: initialObjectState.s_fullname,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Email', initialObjectState.p_email, {
                                    id: 'p_email',
                                    name: 'p_email',
                                    label: 'Email',
                                    placeholder: 'xxxxx@gmail.com',
                                    type: 'text',
                                    value: initialObjectState.p_email,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('Email', initialObjectState.s_email, {
                                    id: 's_email',
                                    name: 's_email',
                                    label: 'Email',
                                    placeholder: 'xxxxx@gmail.com',
                                    type: 'text',
                                    value: initialObjectState.s_email,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Téléphone', initialObjectState.p_phone, {
                                    id: 'p_phone',
                                    name: 'p_phone',
                                    label: 'Téléphone',
                                    placeholder: '06 00 00 00 00',
                                    type: 'text',
                                    validate: 'phone',
                                    value: initialObjectState.p_phone,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('Téléphone', initialObjectState.s_phone, {
                                    id: 's_phone',
                                    name: 's_phone',
                                    label: 'Téléphone',
                                    placeholder: '06 00 00 00 00',
                                    type: 'text',
                                    validate: 'phone',
                                    value: initialObjectState.s_phone,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField(
                                    'Signature',
                                    isAssociation() || currentUser.function == 'Jobers' ? (
                                        initialObjectState.signature ? (
                                            <a
                                                className='list-table-link'
                                                href={createDocumentLink(
                                                    initialObjectState.signature
                                                )}
                                                target='_blank'>
                                                Afficher
                                            </a>
                                        ) : (
                                            'Aucune signature'
                                        )
                                    ) : (
                                        '***********'
                                    ),
                                    {
                                        id: 'signature',
                                        name: 'signature',
                                        label: 'Signature',
                                        placeholder: 'Signature',
                                        type: 'text',
                                        value: 'moncv.pdf',
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                            <div className='list-flex-child profil-flex-container'></div>
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Trésorier(e)', initialObjectState.t_fullname, {
                                    id: 't_fullname',
                                    name: 't_fullname',
                                    label: 'Trésorier(e)',
                                    placeholder: 'John Doe',
                                    type: 'text',
                                    value: initialObjectState.t_fullname,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'></div>
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Email', initialObjectState.t_email, {
                                    id: 't_email',
                                    name: 't_email',
                                    label: 'Email',
                                    placeholder: 'John Doe',
                                    type: 'text',
                                    value: initialObjectState.t_email,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'></div>
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Téléphone', initialObjectState.t_phone, {
                                    id: 't_phone',
                                    name: 't_phone',
                                    label: 'Téléphone',
                                    placeholder: '06 00 00 00 00',
                                    type: 'text',
                                    value: initialObjectState.t_phone,
                                    validate: 'phone',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'></div>
                        </div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField(
                                    'Facebook',
                                    initialObjectState.facebook !== '' ? (
                                        <a
                                            className='list-table-link'
                                            href={initialObjectState.facebook}
                                            target='_blank'>
                                            {initialObjectState.facebook}
                                        </a>
                                    ) : (
                                        'Non renseigné'
                                    ),
                                    {
                                        id: 'facebook',
                                        name: 'facebook',
                                        label: 'Facebook',
                                        placeholder: 'https://www.facebook.com/xxxxx',
                                        type: 'text',
                                        value: initialObjectState.facebook,
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                            {initialObjectState &&
                                displayField(
                                    'Instagram',
                                    initialObjectState.instagram !== '' ? (
                                        <a
                                            className='list-table-link'
                                            href={initialObjectState.instagram}
                                            target='_blank'>
                                            {initialObjectState.instagram}
                                        </a>
                                    ) : (
                                        'Non renseigné'
                                    ),
                                    {
                                        id: 'instagram',
                                        name: 'instagram',
                                        label: 'Instagram',
                                        placeholder: 'https://www.instagram.com/xxxxx',
                                        type: 'text',
                                        value: initialObjectState.instagram,
                                        classes: {
                                            contClass: 'list-flex-child profil-flex-container',
                                            errorClass: 'error-text',
                                            fieldClass: 'profil-value',
                                        },
                                    }
                                )}
                        </div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Code URSSAF', initialObjectState.urssaf, {
                                    id: 'urssaf',
                                    name: 'urssaf',
                                    label: 'Code URSSAF',
                                    placeholder: '123456789',
                                    type: 'text',
                                    value: initialObjectState.urssaf,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('Banque', initialObjectState.bank, {
                                    id: 'bank',
                                    name: 'bank',
                                    label: 'Banque',
                                    placeholder: 'Banque',
                                    type: 'text',
                                    value: initialObjectState.bank,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Code APE', initialObjectState.ape, {
                                    id: 'ape',
                                    name: 'ape',
                                    label: 'Code APE',
                                    placeholder: 'Code APE',
                                    type: 'text',
                                    value: initialObjectState.ape,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('RIB', initialObjectState.iban, {
                                    id: 'iban',
                                    name: 'iban',
                                    label: 'RIB',
                                    placeholder: 'RIB',
                                    type: 'text',
                                    value: initialObjectState.iban,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Numéro TVA', initialObjectState.tva, {
                                    id: 'tva',
                                    name: 'tva',
                                    label: 'Numéro TVA',
                                    placeholder: 'Numéro TVA',
                                    type: 'text',
                                    value: initialObjectState.tva,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('BIC', initialObjectState.bic, {
                                    id: 'bic',
                                    name: 'bic',
                                    label: 'BIC',
                                    placeholder: 'BIC',
                                    type: 'text',
                                    value: initialObjectState.bic,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('SIRET', initialObjectState.siret, {
                                    id: 'siret',
                                    name: 'siret',
                                    label: 'SIRET',
                                    placeholder: 'RIB',
                                    type: 'text',
                                    value: initialObjectState.siret,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'></div>
                        </div>
                    </div>
                )}
                <div className='list-flex-container'>
                    <div className='list-flex-child profil-flex-container profil-footer'></div>
                </div>
            </Form>
        </div>
    );
}
