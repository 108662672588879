import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';

import Form from '../../../Utils/Form';
import Input from '../../../Utils/Input';
import SelectCountry from '../../../Utils/SelectCountry';

import Sidebar from '../../Sidebar/Sidebar';

import { useAuthState } from '../../../../context';

import MissionDataService from '../../../../services/mission.service';

export default function CompanyEdit() {
    const { id } = useParams();

    const [studyLevel, setStudyLevel] = useState([]);
    const [mainSkill, setMainSkill] = useState([]);
    const [secondarySkill, setSecondarySkill] = useState([]);

    const [initialObjectState, setInitialObjectState] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });

    const currentUser = useAuthState();

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                company: 'Jobers',
                name: 'Mise en place de bureaux',
                description: '',
                type: 'Mission ordinaire',
                school_level: '1ère / 2ème / 3ème / 4ème / 5ème / HMNOP',
                mission_duration: '1 mois, renouvelable',
                mission_worktype: 'Convention temps partiel',
                mission_money: 'De 3000€ à 4000€',
                mission_skills: 'Autocad, Photoshop / Illustrator',
                mission_secondary_skills: 'Travail en équipe',
                student_id: 0,
                mission_type: 'normal',
                work_type: 'fulltime',
                je: 5,
                je_ht: 250,
            };
            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (id) {
                let fields =
                    'id, name, description, start_date, end_date, status, display_status, je, price_ht, mission_type, work_type, study_levels, main_skills, secondary_skills';
                let key = id;
                MissionDataService.get(key, fields).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    setStudyLevel(objectData.study_levels);
                    setMainSkill(objectData.main_skills);
                    setSecondarySkill(objectData.secondary_skills);
                    setInitialObjectState(objectData);
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    async function createMission(formFields) {
        try {
            let fields = {
                company_id: currentUser.org_id,
                name: formFields.name.value,
                description: formFields.description.value,
                start_date: formFields.start_date.value,
                end_date: formFields.end_date.value,
                mission_type: formFields.mission_type.value,
                work_type: formFields.work_type.value,
                je: formFields.je.value,
                price_ht: formFields.price_ht.value,
                study_levels: JSON.stringify(studyLevel),
                main_skills: JSON.stringify(mainSkill),
                secondary_skills: JSON.stringify(secondarySkill),
            };
            let output_fields = 'id, friendlyname';

            MissionDataService.upsert(id, fields, output_fields).then(response => {});
        } catch (err) {
            console.log(err.message);
        }
    }

    const studyLevels = [
        { label: '1ère', value: '1' },
        { label: '2ème', value: '2' },
        { label: '3ème', value: '3' },
        { label: '4ème', value: '4' },
        { label: '5ème', value: '5' },
        { label: 'HMNOP', value: 'hmnop' },
    ];

    const mainSkills = [
        { label: 'Word', value: 'word' },
        { label: 'Excel', value: 'excel' },
        { label: 'PowerPoint', value: 'powerpoint' },
        { label: 'Access', value: 'access' },
        { label: 'PowerBI', value: 'powerbi' },
        { label: 'Photoshop', value: 'photoshop' },
        { label: 'InDesign', value: 'indesign' },
        { label: 'Illustrator', value: 'illustrator' },
        { label: 'Lightroom', value: 'lightroom' },
        { label: 'Première', value: 'premiere' },
        { label: 'After Effect', value: 'aftereffect' },
        { label: 'Substance', value: 'substance' },
        { label: 'Autocad', value: 'autocad' },
        { label: 'Rhinoceros3D', value: 'rhinoceros3d' },
        { label: 'Sketchup', value: 'sketchup' },
        { label: 'Revit', value: 'revit' },
        { label: 'Archicad', value: 'archicad' },
        { label: '3dsMax', value: '3dsmax' },
        { label: 'Cinéma4D', value: 'cinema4d' },
        { label: 'Maya', value: 'maya' },
        { label: 'Blender', value: 'blender' },
        { label: 'Vray', value: 'vray' },
        { label: 'Octane', value: 'octane' },
        { label: 'Lumion', value: 'lumion' },
        { label: 'Twinmotion', value: 'twinmotion' },
        { label: 'Enscape', value: 'enscape' },
    ];

    const secondarySkills = [
        { label: 'Travail en équipe', value: 'travailenequipe' },
        { label: 'Autonomie', value: 'autonomie' },
        { label: "Prise d'initiative", value: 'initiative' },
        { label: 'Créativité', value: 'creativite' },
    ];

    const frLocalize = {
        allItemsAreSelected: 'All items are selected.',
        clearSearch: 'Clear Search',
        clearSelected: 'Clear Selected',
        noOptions: 'Aucune valeur',
        search: 'Rechercher',
        selectAll: 'Tout selectionner',
        selectAllFiltered: 'Tout selectionner (Filtré)',
        selectSomeItems: 'Choisir...',
        create: 'Créer',
    };

    const onSubmit = (fields, errors) => {
        setFormData({
            fields,
            errors,
        });
        createMission(fields);
        setSubmitted(true);
    };

    return (
        <div className='missionView screen'>
            <div className='flex-col'>
                <Sidebar />
            </div>
            <div className='flex-col'>
                <div className='flex-row'>
                    {initialObjectState && (
                        <div className='missionTitle'>{initialObjectState.name}</div>
                    )}
                </div>
                {initialObjectState && (
                    <Form
                        className='form'
                        onSubmit={onSubmit}
                        fields={formData.fields}
                        errors={formData.errors}
                        validateOnSubmit={true}>
                        <div className='flex-row missionDetails bordered'>
                            <div className='mission-2-col'>
                                <div className='dashboard-title'>Informations générales</div>
                                <div className='missionLabel inter-medium-oxford-blue-14px'>
                                    &nbsp;
                                </div>
                                <Input
                                    id='name'
                                    label='Nom de la mission'
                                    placeholder='Nom de la mission proposée'
                                    value={
                                        initialObjectState && id > 0 ? initialObjectState.name : ''
                                    }
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field missionTitle',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                                <Input
                                    id='start_date'
                                    label='Date de début'
                                    placeholder='2022-10-05 09:00:00'
                                    value={
                                        initialObjectState && id > 0
                                            ? initialObjectState.start_date
                                            : ''
                                    }
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field missionTitle',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                                <Input
                                    id='end_date'
                                    label='Date de fin'
                                    placeholder='2022-10-19 18:00:00'
                                    value={
                                        initialObjectState && id > 0
                                            ? initialObjectState.start_date
                                            : ''
                                    }
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field missionTitle',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                                <Input
                                    id='work_type'
                                    label='Durée de travail'
                                    defaultValue='full'
                                    value={
                                        initialObjectState && id > 0
                                            ? initialObjectState.work_type
                                            : 'fulltime'
                                    }
                                    type='select'
                                    options={[
                                        { value: 'fulltime', label: 'Convention temps plein' },
                                        { value: 'halftime', label: 'Convention temps partiel' },
                                    ]}
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                            </div>
                            <div className='mission-2-col'>
                                <div className='missionLabel inter-medium-oxford-blue-14px'>
                                    &nbsp;
                                </div>
                                <div className='missionLabel inter-medium-oxford-blue-14px'>
                                    &nbsp;
                                </div>
                                <Input
                                    id='mission_type'
                                    label='Type de mission'
                                    defaultValue='normal'
                                    value={
                                        initialObjectState && id > 0
                                            ? initialObjectState.mission_type
                                            : 'normal'
                                    }
                                    type='select'
                                    options={[
                                        { value: 'normal', label: 'Mission ordinaire' },
                                        { value: 'hmonp', label: 'Mission HMONP' },
                                    ]}
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                                <div className='missionLabel inter-medium-oxford-blue-14px'>
                                    Niveau d'études
                                </div>
                                <MultiSelect
                                    options={studyLevels}
                                    hasSelectAll={true}
                                    value={studyLevel}
                                    onChange={setStudyLevel}
                                    labelledBy={"Niveau d'études"}
                                    className={'multi-select inter-normal-pale-sky-16px'}
                                    overrideStrings={frLocalize}
                                />
                                <Input
                                    id='je'
                                    label='Jours études'
                                    placeholder='5'
                                    value={
                                        initialObjectState && id > 0 ? initialObjectState.je : ''
                                    }
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field missionTitle',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                                <Input
                                    id='price_ht'
                                    label='Rémunération par jour étude (€)'
                                    placeholder='120'
                                    value={
                                        initialObjectState && id > 0
                                            ? initialObjectState.price_ht
                                            : ''
                                    }
                                    validate='required'
                                    classes={{
                                        contClass: 'input-field missionTitle',
                                        errorClass: 'error-text',
                                        fieldClass:
                                            'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                    }}
                                />
                            </div>
                        </div>
                        <div className='flex-row missionDetails bordered'>
                            <div className='flex-row missionDetails'>
                                <div className='mission-2-col missionBigCol'>
                                    <div className='dashboard-title'>
                                        Description de la mission et compétences attendues
                                    </div>
                                    <div className='dashboard-title'>&nbsp;</div>
                                    <div className='missionLabel inter-medium-oxford-blue-14px'>
                                        Compétences principales
                                    </div>
                                    <MultiSelect
                                        options={mainSkills}
                                        hasSelectAll={true}
                                        value={mainSkill}
                                        onChange={setMainSkill}
                                        className={'multi-select inter-normal-pale-sky-16px'}
                                        overrideStrings={frLocalize}
                                    />
                                    <div className='missionLabel inter-medium-oxford-blue-14px'>
                                        Compétences secondaires
                                    </div>
                                    <MultiSelect
                                        options={secondarySkills}
                                        hasSelectAll={true}
                                        value={secondarySkill}
                                        onChange={setSecondarySkill}
                                        className={'multi-select inter-normal-pale-sky-16px'}
                                        overrideStrings={frLocalize}
                                    />
                                </div>
                                <div className='mission-2-col missionBigCol'>
                                    <Input
                                        id='description'
                                        label='Description de la mission proposée'
                                        type='textarea'
                                        value={initialObjectState.description}
                                        rows='30'
                                        cols='20'
                                        placeholder='Décrivez en quelques mots la mission proposée'
                                        validate='required'
                                        classes={{
                                            contClass: 'input-field',
                                            errorClass: 'error-text',
                                            fieldClass:
                                                'input-box border-1px-mischka inter-normal-pale-sky-16px',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex-row missionDetails'>
                            <div className='bottom-actions'>
                                <button type='submit' className='primary-submit'>
                                    {initialObjectState && id > 0
                                        ? 'Mettre à jour la mission'
                                        : 'Créer la mission'}
                                </button>
                                {submitted && <Navigate to='/company/me/missions' replace={true} />}
                            </div>
                        </div>
                        <div className='flex-row missionDetails' />
                    </Form>
                )}
            </div>
        </div>
    );
}
