import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import StudentDataService from '../../services/student.service';
import AttachmentDataService from '../../services/attachment.service';
import ApplicationDataService from '../../services/application.service';
import MissionDataService from '../../services/mission.service';
import CompanyDataService from '../../services/company.service';

export default function MissionApplicationList(props) {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const currentUser = useAuthState();
    const [entreprise, setEntreprise] = useState([]);

    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );

    useEffect(() => {
        const fetchObject = async () => {
            let sKey =
                'SELECT lnkMissionToStudent AS lnk JOIN Mission AS M ON lnk.mission_id = M.id WHERE M.status = "pending" AND lnk.status IN ("pending", "accepted") AND company_id = ' +
                currentUser.org_id;
            let aData = [];

            try {
                const response = await ApplicationDataService.getAll(sKey);
                console.log(response);

                if (response.length > 0) {
                    aData = await Promise.all(
                        response.map(async (element, index) => {
                            const studentResponse = await StudentDataService.get(
                                element.student_id
                            );
                            const fileResponse = await AttachmentDataService.getAll(
                                'SELECT Attachment WHERE item_class = "Person" AND item_id = ' +
                                    element.student_id,
                                'contents'
                            );

                            const files = fileResponse;
                            const cv = files.find(
                                file => file.filename.includes('CV.') || file.type === 'CV'
                            );

                            return {
                                ...element,
                                student: studentResponse,
                                student_book: studentResponse.book_url,
                                student_email: studentResponse.email,
                                student_phone: studentResponse.phone,
                                student_org_id: studentResponse.org_id,
                                student_org_name: studentResponse.org_name,
                                school_year: studentResponse.school_year,
                                files,
                                cv,
                            };
                        })
                    );
                }

                const companyResponse = await CompanyDataService.get(currentUser.org_id);
                setEntreprise(companyResponse);
                setData(aData);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        if (data.length === 0) {
            fetchObject();
        }
    }, [data]);

    const createDocumentLink = (file, student_id) => {
        if (file) {
            return (
                'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=display_document&filename=' +
                file.filename +
                '&id=' +
                file.id +
                '&obj=Person::' +
                student_id
            );
        }
    };

    const refuse_application = async (id, status) => {
        let data = false;
        let operation = 'refuse_application';
        let fields = {
            id: id,
            status: status,
        };

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(fields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        let response = await axios(config);

        if (response.data.code === 0) {
            window.location.reload(false);
        }

        if (response.data.code !== 0) {
            console.log(response.data.message);
        }
    };

    const columns = [
        {
            name: 'Mission',
            cell: row => (
                <Link className='list-table-link' to={'/mission/' + row.mission_id + '/profil'}>
                    {row && row.mission_name}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.mission_name < b.mission_name) {
                    return -1;
                }
                if (a.mission_name > b.mission_name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Candidat',
            cell: row => (
                <Link className='list-table-link' to={'/etudiant/' + row.student_id + '/profil'}>
                    {row.student_id_friendlyname}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.student_id_friendlyname < b.student_id_friendlyname) {
                    return -1;
                }
                if (a.student_id_friendlyname > b.student_id_friendlyname) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Curriculum vitae',
            cell: row =>
                row.cv ? (
                    <a
                        className='list-table-link'
                        href={createDocumentLink(row.cv, row.student.id)}
                        target='_blank'>
                        Télécharger
                    </a>
                ) : (
                    'Aucun CV'
                ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.student_book < b.student_book) {
                    return -1;
                }
                if (a.student_book > b.student_book) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Portfolio',
            cell: row => (
                <a className='list-table-link' target='_blank' href={row.student_book}>
                    Télécharger
                </a>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.student_book < b.student_book) {
                    return -1;
                }
                if (a.student_book > b.student_book) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Valider',
            cell: row =>
                row.status == 'pending' ? (
                    <Link
                        className='list-table-link green-value'
                        to={'/mission/' + row.mission_id + '/profil/' + row.student_id}>
                        O
                    </Link>
                ) : (
                    <span>En attente confirmation étudiant</span>
                ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.student_id < b.student_id) {
                    return -1;
                }
                if (a.student_id > b.student_id) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Refuser',
            cell: row =>
                row.status == 'pending' ? (
                    <a
                        className='list-table-link red-value'
                        href='#'
                        onClick={() => refuse_application(row.id, 'refused')}>
                        X
                    </a>
                ) : (
                    ''
                ),
            filterable: true,
            sortable: true,
        },
    ];

    const renderList = (list, additional_str, separator) => {
        let str = '';
        list.map((item, index) => {
            str += item.label + additional_str;
            if (index < list.length - 1) {
                str += separator;
            }
        });

        return str;
    };

    const ExpandedMission = ({ data }) => (
        <pre>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Association : </span>
                    <span className='list-mission-value'>
                        <Link
                            className='list-table-link'
                            to={'/association/' + data.student_org_id + '/profil'}>
                            {data.student_org_name}
                        </Link>
                    </span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Niveau d'études : </span>
                    <span className='list-mission-value'>{data.student.school_year}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Téléphone : </span>
                    <span className='list-mission-value'>{data.student_phone}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Email : </span>
                    <span className='list-mission-value'>{data.student_email}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Compétences : </span>
                    <span className='list-mission-value'>
                        {renderList(data.student.skills, '', ', ')}
                    </span>
                </div>
            </div>
        </pre>
    );

    return (
        <div className='backend-container'>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>{entreprise && entreprise.name}</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4 list-container-tabs'>
                    <Link to={'/mission/new'} className='profil-tabs'>
                        Création
                    </Link>
                    <Link to={'/mission/application'} className='profil-tabs'>
                        Candidats
                    </Link>
                    <Link to={'/mission/signatures'} className='profil-tabs'>
                        Signatures
                    </Link>
                </div>
                <div className='list-container-searchbar'>
                    <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                    <input
                        id='search'
                        className='list-container-searchinput'
                        type='text'
                        placeholder='Rechercher'
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                    />
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='profil-container-divider-2' />
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child'>
                    <p className='list-container-subtitle list-tab-subtitle'>
                        Voici la liste des candidats aux missions que vous proposez. Vous avez accès
                        aux profils des postulants. <br />
                        Vous pouvez <span className='list-table-link'>valider</span> ou{' '}
                        <span className='list-table-link'>refuser</span> les candidatures. Après
                        validation un contrat sera disponible dans la section "Signatures".
                    </p>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucun candidat trouvé.</span>
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={ExpandedMission}
                    />
                </div>
            </div>
        </div>
    );
}
