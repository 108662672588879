import '../Organization/OrganizationList.css';
import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import Sidebar from '../../Sidebar/Sidebar';

import { useAuthState } from '../../../../context';

import CompanyDataService from '../../../../services/company.service';

export default function CompanyList(props) {
    const [data, setData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const currentUser = useAuthState();

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );

    useEffect(() => {
        const key = 'SELECT Company';
        CompanyDataService.getAll(key)
            .then(response => {
                setData(response);
            })
            .catch(e => {
                console.log(e);
            });
    }, []);

    const handleAction = (e, id) => {
        setCurrentIndex(id);
    };

    const isPartner = (iAsso, row) => {
        if (props.scope === 'asso') {
            return row.missions_list.includes(iAsso);
        }
    };

    function StatusComponent(row) {
        let className = 'rdt_StatusCell';
        if (row.display_status == 'Disponible') {
            className = 'rdt_StatusCell rdt_StatusAvailable';
        }
        if (row.display_status == 'En cours de mission') {
            className = 'rdt_StatusCell rdt_StatusOngoing';
        }
        if (row.display_status == 'En attente') {
            className = 'rdt_StatusCell rdt_StatusWaiting';
        }
        return (
            <span className={className} onClick={e => handleAction(e, row.id)}>
                {row.display_status}
            </span>
        );
    }

    const columns = [
        {
            name: 'Entreprise',
            selector: row => <span onClick={e => handleAction(e, row.id)}>{row.name}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Statut',
            selector: row => StatusComponent(row),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Adresse email référent',
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {isPartner(currentUser.org_id, row) ? row.email : '**********'}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Téléphone',
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {isPartner(currentUser.org_id, row) ? row.phone : '**********'}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
        {
            name: 'SIRET',
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {isPartner(currentUser.org_id, row) ? row.siret : '**********'}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Ville',
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {row.location_city}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
        {
            name: "Date d'inscription",
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {isPartner(currentUser.org_id, row) ? row.started_at : '**********'}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
    ];

    const convertArrayOfObjectsToCSV = array => {
        let result;

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = Object.keys(filteredItems[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    return (
        <div>
            <div className='flex-container'>
                <div className='flex-child'>
                    <Sidebar />
                </div>

                <div className='flex-child flex-list'>
                    <div className='dashboard-topbar-list'>
                        <div className='dashboard-title-subtitle-container'>
                            <div className='dashboard-title'>Entreprises</div>
                            <p className='dashboard-subtitle'>
                                Cet onglet permet de voir toutes les entreprises associées à Jobers
                            </p>
                        </div>
                        <div className='dashboard-topbar-element'>
                            <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                            <input
                                id='search'
                                className='dashboard-search-label inter-normal-pale-sky-16px'
                                type='text'
                                placeholder='Rechercher'
                                value={filterText}
                                onChange={e => setFilterText(e.target.value)}
                            />
                        </div>
                        {currentUser.function === 'Jobers' && (
                            <div className='dashboard-topbar-element dashboard-export-bg'>
                                <img
                                    className='dashboard-icon'
                                    src='/img/export.svg'
                                    alt='download-cloud'
                                />
                                <div
                                    className='dashboard-topbar-label'
                                    onClick={() => {
                                        downloadCSV(data);
                                    }}>
                                    Export
                                </div>
                            </div>
                        )}
                        {/* {currentUser.function === 'Jobers' && (
                            <div className='dashboard-topbar-element dashboard-filter-bg'>
                                <img
                                    className='dashboard-icon'
                                    src='/img/filter.svg'
                                    alt='Filters lines'
                                />
                                <div className='dashboard-topbar-label dashboard-filter-label'>
                                    Filtres
                                </div>
                            </div>
                        )} */}
                        {currentUser.function === 'Jobers' && (
                            <div className='dashboard-topbar-element dashboard-filter-bg'>
                                <div className='dashboard-topbar-label dashboard-filter-label'>
                                    <Link to='/company'>Créer une entreprise</Link>
                                </div>
                            </div>
                        )}
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        persistTableHead
                    />
                    {currentIndex > 0 && currentUser.function === 'Jobers' && (
                        <Navigate to={'/company/' + currentIndex} replace={true} />
                    )}
                    {currentIndex > 0 && currentUser.function === 'Président' && (
                        <Navigate to={'/association/companies/' + currentIndex} replace={true} />
                    )}
                </div>
            </div>
        </div>
    );
}
