import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import axios from 'axios';

import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';

import StudentDataService from '../../services/student.service';
import DocumentDataService from '../../services/document.service';
import AttachmentDataService from '../../services/attachment.service';
import MissionDataService from '../../services/mission.service';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
    label,
    type,
    item_id,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const currentUser = useAuthState();
    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    const sendFiles = async (files, item_id) => {
        let data = false;
        let b64 = false;
        let attachment = false;
        let filename = false;
        let operation = 'add_association_document';

        if (files.length > 0) {
            let file = files[0];
            b64 = await convertBase64(file);
            data = b64.substring(b64.indexOf('base64,') + 7);
            attachment = {
                item_class: 'Mission',
                item_id: item_id,
                temp_id: file.name,
                type: type,
                item_org_id: currentUser.org_id,
                creation_date: new Date(),
                contents: {
                    data: data,
                    filename: filename,
                    mimetype: file.type,
                },
            };
        }

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(attachment));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        return await axios(config);
    };

    const handleNewFileUpload = async (e, item_id) => {
        const { files: newFiles } = e.target;
        if (newFiles.length > 0) {
            let response = await sendFiles(newFiles, item_id);
            if (response.data.code === 0) {
                window.location.reload(false);
            }
        }
    };

    return (
        <section>
            <div
                className='more_actions_item list-table-link'
                onClick={e => handleUploadBtnClick(e)}>
                <span>{label}</span>
            </div>
            <input
                type='file'
                ref={fileInputField}
                onChange={e => handleNewFileUpload(e, item_id)}
                title=''
                value=''
                {...otherProps}
            />
        </section>
    );
};

export default function EtudiantGestion(props) {
    const [data, setData] = useState([]);
    const currentUser = useAuthState();
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    const [type, setType] = useState('candidatures');
    const [editMode, setEditMode] = useState(0);

    useEffect(() => {
        let key = '';
        if (type === 'candidatures') {
            key =
                'SELECT Mission AS M JOIN lnkMissionToStudent AS lnk ON lnk.mission_id = M.id WHERE M.student_id = 0 AND lnk.student_id = ' +
                currentUser.id +
                ' AND lnk.status != "refused" UNION SELECT Mission AS M2 WHERE M2.status = "pending" AND M2.student_id = ' +
                currentUser.id;
            if (key) {
                MissionDataService.getAll(key, []).then(response => {
                    setData(response);
                });
            }
        }
        if (type === 'sign') {
            key =
                'SELECT Mission AS M JOIN lnkMissionToStudent AS MS ON MS.mission_id = M.id JOIN DocumentNote AS D ON M.studentmission_id = D.id WHERE D.documenttype_id_friendlyname = "Acceptation de mission" AND D.status = "draft" AND MS.status = "student_confirmed" AND M.student_id = ' +
                currentUser.id;
            if (key) {
                MissionDataService.getAll(key, []).then(response => {
                    setData(response);
                });
            }
        }
    }, [type]);

    const displayTab = type => {
        return type === 'candidatures'
            ? 'list-container-divider-1 lg-divider-1'
            : 'list-container-divider-2 lg-divider-2';
    };

    const displayMissionStatus = row => {
        let status = '';
        row.students_list &&
            row.students_list.length > 0 &&
            row.students_list.forEach(item => {
                if (item.status === 'accepted' && item.student_id == currentUser.id) {
                    status = (
                        <Link className='list-table-link' to={'/mission/' + row.id + '/profil'}>
                            Consulter la proposition
                        </Link>
                    );
                }
                if (item.status === 'pending' && item.student_id == currentUser.id) {
                    status = (
                        <Link className='list-table-link' to={'/mission/' + row.id + '/profil'}>
                            Candidature envoyée
                        </Link>
                    );
                }
                if (item.status === 'refused' && item.student_id == currentUser.id) {
                    status = <span className='red-value'>Candidature refusée</span>;
                }
            });
        return status;
    };

    const renderList = (list, additional_str, separator) => {
        let str = '';
        list.map((item, index) => {
            str += item.label + additional_str;
            if (index < list.length - 1) {
                str += separator;
            }
        });
        return str;
    };

    const ExpandedMission = ({ data }) => (
        <div>
            <pre>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Titre mission : </span>
                        <span className='list-mission-value'>{data.name}</span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Type : </span>
                        <span className='list-mission-value'>{data.mission_type}</span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Niveau d'études : </span>
                        <span className='list-mission-value'>
                            {renderList(data.study_levels, ' année', ', ')}
                        </span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Adresse : </span>
                        <span className='list-mission-value'>{data.address}</span>
                    </div>
                </div>
                <div className='list-mission-container'>
                    <div className='list-mission-detail'>
                        <span className='list-mission-label'>Compétences : </span>
                        <span className='list-mission-value'>
                            {renderList(data.main_skills, '', ', ')}
                        </span>
                    </div>
                </div>
            </pre>
        </div>
    );

    const columns = () => {
        if (type === 'candidatures') {
            return [
                {
                    name: 'Entreprise',
                    cell: row => (
                        // <Link
                        //     className='list-table-link'
                        //     to={'/entreprise/' + row.company_id + '/profil'}>
                        //     {row.company}
                        // </Link>
                        <span>{row.company}</span>
                    ),
                    filterable: true,
                    sortable: true,
                    sortFunction: (a, b) => {
                        if (a.company < b.company) {
                            return -1;
                        }
                        if (a.company > b.company) {
                            return 1;
                        }
                        return 0;
                    },
                },
                {
                    name: 'Ville',
                    selector: row => row.city,
                    filterable: true,
                    sortable: true,
                },
                {
                    name: 'Date de début',
                    selector: row => moment(row.start_date).format('DD-MM-YYYY'),
                    filterable: true,
                    sortable: true,
                    sortFunction: (a, b) => {
                        if (a.start_date < b.start_date) {
                            return -1;
                        }
                        if (a.start_date > b.start_date) {
                            return 1;
                        }
                        return 0;
                    },
                },
                {
                    name: 'Date de fin',
                    selector: row => moment(row.end_date).format('DD-MM-YYYY'),
                    filterable: true,
                    sortable: true,
                    sortFunction: (a, b) => {
                        if (a.end_date < b.end_date) {
                            return -1;
                        }
                        if (a.end_date > b.end_date) {
                            return 1;
                        }
                        return 0;
                    },
                },
                {
                    name: 'Statut',
                    selector: row => displayMissionStatus(row),
                    filterable: true,
                    sortable: true,
                },
                // {
                //     name: 'Actions',
                //     cell: row => (
                //         <Link className='list-table-link' to={'/mission/' + row.id + '/profil'}>
                //             Consulter
                //         </Link>
                //     ),
                //     filterable: true,
                //     sortable: true,
                // },
            ];
        }
        if (type === 'sign') {
            return [
                {
                    name: 'Entreprise',
                    cell: row => (
                        // <Link
                        //     className='list-table-link'
                        //     to={'/entreprise/' + row.company_id + '/profil'}>
                        //     {row.company}
                        // </Link>
                        <span>{row.company}</span>
                    ),
                    filterable: true,
                    sortable: true,
                    sortFunction: (a, b) => {
                        if (a.company < b.company) {
                            return -1;
                        }
                        if (a.company > b.company) {
                            return 1;
                        }
                        return 0;
                    },
                },
                {
                    name: 'Ville',
                    selector: row => row.city,
                    filterable: true,
                    sortable: true,
                },
                {
                    name: 'Télécharger',
                    selector: row => (
                        <a
                            className='list-table-link'
                            href={'https://itop.jobers.fr/pdf_am.php?mission_id=' + row.id}
                            target='_blank'>
                            Télécharger
                        </a>
                    ),
                    filterable: true,
                    sortable: true,
                },
                {
                    name: 'Charger',
                    cell: row =>
                        row.studyagreement_id_status == 'published' ? (
                            <FileUpload
                                type='Acceptation de mission'
                                accept='.jpg,.png,.jpeg,.pdf'
                                item_id={row.id}
                                label='Charger'
                            />
                        ) : (
                            'En attente signature entreprise'
                        ),
                    filterable: true,
                    sortable: true,
                },
            ];
        }
    };

    const convertArrayOfObjectsToCSV = array => {
        let result;
        let exportableFields = ['company', 'city', 'start_date', 'end_date', 'status'];
        let translatedFields = ['Entreprise', 'Ville', 'Date de début', 'Date de fin', 'Statut'];

        if (type === 'sign') {
            exportableFields = ['company', 'city'];
            translatedFields = ['Entreprise', 'Ville'];
        }

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = exportableFields;

        result = '';
        result += translatedFields.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                let value = item[key];
                if (key === 'start_date' || key === 'end_date') {
                    value = moment(item[key]).format('DD-MM-YYYY');
                }
                if (key === 'status') {
                    value = displayMissionStatus(item);
                    if (typeof value === 'object') value = 'Consulter la proposition';
                }

                result += value;

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    return (
        <div
            className='backend-container'
            onClick={() => {
                if (editMode === 1) {
                    setEditMode(0);
                }
            }}>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>{currentUser.userDetails}</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4 list-container-tabs'>
                    <Link
                        to='#'
                        className='profil-tabs lg-tabs'
                        onClick={() => setType('candidatures')}>
                        Candidatures
                    </Link>
                    <Link to='#' className='profil-tabs' onClick={() => setType('sign')}>
                        Signatures
                    </Link>
                    <Link
                        to='#'
                        className='profil-tabs disabled' /*onClick={() => setType('fisc')}*/
                    >
                        Fiscalité
                    </Link>
                </div>
                <div className='list-container-searchbar searchbar-with-button'>
                    <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                    <input
                        id='search'
                        className='list-container-searchinput'
                        type='text'
                        placeholder='Rechercher'
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                    />
                </div>
                {editMode < 2 && (
                    <div className='profil-container-wipbutton'>
                        <span className='list-container-addlabel'>
                            <a
                                onClick={() => {
                                    editMode === 1 ? setEditMode(0) : setEditMode(1);
                                }}>
                                +
                            </a>
                        </span>
                    </div>
                )}
                {editMode == 1 && (
                    <div className='more_actions'>
                        <div
                            className='more_actions_action'
                            onClick={() => {
                                downloadCSV(filteredItems);
                            }}>
                            {/* display ham-menu icon */}
                            <div className='more_actions_icon'>
                                <img
                                    className='ham_menu'
                                    src='/img/ham-menu.svg'
                                    alt='more actions'
                                />
                            </div>
                            <div className='more_actions_item'>Exporter</div>
                        </div>
                        <div
                            className='more_actions_action'
                            // onClick={() => setEditMode(2)}
                        >
                            {/* display ham-menu icon */}
                            <div className='more_actions_icon'>
                                <img
                                    className='ham_menu'
                                    src='/img/ham-menu.svg'
                                    alt='more actions'
                                />
                            </div>
                            <div className='more_actions_item disabled'>Filtrer</div>
                        </div>
                    </div>
                )}
            </div>
            <div className='list-flex-container'>
                <div className={displayTab(type)} />
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child'>
                    {type === 'candidatures' && (
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Voici la liste des missions auxquelles vous avez postulé. Si une agence
                            décline votre candidature vous serez informé par email <br /> et la
                            ligne correspondante sera retirée. Dans le cas contraire, vous pouvez
                            valider la mission et passer à l'onglet "Signatures".
                        </p>
                    )}
                    {type === 'banque' && (
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Liste des relevés bancaires de l'association.
                        </p>
                    )}
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucune donnée trouvée.</span>
                        }
                        columns={columns()}
                        data={filteredItems}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={ExpandedMission}
                    />
                </div>
            </div>
        </div>
    );
}
