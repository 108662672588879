import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import StudentDataService from '../../services/student.service';
import { render } from 'react-dom';

export default function EtudiantList(props) {
    const [data, setData] = useState([]);
    const [entrepriseMode, setEntrepriseMode] = useState('contact');
    const [editMode, setEditMode] = useState(0);
    const currentUser = useAuthState();
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item => {
        return Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                k != 'year' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        );
    });

    useEffect(() => {
        let key = 'SELECT Person WHERE function = "Student"';

        if (isCompany() && entrepriseMode === 'contact') {
            key =
                'SELECT Person AS P JOIN Mission AS M ON M.student_id = P.id WHERE P.function = "Student" AND M.company_id = ' +
                currentUser.org_id;
            console.log(key);
        }
        if (isCompany() && entrepriseMode === 'network') {
            key = 'SELECT Person WHERE function = "Student" AND status != "pending"';
        }
        if (currentUser.function === 'Président' || currentUser.function === 'Compte association') {
            key = 'SELECT Person WHERE function = "Student" AND org_id = ' + currentUser.org_id;
            // AND P.org_id = ' + currentUser.org_id;
        }

        const fields = 'id, name, status, first_name, email, phone, org_name, org_id, started_at';
        StudentDataService.getAll(key, fields).then(response => {
            response.sort((a, b) => {
                if (a.started_at > b.started_at) {
                    return -1;
                }
                if (a.started_at < b.started_at) {
                    return 1;
                }
                return 0;
            });
            response.sort((a, b) => {
                if (a.status === 'pending') {
                    return -1;
                }
                if (b.status === 'pending') {
                    return 1;
                }
                return 0;
            });
            setData(response);
        });
    }, [entrepriseMode]);

    const isCompany = () => {
        if (currentUser.function === 'Compte entreprise') {
            return true;
        }
        if (currentUser.function === 'Représentant légal') {
            return true;
        }
        if (currentUser.function === 'Personne de contact') {
            return true;
        }
        return false;
    };

    const convertArrayOfObjectsToCSV = array => {
        let result;
        let exportableFields = ['name', 'first_name', 'org_name', 'started_at', 'display_status'];
        let translatedFields = [
            'Référence',
            'Prénom',
            'Association',
            "Date d'inscription",
            'Statut',
        ];

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = exportableFields;

        result = '';
        result += translatedFields.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                let value = item[key];
                if (key === 'start_date' || key === 'end_date') {
                    value = moment(item[key]).format('DD-MM-YYYY');
                }

                result += value;

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    const columns = [
        {
            name: 'Nom',
            cell: row => (
                <Link className='list-table-link' to={'/etudiant/' + row.id + '/profil'}>
                    {row.name}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Prénom',
            selector: row => row.first_name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Association',
            cell: row => {
                if (row.org_name == 'Jobers') {
                    return 'N/A';
                } else {
                    return (
                        <Link
                            className='list-table-link'
                            to={'/association/' + row.org_id + '/profil'}>
                            {row.org_name}
                        </Link>
                    );
                }
            },
            filterable: true,
            sortable: true,
            width: '10vw',
            sortFunction: (a, b) => {
                if (a.org_name < b.org_name) {
                    return -1;
                }
                if (a.org_name > b.org_name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: "Date d'inscription",
            selector: row => moment(row.started_at).format('DD-MM-YYYY'),
            filterable: true,
            sortable: true,
            width: '10vw',
            sortFunction: (a, b) => {
                if (a.started_at < b.started_at) {
                    return -1;
                }
                if (a.started_at > b.started_at) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Statut',
            cell: row => (
                <span className={row.status === 'pending' ? 'red-value' : ''}>
                    {row.display_status}
                </span>
            ),
            filterable: true,
            sortable: true,
            width: '10vw',
            sortFunction: (a, b) => {
                if (a.display_status < b.display_status) {
                    return -1;
                }
                if (a.display_status > b.display_status) {
                    return 1;
                }
                return 0;
            },
        },
    ];

    return (
        <div
            className='backend-container'
            onClick={() => {
                if (editMode === 1) {
                    setEditMode(0);
                }
            }}>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>Etudiants</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            {isCompany() && (
                <div>
                    <div className='list-flex-container'>
                        <div className='list-flex-child flex-4 list-container-tabs'>
                            <div
                                className='list-tabs lg-tabs'
                                onClick={() => setEntrepriseMode('contact')}>
                                Mes contacts
                            </div>
                            <div
                                className='list-tabs list-second-tab'
                                onClick={() => setEntrepriseMode('network')}>
                                Réseau
                            </div>
                        </div>
                        <div className='list-flex-child list-flex-container'>
                            <div
                                className={
                                    currentUser.function == 'Jobers'
                                        ? 'list-container-searchbar searchbar-with-button'
                                        : 'list-container-searchbar'
                                }>
                                <img
                                    className='dashboard-icon'
                                    src='/img/search.svg'
                                    alt='search'
                                />
                                <input
                                    id='search'
                                    className='list-container-searchinput'
                                    type='text'
                                    placeholder='Rechercher'
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                />
                            </div>
                            {editMode < 2 && currentUser.function == 'Jobers' && (
                                <div className='profil-container-wipbutton'>
                                    <span className='list-container-addlabel'>
                                        <a
                                            onClick={() => {
                                                console.log('editMode', editMode);
                                                editMode === 1 ? setEditMode(0) : setEditMode(1);
                                            }}>
                                            +
                                        </a>
                                    </span>
                                </div>
                            )}
                            {editMode == 1 && (
                                <div className='more_actions'>
                                    <div
                                        className='more_actions_action'
                                        onClick={() => {
                                            downloadCSV(filteredItems);
                                        }}>
                                        {/* display ham-menu icon */}
                                        <div className='more_actions_icon'>
                                            <img
                                                className='ham_menu'
                                                src='/img/ham-menu.svg'
                                                alt='more actions'
                                            />
                                        </div>
                                        <div className='more_actions_item'>Exporter</div>
                                    </div>
                                    <div
                                        className='more_actions_action'
                                        onClick={() => setEditMode(2)}>
                                        {/* display ham-menu icon */}
                                        <div className='more_actions_icon'>
                                            <img
                                                className='ham_menu'
                                                src='/img/ham-menu.svg'
                                                alt='more actions'
                                            />
                                        </div>
                                        <div className='more_actions_item disabled'>Filtrer</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='list-flex-container'>
                        {entrepriseMode === 'contact' && (
                            <div className='list-container-divider-1 lg-divider-1'></div>
                        )}
                        {entrepriseMode === 'network' && (
                            <div className='list-container-divider-2 lg-divider-2'></div>
                        )}
                    </div>
                    <div className='list-flex-container'>
                        <div className='list-flex-child flex-4'>
                            {entrepriseMode === 'contact' && (
                                <p className='list-container-subtitle list-tab-subtitle'>
                                    Liste des étudiants du réseau Jobers avec qui vous avez
                                    travaillé. <br />
                                    Veuillez noter que les étudiants diplômés ne sont plus éligibles
                                    aux contrats proposés par Jobers et en sont retirés.
                                </p>
                            )}
                            {entrepriseMode === 'network' && (
                                <p className='list-container-subtitle list-tab-subtitle'>
                                    Liste de tous les étudiants inscrits sur le réseau Jobers.{' '}
                                    <br />
                                    Vous aurez un accès restreint aux informations des étudiants
                                    avec qui vous n'avez pas encore travaillé.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {!isCompany() && (
                <div>
                    <div
                        className='list-flex-container'
                        onClick={() => {
                            if (editMode === 1) {
                                setEditMode(0);
                            }
                        }}>
                        <div className='list-flex-child flex-4'>
                            <p className='list-container-subtitle'>
                                Liste de tous les étudiants du réseau Jobers.
                            </p>
                        </div>
                        <div className='list-flex-child list-flex-container'>
                            <div className='list-container-searchbar searchbar-with-button'>
                                <img
                                    className='dashboard-icon'
                                    src='/img/search.svg'
                                    alt='search'
                                />
                                <input
                                    id='search'
                                    className='list-container-searchinput'
                                    type='text'
                                    placeholder='Rechercher'
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                />
                            </div>
                            {editMode < 2 && (
                                <div className='profil-container-wipbutton'>
                                    <span className='list-container-addlabel'>
                                        <a
                                            onClick={() => {
                                                console.log('editMode', editMode);
                                                editMode === 1 ? setEditMode(0) : setEditMode(1);
                                            }}>
                                            +
                                        </a>
                                    </span>
                                </div>
                            )}
                            {editMode == 1 && (
                                <div className='more_actions'>
                                    <div
                                        className='more_actions_action'
                                        onClick={() => {
                                            downloadCSV(filteredItems);
                                        }}>
                                        {/* display ham-menu icon */}
                                        <div className='more_actions_icon'>
                                            <img
                                                className='ham_menu'
                                                src='/img/ham-menu.svg'
                                                alt='more actions'
                                            />
                                        </div>
                                        <div className='more_actions_item'>Exporter</div>
                                    </div>
                                    <div
                                        className='more_actions_action'
                                        onClick={() => setEditMode(2)}>
                                        {/* display ham-menu icon */}
                                        <div className='more_actions_icon'>
                                            <img
                                                className='ham_menu'
                                                src='/img/ham-menu.svg'
                                                alt='more actions'
                                            />
                                        </div>
                                        <div className='more_actions_item disabled'>Filtrer</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='list-flex-container'>
                        <div className='list-container-divider'></div>
                    </div>
                </div>
            )}
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            filterText && filterText.length > 0 ? (
                                <span className='rdt_noresults'>
                                    Aucun élément ne correspond à votre recherche.
                                </span>
                            ) : entrepriseMode && entrepriseMode === 'contact' && isCompany() ? (
                                <span className='rdt_noresults'>
                                    Vous n’avez pas encore de contacts ! Les étudiants avec qui vous
                                    travaillez seront ajoutés à cette page.
                                </span>
                            ) : (
                                <span className='rdt_noresults'>Aucun étudiant trouvé.</span>
                            )
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    );
}
