import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import MissionDataService from '../../services/mission.service';

export default function MissionList(props) {
    const [data, setData] = useState([]);
    const currentUser = useAuthState();
    const [editMode, setEditMode] = useState(0);
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    useEffect(() => {
        let key = 'SELECT Mission WHERE status = "terminated"';

        if (isCompany()) {
            key =
                'SELECT Mission WHERE status = "terminated" AND company_id = ' + currentUser.org_id;
        }

        const fields =
            'id, name, status, display_status, start_date, end_date, je, price_ht, company_id_friendlyname';
        MissionDataService.getAll(key, fields).then(response => {
            console.log(response);
            setData(response);
        });
    }, []);

    const isCompany = () => {
        if (currentUser.function === 'Compte entreprise') {
            return true;
        }
        if (currentUser.function === 'Représentant légal') {
            return true;
        }
        if (currentUser.function === 'Personne de contact') {
            return true;
        }
        return false;
    };

    const columns = [
        {
            name: 'Référence',
            cell: row => (
                <Link className='list-table-link' to={'/mission/' + row.id + '/profil'}>
                    {row.ref}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.ref < b.ref) {
                    return -1;
                }
                if (a.ref > b.ref) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Etudiant',
            cell: row => (
                <Link className='list-table-link' to={'/etudiant/' + row.student_id + '/profil'}>
                    {row.student}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.student < b.student) {
                    return -1;
                }
                if (a.student > b.student) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: isCompany() ? 'Type' : 'Entreprise',
            cell: row =>
                isCompany() ? (
                    row.mission_type === 'normal' ? (
                        'Convention temps complet'
                    ) : row.mission_type === 'parttime' ? (
                        'Convention temps partiel'
                    ) : (
                        'Convention HMONP'
                    )
                ) : (
                    <Link
                        className='list-table-link'
                        to={'/entreprise/' + row.company_id + '/profil'}>
                        {row.company}
                    </Link>
                ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.company < b.company) {
                    return -1;
                }
                if (a.company > b.company) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: 'Association',
            cell: row => (
                <Link
                    className='list-table-link'
                    to={'/association/' + row.student_org_id + '/profil'}>
                    {row.student_org_name}
                </Link>
            ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.student_org_name < b.student_org_name) {
                    return -1;
                }
                if (a.student_org_name > b.student_org_name) {
                    return 1;
                }
                return 0;
            },
        },
        {
            name: isCompany() ? 'Durée' : 'Fin',
            cell: row =>
                isCompany() ? (
                    Math.floor(
                        moment
                            .duration(
                                moment(row.end_date, 'YYYY/MM/DD').diff(
                                    moment(row.start_date, 'YYYY/MM/DD')
                                )
                            )
                            .asDays()
                    ) + ' jours'
                ) : (
                    <span>{moment(row.end_date).format('DD-MM-YYYY')}</span>
                ),
            filterable: true,
            sortable: true,
            sortFunction: (a, b) => {
                if (a.end_date < b.end_date) {
                    return -1;
                }
                if (a.end_date > b.end_date) {
                    return 1;
                }
                return 0;
            },
        },
    ];

    const convertArrayOfObjectsToCSV = array => {
        let result;
        let exportableFields = [
            'ref',
            'student',
            'mission_type',
            'student_org_name',
            'display_status',
        ];
        let translatedFields = ['Référence', 'Etudiant', 'Type', 'Association', 'Statut'];

        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const keys = exportableFields;

        result = '';
        result += translatedFields.join(columnDelimiter);
        result += lineDelimiter;

        array.forEach(item => {
            let ctr = 0;
            keys.forEach(key => {
                if (ctr > 0) result += columnDelimiter;

                let value = item[key];
                if (key === 'mission_type') {
                    switch (item[key]) {
                        case 'normal':
                            value = 'Convention temps complet';
                            break;
                        case 'parttime':
                            value = 'Convention temps partiel';
                            break;
                        case 'HMONP':
                            value = 'Convention HMONP';
                            break;

                        default:
                            break;
                    }
                }

                result += value;

                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    };

    const downloadCSV = array => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;

        const filename = 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }

        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    };

    const ExpandedMission = ({ data }) => (
        <pre>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Titre mission : </span>
                    <span className='list-mission-value'>{data.name}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Tarif JE : </span>
                    <span className='list-mission-value'>{data.price_ht}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Total JE : </span>
                    <span className='list-mission-value'>{data.je}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Echéance : </span>
                    <span className='list-mission-value'>{data.mission_terms}</span>
                </div>
            </div>
            <div className='list-mission-container'>
                <div className='list-mission-detail'>
                    <span className='list-mission-label'>Numéro de téléphone : </span>
                    <span className='list-mission-value'>01 12 13 14 15 16</span>
                </div>
            </div>
        </pre>
    );

    return (
        <div
            className='backend-container'
            onClick={() => {
                if (editMode === 1) {
                    setEditMode(0);
                }
            }}>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>Missions</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                {isCompany() && (
                    <div className='list-flex-child flex-4 list-container-tabs'>
                        <Link to={'/mission/list'} className='profil-tabs'>
                            En cours
                        </Link>
                        <Link to={'/mission/list/completed'} className='profil-tabs'>
                            Terminées
                        </Link>
                        <Link to={'/mission/list/pending'} className='profil-tabs'>
                            A pourvoir
                        </Link>
                    </div>
                )}
                {!isCompany() && (
                    <div className='list-flex-child flex-4 list-container-tabs'>
                        <Link to={'/mission/list'} className='profil-tabs'>
                            En cours
                        </Link>
                        <Link to={'/mission/list/completed'} className='profil-tabs'>
                            Terminées
                        </Link>
                    </div>
                )}
                <div className='list-flex-child list-flex-container'>
                    <div className='list-container-searchbar searchbar-with-button'>
                        <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                        <input
                            id='search'
                            className='list-container-searchinput'
                            type='text'
                            placeholder='Rechercher'
                            value={filterText}
                            onChange={e => setFilterText(e.target.value)}
                        />
                    </div>
                    {editMode < 2 && (
                        <div className='profil-container-wipbutton'>
                            <span className='list-container-addlabel'>
                                <a
                                    onClick={() => {
                                        editMode === 1 ? setEditMode(0) : setEditMode(1);
                                    }}>
                                    +
                                </a>
                            </span>
                        </div>
                    )}
                    {editMode == 1 && (
                        <div className='more_actions'>
                            <div
                                className='more_actions_action'
                                onClick={() => {
                                    downloadCSV(filteredItems);
                                }}>
                                {/* display ham-menu icon */}
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                <div className='more_actions_item'>Exporter</div>
                            </div>
                            <div className='more_actions_action' onClick={() => setEditMode(1)}>
                                {/* display ham-menu icon */}
                                <div className='more_actions_icon'>
                                    <img
                                        className='ham_menu'
                                        src='/img/ham-menu.svg'
                                        alt='more actions'
                                    />
                                </div>
                                <div className='more_actions_item disabled'>Filtrer</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-container-divider-2' />
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child'>
                    {isCompany() && (
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Vous trouverez ci-dessous la liste de vos missions archivées.
                        </p>
                    )}
                    {!isCompany() && (
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Liste de toutes les missions terminées sur le réseau Jobers.
                        </p>
                    )}
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucune mission trouvée.</span>
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                        expandableRows
                        expandableRowsComponent={ExpandedMission}
                    />
                </div>
            </div>
        </div>
    );
}
