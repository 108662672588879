import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import Form from '../../Utils/Form';
import Input2 from '../../Utils/Input2';
import axios from 'axios';
import { useAuthDispatch, logout, useAuthState } from '../../../context';

export default function Parameter(props) {
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });
    const [updateError, setUpdateError] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const currentUser = useAuthState();
    const dispatch = useAuthDispatch();

    const handleLogout = () => {
        logout(dispatch);
    };

    useEffect(() => {}, [formData]);

    const onSubmit = async (fields, errors) => {
        setFormData({
            fields,
            errors,
        });

        let aDataFields = {
            login: currentUser.email,
        };
        let operation = 'update_account';

        if (typeof fields.delete_password !== 'undefined') {
            aDataFields = {
                ...aDataFields,
                delete_password: fields.delete_password.value,
            };
            operation = 'delete_account';
        }

        if (typeof fields.confirm_password !== 'undefined') {
            aDataFields = {
                ...aDataFields,
                current_password: fields.current_password.value,
                confirm_password: fields.confirm_password.value,
            };
        }

        let data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(aDataFields));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        let response = await axios(config);

        if (response.data.code === 0 && operation === 'delete_account') {
            handleLogout();
        }

        if (response.data.code !== 0) {
            if (operation === 'delete_account') {
                setDeleteError(response.data.message);
            }
            if (operation === 'update_account') {
                setUpdateError(response.data.message);
            }
            console.log(response.data.message);
        }
    };

    return (
        <div className='backend-container'>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>Mes paramètres</h1>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <p className='list-container-subtitle'>
                        Vous pouvez ici modifier votre mot de passe ou désactiver votre compte.
                    </p>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='profil-detail-divider' />
            </div>
            <Form
                onSubmit={onSubmit}
                fields={formData.fields}
                errors={formData.errors}
                validateOnSubmit={true}>
                <div className='list-flex-container'>
                    <Input2
                        id='current_password'
                        name='current_password'
                        label='Mon mot de passe actuel'
                        type='password'
                        validate='required|password'
                        classes={{
                            contClass: 'list-flex-child profil-flex-container',
                            errorClass: 'error-text',
                            fieldClass: 'profil-value',
                        }}
                    />
                    <div className='list-flex-child profil-flex-container'></div>
                </div>
                <div className='list-flex-container'>
                    <Input2
                        id='new_password'
                        name='new_password'
                        label='Nouveau mot de passe'
                        type='password'
                        validate='required|password'
                        classes={{
                            contClass: 'list-flex-child profil-flex-container',
                            errorClass: 'error-text',
                            fieldClass: 'profil-value',
                        }}
                    />
                    <div className='list-flex-child profil-flex-container'></div>
                </div>
                <div className='list-flex-container'>
                    <Input2
                        id='confirm_password'
                        name='confirm_password'
                        label='Confirmation'
                        type='password'
                        validate='required|confirm_password'
                        classes={{
                            contClass: 'list-flex-child profil-flex-container',
                            errorClass: 'error-text',
                            fieldClass: 'profil-value',
                        }}
                    />
                    <div className='list-flex-child profil-flex-container'></div>
                </div>
                {updateError && (
                    <div className='list-flex-container'>
                        <div className='list-flex-child profil-flex-container'>
                            <p class='error-text'>{updateError}</p>
                        </div>
                    </div>
                )}

                <div className='list-flex-container'>
                    <div className='list-flex-child profil-flex-container'>
                        <button type='submit' className='primary-submit'>
                            Mettre à jour mon mot de passe
                        </button>
                    </div>
                    <div className='list-flex-child profil-flex-container'></div>
                </div>
            </Form>
            <Form
                onSubmit={onSubmit}
                fields={formData.fields}
                errors={formData.errors}
                validateOnSubmit={true}>
                <div className='list-flex-container'>
                    <Input2
                        id='delete_password'
                        name='delete_password'
                        label='Saisir mot de passe'
                        type='password'
                        validate='required'
                        classes={{
                            contClass: 'list-flex-child profil-flex-container',
                            errorClass: 'error-text',
                            fieldClass: 'profil-value',
                        }}
                    />
                    <div className='list-flex-child profil-flex-container'></div>
                </div>
                {deleteError && (
                    <div className='list-flex-container'>
                        <div className='list-flex-child profil-flex-container'>
                            <p class='error-text'>{deleteError}</p>
                        </div>
                    </div>
                )}
                <div className='list-flex-container'>
                    <div className='list-flex-child profil-flex-container'>
                        <button type='submit' className='primary-submit'>
                            Supprimer mon compte
                        </button>
                    </div>
                    <div className='list-flex-child profil-flex-container'></div>
                </div>
            </Form>
            {currentUser && currentUser.function === 'Jobers' && (
                <div className='list-flex-container'>
                    <div className='list-flex-child profil-flex-container'>
                        <Link to={'/rates'} className='primary-submit'>
                            Cotisations sociales
                        </Link>
                    </div>
                    <div className='list-flex-child profil-flex-container'></div>
                </div>
            )}
        </div>
    );
}
