import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import moment from 'moment';
import { useAuthState } from '../../context';
import MissionDataService from '../../services/mission.service';

export default function OffrePublic(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        let key = 'SELECT Mission WHERE student_id = 0';
        const fields =
            'id, name, status, display_status, start_date, end_date, je, price_ht, company_id_friendlyname';
        MissionDataService.getAll(key, fields).then(response => {
            setData(response);
        });
    }, []);

    // return each data item in an div with className public-flex-child
    const renderData = () => {
        // return five first element
        return data.slice(0, 5).map((item, index) => {
            return (
                <div key={index} className='public-flex-child bordered'>
                    <p>{item.name}</p>
                    <p>{item.company}</p>
                    <p>{item.city}</p>
                    <p>
                        du {moment(item.start_date).format('DD/MM/YYYY')} au{' '}
                        {moment(item.end_date).format('DD/MM/YYYY')}
                    </p>
                </div>
            );
        });
    };

    return (
        <div className='backend-container'>
            <div className='list-flex-container'>{data && renderData()}</div>
        </div>
    );
}
