import React, { useState, useEffect, useRef } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import axios from 'axios';

import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';

import OrganizationDataService from '../../services/organization.service';
import DocumentDataService from '../../services/document.service';
import AttachmentDataService from '../../services/attachment.service';

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;
const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
    label,
    type,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const currentUser = useAuthState();
    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    async function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = error => {
                reject(error);
            };
        });
    }

    const sendFiles = async files => {
        let data = false;
        let b64 = false;
        let attachment = false;
        let filename = false;
        let operation = 'add_association_document';

        if (files.length > 0) {
            let file = files[0];
            b64 = await convertBase64(file);
            data = b64.substring(b64.indexOf('base64,') + 7);
            attachment = {
                item_class: 'Association',
                item_id: currentUser.org_id,
                temp_id: file.name,
                type: type,
                item_org_id: currentUser.org_id,
                creation_date: new Date(),
                contents: {
                    data: data,
                    filename: filename,
                    mimetype: file.type,
                },
            };
        }

        data = new URLSearchParams();
        data.append('operation', operation);
        data.append('data', JSON.stringify(attachment));

        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };

        return await axios(config);
    };

    const handleNewFileUpload = async e => {
        const { files: newFiles } = e.target;
        if (newFiles.length > 0) {
            let response = await sendFiles(newFiles);
            if (response.data.code === 0) {
                window.location.reload(false);
            }
        }
    };

    return (
        <section>
            <div className='more_actions_item' onClick={e => handleUploadBtnClick(e)}>
                <span>{label}</span>
            </div>
            <input
                type='file'
                ref={fileInputField}
                onChange={handleNewFileUpload}
                title=''
                value=''
                {...otherProps}
            />
        </section>
    );
};

export default function AssociationGestion(props) {
    const [data, setData] = useState([]);
    const { id } = useParams();
    const currentUser = useAuthState();
    const [association, setAssociation] = useState([]);
    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(item =>
        Object.keys(item).some(
            k =>
                typeof item[k] !== 'object' &&
                item[k].toLowerCase().includes(filterText.toLowerCase())
        )
    );
    const [type, setType] = useState('legal');
    const [editMode, setEditMode] = useState(0);

    useEffect(() => {
        let types = type === 'legal' ? '"Compte rendu", "Statuts"' : '"Relevé de banque"';
        let key =
            'SELECT Attachment WHERE item_class = "Association" AND item_id = ' +
            currentUser.org_id +
            ' AND type IN (' +
            types +
            ')';
        if (key) {
            AttachmentDataService.getAll(key, []).then(response => {
                setData(response);
            });
        }
    }, [type]);

    const displayTab = type => {
        return type === 'legal' ? 'list-container-divider-1' : 'list-container-divider-2';
    };

    const createDocumentLink = file => {
        if (file) {
            return (
                'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php?operation=display_document&filename=' +
                file.filename +
                '&id=' +
                file.id +
                '&obj=Association::' +
                currentUser.org_id
            );
        }
    };

    const columns = [
        {
            name: 'Type',
            selector: row => row.type,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Nom',
            selector: row => row.filename,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Date chargement',
            selector: row => <span>{moment(row.creation_date).format('DD-MM-YYYY')}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Télécharger',
            selector: row => {
                return (
                    <a className='list-table-link' href={createDocumentLink(row)} target='_blank'>
                        Télécharger
                    </a>
                );
            },
            filterable: true,
            sortable: true,
        },
    ];

    return (
        <div className='backend-container'>
            <Sidebar />
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4'>
                    <h1 className='list-container-title'>Gestion</h1>
                </div>
                <div className='list-flex-child'>
                    <span className='list-container-counter'>
                        {filteredItems && filteredItems.length}
                    </span>
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child flex-4 list-container-tabs'>
                    <Link to='#' className='profil-tabs' onClick={() => setType('legal')}>
                        Légal
                    </Link>
                    <Link to='#' className='profil-tabs' onClick={() => setType('banque')}>
                        Banque
                    </Link>
                </div>
                <div className='list-container-searchbar searchbar-with-button'>
                    <img className='dashboard-icon' src='/img/search.svg' alt='search' />
                    <input
                        id='search'
                        className='list-container-searchinput'
                        type='text'
                        placeholder='Rechercher'
                        value={filterText}
                        onChange={e => setFilterText(e.target.value)}
                    />
                </div>
                <div className='profil-container-wipbutton'>
                    <span className='list-container-addlabel'>
                        <a
                            onClick={() => {
                                editMode === 0 ? setEditMode(1) : setEditMode(0);
                            }}>
                            +
                        </a>
                    </span>
                </div>
                {editMode == 1 && type == 'legal' && (
                    <div className='more_actions'>
                        <div className='more_actions_action' onClick={() => setEditMode(1)}>
                            {/* display ham-menu icon */}
                            <div className='more_actions_icon'>
                                <img
                                    className='ham_menu'
                                    src='/img/ham-menu.svg'
                                    alt='more actions'
                                />
                            </div>
                            <FileUpload
                                type='Statuts'
                                accept='.jpg,.png,.jpeg,.pdf'
                                label='Charger statuts'
                            />
                        </div>
                        <div className='more_actions_action' onClick={() => setEditMode(1)}>
                            {/* display ham-menu icon */}
                            <div className='more_actions_icon'>
                                <img
                                    className='ham_menu'
                                    src='/img/ham-menu.svg'
                                    alt='more actions'
                                />
                            </div>
                            <FileUpload
                                type='Compte rendu'
                                accept='.jpg,.png,.jpeg,.pdf'
                                label='Charger CR'
                            />
                        </div>
                    </div>
                )}
                {editMode == 1 && type == 'banque' && (
                    <div className='more_actions'>
                        <div className='more_actions_action' onClick={() => setEditMode(1)}>
                            {/* display ham-menu icon */}
                            <div className='more_actions_icon'>
                                <img
                                    className='ham_menu'
                                    src='/img/ham-menu.svg'
                                    alt='more actions'
                                />
                            </div>
                            <FileUpload
                                type='Relevé de banque'
                                accept='.jpg,.png,.jpeg,.pdf'
                                label='Charger'
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className='list-flex-container'>
                <div className={displayTab(type)} />
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child'>
                    {type === 'legal' && (
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Documents officiels de l'association.
                        </p>
                    )}
                    {type === 'banque' && (
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Liste des relevés bancaires de l'association.
                        </p>
                    )}
                </div>
            </div>
            <div className='list-flex-container'>
                <div className='list-flex-child list-table-container flex-4'>
                    <DataTable
                        noDataComponent={
                            <span className='rdt_noresults'>Aucun document trouvé.</span>
                        }
                        columns={columns}
                        data={filteredItems}
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    );
}
