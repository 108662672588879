import './MissionView.css';
import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { useParams, Link, Navigate } from 'react-router-dom';

import DataTable from 'react-data-table-component';

import Sidebar from '../../Sidebar/Sidebar';

import http from '../../../../http-common';
import vars from '../../../../config/vars';

import { useAuthState } from '../../../../context';

import MissionDataService from '../../../../services/mission.service';
import CompanyDataService from '../../../../services/company.service';
import StudentDataService from '../../../../services/student.service';

const MissionView = () => {
    const { id } = useParams();
    const [initialObjectState, setInitialObjectState] = useState(null);
    const [candidateData, setCandidateData] = useState([]);
    const [candidateIndex, setCandidateIndex] = useState(-1);
    const currentUser = useAuthState();

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                company: 'Jobers',
                name: 'Mise en place de bureaux',
                description: 'Description test',
                student_id: 0,
                student_details: null,
            };
            if (typeof id === 'undefined') {
                setInitialObjectState(objectData);
            }
            if (id) {
                MissionDataService.get(id).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    CompanyDataService.get(objectData.company_id).then(response => {
                        objectData.company_details = response;

                        const key =
                            'SELECT Person AS P JOIN lnkMissionToStudent AS lnk ON lnk.student_id = P.id WHERE P.function = "Student" AND lnk.mission_id = ' +
                            id;
                        const fields =
                            'id, first_name, name, email, phone, function, location_address, location_postal_code, location_city, location_country, started_at, joined_at, nationality, year, school_year, school, ssn, bank, iban, bic, book_url, status, org_name';
                        StudentDataService.getAll(key, fields).then(response => {
                            if (objectData.student_id > 0) {
                                StudentDataService.get(objectData.student_id).then(response => {
                                    objectData.student_details = response;
                                    setInitialObjectState(objectData);
                                });
                            }

                            setCandidateData(response);
                            setInitialObjectState(objectData);
                        });
                        if (objectData.student_id === '0') {
                            setInitialObjectState(objectData);
                        }
                    });
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    const handleAction = (e, id) => {
        setCandidateIndex(id);
    };

    const acceptStudent = (e, studentId) => {
        let fields = {
            student_id: studentId,
            status: 'accepted',
        };
        let output_fields = 'id, friendlyname';
        MissionDataService.upsert(id, fields, output_fields).then(response => {});
        setInitialObjectState(false);
    };

    const renderSkills = skills => {
        return skills.map((skill, index) => {
            return (
                <li className='list-group-item list-group-item-primary' key={index}>
                    <span className='dotLi' />
                    <span className='dotLabel'>{skill.label}</span>
                </li>
            );
        });
    };

    let candidateColumns = [
        {
            name: 'Prénom',
            selector: row => <span onClick={e => handleAction(e, row.id)}>{row.first_name}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Nom',
            selector: row => <span onClick={e => handleAction(e, row.id)}>{row.name}</span>,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Statut',
            selector: row => CandidateStatusComponent(row),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Association',
            selector: row => row.org_name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Adresse email',
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {row.email}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
        {
            name: 'Numéro de téléphone',
            selector: row => (
                <span className='rdt_GrayCell' onClick={e => handleAction(e, row.id)}>
                    {row.phone}
                </span>
            ),
            filterable: true,
            sortable: true,
        },
    ];

    {
        initialObjectState &&
            initialObjectState.company_id === currentUser.org_id &&
            initialObjectState.student_id === '0' &&
            candidateColumns.push({
                name: 'Actions',
                cell: row => (
                    <button
                        className='dashboard-filter-bg dashboard-filter-label'
                        onClick={e => acceptStudent(e, row.id)}>
                        Valider candidature
                    </button>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            });
    }

    function CandidateStatusComponent(row) {
        let className = 'rdt_StatusCell';
        if (row.display_status == 'Disponible') {
            className = 'rdt_StatusCell rdt_StatusAvailable';
        }
        if (row.display_status == 'En cours de mission') {
            className = 'rdt_StatusCell rdt_StatusOngoing';
        }
        if (row.display_status == 'En attente') {
            className = 'rdt_StatusCell rdt_StatusWaiting';
        }
        return (
            <span className={className} onClick={e => handleAction(e, row.id)}>
                {row.display_status}
            </span>
        );
    }

    function StatusComponent(status) {
        let className = 'rdt_StatusCell';
        if (status == 'Disponible') {
            className = 'rdt_StatusCell rdt_StatusAvailable';
        }
        if (status == 'En cours de mission') {
            className = 'rdt_StatusCell rdt_StatusOngoing';
        }
        if (status == 'En attente') {
            className = 'rdt_StatusCell rdt_StatusWaiting';
        }
        return <span className={className}>{status}</span>;
    }

    const renderValue = value => {
        switch (value) {
            case 'normal':
                return 'Mission ordinaire';
                break;
            case 'hmonp':
                return 'Mission HMONP';
                break;
            case 'fulltime':
                return 'Temps plein';
                break;
            case 'halftime':
                return 'Temps partiel';
                break;

            default:
                break;
        }
    };

    const onSubmit = (fields, errors) => {};

    const handleChange = event => {};

    const apply = async e => {
        let fields = {
            student_id: currentUser.id,
            mission_id: initialObjectState.id,
        };

        let data = {
            operation: 'core/create',
            comment: "Candidature de l'étudiant",
            class: 'lnkMissionToStudent',
            fields: fields,
            output_fields: 'id, friendlyname',
        };

        console.log(fields);
        console.log(data);

        // BUILD PARAMS ARRAY
        const params = new URLSearchParams();
        params.append('json_data', JSON.stringify(data));

        // STORE REQUEST RESPONSE
        let response = await http.post(
            'https://itop.jobers.fr/webservices/rest.php?version=1.3',
            params
        );

        setInitialObjectState(false);
    };

    return (
        <div className='missionView screen'>
            <div className='flex-col'>
                <Sidebar />
            </div>
            <div className='flex-col'>
                <div className='flex-row'>
                    {initialObjectState && (
                        <div className='missionTitle'>{initialObjectState.name}</div>
                    )}
                    {initialObjectState && StatusComponent(initialObjectState.display_status)}
                    {currentUser.function === 'Student' && (
                        <div className='dashboard-topbar-element dashboard-filter-bg candidateButton'>
                            <div
                                className='dashboard-topbar-label dashboard-filter-label'
                                onClick={e => apply()}>
                                Postuler
                            </div>
                        </div>
                    )}
                    {initialObjectState && initialObjectState.company_id === currentUser.org_id && (
                        <div className='dashboard-topbar-element dashboard-filter-bg editMissionButton'>
                            <div className='dashboard-topbar-label dashboard-filter-label'>
                                <Link to={'/company/missions/' + initialObjectState.id + '/edit'}>
                                    Editer mission
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
                <div className='flex-row missionDetails bordered'>
                    <div className='mission-2-col'>
                        <div className='missionLabel inter-medium-oxford-blue-14px'>
                            Dates de la mission
                        </div>
                        {initialObjectState && (
                            <div className='missionValue inter-normal-pale-sky-16px'>
                                du {moment(initialObjectState.start_date).format('DD MM YYYY')}
                                <br />
                                au {moment(initialObjectState.end_date).format('DD MM YYYY')}
                            </div>
                        )}
                        <div className='divider' />
                        <div className='missionLabel inter-medium-oxford-blue-14px'>
                            Type de mission
                        </div>
                        {initialObjectState && (
                            <div className='missionValue inter-normal-pale-sky-16px'>
                                {renderValue(initialObjectState.mission_type)}
                            </div>
                        )}
                    </div>
                    {initialObjectState && (
                        <div className='mission-2-col'>
                            <div className='missionLabel inter-medium-oxford-blue-14px'>
                                Niveau d'études
                            </div>
                            <div className='missionValue inter-normal-pale-sky-16px'>
                                <ul className='list-group'>
                                    {renderSkills(initialObjectState.study_levels)}
                                </ul>
                            </div>
                            <div className='divider' />
                            <div className='missionLabel inter-medium-oxford-blue-14px'>
                                Durée de travail
                            </div>
                            <div className='missionValue inter-normal-pale-sky-16px'>
                                {renderValue(initialObjectState.work_type)}
                            </div>
                        </div>
                    )}
                </div>
                <div className='flex-row missionDetails'>
                    <div className='mission-2-col missionMedCol bordered'>
                        <div className='frame-1'>
                            {initialObjectState && (
                                <div
                                    className='frame-2'
                                    key={initialObjectState.company_details.picture.filename}>
                                    <img
                                        width={'50px'}
                                        height={'50px'}
                                        src={
                                            'data:' +
                                            initialObjectState.company_details.picture.mimetype +
                                            ';base64,' +
                                            initialObjectState.company_details.picture.data
                                        }
                                    />
                                </div>
                            )}
                            {initialObjectState && (
                                <Link
                                    className='text-10'
                                    to={'/association/companies/' + initialObjectState.company_id}>
                                    {initialObjectState.company}
                                </Link>
                            )}
                        </div>
                        {initialObjectState && (
                            <div className='missionValue inter-normal-pale-sky-16px'>
                                {initialObjectState.company_details.phone}
                            </div>
                        )}
                        {initialObjectState && (
                            <div className='missionValue inter-normal-pale-sky-16px'>
                                {initialObjectState.company_details.email}
                            </div>
                        )}
                    </div>
                    {initialObjectState && initialObjectState.student_id === '0' && (
                        <div className='mission-2-col missionMedCol bordered'>
                            <div className='missionLabel missionSkill inter-medium-oxford-blue-14px'>
                                Compétences principales
                            </div>
                            <div className='missionValue inter-normal-pale-sky-16px'>
                                <ul className='list-group'>
                                    {renderSkills(initialObjectState.main_skills)}
                                </ul>
                            </div>
                            <div className='divider' />
                            <div className='missionLabel missionSkill inter-medium-oxford-blue-14px'>
                                Compétences secondaires
                            </div>
                            <div className='missionValue inter-normal-pale-sky-16px'>
                                <ul className='list-group'>
                                    {renderSkills(initialObjectState.secondary_skills)}
                                </ul>
                            </div>
                            <div className='divider' />
                        </div>
                    )}
                    {initialObjectState && initialObjectState.student_id > 0 && (
                        <div className='mission-2-col missionMedCol bordered'>
                            <div className='frame-1'>
                                {initialObjectState && (
                                    <div className='frame-2' key={initialObjectState.student_id}>
                                        <img
                                            width={'50px'}
                                            height={'50px'}
                                            src='/img/customer.svg'
                                        />
                                    </div>
                                )}
                                {initialObjectState && (
                                    <Link
                                        className='text-10'
                                        to={
                                            '/association/students/' + initialObjectState.student_id
                                        }>
                                        {initialObjectState.student}
                                    </Link>
                                )}
                            </div>
                            {initialObjectState && initialObjectState.student_details && (
                                <div className='missionValue inter-normal-pale-sky-16px'>
                                    {initialObjectState.student_details.phone}
                                </div>
                            )}
                            {initialObjectState && initialObjectState.student_details && (
                                <div className='missionValue inter-normal-pale-sky-16px'>
                                    {initialObjectState.student_details.email}
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {initialObjectState && initialObjectState.student_id === '0' && (
                    <div className='flex-row missionDetails'>
                        <div className='mission-2-col missionBigCol bordered'>
                            <iframe
                                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.0756306489484!2d2.3488791156546567!3d48.85676817928737!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e1dee160761%3A0x7cc70b2b304c91ab!2sPl.%20de%20l&#39;H%C3%B4tel%20de%20Ville%2C%2075004%20Paris!5e0!3m2!1sfr!2sfr!4v1664773421449!5m2!1sfr!2sfr'
                                className='missionMaps'
                                allowFullScreen=''
                                loading='lazy'
                                referrerPolicy='no-referrer-when-downgrade'></iframe>
                        </div>
                        <div className='mission-2-col missionBigCol bordered'>
                            <p
                                className='missionDescription inter-normal-pale-sky-16px'
                                dangerouslySetInnerHTML={{
                                    __html: initialObjectState.description,
                                }}></p>
                        </div>
                    </div>
                )}
                {initialObjectState && initialObjectState.student_id > 0 && (
                    <div className='flex-row'>
                        <div className='mission-2-col missionMedCol missionDocLinks'>
                            <div className='dashboard-topbar-element dashboard-filter-bg missionDocumentLink'>
                                {initialObjectState && (
                                    <Link
                                        className='dashboard-topbar-label dashboard-filter-label'
                                        to={
                                            '/document/studyagreement/' +
                                            initialObjectState.studyagreement_id
                                        }>
                                        Convention d'études
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div className='mission-2-col missionMedCol missionDocLinks'>
                            <div className='dashboard-topbar-element dashboard-filter-bg missionDocumentLink'>
                                {initialObjectState && (
                                    <Link
                                        className='dashboard-topbar-label dashboard-filter-label'
                                        to={
                                            '/document/studentmission/' +
                                            initialObjectState.studentmission_id
                                        }>
                                        Acceptation de mission
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {initialObjectState && initialObjectState.student_id > 0 && (
                    <div className='flex-row missionDetails missionDocumentLinkBox'>
                        <div className='mission-2-col missionMedCol bordered'>
                            <span className='documentsTitle'>
                                Factures associées
                                <br />
                            </span>
                            <ul className='list-group'>
                                {initialObjectState &&
                                    initialObjectState.documents_list.map((document, index) => {
                                        if (document.documenttype_name === 'Facture') {
                                            return (
                                                <li className='list-group-item list-group-item-primary'>
                                                    <span className='dotLi' />
                                                    <span className='dotLabel'>
                                                        <Link
                                                            to={'/document/facture/' + document.id}
                                                            className='inter-normal-pale-sky-16px'>
                                                            {document.name}
                                                        </Link>
                                                    </span>
                                                </li>
                                            );
                                        }
                                    })}
                            </ul>
                        </div>
                        <div className='mission-2-col missionMedCol bordered'>
                            <span className='documentsTitle'>BV associés</span>
                            <ul className='list-group'>
                                {initialObjectState &&
                                    initialObjectState.documents_list.map((document, index) => {
                                        if (document.documenttype_name === 'Facture') {
                                            return (
                                                <li className='list-group-item list-group-item-primary'>
                                                    &nbsp;
                                                </li>
                                            );
                                        }
                                        if (document.documenttype_name === 'Bon de versement') {
                                            return (
                                                <li className='list-group-item list-group-item-primary'>
                                                    <span className='dotLi' />
                                                    <span className='dotLabel'>
                                                        {document.name}
                                                    </span>
                                                </li>
                                            );
                                        }
                                    })}
                            </ul>
                        </div>
                    </div>
                )}

                <div className='flex-child flex-list'>
                    <div className='dashboard-topbar-list'>
                        <div className='dashboard-title-subtitle-container'>
                            <div className='dashboard-title'>{candidateData.length} Candidats</div>
                            <p className='dashboard-subtitle'>
                                Cet onglet permet de voir toutes les candidatures reçues pour cette
                                mission
                            </p>
                        </div>
                    </div>
                    <DataTable
                        columns={candidateColumns}
                        data={candidateData}
                        pagination
                        persistTableHead
                    />
                    {candidateIndex > 0 && (
                        <Navigate to={'/student/' + candidateIndex} replace={true} />
                    )}
                </div>
            </div>
        </div>
    );
};
export default MissionView;
