import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import Form from '../Utils/Form';
import Input2 from '../Utils/Input2';

import moment from 'moment';
import axios from 'axios';

import Sidebar from '../Backend/Sidebar/Sidebar';
import { useAuthState } from '../../context';
import CompanyDataService from '../../services/company.service';

export default function EntrepriseProfil(props) {
    const [tabClass, setTabClass] = useState('profil-container-divider-1');
    const [initialObjectState, setInitialObjectState] = useState(null);
    const [editMode, setEditMode] = useState(0);
    const [formData, setFormData] = useState({
        fields: {},
        errors: {},
    });

    let { id } = useParams();
    const currentUser = useAuthState();

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                name: 'Nom non communiqué',
                siret: 'Pas de numéro de SIRET',
                email: 'Aucune adresse email connue',
                phone: 'Pas de téléphone',
                started_at: '3999-01-01',
                contact: 'Pas de contact connu',
                l_fullname: '',
                l_email: '',
                c_fullname: '',
                c_email: '',
                c_phone: '',
            };
            if (typeof currentUser.id === '0') {
                setInitialObjectState(objectData);
            }
            let key = null;
            if (id !== '0' && id !== 'me') {
                key = id;
            }
            if (currentUser.id !== '0' && (id == 'me' || props.id == 'me')) {
                id = currentUser.org_id;
                key =
                    'SELECT Company AS C JOIN Person AS P ON P.org_id = C.id WHERE P.id = ' +
                    currentUser.id;
            }
            if (key) {
                CompanyDataService.get(key).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    objectData.contact = currentUser.userDetails;
                    objectData.email = '';
                    objectData.phone = '';
                    objectData.address = '';
                    objectData.postalcode = '';
                    objectData.city = '';
                    objectData.country = '';
                    objectData.l_fullname = '';
                    objectData.l_email = '';
                    objectData.c_fullname = '';
                    objectData.c_email = '';
                    objectData.c_phone = '';

                    Object.keys(objectData.persons_list).forEach(key => {
                        if (objectData.persons_list[key].function == 'Compte entreprise') {
                            objectData.email = objectData.persons_list[key].email;
                            objectData.phone = objectData.persons_list[key].phone;
                            objectData.address = objectData.persons_list[key].location_address;
                            objectData.postcode = objectData.persons_list[key].location_postal_code;
                            objectData.city = objectData.persons_list[key].location_city;
                            objectData.country = objectData.persons_list[key].location_country;
                        }
                        if (objectData.persons_list[key].function == 'Représentant légal') {
                            objectData.l_first_name = objectData.persons_list[key].first_name;
                            objectData.l_last_name = objectData.persons_list[key].name;
                            objectData.l_fullname =
                                objectData.l_first_name + ' ' + objectData.l_last_name;
                            objectData.l_email = objectData.persons_list[key].email;
                            objectData.l_phone = objectData.persons_list[key].phone;
                        }
                        if (objectData.persons_list[key].function == 'Personne de contact') {
                            objectData.c_first_name = objectData.persons_list[key].first_name;
                            objectData.c_last_name = objectData.persons_list[key].name;
                            objectData.c_fullname =
                                objectData.c_first_name + ' ' + objectData.c_last_name;
                            objectData.c_email = objectData.persons_list[key].email;
                            objectData.c_phone = objectData.persons_list[key].phone;
                        }
                    });

                    setInitialObjectState(objectData);
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    const handleTab = className => {
        setTabClass(className);
    };

    const confirm = async e => {
        let aDataFields = {
            item_class: 'Company',
            item_id: initialObjectState.id,
        };
        let data = new URLSearchParams();
        data.append('operation', 'confirm_item');
        data.append('data', JSON.stringify(aDataFields));
        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };
        let response = await axios(config);
        if (response.data.code === 0) {
            window.location.reload(false);
        }
        if (response.data.code !== 0) {
            console.log(response.data.message);
        }
    };

    const displayField = (label, value, options) => {
        let readOnlyFields = ['started_at', 'org_id', 'display_status', 'domain', 'name', 'siret'];
        if (true) {
            if (editMode == 2 && readOnlyFields.indexOf(options.name) == -1) {
                return (
                    <>
                        <Input2 {...options} />
                    </>
                );
            }
            return (
                <>
                    <div className='list-flex-child profil-flex-container'>
                        <div className='profil-flex-child'>
                            <div className='profil-label'>{label}</div>
                        </div>
                        <div className='profil-flex-child'>
                            <div className='profil-value gray-value'>{value}</div>
                        </div>
                    </div>
                </>
            );
        }
    };

    const canEdit = () => {
        if (
            initialObjectState &&
            currentUser.org_id == initialObjectState.id &&
            (currentUser.function === 'Représentant légal' ||
                currentUser.function === 'Compte entreprise')
        ) {
            return true;
        }
        if (currentUser.function === 'Jobers') {
            return false;
        }
        return false;
    };

    const isAssociation = () => {
        if (currentUser.function === 'Compte association') {
            return true;
        }
        if (currentUser.function === 'Président') {
            return true;
        }
        return false;
    };

    const isCompany = () => {
        if (currentUser.function === 'Compte entreprise') {
            return true;
        }
        if (currentUser.function === 'Représentant légal') {
            return true;
        }
        if (currentUser.function === 'Personne de contact') {
            return true;
        }
        return false;
    };

    const isConnected = () => {
        if (isAssociation() && initialObjectState) {
            let isFound = false;
            initialObjectState.missions_list.forEach(mission => {
                if (mission.student_org_id === currentUser.org_id) {
                    isFound = true;
                }
            });
            return isFound;
        }
    };

    const onSubmit = async (fields, errors) => {
        setFormData({
            fields,
            errors,
        });
        let key = null;
        if (id !== '0' && id !== 'me') {
            key = id;
        }
        if (currentUser.id !== '0' && (id == 'me' || props.id == 'me')) {
            key =
                'SELECT Company AS C JOIN Person AS P ON P.org_id = C.id WHERE P.id = ' +
                currentUser.id;
        }
        let aDataFields = {
            id: key,
            name: initialObjectState.name,
            // domain: fields.domain.value,
            siret: initialObjectState.siret,
            address: fields.address.value,
            postalcode: fields.postalcode.value,
            city: fields.city.value,
            email: fields.email.value,
            phone: fields.phone.value,
            l_fullname: fields.l_fullname.value,
            c_fullname: fields.c_fullname.value,
            c_email: fields.c_email.value,
            c_phone: fields.c_phone.value,
        };

        let data = new URLSearchParams();
        data.append('operation', 'upsert_entreprise');
        data.append('data', JSON.stringify(aDataFields));
        let config = {
            method: 'post',
            url: 'https://itop.jobers.fr/env-production/jobers-api/ajax.jobers-api.php',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
            },
            data: data,
        };
        let response = await axios(config);
        if (response.data.code === 0) {
            window.location.reload(false);
        }
        if (response.data.code !== 0) {
            if (response.data.message.includes('est déjà utilisée par un autre utilisateur')) {
                alert('Cette adresse email est déjà utilisée par un autre utilisateur');
            }
            console.log(response.data.message);
        }
    };

    if (!initialObjectState || (initialObjectState && initialObjectState.id === null)) {
        return (
            <div className='lds-roller'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

    return (
        <div className='backend-container'>
            <Sidebar />
            <Form
                onSubmit={onSubmit}
                fields={formData.fields}
                errors={formData.errors}
                validateOnSubmit={true}>
                <div className='list-flex-container'>
                    <div className='list-flex-child flex-4'>
                        {initialObjectState && (
                            <h1 className='list-container-title'>{initialObjectState.name}</h1>
                        )}
                    </div>
                </div>
                <div className='list-flex-container'>
                    {initialObjectState && id === null && (
                        <div className='list-flex-child flex-4 list-container-tabs'>
                            <Link
                                to={'#'}
                                className='profil-tabs'
                                onMouseOver={() => handleTab('profil-container-divider-1')}>
                                Profil
                            </Link>
                        </div>
                    )}
                    {(currentUser.function === 'Jobers' ||
                        (initialObjectState &&
                            isCompany() &&
                            currentUser.org_id === initialObjectState.id) ||
                        (isAssociation() && isConnected())) && (
                        <div className='list-flex-child flex-4 list-container-tabs'>
                            <Link
                                to={'/entreprise/' + initialObjectState.id + '/profil'}
                                className='profil-tabs'>
                                Profil
                            </Link>
                            <Link
                                to={'/entreprise/' + initialObjectState.id + '/mission'}
                                className='profil-tabs'>
                                Missions
                            </Link>
                            <Link
                                to={'/entreprise/' + initialObjectState.id + '/document'}
                                className='profil-tabs'>
                                Documents
                            </Link>
                        </div>
                    )}
                    {((currentUser.function === 'Jobers' &&
                        initialObjectState.status == 'pending') ||
                        canEdit()) && (
                        <div className='list-flex-container'>
                            {editMode == 2 && (
                                <div className='profil-container-submitbutton'>
                                    <span className='list-container-submitlabel'>
                                        <button type='submit' className='primary-submit'>
                                            {initialObjectState && id !== 'undefined'
                                                ? 'Mettre à jour'
                                                : 'Créer'}
                                        </button>
                                        {/* <Link to={'/association/' + id + '/profil'}>Enregistrer</Link> */}
                                    </span>
                                </div>
                            )}
                            {editMode < 2 && (
                                <div className='profil-container-wipbutton'>
                                    <span className='list-container-addlabel'>
                                        <a
                                            onClick={() => {
                                                editMode === 1 ? setEditMode(0) : setEditMode(1);
                                            }}>
                                            +
                                        </a>
                                    </span>
                                </div>
                            )}
                            {editMode == 1 &&
                                currentUser.function === 'Jobers' &&
                                initialObjectState.status == 'pending' && (
                                    <div className='more_actions'>
                                        <div
                                            className='more_actions_action'
                                            onClick={() => confirm()}>
                                            {/* display ham-menu icon */}
                                            <div className='more_actions_icon'>
                                                <img
                                                    className='ham_menu'
                                                    src='/img/ham-menu.svg'
                                                    alt='more actions'
                                                />
                                            </div>
                                            <div className='more_actions_item'>Confirmer</div>
                                        </div>
                                    </div>
                                )}
                            {editMode === 1 && canEdit() && (
                                <div className='more_actions'>
                                    <div
                                        className='more_actions_action'
                                        onClick={() => setEditMode(2)}>
                                        {/* display ham-menu icon */}
                                        <div className='more_actions_icon'>
                                            <img
                                                className='ham_menu'
                                                src='/img/ham-menu.svg'
                                                alt='more actions'
                                            />
                                        </div>
                                        <div className='more_actions_item'>Modifier</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className='list-flex-container'>
                    <div className={tabClass} />
                </div>
                <div className='list-flex-container'>
                    <div className='list-flex-child'>
                        <p className='list-container-subtitle list-tab-subtitle'>
                            Ces informations sont visibles par les membres du réseau Jobers.
                        </p>
                    </div>
                </div>
                <div className='list-flex-container'>
                    {initialObjectState &&
                        displayField('Raison sociale', initialObjectState.name, {
                            id: 'name',
                            name: 'name',
                            label: 'Raison sociale',
                            placeholder: 'Nom de l’entreprise',
                            type: 'autocomplete',
                            value: initialObjectState.name,
                            classes: {
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            },
                        })}
                    {initialObjectState &&
                        displayField(
                            'Domaine',
                            initialObjectState.domain.charAt(0).toUpperCase() +
                                initialObjectState.domain.slice(1),
                            {
                                id: 'domain',
                                name: 'domain',
                                label: 'Domaine',
                                placeholder: 'Architecture',
                                type: 'select',
                                value:
                                    initialObjectState.domain.charAt(0).toUpperCase() +
                                    initialObjectState.domain.slice(1),
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                                options: [
                                    { value: 'architecture', label: 'Architecture' },
                                    { value: 'paysage', label: 'Paysage' },
                                    { value: 'commerce', label: 'Commerce' },
                                    { value: 'communication', label: 'Communication' },
                                    { value: 'ingenierie', label: 'Ingenierie' },
                                ],
                            }
                        )}
                    <span id='bo_domain_tooltip'>
                        <div className='icon-size'>
                            <img
                                className='icon-img'
                                alt='go-back'
                                src='/img/10_QUESTION_MARK.svg'
                            />
                        </div>
                        <Tooltip
                            anchorId='bo_domain_tooltip'
                            content='Nous vous mettrons en contact avec les étudiants de votre domaine d’expertise !'
                            place='left'
                            style={{
                                backgroundColor: '#fff',
                                color: 'gray',
                                width: '20vw',
                                border: '1px solid #ccc',
                                marginTop: '10px',
                                marginLeft: '-30px',
                                borderRadius: '8px',
                                fontFamily: 'Mont-Book',
                                fontSize: '11pt',
                                letterSpacing: 0,
                            }}
                        />
                    </span>
                </div>
                <div className='list-flex-container'>
                    {initialObjectState &&
                        displayField(
                            'Première inscription',
                            initialObjectState.started_at &&
                                moment(initialObjectState.started_at).format('DD-MM-YYYY'),
                            {
                                id: 'started_at',
                                name: 'started_at',
                                label: 'Première inscription',
                                placeholder: '2022-01-01',
                                type: 'text',
                                value:
                                    initialObjectState.started_at != '' &&
                                    moment(initialObjectState.started_at).format('DD-MM-YYYY'),
                                classes: {
                                    contClass: 'list-flex-child profil-flex-container',
                                    errorClass: 'error-text',
                                    fieldClass: 'profil-value',
                                },
                            }
                        )}
                    {initialObjectState &&
                        displayField('Statut', initialObjectState.display_status, {
                            id: 'display_status',
                            name: 'display_status',
                            label: 'Statut',
                            placeholder: 'Inactif',
                            type: 'text',
                            value: initialObjectState.display_status,
                            classes: {
                                contClass: 'list-flex-child profil-flex-container',
                                errorClass: 'error-text',
                                fieldClass: 'profil-value',
                            },
                        })}
                </div>
                {(currentUser.function === 'Jobers' ||
                    (initialObjectState &&
                        isCompany() &&
                        currentUser.org_id == initialObjectState.id) ||
                    (isAssociation() && isConnected())) && (
                    <div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            <div className='list-flex-child'>
                                <p className='list-container-subtitle list-tab-subtitle'>
                                    Ces informations restent privées et ne sont visibles que par les
                                    admins Jobers.
                                </p>
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Adresse', initialObjectState.address, {
                                    id: 'address',
                                    name: 'address',
                                    label: 'Adresse',
                                    placeholder: '1 rue de la paix',
                                    type: 'text',
                                    value: initialObjectState.address,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('Email de connexion', initialObjectState.email, {
                                    id: 'email',
                                    name: 'email',
                                    label: 'Email de connexion',
                                    placeholder: 'john.doe@gmail.com',
                                    type: 'text',
                                    value: initialObjectState.email,
                                    validate: 'required|email',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Code postal', initialObjectState.postcode, {
                                    id: 'postalcode',
                                    name: 'postalcode',
                                    label: 'Code postal',
                                    placeholder: '75000',
                                    type: 'text',
                                    value: initialObjectState.postcode,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('Téléphone', initialObjectState.phone, {
                                    id: 'phone',
                                    name: 'phone',
                                    label: 'Téléphone',
                                    placeholder: '06 00 00 00 00',
                                    type: 'text|phone',
                                    value: initialObjectState.phone,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Ville', initialObjectState.city, {
                                    id: 'city',
                                    name: 'city',
                                    label: 'Ville',
                                    placeholder: 'Paris',
                                    type: 'text',
                                    value: initialObjectState.city,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            {initialObjectState &&
                                displayField('N° SIRET', initialObjectState.siret, {
                                    id: 'siret',
                                    name: 'siret',
                                    label: 'N° SIRET',
                                    placeholder: '123456789',
                                    type: 'text',
                                    value: initialObjectState.siret,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                        </div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Représentant légal', initialObjectState.l_fullname, {
                                    id: 'l_fullname',
                                    name: 'l_fullname',
                                    label: 'Représentant légal',
                                    placeholder: 'John Doe',
                                    type: 'text',
                                    value: initialObjectState.l_fullname,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'>
                                    <div className='profil-label'></div>
                                </div>
                                <div className='profil-flex-child'></div>
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            <div className='profil-detail-divider' />
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Contact facultatif', initialObjectState.c_fullname, {
                                    id: 'c_fullname',
                                    name: 'c_fullname',
                                    label: 'Contact facultatif',
                                    placeholder: 'John Doe',
                                    type: 'text',
                                    value: initialObjectState.c_fullname,
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'>
                                    <div className='profil-label'></div>
                                </div>
                                <div className='profil-flex-child'></div>
                            </div>
                            <span id='bo_contact_tooltip'>
                                <div className='icon-size'>
                                    <img
                                        className='icon-img'
                                        alt='go-back'
                                        src='/img/10_QUESTION_MARK.svg'
                                    />
                                </div>
                                <Tooltip
                                    anchorId='bo_contact_tooltip'
                                    content='Si vous souhaitez préciser une personne de contact, nous communiquerons avec vous grâce à ces coordonnées.'
                                    place='left'
                                    style={{
                                        backgroundColor: '#fff',
                                        color: 'gray',
                                        width: '20vw',
                                        border: '1px solid #ccc',
                                        marginTop: '10px',
                                        marginLeft: '-30px',
                                        borderRadius: '8px',
                                        fontFamily: 'Mont-Book',
                                        fontSize: '11pt',
                                        letterSpacing: 0,
                                    }}
                                />
                            </span>
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Mail comptable', initialObjectState.c_email, {
                                    id: 'c_email',
                                    name: 'c_email',
                                    label: 'Mail comptable',
                                    placeholder: 'john.doe@gmail.com',
                                    type: 'text',
                                    value: initialObjectState.c_email,
                                    validate: 'email',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'>
                                    <div className='profil-label'></div>
                                </div>
                                <div className='profil-flex-child'></div>
                            </div>
                        </div>
                        <div className='list-flex-container'>
                            {initialObjectState &&
                                displayField('Téléphone comptable', initialObjectState.c_phone, {
                                    id: 'c_phone',
                                    name: 'c_phone',
                                    label: 'Téléphone comptable',
                                    placeholder: '06 00 00 00 00',
                                    type: 'text',
                                    value: initialObjectState.c_phone,
                                    validate: 'phone',
                                    classes: {
                                        contClass: 'list-flex-child profil-flex-container',
                                        errorClass: 'error-text',
                                        fieldClass: 'profil-value',
                                    },
                                })}
                            <div className='list-flex-child profil-flex-container'>
                                <div className='profil-flex-child'>
                                    <div className='profil-label'></div>
                                </div>
                                <div className='profil-flex-child'></div>
                            </div>
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
}
