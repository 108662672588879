import './FileUpload.css';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { FormCtx } from './Form';

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000;

const convertNestedObjectToArray = nestedObj => Object.keys(nestedObj).map(key => nestedObj[key]);

const convertBytesToKB = bytes => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
    label,
    updateFilesCb,
    maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
    ...otherProps
}) => {
    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const addNewFiles = newFiles => {
        for (let file of newFiles) {
            if (file.size <= maxFileSizeInBytes) {
                if (!otherProps.multiple) {
                    return { file };
                }
                files[file.name] = file;
            }
        }
        return { ...files };
    };

    const callUpdateFilesCb = files => {
        const filesAsArray = convertNestedObjectToArray(files);
        updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = e => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
    };

    const removeFile = fileName => {
        delete files[fileName];
        setFiles({});
        callUpdateFilesCb({});
    };

    useEffect(() => {
        if (otherProps.files) {
            setFiles(otherProps.files);
        }
    }, [otherProps.files]);

    const UploadedFile = props => {
        return (
            <div>
                <div className='panel-uploaded'>
                    <div className='content-uploaded'>
                        <div className='file-uploaded-icon'>
                            <div className='uploaded-featured-icon'>
                                <img
                                    className='file'
                                    alt='file-button'
                                    src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62fd3755a99ae40e7cadc620/img/file@2x.svg'
                                />
                            </div>
                        </div>
                        <div className='uploaded-details-panel'>
                            <div className='uploaded-details'>
                                <div className='uploaded-filename inter-medium-oxford-blue-14px'>
                                    {props.file.name}
                                </div>
                                <div className='uploaded-filesize inter-normal-pale-sky-14px'>
                                    2 MO
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='uploaded-checkbox-base'>
                        <img
                            className='uploaded-check'
                            alt='check-upload'
                            src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62fd3755a99ae40e7cadc620/img/check@2x.svg'
                        />
                    </div>
                </div>
                <div className='uploaded-button'>
                    <div className='uploaded-button-base border-1px-mischka'>
                        <div
                            className='uploaded-delete inter-medium-oxford-blue-14px'
                            onClick={() => removeFile(props.file.name)}>
                            Supprimer
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const UploadFile = props => {
        return (
            <div className='component-upload' onClick={handleUploadBtnClick}>
                <div className='label-upload inter-medium-oxford-blue-14px'>{label}</div>
                <div className='panel-upload'>
                    <div className='file-upload-base border-1px-gallery'>
                        <div className='content-upload'>
                            <div className='featured-icon'>
                                <img
                                    className='upload-cloud'
                                    alt='upload-cloud'
                                    src='https://anima-uploads.s3.amazonaws.com/projects/62ea3a460cb22e52877a2306/releases/62f63225612113ed342a99d0/img/upload-cloud@2x.svg'
                                />
                            </div>
                            <div className='text-upload'>
                                <div className='action-upload'>
                                    <div className='button-upload'>
                                        <div className='button-base-upload'>
                                            <div className='download-upload'>
                                                Cliquer pour télécharger
                                            </div>
                                        </div>
                                    </div>
                                    <div className='drag-upload inter-normal-pale-sky-14px'>
                                        ou drag’n’drop
                                    </div>
                                </div>
                                <p className='format-upload'>PNG, JPG ou PDF (max. 2MO)</p>
                            </div>
                        </div>
                        <input
                            type='file'
                            ref={fileInputField}
                            onChange={handleNewFileUpload}
                            id={props.id}
                            title=''
                            value=''
                            {...otherProps}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className='component-upload'>
            {Object.keys(files).length === 0 && <UploadFile />}
            {Object.keys(files).length !== 0 &&
                Object.keys(files).map((fileName, index) => {
                    let file = files[fileName];

                    return <UploadedFile key={index} file={file} removeFile={removeFile} />;
                })}
        </div>
    );

    /*
                <UploadFileBtn type='button' onClick={handleUploadBtnClick}>
                    <i className='fas fa-file-upload' />
                    <span> Upload {otherProps.multiple ? 'files' : 'a file'}</span>
                </UploadFileBtn>
                <FormField
                    type='file'
                    ref={fileInputField}
                    onChange={handleNewFileUpload}
                    title=''
                    value=''
                    {...otherProps}
                />
            </FileUploadContainer>
            <FilePreviewContainer>
                <span>To Upload</span>
                <PreviewList>
                    {Object.keys(files).map((fileName, index) => {
                        let file = files[fileName];
                        let isImageFile = file.type.split('/')[0] === 'image';
                        return (
                            <PreviewContainer key={fileName}>
                                <div>
                                    {isImageFile && (
                                        <ImagePreview
                                            src={URL.createObjectURL(file)}
                                            alt={`file preview ${index}`}
                                        />
                                    )}
                                    <FileMetaData isImageFile={isImageFile}>
                                        <span>{file.name}</span>
                                        <aside>
                                            <span>{convertBytesToKB(file.size)} kb</span>
                                            <RemoveFileIcon
                                                className='fas fa-trash-alt'
                                                onClick={() => removeFile(fileName)}
                                            />
                                        </aside>
                                    </FileMetaData>
                                </div>
                            </PreviewContainer>
                        );
                    })}
                </PreviewList>
            </FilePreviewContainer>
        */
};

export default FileUpload;
