import '../Organization/OrganizationList.css';
import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Sidebar from '../../Sidebar/Sidebar';

import DocumentDataService from '../../../../services/document.service';

export default function DocumentList() {
    const [data, setData] = useState([]);
    const { type } = useParams();

    const [typeUrl, setTypeUrl] = useState('');
    const [typeDocument, setTypeDocument] = useState('');

    const [currentIndex, setCurrentIndex] = useState(-1);

    useEffect(() => {
        switch (type) {
            case 'bv':
                setTypeUrl('payslip');
                setTypeDocument('Bon de versement');
                break;
            case 'c':
                setTypeUrl('studyagreement');
                setTypeDocument("Convention d'études");
                break;
            case 'am':
                setTypeUrl('studentmission');
                setTypeDocument('Acceptation de mission');
                break;
            case 'f':
                setTypeUrl('facture');
                setTypeDocument('Facture');
                break;
            default:
                console.log(`Sorry, we are out of ${type}.`);
        }
        const key = 'SELECT DocumentNote WHERE documenttype_name = "' + typeDocument + '"';
        const fields = 'id, org_name, name, documenttype_name, status, description';
        DocumentDataService.getAll(key, fields).then(response => {
            setData(response);
            console.log(response);
        });
    }, [type, typeUrl]);

    const handleAction = (e, id) => {
        console.log(id);
        setCurrentIndex(id);
    };

    const columns = [
        {
            name: 'Document',
            selector: row => row.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Association',
            selector: row => row.description.association.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Entreprise',
            selector: row => row.description.company.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Etudiant',
            selector: row => row.description.student.name,
            filterable: true,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => <button onClick={e => handleAction(e, row.id)}>Consulter</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <div className='organizationList screen'>
            <Sidebar />
            <div className='page-header'>
                <div className='content-9'>
                    <div className='text-and-supporting-text-1'>
                        <div className='text-8'>Documents</div>
                        <p className='supporting-text-1 inter-normal-pale-sky-16px'>
                            Cet onglet permet d'accéder à tous les documents
                        </p>
                    </div>
                </div>
                <DataTable columns={columns} data={data} />
                {currentIndex > 0 && (
                    <Navigate to={'/document/' + typeUrl + '/' + currentIndex} replace={true} />
                )}
            </div>
        </div>
    );
}
