import axios from 'axios';

const ROOT_URL = 'https://itop.jobers.fr/webservices/rest.php?version=1.3';

export async function loginUser(dispatch, loginPayload) {
    // CONVERT PAYLOAD TO JSON
    const data = new URLSearchParams();
    data.append('json_data', JSON.stringify(loginPayload));

    // PREPARE AUTH REQUEST
    const requestOptions = {
        method: 'post',
        url: ROOT_URL,
        auth: {
            username: 'admin',
            password: 'Jobers123*$',
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
        },
        data: data,
    };

    try {
        dispatch({ type: 'REQUEST_LOGIN' });

        let response = await axios(requestOptions);
        let data = await response.data;

        if (data.authorized) {
            await setUser(dispatch, loginPayload);
        }

        return data;

        dispatch({ type: 'LOGIN_ERROR', error: data.message });
        return;
    } catch (error) {
        console.log(error);
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}

export async function setUser(dispatch, payload) {
    // CONVERT PAYLOAD TO JSON
    const json_payload = {
        operation: 'core/get',
        class: 'Person',
        key:
            "SELECT Person AS P JOIN User AS U ON U.contactid = P.id WHERE U.login = '" +
            payload.user +
            "'",
        output_fields: 'friendlyname, email, function, org_id, token, joined_at',
    };

    const data = new URLSearchParams();
    data.append('json_data', JSON.stringify(json_payload));

    // PREPARE AUTH REQUEST
    const requestOptions = {
        method: 'post',
        url: ROOT_URL,
        auth: {
            username: 'admin',
            password: 'Jobers123*$',
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'application/json',
        },
        data: data,
    };

    try {
        let response = await axios(requestOptions);
        let data = await response.data;

        if (data.code == '0') {
            Object.keys(data.objects).forEach(key => {
                data.objects[key].fields.id = data.objects[key].key;
                localStorage.setItem('currentUser', JSON.stringify(data.objects[key].fields));
                return data.objects[key].fields;
            });
        }

        dispatch({ type: 'LOGIN_ERROR', error: data.message });
        return;
    } catch (error) {
        dispatch({ type: 'LOGIN_ERROR', error: error });
    }
}

export async function logout(dispatch) {
    dispatch({ type: 'LOGOUT' });
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
}
