import http from '../http-common';
import vars from '../config/vars';
const getAll = async (key, fields) => {
    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Location',
        output_fields: fields,
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        Object.keys(response.data.objects).forEach(key => {
            let currentLocation = {};
            Object.keys(response.data.objects[key].fields.persons_list).forEach(subkey => {
                currentLocation.id = response.data.objects[key].fields.id;
                currentLocation.name = response.data.objects[key].fields.name;
                currentLocation.address = response.data.objects[key].fields.address;
                currentLocation.postal_code = response.data.objects[key].fields.postal_code;
                currentLocation.city = response.data.objects[key].fields.city;
                currentLocation.country = response.data.objects[key].fields.country;
                currentLocation.status = response.data.objects[key].fields.status;
                objects.push(currentLocation);
            });
        });
    }

    return objects;
};
const get = async (key, fields) => {
    let returnValue = {};

    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'Location',
        output_fields: fields,
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if ((response.code = '0')) {
        Object.keys(response.data.objects).forEach(key => {
            returnValue.id = response.data.objects[key].fields.id;
            returnValue.name = response.data.objects[key].fields.name;
            returnValue.address = response.data.objects[key].fields.address;
            returnValue.postal_code = response.data.objects[key].fields.postal_code;
            returnValue.city = response.data.objects[key].fields.city;
            returnValue.country = response.data.objects[key].fields.country;
            returnValue.status = response.data.objects[key].fields.status;
        });
    }

    return returnValue;
};
const upsert = async (key, fields, output_fields) => {
    let returnValue = false;

    // BUILD POST PARAMS
    let data = {
        operation: 'core/create',
        comment: 'Création par Jobers',
        class: 'Location',
        fields: fields,
        output_fields: output_fields,
    };

    // UPDATE CASE
    if (key) {
        data.operation = 'core/update';
        data.key = key;
    }

    // BUILD PARAMS ARRAY
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // RETURN WRITTEN OBJECT
    Object.keys(response.data.objects).forEach(key => {
        returnValue = response.data.objects[key];
    });

    return returnValue;
};
const LocationDataService = {
    getAll,
    get,
    upsert,
};
export default LocationDataService;
