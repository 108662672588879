import React from 'react';
import Association from '../Icon/Association';
import Etudiant from '../Icon/Etudiant';
import Entreprise from '../Icon/Entreprise';
import CreerMission from '../Icon/CreerMission';
import Mission from '../Icon/Mission';
import Facture from '../Icon/Facture';
import BullVer from '../Icon/BullVer';
import Compta from '../Icon/Compta';

export const SidebarData = [
    {
        title: 'Associations',
        icon: <Association />,
        link: '/association/list',
    },
    {
        title: 'Etudiants',
        icon: <Etudiant />,
        link: '/etudiant/list',
    },
    {
        title: 'Entreprises',
        icon: <Entreprise />,
        link: '/entreprise/list',
    },
    {
        title: 'Offres',
        icon: <CreerMission />,
        link: '/offre/list',
    },
    {
        title: 'Missions',
        icon: <Mission />,
        link: '/mission/list',
    },
    {
        title: 'Factures',
        icon: <Facture />,
        link: '/facture/list',
    },
    {
        title: 'Bons de versement',
        icon: <BullVer />,
        link: '/bv/list',
    },
    {
        title: 'Comptabilité',
        icon: <Compta />,
        link: '/compta',
    },
];
