import '../Mission/MissionView.css';
import './OrganizationView.css';

import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { useAuthState } from '../../../../context/context';

import OrganizationDataService from '../../../../services/organization.service';
import Sidebar from '../../Sidebar/Sidebar';

const OrganizationView = props => {
    const [initialObjectState, setInitialObjectState] = useState(null);
    const currentUser = useAuthState();

    useEffect(() => {
        const fetchObject = async () => {
            let objectData = {
                id: null,
                name: 'Association test',
                school_id: 'Ecole',
                siret: '123 568 941 00056',
                tva: 'FR 32 123456789',
                urssaf: '3698',
                ape: '9499Z',
                bank: 'Société générale',
                iban: 'FR7630003035409876543210925',
                bic: 'SOGEFRPP',
                contact: 'Jean Doe',
            };
            if (typeof currentUser.id === '0') {
                setInitialObjectState(objectData);
            }
            if (currentUser.id !== '0') {
                let key =
                    'SELECT Association AS A JOIN Person AS P ON P.org_id = A.id WHERE P.id = ' +
                    currentUser.id;
                OrganizationDataService.get(key).then(response => {
                    objectData = {
                        ...objectData,
                        ...response,
                    };

                    objectData.contact = currentUser.userDetails;

                    setInitialObjectState(objectData);
                });
            }
        };

        if (!initialObjectState) {
            fetchObject();
        }
    }, [initialObjectState]);

    return (
        <div className='missionView organizationView screen'>
            <div className='flex-col'>
                <Sidebar />
            </div>
            <div className='flex-col'>
                <div className='flex-row'>
                    <div className='frame-1'>
                        {initialObjectState && (
                            <div className='frame-2' key={initialObjectState.picture.filename}>
                                <img
                                    width={'50'}
                                    height={'50'}
                                    src={
                                        'data:' +
                                        initialObjectState.picture.mimetype +
                                        ';base64,' +
                                        initialObjectState.picture.data
                                    }
                                />
                            </div>
                        )}
                        {initialObjectState && (
                            <div className='text-10'>{initialObjectState.name}</div>
                        )}
                    </div>
                    {initialObjectState && props.id === 'me' && (
                        <div className='actions'>
                            <div className='button'>
                                <div className='button-base-3'>
                                    <div className='text-12'>
                                        <Link to='/association/me/edit'>
                                            Modifier mes informations
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='section'>
                    <div className='container'>
                        <div className='section-header'>
                            <div className='content-1'>
                                <div className='text-and-supporting-text'>
                                    <div className='supporting-text inter-normal-pale-sky-14px'>
                                        Information relative à votre association
                                    </div>
                                </div>
                            </div>
                            <div className='missionDivider'></div>
                        </div>
                        <div className='form'>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>Nom</div>
                                {initialObjectState && (
                                    <div className='frame-29'>
                                        <div className='frame-27'>
                                            <div className='checkbox'>
                                                <p className='text-15 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='form'>
                            <div className='missionDivider'></div>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>École</div>
                                {initialObjectState && (
                                    <div className='frame-29'>
                                        <div className='frame-27'>
                                            <div className='checkbox'>
                                                <p className='text-15 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.school_id}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='missionDivider'></div>
                        <div className='form'>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>Contact</div>
                                <div className='frame-29'>
                                    <div className='frame-27'>
                                        <div className='checkbox-3'>
                                            {initialObjectState && (
                                                <div className='text-16 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.contact}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='missionDivider'></div>
                        <div className='form'>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>
                                    Numéro de SIRET
                                </div>
                                <div className='frame-29'>
                                    <div className='frame-27'>
                                        <div className='checkbox-1'>
                                            {initialObjectState && (
                                                <div className='text-16 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.siret}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='missionDivider'></div>
                        <div className='form'>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>
                                    Numéro de TVA intracommunautaire
                                </div>
                                <div className='frame-29'>
                                    <div className='frame-27'>
                                        <div className='checkbox-2'>
                                            {initialObjectState && (
                                                <div className='text-16 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.tva}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='missionDivider'></div>
                        <div className='form'>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>URSSAF</div>
                                <div className='frame-29'>
                                    <div className='frame-27'>
                                        <div className='checkbox-4'>
                                            {initialObjectState && (
                                                <div className='text-16 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.urssaf}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='missionDivider'></div>
                        <div className='form'>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>Code APE</div>
                                <div className='frame-29'>
                                    <div className='frame-27'>
                                        <div className='checkbox-4'>
                                            {initialObjectState && (
                                                <div className='text-16 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.ape}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='missionDivider'></div>
                        <div className='form'>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>Banque</div>
                                <div className='frame-29'>
                                    <div className='frame-27'>
                                        <div className='checkbox-4'>
                                            {initialObjectState && (
                                                <div className='text-16 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.bank}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='missionDivider'></div>
                        <div className='form'>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>IBAN</div>
                                <div className='frame-29'>
                                    <div className='frame-27'>
                                        <div className='checkbox-4'>
                                            {initialObjectState && (
                                                <div className='text-16 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.iban}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='missionDivider'></div>
                        <div className='form'>
                            <div className='content-2'>
                                <div className='text-1 inter-medium-oxford-blue-14px'>BIC</div>
                                <div className='frame-29'>
                                    <div className='frame-27'>
                                        <div className='checkbox-4'>
                                            {initialObjectState && (
                                                <div className='text-16 inter-normal-pale-sky-16px'>
                                                    {initialObjectState.bic}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-row'></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OrganizationView;
