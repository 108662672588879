// context/reducer.js
let user = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).friendlyname
    : '';
let email = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).email
    : '';
let userFunction = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).function
    : '';
let userId = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).id
    : '';
let orgId = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).org_id
    : '';
let joinedAt = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).joined_at
    : '';
let token = localStorage.getItem('currentUser')
    ? JSON.parse(localStorage.getItem('currentUser')).token
    : '';

export const initialState = {
    userDetails: '' || user,
    email: '' || email,
    function: '' || userFunction,
    org_id: '' || orgId,
    token: '' || token,
    joined_at: '' || joinedAt,
    id: 0 || userId,
    loading: false,
    errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
    switch (action.type) {
        case 'REQUEST_LOGIN':
            return {
                ...initialState,
                loading: true,
            };
        case 'LOGIN_SUCCESS':
            return {
                ...initialState,
                user: action.payload.friendlyname,
                email: action.payload.email,
                function: action.payload.function,
                token: action.payload.token,
                org_id: action.payload.org_id,
                joined_at: action.payload.joined_at,
                loading: false,
            };
        case 'LOGOUT':
            return {
                ...initialState,
                user: '',
                email: '',
                token: '',
                org_id: '',
                function: '',
                joined_at: '',
            };

        case 'LOGIN_ERROR':
            return {
                ...initialState,
                loading: false,
                errorMessage: action.error,
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
