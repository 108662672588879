import http from '../http-common';
import vars from '../config/vars';
const getAll = async key => {
    const aFields = [
        'id',
        'name',
        'status',
        'data',
        'mission_id',
        'mission_name',
        'mission_ref',
        'mission_terms',
        'mission_id_friendlyname',
        'mission_id_company_id',
        'mission_id_company_name',
        'mission_id_company_phone',
        'mission_id_company_email',
        'mission_id_student_id',
        'mission_id_student_phone',
        'mission_id_student_email',
        'mission_id_student_iban',
        'mission_id_student_firstname',
        'mission_id_student_name',
        'mission_id_student_org_id',
        'mission_id_student_org_name',
        'facture_id',
        'facture_id_friendlyname',
        'facture_status',
        'facture_date',
        'payment_id',
        'payment_id_friendlyname',
        'payment_status',
        'payment_date',
        'start_date',
        'end_date',
        'nb_je',
        'tva',
        'ht',
        'ttc',
        'net_student',
    ];
    // BUILD POST PARAMS
    const data = {
        operation: 'core/get',
        class: 'MissionTerm',
        output_fields: aFields.join(','),
        key: key,
    };
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE AND PREPARE RETURN ARRAY
    let response = await http.post(vars.url, params);
    let objects = [];

    // LOOP OVER FOUND OBJECTS
    if (response.data.code == '0' && response.data.objects) {
        Object.keys(response.data.objects).forEach(key => {
            let currentObject = {};
            currentObject.id = response.data.objects[key].key;
            currentObject.name = response.data.objects[key].fields.name;
            currentObject.status = response.data.objects[key].fields.status;
            currentObject.data = response.data.objects[key].fields.data;
            currentObject.mission_id = response.data.objects[key].fields.mission_id;
            currentObject.mission_name = response.data.objects[key].fields.mission_name;
            currentObject.mission_terms = response.data.objects[key].fields.mission_terms;
            currentObject.mission_ref = response.data.objects[key].fields.mission_ref;
            currentObject.mission_id_friendlyname =
                response.data.objects[key].fields.mission_id_friendlyname;
            currentObject.mission_id_company_id =
                response.data.objects[key].fields.mission_id_company_id;
            currentObject.mission_id_company_name =
                response.data.objects[key].fields.mission_id_company_name;
            currentObject.mission_id_company_phone =
                response.data.objects[key].fields.mission_id_company_phone;
            currentObject.mission_id_company_email =
                response.data.objects[key].fields.mission_id_company_email;
            currentObject.mission_id_student_id =
                response.data.objects[key].fields.mission_id_student_id;
            currentObject.mission_id_student_firstname =
                response.data.objects[key].fields.mission_id_student_firstname;
            currentObject.mission_id_student_name =
                response.data.objects[key].fields.mission_id_student_name;
            currentObject.mission_id_student_phone =
                response.data.objects[key].fields.mission_id_student_phone;
            currentObject.mission_id_student_email =
                response.data.objects[key].fields.mission_id_student_email;
            currentObject.mission_id_student_iban =
                response.data.objects[key].fields.mission_id_student_iban;
            currentObject.mission_id_student_org_id =
                response.data.objects[key].fields.mission_id_student_org_id;
            currentObject.mission_id_student_org_name =
                response.data.objects[key].fields.mission_id_student_org_name;
            currentObject.facture_id = response.data.objects[key].fields.facture_id;
            currentObject.facture_id_friendlyname =
                response.data.objects[key].fields.facture_id_friendlyname;
            currentObject.facture_status = response.data.objects[key].fields.facture_status;
            currentObject.facture_date = response.data.objects[key].fields.facture_date;
            currentObject.payment_id = response.data.objects[key].fields.payment_id;
            currentObject.payment_id_friendlyname =
                response.data.objects[key].fields.payment_id_friendlyname;
            currentObject.payment_status = response.data.objects[key].fields.payment_status;
            currentObject.payment_date = response.data.objects[key].fields.payment_date;
            currentObject.start_date = response.data.objects[key].fields.start_date;
            currentObject.end_date = response.data.objects[key].fields.end_date;
            currentObject.nb_je = response.data.objects[key].fields.nb_je;
            currentObject.tva = response.data.objects[key].fields.tva;
            currentObject.ht = response.data.objects[key].fields.ht;
            currentObject.ttc = response.data.objects[key].fields.ttc;
            currentObject.net_student = response.data.objects[key].fields.net_student;

            objects.push(currentObject);
        });
    }

    return objects;
};
const get = async key => {
    let returnValue = {};

    const aFields = [
        'id',
        'name',
        'status',
        'data',
        'mission_id',
        'mission_name',
        'mission_terms',
        'mission_ref',
        'mission_id_friendlyname',
        'mission_id_company_id',
        'mission_id_company_name',
        'mission_id_company_phone',
        'mission_id_company_email',
        'mission_id_student_id',
        'mission_id_student_phone',
        'mission_id_student_email',
        'mission_id_student_iban',
        'mission_id_student_firstname',
        'mission_id_student_name',
        'mission_id_student_org_id',
        'mission_id_student_org_name',
        'facture_id',
        'facture_id_friendlyname',
        'facture_status',
        'facture_date',
        'payment_id',
        'payment_id_friendlyname',
        'payment_status',
        'payment_date',
        'start_date',
        'end_date',
        'net_student',
    ];

    // BUILD POST PARAMS
    let data = {
        operation: 'core/get',
        class: 'MissionTerm',
        output_fields: aFields.join(','),
        key: key,
    };
    let params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // LOOP OVER FOUND OBJECTS
    if (response.data.code == '0' && response.data.objects) {
        Object.keys(response.data.objects).forEach(async key => {
            returnValue.id = response.data.objects[key].key;
            returnValue.name = response.data.objects[key].fields.name;
            returnValue.status = response.data.objects[key].fields.status;
            returnValue.data = response.data.objects[key].fields.data;
            returnValue.mission_id = response.data.objects[key].fields.mission_id;
            returnValue.mission_name = response.data.objects[key].fields.mission_name;
            returnValue.mission_terms = response.data.objects[key].fields.mission_terms;
            returnValue.mission_ref = response.data.objects[key].fields.mission_ref;
            returnValue.mission_id_friendlyname =
                response.data.objects[key].fields.mission_id_friendlyname;
            returnValue.mission_id_company_id =
                response.data.objects[key].fields.mission_id_company_id;
            returnValue.mission_id_company_name =
                response.data.objects[key].fields.mission_id_company_name;
            returnValue.mission_id_company_phone =
                response.data.objects[key].fields.mission_id_company_phone;
            returnValue.mission_id_company_email =
                response.data.objects[key].fields.mission_id_company_email;
            returnValue.mission_id_student_id =
                response.data.objects[key].fields.mission_id_student_id;
            returnValue.mission_id_student_firstname =
                response.data.objects[key].fields.mission_id_student_firstname;
            returnValue.mission_id_student_name =
                response.data.objects[key].fields.mission_id_student_name;
            returnValue.mission_id_student_phone =
                response.data.objects[key].fields.mission_id_student_phone;
            returnValue.mission_id_student_email =
                response.data.objects[key].fields.mission_id_student_email;
            returnValue.mission_id_student_iban =
                response.data.objects[key].fields.mission_id_student_iban;
            returnValue.mission_id_student_org_id =
                response.data.objects[key].fields.mission_id_student_org_id;
            returnValue.mission_id_student_org_name =
                response.data.objects[key].fields.mission_id_student_org_name;
            returnValue.facture_id = response.data.objects[key].fields.facture_id;
            returnValue.facture_id_friendlyname =
                response.data.objects[key].fields.facture_id_friendlyname;
            returnValue.facture_status = response.data.objects[key].fields.facture_status;
            returnValue.facture_date = response.data.objects[key].fields.facture_date;
            returnValue.payment_id = response.data.objects[key].fields.payment_id;
            returnValue.payment_id_friendlyname =
                response.data.objects[key].fields.payment_id_friendlyname;
            returnValue.payment_status = response.data.objects[key].fields.payment_status;
            returnValue.payment_date = response.data.objects[key].fields.payment_date;
            returnValue.start_date = response.data.objects[key].fields.start_date;
            returnValue.end_date = response.data.objects[key].fields.end_date;
            returnValue.net_student = response.data.objects[key].fields.net_student;
        });
    }

    return returnValue;
};
const upsert = async (key, fields, output_fields) => {
    let returnValue = false;

    // BUILD POST PARAMS
    let data = {
        operation: 'core/create',
        comment: 'Mise à jour par Jobers',
        class: 'MissionTerm',
        fields: fields,
        output_fields: output_fields,
    };

    // UPDATE CASE
    if (key) {
        data.operation = 'core/update';
        data.key = key;
    }

    // BUILD PARAMS ARRAY
    const params = new URLSearchParams();
    params.append('json_data', JSON.stringify(data));

    // STORE REQUEST RESPONSE
    let response = await http.post(vars.url, params);

    // RETURN WRITTEN OBJECT
    Object.keys(response.data.objects).forEach(key => {
        returnValue = response.data.objects[key];
    });

    return returnValue;
};
const MissionTermDataService = {
    getAll,
    get,
    upsert,
};
export default MissionTermDataService;
